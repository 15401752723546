.form .scale_range {
    margin: 0 auto;
    padding: 100px 30px 0;
  
    @media (min-width: 800px) {
      max-width: 60%;
    }
  }
  
  .input-range {
    margin-bottom: 50px;
    height: 1rem;
    position: relative;
    width: 100%;
    margin: 20px 0;
  }

  .input-range__slider {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background: #00b665;
    border: 1px solid #00b665;
    border-radius: 100%;
    cursor: pointer;
    display: block; 
    margin-left: -0.5rem;
    margin-top: -1.2rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out; 
    width: 32px;
    height:32px;
    color: #fff;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    line-height: 30px;
}
.input-range__slider::before {
  content: '|||';
}
.input-range__label-container::before{
  content:'\f156';
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
}
.selector.scale-range-question .input-range__label-container::before{
  display:none;
}
 
    .input-range__slider:active {
      -webkit-transform: scale(1);
              transform: scale(1); }
    .input-range__slider:focus {
      box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2); }
    .input-range--disabled .input-range__slider {
      background: #cccccc;
      border: 1px solid #cccccc;
      box-shadow: none;
      -webkit-transform: none;
              transform: none; 
    }
  
  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out; }
  
  .input-range__label {
    color: #aaaaaa;
    font-family: 'Muli', sans-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    white-space: nowrap; }
  
  .input-range__label--min,
  .input-range__label--max {
    bottom: -2rem;
    position: absolute; }
  
  .input-range__label--min {
    left: 20px; }
  
  .input-range__label--max {
    right: 20px; }
  
  .input-range__label--value {
    position: absolute;
    top: -1.8rem; 
    display:none;
  }
  
  .input-range__label-container {
    left: -50%;
    top:10px;
    position: relative; 
    font-weight: bold;
    color:#707d91;
    font-size: 14px; 
  }
  .input-range__label--max .input-range__label-container {
      left: 50%; 
  }
  @media (max-width:991px){
    .input-range__label--max .input-range__label-container{
      left:30%;
    }
  }
  
  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.4rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out; }
    .input-range--disabled .input-range__track {
      background: #eeeeee; }
  
  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%; }
  
  .input-range__track--active {
    background: #00b665; }
  
  .input-range {
    height: 1rem;
    position: relative;
    width: 100%; }
  
  /*# sourceMappingURL=react-input-range.css.map*/
  

  /* drag and drop */
/* 
  .App {
    max-width: 960px;
    margin: 10px auto;
  }
  
  .text-center {
    display: flex;
    height: 100%;
    justify-content: center;
    align-content: center;
    text-align: center;
  } */
  
  /* Dropzone */
  /* .dropzone {
    padding: 30px;
    height: 30vh;
    border: 5px dashed #7649bc;
    outline: none;
    cursor: pointer;
  }
  .dropzone-active {
    background-color: #f1e2ff;
    border: 5px solid #2fd7b5;
  }
  .dropzone-content {
    align-self: center;
    font-size: 24px;
  } */
  
  /* Images */
  .file-list {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  .file-item {
    width: calc(20% - 20px);
    height: 100px;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    margin: 10px;
    cursor: pointer;
    transition: all 0.2s linear;
  }
  .file-img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  /* Tablets */
  @media (max-width: 1000px) {
    .App {
      max-width: 600px;
    }
    .file-item {
      width: calc(25% - 22px);
      height: 100px;
    }
  }
  
  /* Mobiles */
  @media (max-width: 640px) {
    .file-item {
      width: calc(33% - 22px);
      height: 100px;
    }
  }

  /* test */
  .amit{
    background-color: #aaf1d1;
  }
  