.compliance {
	.radio {
		font-size: inherit;
		margin: 0;
		position: absolute;
		left: calc(1em + 2px);
		top: calc(1.3em + 2px);
		&:checked {
			~ {
				.payment-card {
					border-color: #1464dc;
					background: #f5f7fd;
				}
			}
		}
	}
	.title_box {
		border: 1px solid #e1e5eb;
		background: #fff;
		padding: 1.4rem 2rem;
		width: 100%;
		border-radius: 0.286rem;
		font-weight: 700;
		line-height: 1.714rem;
	}
	.custom_checkbox_blue {
		input {
			padding: 0;
			height: auto;
			width: auto;
			margin-bottom: 0;
			display: none;
			cursor: pointer;
			&:checked+label {
				&:after {
					content: "";
					display: block;
					position: absolute;
					top: 5px;
					left: 7px;
					width: 6px;
					height: 11px;
					border: solid #fff;
					border-width: 0 2px 2px 0;
					transform: rotate(45deg);
				}
			}
			&:checked {
				+ {
					label {
						&:before {
							content: "";
							background-color: #1464dc;
							border-color: #1464dc;
						}
					}
				}
			}
		}
		label {
			&:before {
				content: "";
				background-color: transparent;
				border: 2px solid #ddd;
				padding: 8px;
				border-radius: 5px;
				display: inline-block;
				position: relative;
				vertical-align: middle;
				cursor: pointer;
				margin-right: 8px;
				transition: background-color 0.3s ease-in-out;
				transition: border-color 0.1s ease-in-out;
			}
		}
	}
	.dropedown_input {
		.dropdown_menu {
			.dropdown_item {
				input {
					max-width: 120px;
					font-size: 14px;
					color: #050421;
					font-weight: 600;
					padding: 0.5rem;
					border-bottom: none;
				}
			}
			.dropdown_inner {
				background: #fff;
				padding: 0 6px;
			}
			left: -11px;
    		max-width: 100%;
		}
	}
	.upload_noc {
		padding: 1.4rem 0rem;
		background-color: #f5f7fd;
		width: 100%;
		border-radius: 0.286rem;
		font-weight: 700;
		font-size: 1.143rem;
		line-height: 1.714rem;
	}
	.cstm_checkbox {
		label {
			&:before {
				content: "";
				border-radius: 6px;
			}
		}
	}
	.userpost {
		font-size: 12px;
		color: #1464dc;
	}
	.gray-600 {
		color: #9393ab;
	}
	.font-weight-600 {
		font-weight: 600 !important;
	}
	.bg-disable {
		background: #c9ced7;
		color: #fff;
	}
	.input-group-text {
		padding: 0.375rem 0.75rem;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.5;
		color: #495057;
		background-color: transparent;
		border: 1px solid #e1e5eb;
		border-radius: 0.25rem;
		border-left: 0;
	}
	.input-group {
		>.form-control {
			&:not(:last-child) {
				border-radius: 6px;
				border-right: 0px;
				border-color: #e1e5eb;
				min-height: 46px;
			}
		}
	}
	.input-group-append {
		margin-left: -4px;
	}
	.payment-card {
		border: 1px solid #e1e5eb;
		border-radius: 3px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		padding: 16px;
		color: #9393ab;
		padding-left: 40px;
		height: 100%;
		border-radius: 6px;
		transition: border-color 0.2s ease-out;
		.plan-type {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #050421;
			margin-bottom: 5px;
		}
	}
	.card.flex-card {
		min-width: 372px;
		max-width: 372px;
		margin-right: 15px;
	}
	.formSelect {
		padding: 14px 16px;
		width: 118px;
		background: #fff;
		border: 1px solid #e1e5eb;
		border-radius: 6px;
		appearance: none;
		background: transparent;
		background-image: url("../../../Assets/images/Vector.svg");
		background-repeat: no-repeat;
		background-position-x: 90%;
		background-position-y: 23px;
	}
	.finance_form_inner {
		background: #f5f7fd;
		border-radius: 6px;
		padding: 15px;
	}
	.main-btn {
		padding: 12px 40px !important;
	}
	.disc {
		font-size: 5px;
		padding: 0 8px;
		line-height: 22px;
	}
	.select-arrow {
		top: 18px;
		right: 15px;
	}
	.select-options li {
		text-align: left;
	}
	.filter-dropdown {
		max-width: 120px;
		float: right;
		z-index: 999;
	}
	.select-options {
		top: auto;
		left: 75px;
		max-width: 130px;
	}
	// .modal.fade{
	// 	z-index: 99999;
	// } 
	.tool::before {
		transform: rotate(90deg) translateY(-0.3rem) translateX(1.2rem) !important;
	}
	.finance_form {
		.label {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #9393ab;
		}
		.form-control {
			border-color: #e1e5eb !important;
			min-height: 46px;
			height: auto;
			border-radius: 6px;
		}
		.form-check {
			padding-left: 2.3rem;
		}
		.form-check-label {
			line-height: 21px;
			position: relative;
		}
	}
	.modal-backdrop {
		background: rgba(5, 18, 39, 0.5) !important;
	}
	.modal-backdrop.show {
		opacity: 1 !important;
		display: block !important;
	}
	.modal-header {
		border-bottom: 1px solid #eff1f5 !important;
	}
	.modal-content {
		border: none !important;
	}
	.modal-title {
		font-size: 20px !important;
		font-weight: 700;
	}
	
	.modal.show {
		.modal-dialog {
			margin-top: 10%;
		}
	}
}
.modal-backdrop.show {
	opacity: 1 !important;
	display: block !important;
}
.modal-backdrop {
	background: rgba(5, 18, 39, 0.5) !important;
}
// .modal-backdrop.show.fade{
// 	z-index: 9999!important;
// }
@media (max-width: 767px) {
	.compliance {
		.card.flex-card {
			min-width: 100% !important;
			margin-right: 0px !important;
		}
		.modal-title {
			font-size: 16px !important;
			font-weight: 700;
		}
	}
}
@media (max-width: 1024px) {
	.compliance {
		.card.flex-card {
			min-width: 50%;
			margin-right: 15px;
		}
	}
}

@media (min-width: 992px){
	.compliance {
		.modal-lg {
			max-width: 1100px!important;
		}
	}
}
