.overview_proposal_recieved_wrapper{
        .user_profile_card {
            background-color: $bg-blue-200;
        }
  //     .border_right{
  //         position: relative;
  //       &::after{
  //         content: '';
  //         position: absolute;
  //         width:1px;
  //         height: 100vh;
  //         background-color:red;
  //       }
  // }
        // textarea
        .outer_textarea{
            padding-left:0rem;
            padding-right: 0rem;
        }
        .upload_notes{
            min-height: 3rem;
            background-color: #fff;
            border: 0.071rem solid $border-gray-600;
            border-radius:0.286rem;
            padding: 0.714rem;
            position: relative;
        
            .send_icon{
                position:absolute;
                // top:0.8rem;
                top:1.2rem;
                right: 0;
            }
            textarea{
                // max-height: 4rem;
                color:rgba($color: #050421, $alpha: 0.4);
                // padding-left: 2.5rem;
                padding-right: 2.5rem;
                min-height: 3rem; 
                max-height:calc(100vh - 900px);
                overflow-y: auto;
                background-color: transparent;
            }
            &.disable{
                background-color:$text-blue-100; 
                pointer-events: none;
            }
        }
        .upload_media_icons{
            padding:.375rem .75rem;
        }
        // view proposal
         .view_proposal{
            // max-height: 550px;
            // min-height: 550px;
            // height: calc(100vh - 381px);
            height: calc(100vh - 233px);
            overflow-y: auto;
        }
        .view_proposal::-webkit-scrollbar {
            width: 0.313rem;
        }
        .view_proposal::-webkit-scrollbar-track {
            background-color :$bg-white;
        }
        .view_proposal::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
        // commet_section
        .commet_section{
            // max-height: 550px;
            // min-height: 550px;
            // height: calc(100vh - 381px);
            height: calc(100vh - 291px);
            overflow-y: auto;
        }
        .commet_section::-webkit-scrollbar {
            width: 0.313rem;
        }
        .commet_section::-webkit-scrollbar-track {
            background-color :$bg-white;
        }
        .commet_section::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    }