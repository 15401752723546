.view_brief{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .view_brief_container{
        padding:2rem;
       
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
      
            .proposal_date{
                .date_input{
                    position: relative; 
                    .date_icon{
                        position:absolute;
                        top: 37%;
                        right: 8%;
                    }

                }
            }
            // cards
            .view_proposal_card{
                background-color: $bg-blue-200;
                border: 0px;
                box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
                border-radius: 0.429rem;
                position: relative;
                .card_tag{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            .description{
                font-size: 0.857rem;
                font-weight: 600;
                line-height: 1.071rem;
                color:$text-gray-1300;
            }
            
            .url_input{
                position: relative;
                padding: 0rem 0rem;
                color:$text-blue-800;
                text-decoration: underline;
                ::placeholder{
                    text-decoration: none;
                }
            }
           
            .import{
                background: rgba($bg-gray-300, 0.15);
                border-radius:0.429rem;
                padding: 0.9rem;
            }
            .project_description {
              .description{
                    color: rgba($text-black-700, 0.5);
                    font-size: 1rem;
                    font-weight: 600;
              }
                
            }
            // tabs
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1rem 1rem;
                margin-right: 3rem;
                font-size: 1.1rem;
                font-weight: 600;
                color:$text-gray-1300;
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 
            //dropedownbox
            .dropedown_container.show{
            right: 0px;
            }
            .dropedown_container{
                .btn:focus {
                    outline: 0;
                    box-shadow:none;
                }
                .dropdown-item{
                    padding:0.6rem 1rem;
                }
            }
            .dropedown_icon{
                display: block;
                margin-bottom: 0.3rem;
            }
            .dropdown-menu{
                border-radius: 0.571rem;
                box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
            }
            .dropdown-item{
                padding:0.6rem 1rem;
            }
            .dropdown-menu.show{
                top: -33px !important;
                left: -133px !important;
            }
          
        
    }
} 