.creator_active_brief_chat_room_empty{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .creator_active_brief_chat_room_empty_container{
        padding:2rem;
        padding-bottom: 0px;
       
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
      
            
            
           
            .description{
            font-size: 0.857rem;
            font-weight: 600;
            line-height: 1.214rem;
            color:$text-gray-1300;
            }
             // nav tabs
             .pills_tab_nav{
                .nav-link.active{
                  color:$text-blue-800;
                  background-color: $bg-white;
                }
                .nav-link{
                  color:$text-gray-1300;
                  font-weight: 700;
                  font-size: 1.143rem;
                  background-color: transparent;
                  padding: 0.714rem 2.43rem;
                  border-radius: 0.429rem; 
                }

            }


            // chat_costom_tabs
            
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1rem 1rem;
                margin-right: 1rem;
                margin-left:1rem;
                font-size: 1.143rem;
                font-weight: 600;
                color:rgba($color: $text-gray-1300, $alpha: 100);
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 


            // chat_outer_container
            .chat_body{
              
              padding-top: 3rem;
              padding-bottom: 3rem;
            //   min-height: calc(100vh - 229.47px - 4rem - 163px);
            //   max-height:calc(100vh - 229.47px - 4rem - 163px);
            min-height: calc(100vh - 325px);
            max-height:calc(100vh - 325px);
              overflow-y:auto ;
              
            }
            .empty_chat_box{
                display: flex;
                align-items: center;
                justify-content: center; 
            }
            .user_profile_image{
                width: 50px;
                height: 50px;
                background-color:$bg-blue-200;
                border: 0.071rem solid $border-gray-300;
                // text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .company_profile_image{
                width: 50px;
                height: 50px;
                background-color:$bg-blue-200;
                border: 0.071rem solid $border-gray-300;
                // text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;

                img{
                    width:100%;
                    height:100%;
                    object-fit: none;
                }
            }
            .user_company_profile_image{
                    // width: 20px;
                    // height: 20px;
                    background-color:$bg-blue-200;
                    // border: 0.071rem solid $border-gray-300;
                    width: 30px;
                    height: 30px;
                    overflow: hidden;
                    line-height: 18px;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }

            }
            .user_company_profile_name{
                // width: 20px;
                // height: 20px;
                background-color:transparent;
                // border: 0.071rem solid $border-gray-300;
                width: 30px;
                height: 30px;
                overflow: hidden;
                line-height: 18px;
            img{
                width:100%;
                height:100%;
                object-fit:none;
            }

            }
            .more_users_profile{
                // width: 20px;
                // height: 20px;
                background-color:$bg-purple-300;
                // border: 0.071rem solid $border-gray-300;
                width: 30px;
                height: 30px;
                overflow: hidden;
                line-height: 18px;
                border: 0.071rem solid #F5F7FD;
                margin-left: -0.8rem;
                display:flex;
                align-items: center;
                justify-content: center;
            img{
                width:100%;
                height:100%;
                object-fit:none;
            }

            }
            .user_info{
                display: flex;
                flex-direction: column;
                flex: auto;
            }

             .Conversation_card{
                cursor: pointer;

                
             }
             .Conversation_card.active{
                background-color: $bg-blue-100;
            }
            
            .conversation_card_inner{
                border-bottom:0.071rem solid rgba($color:$border-gray-400, $alpha: 0.2) ;
            }
            .conversation_body{
                // max-height: calc(100vh - 250px);
                max-height: calc(100vh - 258.06px - 3rem - 18.38px - 30px);
                overflow-y: auto;
            }



            .user_uploads{
                flex-wrap: wrap;
                align-items: baseline;
            }
            .pdf_upload{
                color: #6C7A8F;
                background-color: rgba(20, 100, 220, 0.1);
                align-items: center;
                border-radius: 0.286rem;
                padding: 0.9rem 1.5rem;
                font-weight: 400;
                font-size: 0.857rem;
                cursor: pointer;
                display:flex;
                margin-right: 1rem;
                margin-bottom: 1rem;
                justify-content: space-around;
                width: 150px;
                // grid-template-columns: auto auto auto;
                // grid-gap: 10px;
                // grid-template-rows: auto;
                // grid-auto-flow: row;
            }
            .image_tag{
                width: 150px;
                height: 100px;
                background-color: blue;
                margin-right: 1rem;
                margin-bottom: 1rem;
                border-radius: 0.143rem;
                img{
                    object-fit: cover;
                    width: 150px;
                    height: 100px;
                }
            }
        // body_scroll_bar
        .body_scroll_bar::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll_bar::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll_bar::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }



        // teatarea
        .outer_textarea{
            padding-left:1rem;
            padding-right: 1rem;
        }
        .upload_notes{
            min-height: 10.143rem;
            background-color: #fff;
            border: 0.071rem solid $border-gray-600;
            border-radius:0.286rem;
            padding: 0.714rem;
            position: relative;
            .send_icon{
                position:absolute;
                top:0.8rem;
                right: 0;
            }
            .dander_icon{
                position:absolute;
                top:0.8rem;
                left: 20px;
            }
            textarea{
                // max-height: 4rem;
                color:rgba($color: #050421, $alpha: 0.4);
                padding-left: 2.5rem;
                padding-right: 2.5rem;
                min-height: 5.5rem; 
                max-height:calc(100vh - 900px);
                overflow-y: auto;
            }
        }
        .upload_media_icons{
            padding:.375rem .75rem;
        }
    }
            
} 