.worklog_extension_rejection_wrapper{
    .modal-body{
        padding-right: 1.5rem;
        padding-left: 1.5rem;
    }
    // Custom Input
    .cstm_checkbox{
        position: static;
        top: unset;
        right: unset;
        text-align: right;
    }
    .cstm_checkbox input{
        padding:0;
        height:initial;
        width: initial;
        display: none;
        cursor:pointer;
    }
    .cstm_checkbox label{
        position: relative;
        cursor: pointer;
        color:$text-black;
        font-size:1.1rem;
        margin-bottom:0rem;
    }
    .cstm_checkbox label:before{
        content:'';
        background-color: transparent;
        border:0.1rem solid $text-gray-300;
        padding:11px;
        border-radius: .5rem;
        display:inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        // margin-right: 15px; 
        margin-right: 0rem;
        transition: background-color 0.3s ease-in-out;
    }
    .cstm_checkbox input:checked + label::after{
        content:'';
        display: block;
        position: absolute;
        top: 5px;
        left: 8px;
        width: 8px;
        height: 12px;
        border:solid $bg-white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media(max-width:$breakpoint-md){
            top:3px;
        }
    }
    .cstm_checkbox input:checked + label::before{
        content:'';
        background-color: $bg-blue-800;
        border-color: $bg-blue-800;
    }
    .upload_notes{
        min-height: 10.143rem;
        background-color: #fff;
        border: 0.071rem solid $border-gray-500;
        border-radius:0.426rem;
        padding: 0.714rem;
    }
    
}