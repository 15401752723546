// select_Revlent_project
.upload_milestone{
  
         
        .modal_top_header {
         padding: 1.5rem 2.7rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }

        .proposal_header{
            padding:1rem 1rem; 
            min-height: 4.9rem;
        }
  
        .proposal_date{
            .date_input{
                position: relative; 
                .date_icon{
                    position:absolute;
                    top: 37%;
                    right: 8%;
                }

            }
        }
        .upload_milestone_media{
            background-color:$bg-blue-200; 
            padding: 1.5rem 2rem;
            min-height: calc(100vh - 325px);
            max-height:calc(100vh - 325px);
            overflow-y:auto ;


            .upload_meliston_box{
                position: relative;
                .upload_box {
                    min-height: 12.857rem;
                    border: 2px dashed rgba(20, 100, 220, 0.5);
                    border-radius: 6px;
                    background-color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .upload_milestone_sub_title{
                    position: absolute;
                    bottom: 1rem;
                    margin-bottom: 0px;
                    left: calc(50% - 114.03px);
                }
    
            }  
        } 


        // End Circle Modal CSS

        .small_image_preview{
            width:2.5rem;
            height:2.5rem;
            border-radius: 0.200rem;
            overflow: hidden;
        }
        .small_icon_preview{
            width:2.5rem;
            height:2.5rem;
            border-radius: 0.500rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color:$bg-gray-500;
            overflow: hidden;
        }

        .end_cycle_modal_wrapper{
            background-color:$bg-blue-200; 
            padding: 0 0rem 3rem 0;
            min-height: calc(100vh - 300px);
            max-height:calc(100vh - 300px);
            overflow-y:auto ;
            
            .end_cycle_upload_inner{  
                .small_image_preview{
                    width:2.5rem;
                    height:2.5rem;
                    border-radius: 0.200rem;
                    overflow: hidden;
                }
                .small_icon_preview{
                    width:2.5rem;
                    height:2.5rem;
                    border-radius: 0.500rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color:$bg-gray-500;
                    overflow: hidden;
                }
            }
        } 
        
        // cards
        .view_proposal_card{
            background-color: $bg-blue-200;
            border: 0px;
            box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
            border-radius: 0.429rem;
            position: relative;
            .card_tag{
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .description{
            font-size: 0.857rem;
            font-weight: 600;
            line-height: 1.071rem;
            color:$text-gray-1300;
        }
        
        .url_input{
            position: relative;
            padding: 0rem 1rem;
            color:$text-blue-800;
            background-color: transparent;
            // text-decoration: underline;
            ::placeholder{
                text-decoration: none;
            }
        }

        .upload_files_checkbox{
            display: flex;
            align-items: center;

            .cstm_checkbox{
                position:static;
                top:unset;
                right:unset;

                label{
                    margin-bottom:0;

                    &:before{
                        border-radius: 0.289rem;
                        padding:0.675rem;
                    }
                }

            }
            .cstm_checkbox input:checked + label::after{
                top:5px;
                left:7px;
            }
        }
       
        .input_content{
            position:absolute;
            top:34%;
            left:0%;

            &.error_input_image{
                top:15px;
            }
        } 
        .add_url button{
            background: transparent;
            border: none;
            position: absolute;
            top: 32%;
            right: 2%;
            font-size: 0.857rem;
            font-weight: 700;
            color:$text-blue-800;
            &.error_button{
                top:15px;
            }
        }
        .add_on_milestone{
                
            color:$bg-blue-800;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.289rem;
            .add_on_milstone_icon{
                margin-right:0.8rem;
                font-size: 1.5rem;
            }
        }
       
        .import{
            background: rgba($bg-gray-300, 0.15);
            border-radius:0.429rem;
            padding: 0.9rem;
        }
        .project_description {
          .description{
                color: rgba($text-black-700, 0.5);
                font-size: 1rem;
                font-weight: 600;
          }
            
        }
      
        
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }

        .view_document_wrapper{
            padding:2rem;
            background-color:$bg-blue-200;
            border-bottom-left-radius: 0.675rem;
            border-bottom-right-radius: 0.675rem;
        }
        .zoom_icons{
            position:absolute;
            left:4%;
            bottom:4%;

            .zoom_in,
            .zoom_out{
                padding: 0.675rem;
                background:$bg-white;
                border-radius: 0.675rem;
                cursor: pointer;
            }
            span{
                padding:0.675rem 0;
                display: block;
            }
        }
    
    }
