.sucess{
    // modal_width
    .sucess_modal_dialog{
        max-width:calc(100vw - 100px);
        @media(min-width:$breakpoint-sm){
            max-width: calc(100vw - 150px); 
        }
        @media(min-width:$breakpoint-lg){
            max-width: calc(100vw - 700px); 
        }
        @media(min-width:$breakpoint-xxl){
            max-width: calc(100vw - 900px); 
        } 
        
    }
    .sucess_modal_body{
        // margin: 4rem 2rem;
        margin: 0rem 0rem;
        padding: 3rem 3rem;
    }
    .sucess_title{
        margin-bottom: 2rem;
    }
    .sucess_msg{
        line-height: 1.714rem;
        margin-bottom: 2rem;
    }
    .btn_sucess{
        padding: 0.75rem 3.8rem;
    }
}
