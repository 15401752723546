.profile li{
    list-style:none;
}

/* .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
  } */
  
  /* .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  } */
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;                            
    bottom: 0px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  /* input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  } */
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/***********home new ************/
.projects{
  border-bottom:1px solid silver;
}
.projects li{
  list-style:none;
  padding:12px;
  border-bottom: 2px solid #3F8839;
  display:inline-block;
  font-size:12px;
}
.search-pro .form-control{
  font-size:14px !important;
}
.milestone-img{
  font-size:30px;
  color:#3F8839;
}
.pro-draft{
  font-size:12px;
  color:gray;
}
.draft-grappus{
  background-color:white;
  border-radius:5px;
 
}
.invite-creator{
  font-size:12px;
  color:black;
  font-weight:bold;
}
.draft-left{
  padding:20px 40px;
}
.creator-img{
  border-radius:50%;
  height:20px;
  width:20px;
  background:red;
}
.update-icon{
  color:#3F8839;
}
.rejection{
  color:gray;
}
.dash-p{
  font-weight:bold;
}
.dash-user{
  font-size:10px;
  color:gray;
}
.star-rating{
  font-size:12px;
  color:#3F8839;
  margin-right:5px;
}
.popular-services{
  border:1px solid silver;
  border-radius:10px;
}
.service-image{
  height:100px;
  margin-top:15px;
}
.service-image img{
  height:100px;
  object-fit:cover;
  border-radius:5px;
}
.view-all{
  font-size:12px;
  margin:0 auto;
  color:blue;
}
.upcoming-milestone{
  border:1px solid silver;
  border-radius:10px;
}
.grappus-p{
  color:black;
  font-size:12px;
}
.milestone-grappus{
  border-top:1px solid silver;
  font-weight:bold;
}
.mile-counting{
  background-color: orchid;
  font-size: 10px;
  border-radius: 2px;
  color:white;
}
.inc-briefs{
  background: white;
  border-radius: 10px;
}
.breifs-right{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: silver;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breifs-right:hover{
  background: #3F8839;
  color: white;
}
.briefs-pic{
  height: 30px;
  width: 30px;
  background: red;
  border-radius: 50%;
}
.briefs-name{
  color: black;
  font-size: 12px
}
.briefs-text{
  color:gray;
  font-size: 12px
}



/********profile********/
.profile li{
  list-style:none;
}

/********switch*******/
/* .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 20px;
} */

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;                            
  bottom: 0px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

/* input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/********switch*******/

.pro-pic li{
  height: 45px;
  width:45px;
  border-radius:50%;
  background:silver;
  list-style:none;
}

.pro-pic li img{
  border-radius:50%;
}
.sidebar-profile{
  box-shadow: 0px 0px 4px 0px silver;
  padding : 10px;
  border-radius : 5px; 
}
.company-detail{
  box-shadow: 0px 0px 4px 0px silver;
  padding : 10px;
  border-radius : 5px;
}


.del-acc{
  font-size:12px;
  color : gray;
}

.del-acc{
  font-size:12px;
  color : gray;
}

.pro-req{
  border-top:1px solid silver;
  padding-top:10px;
  padding-bottom:10px;
}
.sub-text{
  font-size:12px;
  color:gray;
}
.basic-icon{
  font-size: 24px;
  color: gray;
}
.account-label{
  font-size: 14px;
}

.company-detail{
  box-shadow: 0px 0px 4px 0px silver;
  padding : 10px;
  border-radius : 5px;
}
.user-detail{
  box-shadow: 0px 0px 4px 0px silver;
  padding : 10px;
  border-radius : 5px; 
}
.text-container {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.clearBtn {
  position: absolute;
  top: 0;
  right: -15px;
  transition: right 0.2s;
  background:red;
}
.show {
  right: 5px;
}
.collateral-info{
  background-color: darkmagenta;
  border-radius: 5px;
  color: white;
}
.collateral-info li{
  font-size: 10px;
  display: inline-block;
  padding-left: 20px;
}
.collater-month{
  visibility: hidden;
}
.work-experience{
  border-radius: 5px;
}
.work-experience li{
  font-size: 10px;
  display: inline-block;
  padding-left: 20px;
}
.experience-form{
  border-radius: 5px;
}

/* Ajit */

.collateralImageListing {
  background-position: center right; 
  background-size: 120px; 
  background-repeat: no-repeat; 
  width: 130px; 
  height: 52px; 
  border-radius: 8px; 
  -webkit-border-radius: 8px; 
  -moz-border-radius: 8px; 
  -ms-border-radius: 8px; 
  -o-border-radius: 8px; 
  position: relative;
}
.collateralImageListing::before{
  content:'';
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
}

.pointer {
  cursor: pointer;
}

/* SSK css */
/* Country code dropdown */
.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: inherit !important;
}

.react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag {
  background-color: inherit !important;
}

.react-tel-input .form-control {
  font-family: 'Muli';
}

/* Date picker customisation */
.react-date-picker__wrapper {
  border: none !important;
}
.react-date-picker__calendar{
  bottom:auto !important;
  top:0px !important;
  z-index:9999;
}

.react-date-picker__inputGroup__input:invalid {
  background-color: inherit !important;
}

.react-date-picker__inputGroup__input {
  font-weight: 600;
  /* color: grey; */
}

.react-date-picker__inputGroup__leadingZero {
  font-weight: 600;
}
.creator-busy .react-date-picker__calendar{
  left:-25px !important;
  top:100% !important;
  width:320px !important;
}
.creator-busy .react-date-picker{
  background: #f8fafe;
  border: 1.5px solid #e8ecf4;
  min-height:45px;
}
.apniClass.creator-busy-datapicker .react-calendar__tile{
  padding:12px 10px !important;
}
.apniClass.react-calendar{
  border: 1px solid #eee !important;
  border-radius: 5px !important;
  box-shadow: 0px 2px 10px rgba(0,0,0,.06) !important;
}
.apniClass .react-calendar__navigation__arrow{
  font-size:20px !important;
}
.apniClass .react-calendar__navigation button,
.apniClass .react-calendar__month-view__days__day--weekend,
.apniClass .react-calendar button{
  color: #6b7a90 !important; 
}
.apniClass .react-calendar__tile:enabled:hover, 
.apniClass .react-calendar__tile:enabled:focus{
  background:#f3f2f2 !important; 
  color:#000 !important; 
  border-radius: 100% !important;
}
.apniClass .react-calendar__month-view__weekdays__weekday{
  color: #c1c1c1 !important; 
}
.react-date-picker__inputGroup__input:focus{
  outline:none !important;
}
.apniClass .react-calendar__tile{
  padding:16px 10px !important; 
  color: #6b7a90 !important; 
}
.apniClass .react-calendar__tile--now{
  background: #6b7a90 !important;
  color:#fff !important; 
}
.apniClass .react-calendar__navigation__label{
  font-size:16px !important;
  font-weight:600 !important;
}
.apniClass .react-calendar__navigation button:hover, 
.apniClass .react-calendar__navigation button:focus{
  background:#f3f2f2 !important;
  border-radius: 100%;
}
.react-date-picker__button:enabled{
  outline:none !important;
}

.apniClass .react-calendar__tile--active {
  background: #f3f2f2 !important;
  color:#000 !important;
  border-radius:100% !important;
}
.apniClass .react-calendar__navigation__label:hover,
.apniClass .react-calendar__navigation__label:focus,
.apniClass .react-calendar__tile.react-calendar__year-view__months__month:hover,
.apniClass .react-calendar__tile.react-calendar__year-view__months__month:focus,
.apniClass .react-calendar__tile.react-calendar__decade-view__years__year:hover,
.apniClass .react-calendar__tile.react-calendar__decade-view__years__year:focus{
  border-radius: unset !important;
}
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__next-button:hover,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__next-button:focus,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__prev-button:hover,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__prev-button:focus,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__next2-button:hover,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__next2-button:focus,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button:hover,
.apniClass .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button:focus{
  border-radius: unset !important;
}
.mt-15px {
  margin-top: 15px !important;
}

.pd-left-15px {
  padding-left: 15px !important;
}

.mt-left-15px {
  margin-left: 15px;
}


.popularServices {
  background-position: center right; 
  background-size: 120px; 
  background-repeat: no-repeat; 
  width: 130px; 
  height:52px; 
  border-radius: 8px; 
  -webkit-border-radius: 8px; 
  -moz-border-radius: 8px; 
  -ms-border-radius: 8px; 
  -o-border-radius: 8px; 
  position: relative;
}
.popularServices::before{
  content:'';
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.3);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
}
.collateral_text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 220px;
}

.inComingBrief {
  width: 2948px; 
  /* transform: translate3d(-884px, 0px, 0px);  */
  transition: all 0s ease 0s;
 }

button.react-calendar__tile.react-calendar__century-view__decades__decade {
  height: 50px;
}
.apniClass .react-calendar__tile.react-calendar__century-view__decades__decade:hover,
.apniClass .react-calendar__tile.react-calendar__century-view__decades__decade:focus{
  border-radius: unset !important;
}
button.react-calendar__tile.react-calendar__year-view__months__month {
  height:50px;
  flex-basis: 25% !important;
  max-width: 25% !important;
  padding: 0px 0px !important;
}

button.react-calendar__tile.react-calendar__decade-view__years__year {
  height: 50px;
  flex-basis: 20% !important;
  max-width: 20% !important;
}
.react-calendar__tile--hasActive{
  background:#f3f2f2 !important;

}

/* featured collatral */

.packages .user-upload-content-work {
  height: 140px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
/* ************ */


.earning_info .tool.tooltip-bottom::after {
  top: 114%;
  bottom: unset;
}

.earning_info .tool.tooltip-bottom::before {
  top: 97%;
  transform: rotate(180deg);
}

/* disabled dashboard css */

.disbled-user- {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 8%;
}

.disbled-user- img {width: 100%;max-width: 250px;}

/* ************** */
.user-survey .btn-scale.active {
  background: #10c89b;
  color: #ffffff;
}


/* loader for active brief modal right to slide */

.modal-body.loader-position-cu01 {
  display: flex;
  align-items: center;
}

.modal-body.loader-position-cu01 .text-center {
  top: unset!important;
}