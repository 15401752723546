.sidebar{
    position:fixed;
    top:0;
    left:0;
    height:100%;
    width:4.875rem;
    background:$bg-black-700;
    padding:0.375rem 0.875rem;
    z-index:9999; 
    transition:all 0.5s ease; 

    .logo_content .logo{
        color:$text-white;
        display: flex; 
        width:100%;
        height:auto; 
        padding:2rem 1rem 1.5rem;
        align-items: center;
        opacity: 1; 
        cursor: pointer;
        transition:all 0.1s ease; 

        .logo_icon{
            height:auto;
            min-width: 2.5rem; 
            width: 22%; 

            img{
                width:35px;
                height:35px;
            }
        }
        .logo_text{
            width:75%;
            margin-left:5%;

            img{
                max-width: 75%;
            }
        } 
      } 
     
    #btn{
        position:absolute;
        color:$text-white;
        top:0.9rem;
        right:0;
        font-size: 1.375rem;
        height:1.8rem;
        width:1.8rem;
        text-align: center;
        line-height: 1.8rem;
        transform: translateX(-50%);
        cursor: pointer;
      }
    .nav_list{  
        overflow-y: visible;
        height: calc(100vh - 230px);
    }
    // nav_list_scrollbar
    .nav_list::-webkit-scrollbar {
        width: 0.313rem;
    }
    .nav_list::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .nav_list::-webkit-scrollbar-thumb {
        border-radius:0.375rem;
        background-color:$border-gray-400;
    }
    
     ul{
        margin-top:1.25rem;
      }
     ul li{
        position:relative;
        height:3.125rem;
        width:3.125rem;
        margin:0 0 0.500rem;
        list-style: none;
        line-height: 3.125rem; 
    }
    ul li .tooltip{
        position:absolute;
        left:7.813rem;
        top:0;
        transform:translate(-50%, -50%);
        border-radius: 0.375rem;
        height:2.188rem;
        width:7.5rem;
        color:$text-white;
        background:$bg-navy-blue-500;
        border:0.063rem solid $bg-gray-400;
        line-height:2.188rem;
        text-align: center;
        box-shadow: 0 0.313rem 0.625rem rgba(0,0,0,0.2);
        transition:0s;
        opacity:0;
        pointer-events: none;
        display:block; 
      }
    ul li .tooltip{
        &::before{
            content:'';
            width:0;
            height:0;
            border-top:0.625rem solid transparent;
            border-bottom:0.625rem solid transparent;
            border-right:0.625rem solid $bg-navy-blue-500;
            position:absolute;
            left:-0.5rem;
            top:0.438rem;
          }
    }
    
    ul li:hover .tooltip{
        transition:all 0.5s ease;
        opacity:1;
        top:50%;
      } 
    ul li a{
        color:$text-white;
        display:flex;
        align-items:center;
        text-decoration: none;
        border-radius: 0.75rem;
        white-space: nowrap;
        transition:all 0.4s ease; 
    }
      
    ul li a{ 
        &.active,
        &:hover{
            // color:$text-primary;
            background:$bg-navy-blue-500;
        }  
    }
    ul li .icon_list{
        text-align: center;
        margin-right:0.6rem;   

        img{
            font-size: 1rem;
            font-weight: 400;
            height:1.8rem;
            min-width: 1.8rem;
            max-width: 1.8rem;
            border-radius: 0.75rem;
            line-height: 1.8rem;
            text-align: center;
        }
      } 
    .links_name{
        font-size: 0.938rem;
        font-weight: 400;
        opacity:0;
        pointer-events: none;
        transition:all 0.3s ease;
      }
    
    .user_profile_wrapper{
        li{
            margin-bottom:0;
        }
        .user_profile_border_bottom{
            width: 100%;
            height: 0;
            margin:0.500rem 0;
            line-height: 0;
            border-bottom:0.063rem solid $bg-gray-100;
        }
    } 
    .sidebar_bottom{
        // position:absolute;
        color:$text-white;
        // bottom:0rem;
        // left:0;
        width:100%;
    }
    .sidebar_bottom .user_img{
        position:relative;
        padding:0.625rem 0.375rem 0.625rem 1.8rem;
        height:3.75rem;
        background: none; 
        transition:all 0.4s ease;  
      }
    
    .sidebar_bottom .user_img .user_details{
        display:flex;
        align-items:center;
        opacity:0;
        pointer-events: none;
        white-space: nowrap;
        transition:all 0.4s ease;
      }
    
    .sidebar_bottom .user_img .user_details img{
        height:2.3rem;
        width:2.3rem;
        object-fit: cover;
        border-radius: 1.8rem;
        cursor: pointer;
      }
    .name_job{
        margin-left:0.7rem;
    }
    .user_details .name{
        font-size: 0.938rem;
        font-weight: 400;
      }
    .user_details .job{
        font-size: 0.75rem; 
      }
    #log_out{
        position:absolute;
        bottom:0.313rem;
        left:50%;
        transform:translateX(-50%);
        min-width: 3.125rem;
        line-height: 3.125rem;
        font-size: 1.25rem;
        border-radius: 0.75rem;
        text-align: center;
        transition:all 0.4s ease;
        cursor: pointer;

        &:hover{  
            background:$bg-navy-blue-500;
        } 
      }
      .mini_logo,
      .expand_logo{
        opacity: 0;
        display: none;
      }

    // Mini_Sidebar_Active
    &.mini_active{
        ul li a{ 
            padding:0 0.6rem;
        }
        .logo_content .logo{
            // padding: 4rem 0.6rem 1.5rem;
            padding: 2rem 0.6rem 1.5rem 0.3rem;
        }   
        .logo_content .logo .mini_logo{
            opacity: 1;
            display: inline-block;
        }
    }

    
    //   Expand_Sidebar_active
    // &.expand_in_active{
    //     width:15rem;

    //     .logo_content .logo .expand_logo{
    //         // opacity:1; 
    //         display: inline-block;
    //         animation:show 800ms forwards 0.1s;

    //         @keyframes show{
    //             0%{
    //                 opacity: 0; 
    //             }
    //             25%{
    //                 opacity: 0.25; 
    //             }
    //             50%{
    //                 opacity: 0.5; 
    //             }
    //             75%{
    //                 opacity: 0.75; 
    //             }
    //             100%{
    //                 opacity: 1; 
    //             }
    //         }
    //     }
    //     #btn{
    //         top:0.4rem;
    //         left:90%;
    //         width:2.2rem;
    //         height:2.2rem;
    //     }
    //     ul li{ 
    //         height:auto;
    //         width:100%; 
    //     }
    //     ul li a{ 
    //         padding:0 1rem;
    //     }
    //     ul li .tooltip{
    //         display:none;
    //     }
    //     .links_name{
    //         transition:0s;
    //         opacity:1;
    //         pointer-events: auto;
    //     }
    //     .content .user_img{
    //         background:$bg-gray-400;
    //     }
    //     .user_img .user_details{
    //         opacity:1;
    //         pointer-events: auto;
    //     }
    //     .user_img #log_out{
    //         left:90%; 
    //       }
    // }    


    &:hover{
        width:15rem;

        .logo_content .logo .mini_logo{
            opacity: 0;
            display:none;
        }
        .logo_content .logo .expand_logo{
            // opacity:1; 
            display: inline-block;
            animation:show 100ms forwards 0.1s;

            @keyframes show{
                0%{
                    opacity: 0; 
                }
                25%{
                    opacity: 0.25; 
                }
                50%{
                    opacity: 0.5; 
                }
                75%{
                    opacity: 0.75; 
                }
                100%{
                    opacity: 1; 
                }
            }
        }
        #btn{
            top:0.4rem;
            left:90%;
            // width:2.2rem;
            // height:2.2rem;
        }
        ul li{ 
            height:auto;
            width:100%; 
        }
        ul li a{ 
            padding:0 1rem;
        }
        ul li .tooltip{
            display:none;
        }
        .links_name{
            transition:0s;
            opacity:1;
            pointer-events: auto;
        }
        .content .user_img{
            background:$bg-gray-400;
        }
        .user_img .user_details{
            opacity:1;
            pointer-events: auto;
        }
        .user_img #log_out{
            left:90%; 
          }
    }
  }
   