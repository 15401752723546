// botton 
@mixin button ($textColor: $text-white, $backgroundColor: $bg-primary, $size:0.75rem 1rem) {
    display: inline-block;
    width: fit-content;
    padding:$size;
    border-radius: 0.4rem;
    background-color:$backgroundColor;
    color:$textColor;
    cursor: pointer;
    transition:all 400ms ease; 

    &:hover,
    &:focus{
        background-color:lighten($backgroundColor, 4%);
        color:$textColor;  
        outline:none;
        box-shadow:none;
    }
}
@mixin solid_button ($textColor: $text-white, $backgroundColor: $bg-primary, $size:0.75rem 1rem) {
    display: inline-block;
    width: fit-content;
    padding:$size;
    border-radius: 0.4rem;
    background-color:$backgroundColor;
    color:$textColor;
    cursor: pointer;
    transition:all 400ms ease; 

    &:hover,
    &:focus{
        // background-color:lighten($backgroundColor, 100%);
        color:$textColor;  
        outline:none;
        box-shadow:none;
    }
}
@mixin button_link ($textColor: $text-white, $size:0rem, $backgroundColor:transparent) {
    display: inline-block;
    width: fit-content;
    padding:$size;
    border-radius: 0.4rem;
    background-color:$backgroundColor;
    color:$textColor;
    cursor: pointer;
    &:hover,
    &:focus{
        background-color:lighten($backgroundColor, 4%);
        color:$textColor;  
        outline:none;
        box-shadow:none;
    }
}
@mixin button_outline ($size:0.75rem 1rem, $backgroundColor:$bg-white,$textColor: $text-gray-1300, $bdr_color:$border-gray-400,$hoverbackgroundColor:$bg-gray-700,$hovertextColor:$text-white, $f_size:1rem, $weight:700) {
    display: inline-block;
    width: fit-content;
    padding:$size;
    border-radius: 0.4rem;
    background-color:$backgroundColor;
    color:$textColor;
    outline: 1px solid $bdr_color;
    font-size: $f_size;
    font-weight: $weight;
    cursor: pointer;
    transition:all 400ms ease; 

    &:hover,
    &:focus{
        background-color:lighten($hoverbackgroundColor, 4%);
        color:$hovertextColor;
        box-shadow:none;
    }
}

// Content Overflow
@mixin content_overflow($height:300px, $maxHeight:300px, $paddingBottom:40px){
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - $height);
    max-height: calc(100vh - $maxHeight);
    padding-bottom: $paddingBottom;
}


// main page
@mixin main_page{
    margin-left: 5rem;
    background-color: $bg-blue-200;
    // min-height: 100vh;
}
// font style
@mixin font($size:1rem, $fontcolor:$text-gray-1400, $weight:600){
    font-size:$size;
    color:$fontcolor;
    font-weight: $weight;
}
// modal_body_scroll
@mixin modal_body_scroll($mnheight:auto, $mxheight:calc(100vh - 250px)){
    min-height: $mnheight;
    max-height: $mxheight;
    overflow-y: auto;
    overflow-x: hidden;
}
// border
@mixin border_bottom($size:0.071rem, $color:$border-gray-600){
    border-bottom: $size solid $color;
}
@mixin border_top($size:0.071rem, $color:$border-gray-300){
    border-top: $size solid $color;
}
@mixin border($size:0.071rem, $color:$border-gray-600){
    border: $size solid $color;
}
// modal_title
@mixin modal_title($textcolor:$text-black-700){
    font-size: 1.429rem;
    font-weight: 700;
    line-height: 1.714rem;
    color:$textcolor;
}
// modal_close_btn
@mixin modalclose-btn-icon($bgcolor:$bg-blue-100){
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.1rem 0.6rem 0.3rem!important;
        border-radius: 0.426rem;
        height: 100%;
        color: $bg-blue-800;
        background-color:$bgcolor!important;
        font-size: 1.8rem;
}
@mixin modal_bg($color:$bg-black-100, $opacity:50%){
    background-color: rgba( $color, $opacity) ;
}
@mixin modal_box($radius:0.429rem){
    border-radius: $radius;
}
@mixin box_title($bgcolor:$bg-blue-200, $size:1.4rem 2rem){
    padding: $size;
    background-color:$bgcolor;
    width: 100%;
    border-radius:0.286rem;
    font-weight: 700;
    font-size: 1.143rem;
    line-height: 1.714rem;
}

@mixin from_input_sm{
    border: 1px solid $text-gray-1000;
    min-height: 3.286rem;
    height:3.286rem;
    // padding: 0.375rem 1.2rem;
    // min-width: 9.571rem;
    // width:9.571rem;
    font-size: 1rem;
    font-weight: 600;
    color: $text-black-700;
    border-radius: 0.429rem;
}
@mixin from_input_md($min_h:3.286rem, $height:3.286rem, $radius:0.429rem, $size:1rem){
    border: 1px solid $text-gray-1000;
    min-height:$min_h;
    height:$height;
    border-radius:$radius;
    padding:$size;
}
@mixin input_sm_height($min_height:4rem, $height:4rem){ 
    min-height:$min_height;
    height:$height; 
}
@mixin input_md_width($min_width:12rem, $width:12rem){ 
    min-width:$min_width;
    width:$width; 
}





// featured_project_next_and_previous_btn_background 
@mixin btn_bg_box($bg_color:$text-white){
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.371rem 0.771rem;
    background-color: $bg_color;
    border-radius: 0.429rem;
} 
// featured_upload_box
@mixin upload_image_box($color:$bg-blue-800, $opacity:50%,$bgcolor:$bg-blue-300 ){
    min-height: 12.857rem;
    height:100%;
    border: 2px dashed rgba($color,$opacity);
    border-radius: 6px;
    background-color:$bgcolor;
    display: flex;
    align-items: center;
    justify-content: center;
}
@mixin upload_milestone_image_box($color:$bg-blue-800, $opacity:50%,$bgcolor:$bg-white, $size: 2.786rem 6.5rem 6.5rem 6.5rem){
    min-height: 12.857rem;
    border: 2px dashed rgba($color,$opacity);
    border-radius: 6px;
    background-color:$bgcolor;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    padding: $size;
}
// feature_upload_icon_box  
@mixin upload_icon_box($bgcolor:$bg-white, $bgopacity:100%, $size:1.786rem 1.071rem, $bdr_size:0px, $color:$border-white,$opacity:100%, $radius:0.714rem ){
    background-color:rgba($bgcolor,$bgopacity);
    padding: $size;
    align-items: center;
    justify-content: center;
    border: $bdr_size solid rgba($color,$opacity);
    border-radius:$radius;
    display: flex;
    flex-direction: column;
}


// scrolllbar
@mixin scrollbars($size :1px, $foreground-color:$border-gray-400, $radius:6px, $background-color: transparent) {
  ::-webkit-scrollbar {
      height: $size;
      width: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
      border-radius: $radius;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

}

// sidebar
@mixin active_nav_item($bg-color:$bg-navy-blue-500){
    // background-color: $bg-color;
    background-color: rgba($color: $bg-color, $alpha: 40%);
    // padding: 0.4rem;
    padding-top:0.4rem;
    padding-bottom: 0.4rem;
    border-radius: 0.375rem;
    color:$text-white;
}

// end_sidebar
 
// page_card
@mixin page_card_box($bg_color:$bg-white, $opacity:100%, $color:$text-black, $radius: 0.429rem){
    background-color:rgba($bg_color, $opacity);
    color:$color;
    border-radius:$radius;
}
//tabs
@mixin tab_active{
    border: 0px;
    border-bottom: 4px solid $bg-blue-800 ;
    padding: 1rem 0rem;
    margin-right: 3rem;
    
    }

 @mixin nav_link_tab{
    padding: 1rem 0rem;
    margin-right: 3rem;
 }

 @mixin discription($font_size:0.857rem, $weight:600,$height:1.4rem,$color:$text-gray-1300){
    font-size: 0.857rem;
    font-weight:600;
    line-height: $height;
    color:$color;
 }
 @mixin delete_active_box($position:absolute, $radius:0rem, $color:$bg-white, $opacity:100%){
     position:$position;
    bottom:0%;
     left: 0%;
     width: 100%;
     height: 35px;
     border-radius:$radius;
     background-color:rgba($color,$opacity);
 }
 @mixin bg_box($bgcolor:$bg-white, $radius:0.3rem, $size:1.3rem,$position:absolute, $top:calc(50% - 30px), $left:50%){
     background-color:$bgcolor;
     border-radius: $radius;
     padding: $size;
     position:$position;
     top: $top;
     left: $left;
     transform: translate(-50%, -50%);
     cursor: pointer;
 }
 @mixin trash_transparent($bgcolor:transparent, $radius:0.3rem, $size:0rem,$position:absolute, $top:50%, $right:0%){
    background-color:$bgcolor;
    border-radius: $radius;
    padding: $size;
    position:$position;
    top: $top;
    right: $right;
    transform: translate(-19%, -50%);
}
 @mixin Upload_image_title($size:0rem, $top:50%, $left:0%) {
    padding: $size;
    position: absolute;
    top: $top;
    left: $left;
    transform: translate(12%, -50%);
 }
 @mixin url_input_box($position:$relative){
     position:$position;
 }
 @mixin link_icon($position:relative, $left:2%, $top:35%){
     position: $position;
     left: $left;
     top: $top;
 }

//  tags
 @mixin tag_gray_btn($color_text:$text-gray-1300,$color:$bg-white, $opacity:100%,$size: 0.429rem 0.789rem, $border:$border-gray-400, $fsize:0.857rem){
    color:$color_text;
    background-color: rgba( $color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.429rem;
    padding: $size;
    font-weight: 600;
    border: 1px solid $border;
    font-size: $fsize;
    }
@mixin tag_round_btn_common($textcolor:$text-black-700,$color:$bg-white,$opacity:100%, $radius:50%, $size:1.429rem 1.429rem,$height:45px, $width:45px,$bdrwidth:1px, $bordercolor:$border-gray-500, $fontsize:0.8rem,$weigth:600) {
    color:$textcolor;
    background-color: rgba($color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius:$radius;
    padding: $size;
    height:$height;
    width:$width;
    border:$bdrwidth solid $bordercolor;
    font-size: $fontsize;
    font-weight: $weigth;
}  
@mixin tag_btn($color_text:$bg-blue-800,$color:$bg-blue-800, $opacity:10%,$size: 0.429rem 0.789rem, $radius:0.286rem,$fweight:600, $fsize:0.857rem){
    color:$color_text;
    background-color: rgba( $color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    padding: $size;
    font-weight: $fweight;
    font-size: $fsize;
    }
@mixin tag_btn_tab($color_text:$bg-blue-800,$color:$bg-blue-800, $opacity:10%,$size: 0rem, $radius:0.286rem,$fweight:600, $fsize:1rem, $mheight:1.5rem, $mwidth:1.5rem){
    color:$color_text;
    background-color: rgba( $color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    padding: $size;
    font-weight: $fweight;
    font-size: $fsize;
    min-height: $mheight;
    min-width: $mheight;
    }  
// badges
    
// pills_tag_btn
@mixin pills_tag_btn($color_text:$text-primary,$color:$bg-drak-green-500, $opacity:10%,$size:0.129rem 0.8rem, $radius:9.071rem,$fweight:600, $fsize:0.857rem){
    color:$color_text;
    background-color: rgba( $color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    padding: $size;
    font-weight: $fweight;
    font-size: $fsize;
    }

// badge
@mixin badge_btn($color_text:$text-white,$color:$bg-blue-800, $opacity:100%,$size: 0.329rem 0.389rem, $radius:0.386rem,$fweight:600, $fsize:0.857rem){
    color:$color_text;
    background-color: rgba( $color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $radius;
    padding: $size;
    font-weight: $fweight;
    font-size: $fsize;
    line-height: 0.071rem;
    }

@mixin badge_gray_1300_btn($color_text:$text-gray-1300,$color:$bg-white, $opacity:100%,$size: 0.5rem 0.789rem, $border:$bg-gray-500, $fsize:0.857rem){
    color:$color_text;
    background-color: rgba( $color, $opacity);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.289rem;
    padding: $size;
    font-weight: 600;
    border: 1px solid $border;
    font-size: $fsize;
    }

    
// anchor tag
@mixin anchor_text($color:$text-blue-800,$weight:700, $size:1rem, $decoration:none, $h_decoration:underline,$h_color:$text-blue-800 ){
    color: $color !important;
    font-weight:$weight;
    font-size: $size;
    cursor:pointer;
    text-decoration: $decoration;
    &:hover{
        text-decoration: $h_decoration;
        color:$h_color
    }
}   
  

// pills_tabs
// @mixin pils_tabs($sizex:2rem, $sizey:3rem, $bg_color:$bg-blue-800){
//     padding: $sizex $sizey;
//     background-color:$bg_color;
// }
@mixin textarea_placeholder($color:#53637B, $opacity:0.5%, $weight:600, $size:1rem){
    color:rgba( $color, $opacity);
    font-weight: $weight;
    font-size: $size;
}

// online cricle 
@mixin online_circle($wsize:10px, $hsize:10px, $bgcolor:$bg-blue-800, $brsize:1px, $brcolor:$border-white, $br-radius:50%){
    width:$wsize;
    height:$hsize;
    background-color:$bgcolor;
    border: $brsize solid $brcolor;
    border-radius: $br-radius;
}

// profile_image
@mixin profile_image_commn($width:100px, $height:100px, $object:cover, $radius:50%){
    width:$width;
    height:$height;
    background-color:$bg-blue-100;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    overflow: hidden;
    img{
        width:100%;
        height:100%;
        object-fit: $object;
        border-radius: $radius;
        vertical-align: inherit;
    }
   
}
@mixin cards_profile_image_commn($width:25px, $height:25px, $brcolor:$text-gray-1000, $object:contain, $radius:50%, ){
    width:$width;
    height:$height;
    background-color:$bg-blue-100;
    border-radius: 50%;
    border: 0.071rem solid $brcolor;
    img{
        width:100%;
        height:100%;
        object-fit: $object;
        border-radius: $radius;
    }
   
}
@mixin profile_image_outline($size:0.071rem, $color:$border-gray-300){
    border:$size solid $color;
    background-color: transparent;
    img{
        object-fit: cover;
    }
}

// tab_pills
@mixin nav_link_btn_common($size:1rem 0.5rem,$bg_color:$bg-blue-200, $color:$text-black-700,$active_bgcolor:$bg-blue-800,$active_color:$text-blue-100  ){
    padding:0.9rem 0.5rem;
    background-color: $bg_color;
    color:$color;
    border-radius: 0.429rem; 
    // margin-right:1rem;
    &:active{
        padding:0.9rem 0.5rem !important;
        background-color:$active_bgcolor !important;
        color:$active_color !important;
    }
}
@mixin nav_pills_link_common($size:1rem 2rem, $bg_color:$bg-blue-200,$color:$text-black-700,$f_size:0.857rem,$weight:600, $active_bg_color:$bg-blue-800, $active_color:$text-blue-100){
    padding:$size;
    background-color: $bg_color;
    color:$color;
    font-size: $f_size;
    font-weight:$weight;
    border-radius: 0.429rem !important;  
    margin-left:1rem;
    white-space: pre;

    &:active{
        background-color: $active_bg_color !important ;
        color: $active_color !important;
    }
    &:hover{
        color:$color;
    }
}
// danger container
@mixin danger_container_commn($bdr_color:$bg-red-500,$color:$bg-red-500,$opacity:10%,$size:2rem){
    border:0.071rem solid $bdr_color;
    background-color: rgba( $color, $opacity);
    border-radius: 0.289rem;
    padding:$size;
}

// overflow height
// @mixin overflow_height( $mxheight:734px, $height:140px){
//     height: calc(100vh - $height);
//     max-height: calc(100vh - $mxheight);
//     height: $height;
// }
@mixin overflow_height( $mxheight:734px, $height:auto){
    // height: calc(100vh - $height);
    max-height: calc(100vh - $mxheight);
    height: $height;
}


