.client_manage_participants{
    // .invite_participant{
    //     border: 0.071rem solid $border-gray-500;
    //     border-radius: 0.429rem;
   
    // .invite_input{
    //     position: relative;
    //     .invite_btn{
    //         position:absolute;
    //         right: 0rem;
    //         bottom: 0rem;
    //         top:0rem;
    //         .btn{
    //             background:$bg-blue-600;
    //             color:$bg-blue-800;
    //             font-size: 1rem;
    //             font-weight: 700;
    //             border:0rem;
    //             border-top-left-radius: 0.357rem;
    //             border-bottom-left-radius: 0.357rem;
    //             border-top-right-radius: 0.429rem;
    //             border-bottom-right-radius: 0.429rem;
                
    //             .btn:focus{
    //                 background:$bg-blue-600 !important;
    //                 color:$bg-blue-800 !important;
    //             }
    //         }

    //     }
    // }
    // }
    .invite_participant{
        border: 0.071rem solid $border-gray-500;
        border-radius: 0.429rem;
        position: relative;
        input{
            border-radius: 0.429rem;
            line-height: 30px;
        }
        
            .btn{
                background:$bg-blue-600;
                color:$bg-blue-800;
                font-size: 1rem;
                font-weight: 700;
                border:0rem;
                border-top-left-radius: 0.357rem;
                border-bottom-left-radius: 0.357rem;
                border-top-right-radius: 0.429rem;
                border-bottom-right-radius: 0.429rem;
                line-height: 30px;
               
            }

     
 
    }
}