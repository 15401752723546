@media (max-width: 3000px) {
  /*-- Chat View Proposal --*/
  .custom_review_proposal .modal-lg,
  .custom_review_proposal .modal-xl {
    max-width: 1124px;
  }

  /* Modal Screen Width */
  .screen-2k-900 {
    max-width: 900px;
  }
}

@media (max-width: 1920px) {
  .web-links {
    margin-left: 130px;
  }

  .review_milestone_modal .modal-lg,
  .review_milestone_modal .modal-xl {
    max-width: 1024px;
  }

  .lightbox-modal .modal-lg,
  .lightbox-modal .modal-xl {
    max-width: 1024px;
  }

  /*=== Chat Input ===*/
  .chat-summernote {
    width: 75%;
  }

  .submit_btn {
    right: 4.5%;
    bottom: 30%;
  }

  /*=== Unread Msg ===*/
  .unread_msg {
    display: inline-block;
  }
}

@media (max-width: 1680px) {
  /*-- Chat View Proposal --*/
  .custom_review_proposal .modal-lg,
  .custom_review_proposal .modal-xl {
    max-width: 1024px;
  }

  /* Modal Screen Width */
  .screen-2k-900 {
    max-width: 900px;
  }

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -45px;
  }

  .web-links {
    margin-left: 130px;
  }

  .overflow-content.edit-collateral-height.mid_height {
    max-height: 500px;
  }

  .screen-col-20 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .screen-col-30 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .screen-col-70 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  /*=== Chat Input ===*/
  .chat-summernote {
    width: 70%;
  }

  .submit_btn {
    right: 2.5%;
    bottom: 30%;
  }

  /*=== Unread Msg ===*/
  .unread_msg {
    display: inline-block;
  }
}

@media (max-width: 1440px) {
  /*-- Screen size width --*/
  .screen-small-col-18 {
    flex: 0 0 18%;
    max-width: 18%;
  }

  .screen-small-col-22 {
    flex: 0 0 22%;
    max-width: 22%;
  }

  .laptop-font-16 {
    font-size: 16px !important;
  }

  /*-- Upload a Project --*/
  /* .uploading-image .upload-thumbnail .upload-thumb {
    width: 100px;
  } */

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -30px;
  }

  .right_sidebar .dropdown-menu {
    left: -50px !important;
  }

  /*=== Unread Msg ===*/
  .unread_msg {
    display: inline-block;
  }
}

@media (max-width: 1400px) {
  /*=== Unread Msg ===*/
  .unread_msg {
    display: none;
  }

  /*-- Landing Page --*/
  .landing-banner-image {
    background-position: center -80px;
  }

  .services_card .service-box-logo {
    width: 310px;
    height: calc(310px / 1.3333);
  }

  /* New Profile Selection CSS */
  .profile_selection_wrapper .card_works img {
    width: 350px !important;
    height: calc(350px / 1.4);
  }

  .profile_selection_wrapper .card_details .client_logo img {
    width: 28px;
    height: 28px;
  }

  .profile_selection_wrapper .card_works .view_profile {
    padding: 0px;
  }
}

@media (max-width: 1366px) {
  .overflow-content.edit-work-height.awards {
    max-height: 380px;
  }

  .overflow-content.basic-height.mid_height {
    max-height: 380px;
  }

  .overflow-content.edit-collateral-height.mid_height {
    max-height: 380px;
  }

  .custom_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl {
    max-width: 1024px;
  }

  .forget_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl {
    max-width: 800px;
  }

  .custom_form .mob_background_image {
    display: none;
  }

  .custom_form .remove-pd {
    display: block;
  }

  /*-- Margin laptop ---*/
  .laptop-mb-90 {
    margin-bottom: 90px !important;
  }

  /*-- ipad & laptop Margin -- */
  .laptop-minus-30 {
    margin-left: -30px;
  }

  /*-- Padding --*/
  .laptop-p-0 {
    padding: 0px !important;
  }

  .laptop-px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .laptop-pr-0 {
    padding-right: 0px !important;
  }

  .laptop-pl-60 {
    padding-left: 60px !important;
  }

  /*-- Laptop Width -- */
  .laptop-w-100 {
    width: 100% !important;
  }

  .laptop-w-30 {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .laptop-w-70 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  /*-- Dashboard Submit -- */
  .dashboard-content .dashboard-welcome {
    padding: 20px 15px;
  }

  .dashboard-content .dashboard-welcome h2 {
    font-size: 20px;
  }

  .web-links {
    margin-left: 125px;
  }

  /*-- Upload a project --*/
  .upload-project-big-thumbnail img {
    height: 300px;
  }

  .uploading-image .upload-thumbnail {
    grid-gap: 15px 30px;
  }

  /* .uploading-image .upload-thumbnail .upload-thumb {
    width: 100px;
  } */

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -25px;
  }

  .add-collateral-underline {
    width: 80%;
  }

  /* New Profile Selection CSS */
  .profile_selection_wrapper .card_works img {
    width: 350px;
    height: calc(350px / 1.52);
  }
}

@media (max-width: 1300px) {
  .web-links {
    margin-left: 115px;
  }

  /* Scope of work */
  .deadline-project .deadline {
    height: 270px;
  }

  /*== Services Page ==*/
  .services_card .service-box-logo {
    width: 300px;
    height: calc(300px / 1.3333);
  }

  /*-- Chat Lightbox modal --*/
  .overflow-content.overflow-lightbox {
    padding-bottom: 100px;
  }

  /* New Profile Selection CSS */
  .profile_selection_wrapper .card_details {
    width: 40%;
  }

  .profile_selection_wrapper .card_works {
    width: 60%;
  }

  .profile_selection_wrapper .card_works img {
    width: 350px;
    height: calc(350px / 1.52);
  }

  .profile_selection_wrapper .card_details .client_logo img {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 1280px) {
  /*-- Width Classes --*/
  .mid-laptop-w-50 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mid-laptop-w-70 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .mid-laptop-w-20 {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .mid-laptop-w-80 {
    flex: 0 0 80%;
    max-width: 80%;
  }

  .web-links {
    margin-left: 115px;
  }

  /*-- Landing Page --*/
  .landing-banner-image {
    background-position: center -50px;
  }

  /*=== Services Page ===*/
  .services_card .service-box-logo {
    width: 300px;
    height: calc(300px / 1.3333);
  }

  /*-- Upload a Project --*/
  .uploading-image .upload-thumbnail {
    grid-gap: 15px 20px;
  }

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -15px !important;
  }
}

@media (max-width: 1260px) {
  /*== Services Page ==*/
  .services_card .service-box-logo {
    width: 275px;
    height: calc(275px / 1.3333);
  }
}

@media (max-width: 1152px) {
  .web-links {
    margin-left: 100px;
  }

  /*== Services Page ==*/
  .services_card .service-box-logo {
    width: 270px;
    height: calc(270px / 1.3333);
  }

  /*-- Upload a Project --*/
  .uploading-image .upload-thumbnail {
    grid-gap: 15px 15px;
  }

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -18px !important;
  }

  .laptop-w-38 {
    -ms-flex: 0 0 38.333333%;
    flex: 0 0 38.333333%;
    max-width: 38.333333%;
  }

  .laptop-w-61 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 61.666667%;
    max-width: 61.666667%;
  }

  /* New Profile Selection CSS */
  .profile_selection_wrapper .card_works img {
    width: 350px;
    height: calc(350px / 1.52);
  }

  /* Job Section CSS */
  .dashboard_right_sidebar {
    display: none !important;
  }
}

@media (max-width: 1030px) {
  .lightbox-modal .modal-lg,
  .lightbox-modal .modal-xl {
    max-width: 900px;
  }

  .review_milestone_modal .modal-lg,
  .review_milestone_modal .modal-xl {
    max-width: 1000px;
  }

  .custom_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl l {
    max-width: 960px;
  }

  .custom_form .social_icon {
    padding: 50px 0px;
  }

  .custom_form .mob_background_image {
    display: none;
  }

  .custom_form .remove-pd {
    display: block;
  }

  .wrapper-form .input-icon.icon-right {
    right: 233px;
  }

  /*-- Onboarding Css -- */
  .mob-hidden,
  .mobile-footer-fixed {
    display: none;
  }

  .desktop-hidden {
    display: block;
  }

  .ipad-pro-mobile-show {
    display: block !important;
  }

  .mob-justify-content-between {
    justify-content: space-between !important;
  }

  .mob_display_none,
  .desktop_ipadPro_display_none {
    display: none;
  }

  /*-- text align --*/
  .ipad-pro-text-center {
    text-align: center;
  }

  .ipad-pro-text-left {
    text-align: left !important;
  }

  /*-- width css --*/
  .ipad-pro-w-100 {
    width: 100% !important;
  }

  /*-- Small Laptop Width --*/
  .small-laptop-w-42 {
    -ms-flex: 0 0 42%;
    flex: 0 0 42%;
    max-width: 42%;
  }

  .small-laptop-w-50 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .small-laptop-w-58 {
    -ms-flex: 0 0 58%;
    flex: 0 0 58%;
    max-width: 58%;
  }

  .small-laptop-w-40 {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .small-laptop-w-60 {
    flex: 0 0 60%;
    max-width: 60%;
  }

  .small-laptop-w-70 {
    flex: 0 0 70%;
    max-width: 70%;
  }

  .small-laptop-w-100 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mob-col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .ipadPro-w-100 {
    width: 100% !important;
  }

  .ipad-minus-15 {
    margin-left: -15px;
  }

  .ipad-minus-30 {
    margin-left: -30px;
  }

  /*-- Padding --*/
  .ipadPro-p-15 {
    padding: 15px !important;
  }

  .ipad-p-0 {
    padding: 0px !important;
  }

  .ipad-pro-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ipad-pl-0 {
    padding-left: 0px !important;
  }

  .ipad-pr-0 {
    padding-right: 0px !important;
  }

  .ipad-pro-only-p-10 {
    padding: 10px !important;
  }

  .ipad-pro-pt-15 {
    padding-top: 15px !important;
  }

  .ipad-pl-15 {
    padding-left: 10px !important;
  }

  .ipad-pl-60 {
    padding-left: 60px !important;
  }

  .ipad-p-30 {
    padding: 30px 15px !important;
  }

  .ipad-p-50 {
    padding: 50px !important;
  }

  .ipad-py-24 {
    padding: 24px 0px !important;
  }

  /*-- Margin --*/
  .ipad-pro-m-0 {
    margin: 0px !important;
  }

  /*-- font size -- */
  .ipad-pro-font-11 {
    font-size: 11px !important;
  }

  .ipad-font-10 {
    font-size: 10px !important;
  }

  .ipad-font-11 {
    font-size: 11px !important;
  }

  .ipad-font-13 {
    font-size: 13px !important;
  }

  .ipad-font-14 {
    font-size: 14px !important;
  }

  /*-- Dashboard Submit -- */
  .dashboard-content .dashboard-welcome {
    padding: 20px 15px;
  }

  .dashboard-content .dashboard-welcome h2 {
    font-size: 20px;
  }

  /*-- OnBoarding Css -- */
  .overflow-content.collacteral_height {
    max-height: calc(100vh - 490px);
  }

  .overflow-content.basicDetails-height {
    height: calc(100vh - 285px);
    padding-bottom: 70px;
    max-height: calc(100vh - 285px);
  }

  .mob_get_verify {
    border-top: 1px solid #a8abb2;
    border-bottom: 1px solid #e2e6f2;
    display: block;
  }

  .mob_get_verify h2 {
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0px;
    margin: 0;
  }

  .mob_get_verify i {
    color: #6c7a8f;
  }

  .web-links {
    margin-left: 140px;
  }

  /*-- Profile Creator View -- */
  .rating ul li:before {
    left: -11px;
  }

  /*-- profile creator view -- */
  .edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
    right: 320px;
  }

  .user-work-thumbnail .thumb-img {
    width: 90px;
  }

  /*-- upload a project -- */
  .user-upload-content-work {
    background-size: 100px;
    /* height: 115px; */
  }

  .job_wrapper .user-upload-content-work {
    height: 100%;
  }

  /* .uploading-image .upload-thumbnail .upload-thumb {
    width: 80px;
  } */
  .uploading-image .upload-thumbnail {
    grid-gap: 15px 0px;
  }

  .upload-project-big-thumbnail img {
    height: 300px;
  }

  .uploading-image .card {
    height: 363px;
    width: 484px;
  }

  .upload_url {
    height: 400px;
  }

  .uploading-image .upload-file {
    height: 363px;
  }

  .upload-a-project {
    background-position: 115% 30%;
  }

  .videoPlayer {
    height: 370px;
  }

  .fullscreen_video {
    width: 900px;
    height: calc(900px / 1.3333);
    margin: auto;
  }

  /*-- Account setting & Account Security --*/
  .account-setting h2 .underline_1 {
    width: 77%;
  }

  .verified,
  .verified-again {
    font-size: 10px;
  }

  .bg-image {
    background-size: 160px;
    background-position: 148px 124px;
    min-height: 220px;
  }

  .bg-image-creator {
    min-height: 100px;
    background-size: 109px;
    background-position: 200px 61px;
  }

  /*-- Account Setting Billing Information --*/
  .account-setting h2 .underline_3 {
    width: 70%;
  }

  .account-setting h2 .underline_4 {
    width: 70%;
  }

  /*-- Creator Recommendations --*/
  .creator-recommended-slider h2 {
    font-size: 18px;
  }

  .creator-recommended-slider .creator-tags {
    padding: 5px;
  }

  .recommentation-overflow {
    height: calc(100vh - 225px);
    max-height: calc(100vh - 225px);
  }

  /*-- compand details --*/
  .overflow-content.company-height {
    height: calc(100vh - 210px);
    padding-bottom: 40px;
    max-height: calc(100vh - 210px);
  }

  .request-reviewed .card {
    width: 604px;
    height: 450px;
  }

  /*-- Fullscreen view modal --*/
  .fullscreen_modal .user-work-thumbnail {
    justify-content: center;
  }

  .custom_form.fullscreen_modal .close_icon {
    top: 18px;
  }

  .fullscreen_thumb {
    display: inline-block;
  }

  .fullscreen-scroll {
    overflow-x: auto;
  }

  .fullscreen-scroll::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  .fullscreen-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .fullscreen-scroll::-webkit-scrollbar-thumb {
    background: #b7babc;
  }

  .fullscreen_modal .user-work-thumbnail {
    padding: 15px 0px;
    margin: 0px;
    flex-wrap: unset;
    display: inline-flex;
    width: auto;
  }

  .full-w-100 {
    width: unset;
  }

  .overflow-content.user-work-content {
    max-height: calc(100vh - 575px);
    height: calc(100vh - 35px);
  }

  .landing-headline h1 {
    font-size: 2.5em;
  }

  .img-area {
    width: 270px;
    height: calc(270px / 1.333);
  }

  .service-icon-next,
  .service-icon-prev {
    display: block;
  }

  .service_desktop_prev,
  .service_desktop_next {
    display: none;
  }

  /*==== mobile footer =====*/
  .mobile-footer-fixed.show {
    /* display:block; */
    display: none;
  }

  /*=== Chat Input ===*/
  .chat-summernote {
    width: 60%;
  }

  .submit_btn {
    right: 4.5%;
    bottom: 30%;
  }

  /*-- Landing Page --*/
  .landing-banner-image {
    background-position: center 0px;
  }

  .footer-link {
    padding: 0px 15px !important;
  }

  /*== Services Page ==*/
  .services_card .service-box-logo {
    width: 240px;
    height: calc(240px / 1.3333);
  }

  /*==== Active Brief Page ====*/
  .active_brief_cards .card-body .project_slider .project-list {
    width: 300px;
    height: calc(300px / 1.333);
  }

  /* New Profile Selection CSS */
  .profile_selection_wrapper .card_works img {
    width: 350px !important;
    height: calc(350px / 1.52);
  }
}

@media (max-width: 992px) {
  /*-- Chat Lightbox modal --*/
  .lightbox-modal .modal-lg,
  .lightbox-modal .modal-xl {
    max-width: 700px;
  }

  .custom_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl {
    max-width: 920px;
  }

  .forget_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl,
  .forget_Modal_Box .modal-dialog {
    max-width: 650px;
  }

  .ipad-screen-650 {
    max-width: 650px;
  }

  .custom_form .social_icon {
    padding: 50px 0px;
  }

  .custom_form .mob_background_image {
    display: none;
  }

  .custom_form .remove-pd {
    display: block;
  }

  .custom_form .login-background {
    min-height: 510px;
  }

  .custom_form .login h2 {
    padding: 25px 0px;
  }

  .custom_form .login {
    padding: 20px 15px;
  }

  /* .social_desktop_none {
    display: none;
  } */
  .custom_form .social_icon p {
    font-size: 14px;
  }

  .mob-border-radius-0 {
    box-sizing: border-box;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .social_platform {
    border-radius: 0px;
    padding-right: 15px;
    border-right: none;
    font-weight: 800;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .social_platform:nth-child(2) {
    border-right: none;
  }

  .social_platform .google_icon {
    cursor: pointer;
    width: 17px;
    margin-right: 6px;
    margin-top: -1px;
  }

  .reCaptcha p {
    font-size: 11px;
    padding: 0px;
  }

  .overflow-content {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
  }

  .overflow-content.user-work-content {
    max-height: calc(100vh - 500px);
    min-height: unset;
    height: 100vh;
  }

  /*======= Mobile Close Icon ======*/
  .closeIcon {
    position: absolute;
    top: 8px;
    right: 11px;
    cursor: pointer;
    display: block;
  }

  /*-- Header mobile --*/
  .main-header .navbar-collapse.collapse.show {
    background: #000;
    z-index: 100;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-top: 9px;
    padding: 0px 0px 24px;
  }

  .profile-inner .dropdown-menu.outer-nav-wrap {
    margin-bottom: 15px;
  }

  /*-- Onboarding css --*/
  .mob-hidden {
    display: none;
  }

  .desktop-hidden,
  .mobile-ipad-show {
    display: block;
  }

  .desktop_ipadPro_display_none {
    display: block;
  }

  .mobile-ipad-none {
    display: none !important;
  }

  .mob-justify-content-between {
    justify-content: space-between !important;
  }

  .mob_display_none,
  .mob_display_none_off {
    display: none;
  }

  .ipad-w-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .ipad-w-70 {
    width: 70%;
  }

  .ipad-w-30 {
    width: 30%;
  }

  .mob-col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .mob-col-58 {
    width: 58%;
    max-width: 58%;
    flex: 0 0 58%;
  }

  .mob_get_verify {
    border-top: 1px solid #a8abb2;
    border-bottom: 1px solid #e2e6f2;
    display: block;
  }

  .mob_get_verify h2 {
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0px;
    margin: 0;
  }

  .mob_get_verify i {
    color: #6c7a8f;
  }

  .collacteral::after {
    top: -10px;
  }

  .tabs-icon::after {
    left: 30px;
  }

  .wrapper-form .input-icon.icon-right {
    right: 286px;
  }

  .web-links {
    margin-left: 105px;
  }

  .overflow-content.basicDetails-height {
    height: calc(100vh - 245px);
    padding-bottom: 70px;
    max-height: calc(100vh - 245px);
  }

  .overflow-content.review_proposal {
    height: calc(100vh - 222px);
    max-height: calc(100vh - 230px);
  }

  /*-- Font Size --*/
  .ipad-only-font-12 {
    font-size: 12px !important;
  }

  /*-- Padding --*/
  .ipad-only-p-0 {
    padding: 0px !important;
  }

  .ipad-only-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .ipad-only-p-10 {
    padding: 10px !important;
  }

  .ipad-only-p-15 {
    padding: 15px !important;
  }

  .ipad-only-p-20 {
    padding: 20px !important;
  }

  .ipad-only-pb-0 {
    padding-bottom: 0px !important;
  }

  .ipad-only-px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .ipad-only-px-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .ipad-only-py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .ipad-only-mb-55 {
    margin-bottom: 55px !important;
  }

  .ipad-only-mb-90 {
    margin-bottom: 90px !important;
  }

  .ipad-p-0 {
    padding: 0px !important;
  }

  .ipad-p-15 {
    padding: 15px !important;
  }

  .ipad-px-15 {
    padding: 15px !important;
  }

  .ipad-pt-10 {
    padding-top: 10px !important;
  }

  .ipad-pt-40 {
    padding-top: 40px !important;
  }

  .ipad-pb-160 {
    padding-bottom: 160px !important;
  }

  .ipad-pb-230 {
    padding-bottom: 230px !important;
  }

  .ipad-normal-p-0 {
    padding: 0px !important;
  }

  .ipad-normal-p-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .ipad-pr-0 {
    padding-right: 0px !important;
  }

  .ipad-mx-0-my-30 {
    padding: 30px 0px !important;
  }

  .ipad-py-10 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  /*-- Margin css --*/
  .ipad-m-0 {
    margin: 0px !important;
  }

  .ipad-minus-82 {
    margin-top: -82px;
  }

  .ipad-ml-mr-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .ipad-my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .ipad-my-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .ipad-mt-10 {
    margin-top: 10px !important;
  }

  .ipad-mt-50 {
    margin-top: 50px !important;
  }

  .ipad-mt-70 {
    margin-top: 70px !important;
  }

  .ipad-mt-80 {
    margin-top: 80px !important;
  }

  .ipad-mb-15 {
    margin-bottom: 15px !important;
  }

  .ipad-mb-50 {
    margin-bottom: 50px !important;
  }

  .ipad-mb-70 {
    margin-bottom: 70px !important;
  }

  /*-- Display --*/
  .ipad-only-show {
    display: block;
  }

  .ipad-only-hide {
    display: none;
  }

  .ipad-d-block {
    display: block !important;
  }

  /*-- Normal Ipad Size -- */
  .ipad-normal-col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .ipad-normal-col-60 {
    width: 60%;
    max-width: 60%;
    flex: 0 0 60%;
  }

  .ipad-normal-col-40 {
    width: 40%;
    max-width: 40%;
    flex: 0 0 40%;
  }

  /*-- ipad Width -- */
  .ipad-w-100 {
    width: 100% !important;
  }

  .ipad-only-w-12 {
    width: 12% !important;
  }

  .ipad-only-w-100 {
    width: 100% !important;
  }

  .ipad-w-75 {
    width: 75% !important;
  }

  /*-- flex grow css --*/
  .ipad-flex-grow-1 {
    flex-grow: 1;
  }

  /*-- Display css --*/
  .ipad-d-inline {
    display: inline-block !important;
  }

  /*-- text alignment -- */
  .mob-text-center {
    text-align: center !important;
  }

  .mob-text-left {
    text-align: left !important;
  }

  .ipad-justify-content-between {
    justify-content: space-between !important;
  }

  .mob-justify-content-center {
    justify-content: center !important;
  }

  /*-- Font size --*/
  .mob-font-24 {
    font-size: 24px !important;
  }

  .mob-font-18 {
    font-size: 18px !important;
  }

  .mob-font-11 {
    font-size: 11px !important;
  }

  .mob-font-14 {
    font-size: 14px !important;
  }

  .mob-font-13 {
    font-size: 13px !important;
  }

  .ipad-only-font-11 {
    font-size: 11px !important;
  }

  .ipad-only-font-12 {
    font-size: 12px !important;
  }

  .ipad-only-font-30 {
    font-size: 30px !important;
  }

  /*-- Font weight --*/
  .mob-font-weight-700 {
    font-weight: 700 !important;
  }

  /*-- Common Css Modal --*/
  .position-bottom-modal {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    bottom: 0px;
  }

  .position-bottom-modal.breakdown-modal {
    bottom: -17px;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .ipad-full-height {
    height: calc(100vh - 50px);
  }

  /*-- border ipad css --*/
  .ipad-border-bottom-none {
    border-bottom: none !important;
  }

  /*-- Dashboard Submit -- */
  .mob_dashboard_background_image {
    display: block;
    z-index: -1;
  }

  .mob_dashboard_background img {
    min-height: 260px;
    filter: brightness(0.5);
    -webkit-filter: brightness(0.5);
    margin-top: -1px;
  }

  .mob_banner_text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.02);
    text-align: center;
  }

  .mob_banner_text .dashboard-welcome {
    width: 50%;
    margin: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -100%);
  }

  .mob_banner_text .dashboard-welcome h2 {
    color: #fff;
    font-size: 22px;
  }

  .mob_banner_text .dashboard-welcome p {
    color: #fff;
    font-size: 12px;
    color: #9b9b9b;
  }

  .mob_dashboard_content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #fff;
    margin-top: -25px;
    display: block;
  }

  .mob_dashboard_content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #fff;
    margin-top: -25px;
    display: block;
  }

  .user-application {
    border: 2px solid #e9e7e7;
    border-radius: 100%;
    padding: 15px;
    border-left-width: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 2px;
    position: relative;
    width: 160px;
    height: 160px;
    margin: auto;
  }

  .timeline-item {
    text-align: center;
    padding: 15px 10px;
    width: 82%;
    margin: 10px auto 0px;
  }

  .timeline-item h1.active {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 10px !important;
  }

  .timeline-item-dot-1 {
    width: 6px;
    height: 6px;
    top: 146px;
    right: 114px;
  }

  .timeline-item-dot-1.active {
    width: 10px;
    height: 10px;
    background: #00b665;
    right: 75px;
    top: 154px;
  }

  .timeline-item-dot-1.profile-verified {
    top: 134px;
    right: 132px;
  }

  .timeline-item-dot-2 {
    width: 6px;
    height: 6px;
    right: 44px;
  }

  .timeline-item-dot-2.active {
    right: 74px;
    top: 153px;
    width: 10px;
    height: 10px;
    background: #00b665;
  }

  .timeline-item-dot-2.profile-verified {
    right: 107px;
    top: 150px;
  }

  .timeline-item-dot-3 {
    width: 6px;
    height: 6px;
    top: 132px;
    right: 20px;
  }

  .timeline-item-dot-3.active {
    right: 75px;
    top: 154px;
    background: #00b665;
    width: 10px;
    height: 10px;
  }

  .timeline-item-dot-3.profile-under-review {
    top: 144px;
    right: 36px;
  }

  .timeline-item-dot-3.temporarily.active {
    background-color: #e9cb02;
  }

  .go_to_dashboard {
    border: 1.5px solid #007bff;
    padding: 2px;
    border-radius: 4px;
  }

  .before_or {
    border-bottom: 2px solid #eee;
  }

  .before_or:before {
    content: "or";
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    width: 25%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    background-color: #fff;
  }

  .re-submit {
    background-color: #f5f7fd;
    padding: 15px 15px 20px;
    margin: 0;
    border-radius: 8px;
    margin-top: 40px;
  }

  /*-- Profile Creator View -- */
  .content {
    padding: 30px 15px;
  }

  .rating ul li:before {
    left: 0px;
  }

  .main-content .nav {
    padding: 0px 15px;
  }

  .right_sidebar .dropdown-menu {
    left: -25px !important;
  }

  .edit_icon {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  .edit_icon i {
    border: 1px solid #e4e4e4;
    border-radius: 100%;
    padding: 10px;
    cursor: pointer;
  }

  .mobile-header-fixed img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }

  .mobile-header-fixed {
    width: 100%;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 100;
    top: -2px;
    left: 0px;
    right: 0px;
    padding: 7px 15px 8px;
    display: block;
  }

  .mobile-sub-header {
    width: 100%;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    z-index: 100;
    top: -2px;
    left: 0px;
    right: 0px;
    padding: 2px 15px 2px;
    display: block;
  }

  .mobile-header-edit i {
    border: 1px solid #e4e4e4;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    line-height: 42px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }

  .mobile-footer-fixed {
    width: 100%;
    background: #fff;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 100;
    bottom: 0px;
    left: 0px;
    right: 0px;
    padding: 15px;
    /* display: block; */
    z-index: 999;
    display: none;
  }

  .mobile-footer-fixed ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .mobile-footer-fixed ul li a {
    color: #6c7b91;
    font-weight: 600;
    font-size: 14px;
  }

  .mobile-footer-fixed ul li a.active {
    color: #000;
    font-size: 700;
  }

  .mobile-footer-fixed ul li a .footer_icon {
    font-size: 18px;
    margin-bottom: 3px;
  }

  .mobile-footer-fixed ul li a .footer_icon.extra-size {
    font-size: 24px;
    margin-bottom: 0px;
  }

  .mobile-footer-fixed ul li a .footer-icon-border {
    border: 1.5px solid #6c7b91;
    padding: 2px;
    border-radius: 4px;
  }

  /* .mobile-footer-fixed ul li a .upload-btn-minus {
    margin-top: -18px;
  } */
  .mobile-footer-fixed ul li a .footer-icon-border.mobile-upload {
    border: 1.5px solid #027d46;
    border-radius: 36px;
    background: #00b665;
    color: #fff;
    font-size: 22px;
    width: 40px;
    height: 40px;
    line-height: 32px;
  }

  .mobile-footer-fixed ul li a .footer-icon-border.active {
    color: #00b665;
    border-color: #00b665;
  }

  .mobile-footer-fixed ul li a .footer_icon.extra-size.active {
    color: #00b665;
  }

  .mobile-footer-fixed ul li .footer_icons {
    filter: brightness(0);
  }

  .mobile-footer-fixed ul li .footer_icons.active {
    filter: brightness(1) contrast(1);
  }

  .edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
    right: 248px;
  }

  .add-collateral-underline {
    width: 80%;
  }

  .custom_url .add-collateral-underline {
    width: 80%;
  }

  /*-- upload a project --*/
  .user-upload-content-work {
    background-size: 53px;
    /* height:90px; */
  }

  .choose-underline {
    width: 30px;
  }

  .user-upload-content-text {
    position: absolute;
    bottom: unset;
    top: 70%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .user-upload-content-work:after {
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: 10px;
  }

  .upload-a-project {
    background-size: 120px;
    background-position: 112% 20%;
  }

  .uploading-image .upload-thumbnail {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }

  .uploading-image .upload-thumbnail {
    grid-gap: 15px 30px;
  }

  .uploading-image .upload-file {
    height: 250px;
    padding: 0px;
  }

  .select-btn {
    padding: 5px;
    font-size: 13px;
  }

  .upload-project-big-thumbnail img {
    height: 250px;
  }

  .uploading-image .card {
    height: 450px;
    width: 604px;
  }

  .uploading-image .upload-file {
    height: 450px;
  }

  .user-work-thumbnail {
    margin: 0px 5px;
  }

  .user-work-section {
    width: 400px;
    height: 300px;
  }

  .video_iframe {
    width: 400px;
    height: 300px;
    margin: auto;
  }

  .fullscreen_video {
    width: 300px;
    height: calc(300px / 1.3333);
    margin: auto;
  }

  .upload_url {
    height: 460px;
  }

  /*-- Account Setting & Security --*/
  .account-notification .select {
    width: 100%;
  }

  .account-setting-dropdown .select-class {
    left: 22px;
    top: 42px;
  }

  .account-setting h2 {
    font-size: 14px;
  }

  .account-setting h2 .underline_1 {
    width: 82%;
  }

  .face-issue.bg-image {
    background-size: 170px;
    background-position: right bottom;
    min-height: 170px;
    border-radius: 0px;
  }

  .logout.main-btn {
    background: transparent;
    color: #848484;
    border: 1px solid #848484;
    transition: 0.3s all;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -o-transition: 0.3s all;
    -ms-transition: 0.3s all;
  }

  .logout.main-btn:hover {
    background: #10c89b;
    color: #fff;
    border: 1px solid #10c89b;
  }

  /*-- Account setting billing Information --*/
  .account-setting h2.company_underline .underline_4 {
    width: 58%;
  }

  .account-setting h2 .underline_4 {
    width: 45%;
  }

  .tax-account h2 {
    font-size: 20px;
  }

  .tax-account {
    background-position: center;
    min-height: 120px;
  }

  /*-- Post a Project --*/
  .overflow-content.project-height {
    height: calc(100vh - 190px);
    padding-bottom: 0px;
    max-height: calc(100vh - 190px);
  }

  .overflow-content.project-height {
    height: calc(100vh - 200px);
    padding-bottom: 40px;
    max-height: calc(100vh - 200px);
  }

  .overflow-content.budget-deadline {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }

  .overflow-content.type-height {
    height: calc(100vh - 215px);
    padding-bottom: 40px;
    max-height: calc(100vh - 215px);
  }

  .post_a_project .user-upload-content-text {
    position: absolute;
    bottom: 0;
    top: unset;
  }

  .post_a_project .user-upload-content-work {
    background-size: 160px;
    height: 160px;
    background-position-y: inherit;
  }

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -118px !important;
  }

  /*-- Creator Mobile Slider --*/
  .creatorSliderImage .carousel-inner {
    max-width: 320px;
    margin: auto;
  }

  .creatorSliderImage .carousel-item img {
    height: 240px;
  }

  /* .creator-recommendations .overflow-content {
    height: calc(100vh - 200px);
    padding-bottom: 0px;
    max-height: calc(100vh - 200px);
  }  */
  .creator-recommendations {
    height: calc(100vh - 115px);
    overflow: hidden;
    max-height: unset;
  }

  /*-- Chat Screen Section --*/
  .timeline-due-date .vertical-line.advance-payment {
    bottom: -24px;
    height: 52%;
  }

  .timeline-due-date .vertical-line {
    height: 34%;
    bottom: -28px;
  }

  .chat-box-issue .profile-help {
    padding: 22px 20px 22px;
  }

  .chat-box-issue .profile-help h2 {
    font-weight: 500;
    line-height: 1.5;
  }

  .chat-box-issue .profile-help h3 {
    font-size: 16px;
  }

  .chat-box-issue .bg-image-creator {
    background-position: bottom right;
    background-size: 160px;
  }

  .overflow-content.chat-height.mob-chat-height {
    height: calc(100vh - 165px);
    max-height: calc(100vh - 165px);
  }

  .chat-box-issue {
    position: fixed;
    padding: 20px 20px 20px 15px;
    bottom: 0px;
  }

  .chat-box-issue .bg-image-creator {
    background-position: 245px 0px;
  }

  .mobile-chat-header .mobile-header-edit i {
    border: 1px solid #c6c7c8;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }

  .chat-start-day-status {
    position: static;
    top: unset;
    color: #707d91 !important;
    font-weight: 400;
    text-transform: capitalize !important;
  }

  .input-chat .input-chat-link,
  .input-chat .input-chat-location,
  .submit-proposal {
    display: none;
  }

  .input-chat {
    background-color: #f8fafe;
    padding: 10px 15px;
  }

  .input-chat .email {
    background-color: #fff;
  }

  .mobile_userchat_input .input-group-text.mobile_group_text {
    background-color: transparent;
    border: none;
  }

  .mob-send-icon {
    padding: 15px;
    background-color: #00b665;
    color: #fff;
    border: none;
    font-weight: 700;
    font-size: 17px;
    margin-left: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    transition: background-color 0.5s ease-in-out;
  }

  .project-delivery-date:before {
    left: -15px;
  }

  /*-- Milestones Modal Css --*/
  .add_milestone {
    padding-left: 65px;
  }

  .add_milestone .add-collateral-underline {
    width: 77%;
  }

  /*-- File Folder Modal Css --*/
  .file-folder-box {
    position: absolute;
    right: 0;
    z-index: 2;
    bottom: 20px;
    width: 75%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  /*-- ====  Placeholder Css ==== --*/
  .custom_form .email::placeholder {
    color: #aeb3be;
    font-size: 13px;
  }

  .custom_form .email::-ms-input-placeholder {
    color: #aeb3be;
    font-size: 13px;
  }

  .custom_form .email::-ms-input-placeholder {
    color: #aeb3be;
    font-size: 13px;
  }

  /*-- Set Quote and Milestones --*/
  .client-cost .project-delivery-date:before {
    left: -35px;
  }

  .timeline-due-date.quote-breakdown .vertical-line {
    height: 32px;
    bottom: -36px;
  }

  /*-- Attached File Section ---*/
  /* .preview-fileupload {
    bottom: 135px;
    max-height: 300px;
    overflow-y: auto;
  }  */
  .fullscreen_modal .user-work-thumbnail {
    justify-content: unset;
  }

  .client-content {
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }

  /*=== style & type questions ======*/
  .onboarding_style_questions .content li {
    max-width: 140px;
    margin-right: 25px;
  }

  .onboarding_style_questions .content .deadline {
    height: 106px;
  }

  .onboarding_style_questions.xl_size .content li {
    max-width: 160px;
  }

  .onboarding_style_questions.xl_size .content .deadline {
    height: 128px;
  }

  /*===== landing page css ===*/
  .creators_mobile_section .owl-carousel .owl-item img {
    display: inline-block;
    width: inherit;
  }

  .creators_mobile_section .worked-with .company-icon img {
    width: 25px;
    height: 25px;
  }

  .landing-banner-image {
    background-image: none;
    min-height: auto;
  }

  .landing-page-video video {
    display: none;
  }

  .creators_mobile_section .owl-dot {
    margin: 10px 5px 5px;
  }

  .img-area {
    width: 42vw;
    height: calc(42vw / 1.333);
  }

  .service_card {
    display: block;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 6px;
    width: 220px;
    height: calc(220px / 1.333);
    max-width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .service_card img {
    display: inline-block;
    width: inherit;
    height: 100%;
  }

  /*=== Services Page ===*/
  .services_card .service-box-logo {
    width: 225px;
    height: calc(225px / 1.3333);
  }

  /*====== landing page exit =====*/

  /*=== Chat Input ===*/
  .chat-summernote {
    width: 100%;
    margin-left: unset;
  }

  .chat-summernote .note-editor.note-frame .note-editing-area .note-editable,
  .chat-summernote
    .note-editor.note-airframe
    .note-editing-area
    .note-editable {
    padding-right: 14px;
    margin-left: 5%;
    margin-right: 8%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .chat-summernote .note-editor.note-frame .note-placeholder,
  .chat-summernote .note-editor.note-airframe .note-placeholder {
    margin-left: 5%;
  }

  .mob-add-attached {
    position: absolute;
    z-index: 101;
    left: 0%;
    top: 14px;
  }

  .mob-enter-btn {
    position: absolute;
    z-index: 102;
    right: 2%;
    top: 2%;
    background-color: #10c89b;
    padding: 15px 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  /*-- Chat Lightbox modal --*/
  .overflow-content.overflow-lightbox {
    padding-bottom: 20px;
  }

  .custom_form.lightbox-modal .modal-content {
    height: auto;
  }

  .lightbox-modal .lightbox-big-preview {
    width: 600px;
    height: calc(600px / 1.333);
  }

  /*==== User Survey CSS ====*/
  .user-survey .btn-scale {
    min-width: 38px;
    width: calc(86% / 12);
  }

  /*==== Active Brief Page ====*/
  .active_brief_cards {
    margin-bottom: 30px;
  }

  .activeBrief_Modal .modal-dialog {
    max-width: 100%;
  }

  .mobile_cards_number .Numbers {
    margin: 0 8px;
    font-weight: 600;
    font-size: 24px;
  }

  .mobile_cards_number .left_icon,
  .mobile_cards_number .right_icon {
    font-size: 24px;
  }

  /* Chat mobile Dropdown */
  .chat_mobile_dropdown.top_space {
    margin-top: 15px;
  }

  .artist_top_space {
    margin: 10px 0;
  }

  .chat_mobile_dropdown .mobile-ipad-show .select-styled {
    background-color: transparent !important;
  }

  .chat_mobile_dropdown.mobile-ipad-show .select-styled {
    padding: 13px 12px !important;
  }

  /* New Profile Selection CSS */
  .profile_selection_wrapper {
    padding: 2rem 0;
  }

  .profile_selection_wrapper .profile_title h1 {
    font-size: 24px;
  }

  .profile_selection_wrapper .profile_title p,
  .profile_selection_wrapper .profile_title h2 {
    font-size: 14px;
    margin: 3px;
  }

  .profile_selection_wrapper .card_works img {
    width: 400px !important;
    height: calc(400px / 1.8);
  }

  .profile_selection_wrapper .card-body {
    display: flex;
    flex-direction: column;
  }

  .profile_selection_wrapper .card_details {
    width: 100%;
  }

  .profile_selection_wrapper .card_works {
    width: 100%;
  }

  .profile_selection_wrapper .card_details .mobile_view_profile .btn {
    display: block;
    margin: 0px auto 0;
    padding-right: 0;
  }

  .profile_selection_wrapper .card_works .view_profile .btn {
    display: none;
  }

  .profile_selection_wrapper .card_details .client_works {
    display: none;
  }

  .profile_selection_wrapper .card_details .client_works.mob_show_client {
    display: block;
  }

  .profile_selection_wrapper .card_works .new_profile_next i,
  .profile_selection_wrapper .card_works .new_profile_prev i {
    top: 32%;
  }

  .profile_selection_wrapper .card_details .price {
    font-size: 15px;
  }

  .profile_selection_wrapper .card_profile {
    margin: 0 auto 5px;
  }

  .profile_selection_wrapper .card_details .per_project {
    margin-bottom: 0;
  }

  .profile_selection_wrapper .profile_selection_loader {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .custom_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl {
    max-width: 740px;
  }

  .custom_form .mob_background_image {
    display: none;
  }

  .custom_form .remove-pd {
    display: block;
  }

  .custom_form .login {
    padding: 20px 10px;
  }

  .custom_checkbox label {
    font-size: 13px;
  }
}

@media (max-width: 767px) {
  /*-- Header logo -- */
  .main-header .navbar-light .navbar-brand img {
    width: 140px;
  }

  /*-- login, Signup, Forget, Verify & Sms --*/
  .custom_Modal_Box .modal-lg,
  .custom_Modal_Box .modal-xl {
    max-width: 700px;
  }

  .custom_form .user_bg {
    background: #fff;
  }

  .custom_form .login {
    padding: 10px 5px;
  }

  .custom_form .login-background {
    min-height: 530px;
    display: none;
  }

  .custom_form .close_icon {
    right: -30px;
  }

  .custom_form .social_icon {
    padding: 30px 0px;
  }

  .custom_form.custom_Modal_Box {
    padding-right: 0px;
  }

  .custom_form .mob-modal-xs {
    width: 100%;
    margin: auto;
  }

  .custom_form .close_icon {
    right: 10px;
    top: 0;
  }

  .custom_form .close_icon.cross_black {
    filter: brightness(0);
  }

  .custom_form .mob_background_image {
    padding: 0px;
    display: block;
  }

  .custom_form .mob-login-background {
    background-image: url(../images/mob-login-2.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 220px;
    margin-top: -1px;
    margin-bottom: 10px;
    background-color: #050421;
  }

  .custom_form .mob-overflow-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #fff;
    position: relative;
    margin-top: -25px;
  }

  .custom_form .login h2 {
    width: 100%;
    font-size: 22px;
    text-align: left;
  }

  .custom_form.shareModal .modal-content {
    height: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .custom_form.kick_off_project .modal-content {
    height: auto;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .custom_form.kick_off_project .modal-content {
    height: auto;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .custom_form.verify_modal .modal-content {
    height: auto;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }

  .custom_form.user_form_section .modal-content {
    margin-top: 50px;
    height: auto;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .overflow-content.basic-height {
    max-height: 500px;
  }

  .overflow-content.user-work-content {
    max-height: calc(100vh - 550px);
    min-height: unset;
    height: 100vh;
  }

  .reCaptcha p {
    text-align: center;
    font-size: 12px;
  }

  .custom_form .social_icon p {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  .social_platform {
    border: 1.5px solid #eee;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
  }

  .mob_d_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .social_platform:nth-child(2) {
    border-right: 1.5px solid #eee;
  }

  .social_platform .google_icon {
    cursor: pointer;
    width: 17px;
    margin-right: 6px;
    margin-top: -5px;
  }

  .social_platform .mob_google_icon {
    margin-top: 0px;
  }

  .mob_width {
    width: 100%;
    display: block !important;
  }

  /* .mob_width:last-child {
    margin-bottom:90px !important;
  } */
  .social_desktop_none {
    display: block;
  }

  .join-content h2 {
    font-size: 22px !important;
    text-align: center !important;
  }

  .mob_display_none,
  .mob_display_none_off {
    display: none;
  }

  .desktop_ipadpro_ipad_none {
    display: block;
  }

  .mob-card-wrapper {
    margin-bottom: 0px;
  }

  .mob-border-radius-0 {
    box-sizing: border-box;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
  }

  .mob-only-text-center {
    text-align: center;
  }

  /*-- Justify Content --*/

  .mob-justify-content-between {
    justify-content: space-between !important;
  }

  .mob-justify-content-unset {
    justify-content: unset !important;
  }

  .mob-only-justify-content-center {
    justify-content: center !important;
  }

  .mob-d-block {
    display: block !important;
  }

  .mob-mx-auto {
    margin: auto !important;
  }

  .mob-hidden {
    display: none !important;
  }

  .mobile-hide {
    display: none !important;
  }

  .desktop-hidden,
  .mobile-ipad-show {
    display: block;
  }

  .mobile-only-show,
  .mobile-show {
    display: block !important;
  }

  .mobile-hide {
    display: none;
  }

  .desktop-ipad-ipad-pro-show {
    display: none;
  }

  .mob-d-inline {
    display: inline-block !important;
  }

  .mob-flex-column {
    flex-direction: column !important;
  }

  .mob-overflow-x {
    overflow-x: scroll !important;
  }

  /*-- Width --*/
  .mob-min-w-170 {
    min-width: 170px !important;
  }

  .mob-col-58 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .mob-w-col-100 {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .mob-w-auto {
    width: auto !important;
  }

  .mob-w-100 {
    width: 100% !important;
  }

  .mob-w-10 {
    width: 10% !important;
  }

  .mob-w-15 {
    width: 15% !important;
  }

  .mob-w-30 {
    width: 30% !important;
  }

  .mob-w-35 {
    width: 35% !important;
  }

  .mob-w-38 {
    width: 38% !important;
  }

  .mob-w-40 {
    width: 40% !important;
  }

  .mob-w-45 {
    width: 45% !important;
  }

  .mob-w-47 {
    width: 47% !important;
  }

  .mob-w-62 {
    width: 62% !important;
  }

  .mob-w-65 {
    width: 65% !important;
  }

  .mob-w-70 {
    width: 70% !important;
  }

  .mob-w-75 {
    width: 75% !important;
  }

  .mob-w-80 {
    width: 80% !important;
  }

  .mob-w-90 {
    width: 90% !important;
  }

  .mob-w-50 {
    width: 50% !important;
  }

  .mob-w-60 {
    width: 60% !important;
  }

  /*-- Height Css --*/
  .full-height {
    height: calc(100vh - 50px) !important;
  }

  /*-- Padding --*/
  .mob-p-0 {
    padding: 0px !important;
  }

  .mob-pl-0 {
    padding-left: 0px !important;
  }

  .mob-px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .mob-pb-0 {
    padding-bottom: 0px !important;
  }

  .mob-pb-40 {
    padding-bottom: 40px !important;
  }

  .mob-pb-45 {
    padding-bottom: 45px !important;
  }

  .mob-pb-50 {
    padding-bottom: 50px !important;
  }

  .mob-pb-55 {
    padding-bottom: 55px !important;
  }

  .mob-pb-60 {
    padding-bottom: 60px !important;
  }

  .mob-p-10 {
    padding: 10px !important;
  }

  .mob-p-15 {
    padding: 15px !important;
  }

  .mob-p-20 {
    padding: 20px !important;
  }

  .mob-pl-0 {
    padding-left: 0px !important;
  }

  .mob-pl-6 {
    padding-left: 6px !important;
  }

  .mob-pl-15 {
    padding-left: 15px !important;
  }

  .mob-pl-18 {
    padding-left: 18px !important;
  }

  .mob-pl-50 {
    padding-left: 50px !important;
  }

  .mob-pr-0 {
    padding-right: 0px !important;
  }

  .mob-pr-15 {
    padding-right: 15px !important;
  }

  .mob-pt-0 {
    padding-top: 0px !important;
  }

  .mob-pt-5 {
    padding-top: 5px !important;
  }

  .mob-pt-10 {
    padding-top: 10px !important;
  }

  .mob-pt-15 {
    padding-top: 15px !important;
  }

  .mob-pt-18 {
    padding-top: 18px !important;
  }

  .mob-pt-25 {
    padding-top: 25px !important;
  }

  .mob-pb-5 {
    padding-bottom: 5px !important;
  }

  .mob-pb-10 {
    padding-bottom: 10px !important;
  }

  .mob-pt-py-8 {
    padding: 8px 15px 15px !important;
  }

  .mob-pt-py {
    padding: 18px 15px 0px !important;
  }

  .mob_pX_pY_30 {
    padding: 10px 0px 20px !important;
  }

  .mob-pt-py-15 {
    padding: 10px 15px 20px !important;
  }

  .mob-py-15-px-0 {
    padding: 15px 0px !important;
  }

  .mob-px-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .mob-px-10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .mob-px-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .mob-px-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .mob-pt-20 {
    padding-top: 20px !important;
  }

  .mob-pt-30 {
    padding-top: 30px !important;
  }

  .mob-py-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .mob-py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .mob-py-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }

  .mob-mx-0-my-30 {
    padding: 30px 0px !important;
  }

  .mob-px-0-py-20 {
    padding: 20px 0px !important;
  }

  .mob-only-p-30 {
    padding: 30px 10px !important;
  }

  /*-- margin --*/
  .mob-m-0 {
    margin: 0px !important;
  }

  .mob-ml-0 {
    margin-left: 0px !important;
  }

  .mob-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mob-mx-15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .mob-my-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .mob-mt-8 {
    margin-top: 8px !important;
  }

  .mob-mt-10 {
    margin-top: 10px !important;
  }

  .mob-mt-15 {
    margin-top: 15px !important;
  }

  .mob-mt-20 {
    margin-top: 20px !important;
  }

  .mob-mt-40 {
    margin-top: 40px !important;
  }

  .mob-mb-10 {
    margin-bottom: 10px !important;
  }

  .mob-mb-15 {
    margin-bottom: 15px !important;
  }

  .mob-mb-20 {
    margin-bottom: 20px !important;
  }

  .mob-mb-25 {
    margin-bottom: 25px !important;
  }

  .mob-mb-30 {
    margin-bottom: 30px;
  }

  .mob-mb-35 {
    margin-bottom: 35px !important;
  }

  .mob-mb-45 {
    margin-bottom: 45px !important;
  }

  .mob-mt-50 {
    margin-top: 50px !important;
  }

  .mob-mb-70 {
    margin-bottom: 70px !important;
  }

  .mob-mb-80 {
    margin-bottom: 80px !important;
  }

  .mob-mb-90 {
    margin-bottom: 90px !important;
  }

  .mob-minus-mb-20 {
    margin-bottom: -20px !important;
  }

  .mob-minus-82 {
    margin-top: -82px;
  }

  .mob-ml-mr-auto {
    margin-left: auto;
    margin-right: auto;
  }

  /*-- Mobile Text align --*/
  .mob-only-text-left {
    text-align: left !important;
  }

  /*-- Font size --*/
  .mob-font-20 {
    font-size: 20px !important;
  }

  .mob-font-18 {
    font-size: 18px !important;
  }

  .mob-font-16 {
    font-size: 16px !important;
  }

  .mob-font-10 {
    font-size: 10px !important;
  }

  .mob-font-11 {
    font-size: 11px !important;
  }

  .mob-font-12 {
    font-size: 12px !important;
  }

  .mob-font-14 {
    font-size: 14px !important;
  }

  .mob-font-13 {
    font-size: 13px !important;
  }

  /*-- Font weight --*/
  .mob-font-weight-700 {
    font-weight: 700 !important;
  }

  /* Float CSS */
  .mob-float-unset {
    float: unset !important;
  }

  /*-- Modal Css --*/
  .mob-border-left-right {
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .mob-bg-white {
    background-color: #fff !important;
  }

  .mob-bg-transparent {
    background-color: transparent !important;
  }

  /*-- Mobile Box Shadow ---*/
  .mob-bg-shadow {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.06) !important;
  }

  /*-- Mobile Background color --*/
  .mob-bg-white {
    background-color: #fff !important;
  }

  .mob_get_verify {
    border-top: 1px solid #a8abb2;
    border-bottom: 1px solid #e2e6f2;
    display: block;
  }

  .mob_get_verify.remove_border {
    border-top: 1px solid transparent;
    border-bottom: 1px solid #e2e6f2;
    display: block;
  }

  .mob_get_verify h2 {
    font-size: 16px;
    font-weight: 800;
    padding: 15px 0px;
    margin: 0;
  }

  .mob_get_verify i {
    color: #6c7a8f;
  }

  .mob-profile-user img {
    width: 80px;
    height: 80px;
  }

  .select-arrow {
    right: 18px;
  }

  .collacteral::after {
    top: -10px;
  }

  .wrapper-form .input-icon:nth-child(2) {
    top: 52px;
  }

  .wrapper-form .input-icon.icon-right {
    right: 108px;
    top: 52px;
  }

  .web-links {
    margin-left: 105px;
  }

  /*-- Dashboard submit -- */
  .mob_banner_text .dashboard-welcome {
    width: 50%;
    margin: auto;
    padding: 50px 0px;
    transform: translate(-50%, -60%);
  }

  /*-- On Boarding ---*/
  .overflow-content.basicDetails-height {
    height: calc(100vh - 210px);
    padding-bottom: 40px;
    max-height: calc(100vh - 210px);
  }

  .onBoarding-loader .common-wrapper {
    width: 70px;
  }

  .custom_checkbox input:checked + label:after {
    top: 3px;
  }

  /*-- Creator Profile View -- */
  .profile-inner .custom_form .dropdown-menu.dropdown-list-menu {
    left: -28px !important;
  }

  .rating ul li {
    font-weight: 800;
    font-size: 13px;
  }

  .rating ul li:before {
    left: -5px;
  }

  .rating ul li:nth-child(3):before {
    left: 8px;
  }

  .main-content .nav {
    display: flex;
    flex-wrap: initial;
    /* overflow-x: auto; */
  }

  .main-content .nav::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  .main-content .nav::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .main-content .nav::-webkit-scrollbar-thumb {
    background: #b7babc;
  }

  .main-content .nav-tabs .nav-item.show .nav-link,
  .main-content .nav-tabs .nav-link {
    min-width: 115px;
  }

  .main-content .nav-tabs .nav-item {
    white-space: nowrap;
  }

  .main-content .nav {
    padding: 0px 0px 10px;
  }

  .content {
    grid-gap: 10px;
    padding: 30px 0px 15px;
  }

  /* .content .team {
    box-shadow: none;
    border: none;
  } */
  .content-img img {
    height: 240px;
  }

  /* .recommendation img {
    width: 130px;
    height: 100px;
    max-width: 100%;
  } */
  .right_sidebar .dropdown-menu {
    left: -15px !important;
  }

  /*-- Profile creator modals -- */
  .add-collateral-underline {
    width: 66%;
  }

  .custom_url .add-collateral-underline {
    width: 68%;
  }

  .edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
    right: 100px;
  }

  .mobile-edit-collateral {
    display: block;
  }

  .desktop-ipad-show {
    display: none;
  }

  .modal-tab.tabs-icon::after {
    left: 56px;
  }

  /*-- Mobile footer navition -- */
  .mobile-footer-fixed ul {
    justify-content: space-between;
  }

  /*-- User work section --*/

  .user-work-section {
    /* padding-top: 10px; */
    height: 250px;
    max-width: 333px;
  }

  .user-work-section img {
    height: 250px;
    width: 100%;
  }

  .user-work-thumbnail {
    padding: 15px 10px;
    margin: 0px 5px;
    flex-wrap: nowrap;
  }

  .user-work-thumbnail img {
    width: 90px;
    height: 73px;
  }

  .user-work-content .tags {
    padding: 4px 10px;
    font-size: 11px;
  }

  .user-work-section .next-prev.left {
    left: 15px;
    opacity: 1;
  }

  .user-work-section .next-prev.right {
    right: -22px;
    opacity: 1;
  }

  /*-- upload a project -- */
  .job_wrapper .user-upload-content-work {
    height: 100%;
  }

  .user-upload-content-work {
    background-size: 75px;
    height: 105px;
  }

  .user-upload-content-work:after {
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 13px;
  }

  /* .uploading-image .upload-thumbnail .upload-thumb {
    height: 60px;
  } */
  .uploading-image .upload-thumbnail .upload-thumb img {
    height: 60px;
  }

  .uploading-image .upload-thumbnail .upload-thumb img.active {
    height: 54px;
  }

  .upload-project-big-thumbnail img {
    height: 200px;
  }

  .uploading-image .card {
    width: 100%;
  }

  .uploading-image .upload-thumbnail {
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  }

  .uploading-image .upload-thumbnail {
    grid-gap: 15px 0px;
  }

  .uploading-image .card {
    height: 250px;
  }

  .upload_url {
    height: 250px;
  }

  .upload_url .ext-url {
    max-width: 300px;
  }

  .uploading-image .upload-file {
    height: 250px;
  }

  .upload-a-project {
    background-size: 120px;
    background-position: 104% 20%;
  }

  .videoPlayer {
    height: 250px;
  }

  .video_iframe {
    width: 333px;
    height: 250px;
    margin: auto;
  }

  .videoPlayer .reactplayer {
    width: 100% !important;
    height: 250px !important;
    margin-top: 0px;
  }

  .onboarding_style_questions .content li {
    margin-right: 15px;
    max-width: 160px;
  }

  .onboarding_style_questions.xl_size .content li {
    max-width: 160px;
  }

  .onboarding_style_questions.xl_size .content .deadline {
    height: 128px;
  }

  .overflow-content.chat-height.post-box-overflow {
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    margin-bottom: 120px !important;
  }

  .overflow-content.inComingBrief {
    height: unset;
    max-height: 320px;
  }

  .overflow-content.incoming-max-height {
    /* height: calc(100vh - 80px);
      max-height: calc(100vh - 80px); */
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
    padding-bottom: 60px !important;
  }

  .overflow-content.incoming-max-height.hide-buttons {
    height: calc(100vh - 20px);
    max-height: calc(100vh - 20px);
  }

  .overflow-content.incoming-height {
    max-height: calc(100vh - 425px);
  }

  .user-work-section .project_big_image {
    min-height: 250px;
  }

  .mobile_modal_footer_fixed {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 999;
  }

  .preview_image_upload {
    min-height: 250px;
  }

  .submit-milestone-iframe {
    width: 100%;
    height: calc(100vw / 1.333);
  }

  /*-- progress bar loader --*/
  .c100 {
    font-size: 40px;
  }

  /*-- Account Setting & Account Security --*/
  .profile_title {
    font-size: 16px;
  }

  .profile-subTitle {
    font-size: 12px;
  }

  .face-issue.bg-image {
    background-size: 170px;
    background-position: right bottom;
    min-height: 170px;
    border-radius: 0px;
  }

  .profile-help h2,
  .profile-help h3 {
    font-size: 15px;
  }

  .profile-help button {
    font-size: 14px;
  }

  .account-setting .account-client {
    background-position: 30px 5px;
  }

  .account-setting .account-creator {
    background-position: 30px 5px;
  }

  .account-setting h2 .underline_1 {
    width: 58%;
  }

  .account-setting-dropdown .select-class {
    left: 8px;
    top: 42px;
  }

  .profile-headline {
    padding: 15px;
  }

  .wallet {
    width: 60%;
    word-break: break-all;
  }

  .wallet .profile-subTitle {
    display: inline-block;
    margin-top: 5px;
  }

  .account-deactivated {
    min-height: 100px;
  }

  /*-- Post a project --*/
  .deadline {
    height: 150px;
    background-size: 130px;
  }

  .deadline-project .content {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    padding: 5px 0px;
  }

  .overflow-content.project-height {
    height: calc(100vh - 165px);
    padding-bottom: 100px;
    max-height: calc(100vh - 165px);
  }

  .overflow-content.budget-deadline {
    height: calc(100vh - 165px);
    max-height: calc(100vh - 165px);
  }

  .overflow-content.type-height {
    height: calc(100vh - 165px);
    padding-bottom: 40px;
    max-height: calc(100vh - 165px);
  }

  /*-- Creator Mobile Slider --*/
  .creatorSliderImage .carousel-item img {
    height: 220px;
  }

  .mobile-footer-recommendation {
    bottom: -18px;
  }

  .mobile_full_screen_modal .modal-dialog {
    -webkit-transform: translate(0px, 0px) !important;
    transform: translate(0px, 0px) !important;
  }

  .mobile_full_screen_modal .overflow-content.basic-height {
    max-height: calc(100vh - 95px);
  }

  .custom_form .close_icon.recommendation_modal_icon {
    right: 15px;
    top: 15px;
  }

  .mob-price-range-modal .range_slider > div {
    height: 5px;
  }

  .mob-price-range-modal .range_slider > div > .range {
    height: 5px;
  }

  .mob-price-range-modal .range_slider > div > .inverse-right {
    height: 5px;
  }

  .mob-price-range-modal .range_slider > div > .inverse-left {
    height: 5px;
  }

  .mob-price-range-modal .range_slider {
    margin: 0px;
  }

  .mob-level-icon .post-a-icon i {
    width: 30px;
    height: 30px;
    line-height: 28px;
    font-size: 13px;
  }

  .mob-request-call-modal .custom_checkbox input:checked + label:after {
    top: 3px;
  }

  /*-- Chat Screen Section --*/
  .overflow-content.chat-height.mob-chat-height {
    height: calc(100vh - 285px);
    max-height: calc(100vh - 285px);
  }

  .input-chat {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
  }

  .chat-box-issue .bg-image-creator {
    background-position: right 36px;
    background-size: 190px;
  }

  .timeline-due-date .vertical-line.advance-payment {
    bottom: -22px;
    height: 50%;
  }

  .timeline-due-date .vertical-line {
    height: 30%;
    bottom: -31px;
  }

  /* === chat screen modals css ===== */
  .overflow-content.chat-height.chatting-box-overflow {
    height: calc(100vh - 300px);
    padding-bottom: 40px;
  }

  .overflow-content.chat-height.chatting-box-overflow.tab-none {
    height: calc(100vh - 250px);
    padding-bottom: 40px;
  }

  .overflow-content.mobile-milestone-overflow {
    height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }

  .overflow-content.chat-modal-height.creator-feedback {
    max-height: unset;
  }

  .overflow-content.chat-modal-height {
    height: calc(100vh - 175px);
    max-height: calc(100vh - 175px);
  }

  .overflow-content.chat-modal-height.new-proposal-height {
    /* height: calc(100vh - 240px);
    max-height: calc(100vh - 240px); */
    height: calc(100vh - 180px);
    max-height: calc(100vh - 180px);
  }

  .overflow-content.chat-modal-height.files-folder {
    height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
  }

  .overflow-content.chat-modal-height.version-history {
    max-height: calc(100vh - 100px);
    height: calc(100vh - 100px);
  }

  .overflow-content.project-details-height {
    height: calc(100vh - 200px);
    max-height: calc(100vh - 200px);
  }

  .project-delivery-date:before {
    left: -30px;
  }

  .feedback-creator .feedback-better:before,
  .feedback-creator .feedback-better:after {
    height: 1px;
    background-color: #c8cbd0;
  }

  .milestone-deliverable .milestone-img {
    height: 125px;
  }

  .mobile-tabs-milestone .wrapper-form {
    border: 1.5px solid #e7e7e7;
  }

  .position-bottom-modal {
    position: absolute;
    width: 100%;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    bottom: 0px;
  }

  .project-delivery-date:before {
    left: -30px;
  }

  .feedback-creator .feedback-better:before,
  .feedback-creator .feedback-better:after {
    height: 1px;
    background-color: #c8cbd0;
  }

  .milestone-deliverable.deliverable_images {
    width: 116px !important;
    height: 88px;
  }

  .milestone-deliverable.deliverable_images .send_deliverable {
    height: 88px;
  }

  .mobile-tabs-milestone .wrapper-form {
    border: 1.5px solid #e7e7e7;
  }

  .mobile_proposal_cost .tool::after {
    margin-left: -5.75em;
  }

  /*-- Milestones Modal Css --*/
  .milestone-approve-section {
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .overflow-content.mob-milestone-modal {
    max-height: unset;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;
    height: calc(100vh - 285px);
  }

  .overflow-content.chat-milestone-modal-height {
    max-height: calc(100vh - 120px);
  }

  .chat-modal-section .version-profile img {
    width: auto;
    height: auto;
    max-height: 50px;
  }

  .file-folder-images .content {
    padding: 0px 10px;
    display: grid;
    list-style: none;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }

  .file-folder-images .content li img {
    width: auto;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  /*-- Milestones Modal Css --*/
  .milestone-approve-section {
    border-radius: 0px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .overflow-content.mob-milestone-modal {
    max-height: unset;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;
    height: calc(100vh - 285px);
  }

  .overflow-content.mob-milestone-modal.creator_review_milestone {
    max-height: unset;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 10px;
    height: calc(100vh - 225px);
  }

  .overflow-content.mobile_version_history_height {
    max-height: calc(100vh - 130px);
    height: calc(100vh - 130px);
  }

  .overflow-content.chat-milestone-modal-height {
    max-height: calc(100vh - 120px);
  }

  .chat-modal-section .version-profile img {
    width: auto;
    height: auto;
    max-height: 50px;
  }

  .file-folder-images .content {
    padding: 0px 10px;
    display: grid;
    list-style: none;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }

  .file-folder-images .content li img {
    width: auto;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
  }

  .overflow-content.review_proposal {
    height: calc(100vh - 170px);
    max-height: calc(100vh - 170px);
    padding-bottom: 70px;
    margin-bottom: 0px;
  }

  .overflow-content.review_proposal.mobile_creator_proposal {
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
  }

  /*-- set quote and milestones --*/
  .add_milestone .add-collateral-underline {
    width: 65%;
  }

  .client-cost .project-delivery-date:before {
    left: -5px;
  }

  .timeline-due-date.quote-breakdown .vertical-line {
    height: 32px;
    bottom: -38px;
  }

  .timeline-due-date.quote-breakdown:first-child .vertical-line {
    height: 42px;
    bottom: -44px;
  }

  /*-- Fullscreen View modal --*/
  .fullscreen-scroll {
    overflow-x: scroll;
  }

  .fullscreen_modal .user-work-thumbnail {
    padding: 15px 0px 5px;
    margin: 0px;
    justify-content: unset;
    flex-wrap: unset;
    display: inline-flex;
    width: auto;
  }

  /*=== styles & types question =====*/
  .tabs-icon::after {
    left: 70px;
  }

  .tabs-icon.milestone-icon::after {
    left: 10vw;
  }

  .tabs-icon.milestone-icon.first_icon::after {
    height: 132%;
  }

  /*-- view all proposal --*/
  .view-all-proposal .basic_profile {
    width: 50px;
    height: 50px;
  }

  .view-all-proposal .basic_profile img {
    max-height: 50px;
    height: 50px;
  }

  .style_name {
    font-size: 14px;
  }

  .review.brief_posted {
    width: 35px;
  }

  /*===== calling modal =======*/
  .custom_form.calling_modal .modal-content {
    border-radius: 12px;
    height: auto;
  }

  /*======= kick_off_project ======*/
  .kick_off_project .modal-dialog {
    width: 95%;
    margin: auto;
  }

  .kick_off_project .cash_icon .profile-user-image {
    width: 70px;
    height: 70px;
  }

  .kick_off_project .cash_icon .profile-user-image img {
    max-width: 50px;
  }

  /*====== kick_off_project End ====*/

  /*==== Landing Page =====*/
  .all_service_banner.all_services_mob,
  .all_service_banner.footer_banner {
    background-image: none;
    min-height: auto;
  }

  .all_service_banner .footer_mob {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .img-area {
    width: 70vw;
    height: calc(70vw / 1.333);
  }

  .boat-img {
    margin: 15px auto 0;
    max-width: 85%;
  }

  /*=== Services Page ===*/
  .mob-container {
    max-width: 650px;
  }

  .services_card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .services_card .service-box-logo {
    width: 340px;
    height: calc(340px / 1.3333);
  }

  /* .parent-category-menu{
    padding:0 15px;
  } */

  /*===== Landing Page Exit =====*/

  /*==== chat Bottom Input ====*/
  .chat-summernote .note-editor.note-frame .note-placeholder,
  .chat-summernote .note-editor.note-airframe .note-placeholder {
    margin-left: 10%;
  }

  .chat-summernote .note-editor.note-frame .note-editing-area .note-editable,
  .chat-summernote
    .note-editor.note-airframe
    .note-editing-area
    .note-editable {
    margin-left: 10%;
    margin-right: 16.9%;
  }

  /*-- Chat Lightbox modal --*/
  .overflow-content.overflow-lightbox {
    padding-bottom: 20px;
  }

  .custom_form.lightbox-modal .modal-content {
    height: calc(100vh - 50px);
    margin-top: 50px;
  }

  .lightbox-modal .lightbox-big-preview {
    width: 320px;
    height: calc(320px / 1.333);
  }

  /*==== User Survey CSS ====*/
  .user-survey .btn-scale {
    min-width: 45px;
    font-size: 12px;
  }

  .user-survey .survey-radio .form-check {
    margin-bottom: 15px;
  }

  /*==== Active Brief Page ====*/
  .active_brief_page .active_brief_tabs {
    padding: 20px 15px;
  }

  .active_brief_page .main-content .nav {
    padding: 0;
    overflow: unset;
  }

  .active_brief_page .main-content .nav-tabs .nav-link {
    min-width: 80px;
  }

  .active_brief_cards .card-body .project_slider .owl-prev {
    left: 0%;
  }

  .active_brief_cards .card-body .project_slider .owl-next {
    right: 0%;
  }

  .activeBrief_Modal .modal-body {
    padding: 30px 15px;
  }

  .activeBrief_Modal .profile {
    margin: auto;
  }

  .active_profile_wrapper .dropdown.m-show {
    position: absolute;
    top: 12%;
    right: 5%;
  }

  .active_profile_wrapper .dropdown .dropdown-menu {
    transform: translate3d(-88px, 28px, 0px) !important;
  }

  .active_profile_wrapper .dropdown-menu-arrow:after,
  .active_profile_wrapper .dropdown-menu-arrow:before {
    right: 20px;
  }

  .briefServices .content li {
    width: 100%;
  }

  .briefServices .content .project-view {
    min-height: 220px;
  }

  /* Page Not Found CSS */
  .page_not_found .page_inner h3 {
    font-size: 40px;
    font-weight: 800;
    margin: 20px 0;
  }

  .page_not_found .page_inner .sub_text {
    font-size: 16px;
    font-weight: 600;
    padding: 0 0px;
    margin: 30px 0;
  }

  /* Call Confirmed */
  .call_confirmed .call_wrapper {
    padding: 20px;
  }

  .call_confirmed .call_wrapper .notes {
    width: 100%;
  }

  .call_confirmed .call_wrapper h1 {
    font-size: 1rem;
    font-weight: 800;
  }

  .call_confirmed .call_wrapper p {
    font-size: 12px;
  }

  .mobile_footer_div {
    position: fixed;
    bottom: -1px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 100;
    padding: 15px;
    border-radius: 0px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  }

  /* Scope of Work */
  .deadline-project .deadline {
    background-size: 60px;
    height: 220px;
  }

  .profile_selection_wrapper .proceed_btn {
    padding: 10px 12px;
  }
}

@media screen and (min-width: 641px) {
  .timeline-due-date .timeline:before {
    left: 25px;
  }

  .timeline-due-date .timeline__box {
    padding-left: 70px;
    padding-top: 15px;
  }

  .timeline-due-date .timeline__date {
    top: 50%;
    margin-top: -27px;
  }
}

@media screen and (max-width: 640px) {
  .timeline-due-date .timeline__box {
    padding-left: 70px;
  }

  .timeline-due-date .timeline__date {
    top: 0;
  }
}

@media (max-width: 576px) {
  .custom_form .mob_background_image {
    display: block;
  }

  .custom_form .remove-pd {
    display: none;
  }

  /*-- Post a Project --*/
  .post_a_project .user-upload-content-work {
    background-size: 130px;
    height: 115px;
    background-position-y: inherit;
  }

  .custom_url .add-collateral-underline {
    width: 58%;
  }
}

@media only screen and (min-width: 380px) and (max-width: 480px) {
  .wrapper-form .input-icon:nth-child(2) {
    top: 35px;
  }

  .wrapper-form .input-icon.icon-right {
    right: 130px;
    top: 35px;
  }

  .web-links {
    margin-left: 125px;
  }

  /*-- Profile creator modals -- */
  .edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
    right: 120px;
  }

  /*-- Upload a project --*/
  .uploading-image .upload-thumbnail {
    grid-gap: 15px 12px;
  }

  /* .uploading-image .upload-thumbnail .upload-thumb {
    width: 68px;
  } */

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -35px !important;
  }

  .mobile-footer-recommendation {
    bottom: -18px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 420px) {
  .web-links {
    margin-left: 130px;
  }

  /*-- Upload Image Thumbnail --*/
  .uploading-image .upload-thumbnail {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }

  .upload_image_thumbnail {
    min-height: 70px;
  }
}

@media only screen and (min-width: 361px) and (max-width: 379px) {
  /*-- Small Screen Font -- */
  .small-mid-p-0 {
    padding: 0px !important;
  }

  .small-mid-font-13 {
    font-size: 13px !important;
  }

  .web-links {
    margin-left: 125px;
  }

  .div-border:before {
    left: -25px !important;
  }

  /*-- Creator Mobile Slider --*/
  /* .creatorSliderImage .carousel-item img {
    height: 150px;
  } */

  /*-- Set Quote And Milestones ---*/
  .client-cost .project-delivery-date:before {
    left: 4px;
  }

  .uploading-image .upload-thumbnail {
    grid-gap: 15px 18px;
  }

  /*====== onboarding images ========*/
  .onboarding_style_questions .content li {
    margin-right: 15px;
    max-width: 120px;
  }

  .onboarding_style_questions .content .deadline {
    height: 91px;
  }

  .onboarding_style_questions.xl_size .content li {
    max-width: 150px;
  }

  .onboarding_style_questions.xl_size .content .deadline {
    height: 113px;
  }

  /*=== chat Bottom Input ===*/
  .chat-summernote .note-editor.note-frame .note-editing-area .note-editable,
  .chat-summernote
    .note-editor.note-airframe
    .note-editing-area
    .note-editable {
    margin-left: 10%;
    margin-right: 18%;
  }
}

@media (max-width: 360px) {
  /*-- Small Screen Font -- */
  .small-mid-p-0 {
    padding: 0px !important;
  }

  .small-mid-col-30 {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  .mobile-footer-fixed ul li a {
    font-size: 12px;
  }

  .mobile-header-edit i {
    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 18px;
    cursor: pointer;
  }

  .web-links {
    margin-left: 120px;
  }

  /*-- Upload Image Thumbnail --*/
  .upload_image_thumbnail {
    min-height: 58px;
  }

  /*-- Profile creator modals -- */
  .edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
    right: 96px;
  }

  .add-collateral-underline {
    width: 60%;
  }

  .custom_url .add-collateral-underline {
    width: 60%;
  }

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -18px !important;
  }

  /*-- Creator Mobile Slider --*/
  /* .creatorSliderImage .carousel-item img {
    height: 150px;
  } */

  /*-- Didn't like the recommendations Modal --*/
  .modal360-p-15 {
    padding: 15px !important;
  }

  /*==== Chat Screen modal css =======*/
  .project-delivery-date:before {
    left: -20px;
  }

  /*-- File Folder Modal Css --*/
  .file-folder-box {
    width: 80%;
  }

  /*-- Set Quote And Milestones ---*/
  .client-cost .project-delivery-date:before {
    left: 6px;
  }

  .uploading-image .upload-thumbnail {
    grid-gap: 15px 25px;
  }

  /*=== styles & types question =====*/
  .tabs-icon::after {
    left: 58px;
  }

  .onboarding_style_questions .content li {
    max-width: 120px;
    margin-right: 14px;
  }

  .onboarding_style_questions .content .deadline {
    height: 91px;
  }

  .onboarding_style_questions.xl_size .content li {
    max-width: 140px;
  }

  .onboarding_style_questions.xl_size .content .deadline {
    height: 106px;
  }

  /*=== chat Bottom Input ===*/
  .chat-summernote .note-editor.note-frame .note-editing-area .note-editable,
  .chat-summernote
    .note-editor.note-airframe
    .note-editing-area
    .note-editable {
    margin-left: 10%;
    margin-right: 19%;
  }
}

@media (max-width: 330px) {
  /*-- Small Screen Font -- */
  .small-p-0 {
    padding: 0px !important;
  }

  .small-pl-0 {
    padding-left: 0px !important;
  }

  .small-pr-0 {
    padding-right: 0px !important;
  }

  .small-pl-5 {
    padding-left: 5px !important;
  }

  .small-p-15 {
    padding: 15px !important;
  }

  .small-font-8 {
    font-size: 8px !important;
  }

  .small-font-9 {
    font-size: 9px !important;
  }

  .small-font-10 {
    font-size: 10px !important;
  }

  .small-font-11 {
    font-size: 11px !important;
  }

  .small-font-12 {
    font-size: 12px !important;
  }

  .small-font-14 {
    font-size: 14px !important;
  }

  .small-font-16 {
    font-size: 16px !important;
  }

  /*-- small margin --*/
  .small-mt-60 {
    margin-top: 60px !important;
  }

  .small-m-0 {
    margin: 0px !important;
  }

  /*-- Small Screen Padding -- */
  .small-py-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .wrapper-form .input-icon.icon-right {
    right: 88px;
    top: 52px;
  }

  .web-links {
    margin-left: 105px;
  }

  .timeline-item {
    width: 90%;
  }

  .mob_banner_text .dashboard-welcome {
    width: 60%;
  }

  /*-- mobile footer navigation -- */
  .mobile-footer-fixed ul li a .footer-icon-border.mobile-upload {
    font-size: 18px;
    width: 35px;
    height: 35px;
    line-height: 28px;
  }

  /* .mobile-footer-fixed ul li a .upload-btn-minus {
    margin-top: -12px;
  } */
  .mobile-footer-fixed ul li a .footer_icon {
    font-size: 16px;
  }

  .mobile-footer-fixed ul li a .footer_icon.extra-size {
    font-size: 22px;
  }

  /*-- Profile creator modals -- */
  .add-collateral-underline {
    width: 60%;
  }

  .custom_url .add-collateral-underline {
    width: 54%;
  }

  .edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
    right: 78px;
  }

  .custom_form.edit-basic-profile .close_icon {
    top: -10px;
  }

  .custom_form.edit-basic-profile.mobile_full_screen_modal .close_icon {
    top: 15px;
  }

  /*-- Progress bar loader --*/
  .uploading-image .upload-thumbnail .upload-thumb img.active {
    height: 43px;
  }

  .uploading-image .upload-thumbnail {
    grid-gap: 15px 30px;
  }

  .upload_image_thumbnail {
    min-height: 50px;
  }

  /*-- Account Setting Billing Information --*/
  .account-deactivated {
    min-height: 80px;
  }

  .upload-a-project {
    background-size: 120px;
    background-position: 115% 20%;
  }

  /*-- Creator Recommendations --*/
  .div-border:before {
    left: -18px !important;
  }

  /*-- Creator Mobile Slider --*/
  /* .creatorSliderImage .carousel-item img {
    height: 130px;
  } */
  .creatorSliderImage .carousel-control-next,
  .creatorSliderImage .carousel-control-prev {
    margin: 0px 5px;
  }

  /*-- Chat Screen Modal css --*/
  .project-delivery-date:before {
    left: -18px;
  }

  .feedback-creator .feedback-better:before,
  .feedback-creator .feedback-better:after {
    width: 16%;
  }

  /*-- File Folder Modal Css --*/
  .file-folder-box {
    width: 90%;
  }

  /*=== styles & types question =====*/
  .tabs-icon::after {
    left: 50px;
  }

  .onboarding_style_questions .content li {
    max-width: 100px;
  }

  .onboarding_style_questions .content .deadline {
    height: 76px;
  }

  .onboarding_style_questions.xl_size .content li {
    max-width: 120px;
  }

  .onboarding_style_questions.xl_size .content .deadline {
    height: 91px;
  }

  .style_name {
    font-size: 13px;
  }

  /*-- Post a Project --*/
  .post_a_project .user-upload-content-work {
    height: 85px;
  }

  /*-- Chat Lightbox modal --*/
  .lightbox-modal .lightbox-big-preview {
    width: 280px;
    height: calc(280px / 1.333);
  }

  /*=== Services Page ===*/
  .services_card .service-box-logo {
    width: 290px;
    height: calc(290px / 1.3333);
  }
}
