// ask question Modal CSS
.ask_question{
    .modal-content{
        border-radius: 0.429rem;
        border: none;
        // padding:2rem;
        .modal-header{
            padding: 2rem 2rem 1.4rem;
            border-color:$bg-gray-500;

            .header_title{
                .modal_title{
                    font-size: 1.429rem;
                    font-weight: 700;
                    line-height: 1.714rem;
                    color:$text-black-700;
                }
                .close_btn{
                    opacity: 1.5;
                }
            }
            .header_subtitle{
                color:$text-gray-1200;
                font-weight: 600;
                font-size: 1rem;
                line-height: 150%;
            }
           
        }
        .modal-body{
            padding: 0rem 2rem;
            .add_question{
                .question_box{
                    p{
                        color:$text-black-700;
                        font-weight: 700;
                        font-size: 1rem;
                        line-height: 1.286rem;
                    }
                    input{
                        height: 3.285rem;
                        border: 0.071rem solid $border-gray-300;
                        border-radius: 0.429rem;
                    }

                }
            }
            // .question_box:nth-last-child(1){
            //     p{
            //         color:$bg-blue-800;
            //         font-weight: 700;
            //         font-size: 1rem;
            //         line-height: 1.286rem;
                    
                    
            //     }
            // }
        }
        .modal-footer{
            padding: 1rem; 
            border-color:$bg-gray-500;
            .btn_submit{ 
                padding: 0.6rem 2rem;
            }
        }  
        
        // Custom Input Design
        .cstm_checkbox{
            position:inherit;
            top: 0rem;
            left: 0rem;
        }
        .cstm_checkbox input{
            padding:0;
            height:initial;
            width: initial;
            display: none;
            cursor:pointer;
        }
        .cstm_checkbox label{
            position: relative;
            cursor: pointer;
            color:$text-black;
            font-size:1.1rem;
            margin-bottom:0rem;
        }
        .cstm_checkbox label:before{
            content:'';
            background-color: transparent;
            border:0.1rem solid $text-gray-300;
            padding:10px;
            border-radius: 0.5rem;
            display:inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 0px; 
            transition: background-color 0.3s ease-in-out;
        }
        .cstm_checkbox input:checked + label::after{
            content:'';
            display: block;
            position: absolute;
            top: 5px;
            left: 8px;
            width: 8px;
            height: 12px;
            border:solid $bg-white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .cstm_checkbox input:checked + label::before{
            content:'';
            background-color: $bg-blue-800;
            border-color: $bg-blue-800;
        }
       
         
    }

}

//==================================== media-query==============================

@media (max-width :$breakpoint-sm) {
    .ask_question{
        .modal-dialog{
            width:90vw;
            margin: auto;
            .modal-header{
                .header_title{
                    .modal_title{
                        font-size: 1rem;
                        line-height: 2.4rem;
                    }
                }

            }
            .modal-body{
                .add_question{
                    .question_box{
                        p{
                        font-size:1.2rem !important;
                        line-height: 1.7rem;
                        }
                        input{
                            height: 4rem;
                            border: 0.071rem solid #EFF1F5;
                            border-radius: 0.6rem;
                        }
    
                    }
                    .question_box:nth-last-child(1){
                        p{
                            line-height: 1.5rem;
                            
                        }
                    }
                }
            }
            .modal-footer{
                .btn_submit{ 
                    padding: 1.4rem 3rem;
                    font-size: 1.2rem;
                    border-radius: 0.6rem;
                }
            }   
        }
    } 
    
}