.creator_active_brief_chat_project_room{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .creator_active_brief_chat_project_room_container{
        padding:2rem;
        padding-bottom: 0px;
       
            // .proposal_header{
            //     padding:1rem 1rem; 
            //     min-height: 4.9rem;
            // }
            .description{
            font-size: 0.857rem;
            font-weight: 600;
            line-height: 1.214rem;
            color:$text-gray-1300;
            }

            //top main nav tabs
             .pills_tab_nav{
                .nav-link.active{
                  color:$text-blue-800;
                  background-color: $bg-white;
                }
                .nav-link{
                  color:$text-gray-1300;
                  font-weight: 700;
                  font-size: 1.143rem;
                  background-color: transparent;
                  padding: 0.714rem 2.43rem;
                  border-radius: 0.429rem; 
                }

            }

            // chat_custom_tabs
            
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1rem 0rem;
                margin-right: 1rem;
                margin-left:1rem;
                font-size: 1.143rem;
                font-weight: 600;
                color:rgba($color: $text-gray-1300, $alpha: 100);
                cursor: pointer;
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 

            // profiles_image_dimensions

            // user_profile
            .user_profile_image{
                width: 50px;
                height: 50px;
                background-color:$bg-blue-200;
                border: 0.071rem solid $border-gray-300;
                // text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }
            .company_profile_image{
                width: 50px;
                height: 50px;
                background-color:$bg-blue-200;
                border: 0.071rem solid $border-gray-300;
                // text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;

                img{
                    width:100%;
                    height:100%;
                    object-fit: cover;
                }
            }
            // company_profile
            .user_company_profile_image{
                    // width: 20px;
                    // height: 20px;
                    background-color:$bg-blue-200;
                    // border: 0.071rem solid $border-gray-300;
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    line-height: 18px;
                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }

            }
            .user_company_profile_name{
                // width: 20px;
                // height: 20px;
                background-color:transparent;
                // border: 0.071rem solid $border-gray-300;
                width: 30px;
                height: 30px;
                overflow: hidden;
                line-height: 18px;
            img{
                width:100%;
                height:100%;
                object-fit:none;
            }

            }
            // more_users_profile_image
            .more_users_profile{
                // width: 20px;
                // height: 20px;
                background-color:$bg-purple-300;
                // border: 0.071rem solid $border-gray-300;
                width: 30px;
                height: 30px;
                overflow: hidden;
                line-height: 18px;
                border: 0.071rem solid #F5F7FD;
                margin-left: -0.8rem;
                display:flex;
                align-items: center;
                justify-content: center;
            img{
                width:100%;
                height:100%;
                object-fit:none;
            }

            }

            .attendees_img{ 
                background-color:$bg-purple-300; 
                width: 20px;
                height: 20px;
                overflow: hidden;
                line-height: 18px;
                border: 0.071rem solid #F5F7FD;
                margin-left: -0.8rem;
                display:flex;
                align-items: center;
                justify-content: center;
                img{
                    width:100%;
                    height:100%;
                    object-fit:none;
                }
            }

            .user_img{ 
                width: 40px;
                height: 40px;
                overflow: hidden;
                line-height: 18px; 
                margin-left: -0.8rem;
                display:flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                img{
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
            }

            // icon_profile
            .icon_profile_image{
                width: 40px;
                height: 40px;
                background-color:$text-white;
                border: 0.071rem solid $border-gray-500;
                // text-align: center;
                // display: flex;
                // align-items: center;
                // justify-content: center;

                img{
                    width:100%;
                    height:100%;
                    object-fit: none;
                }
            }
            // .url_input{
            //     position: relative;
            //     padding: 0rem 0rem;
            //     color:$text-blue-800;
            //     text-decoration: underline;
            //     ::placeholder{
            //         text-decoration: none;
            //     }
            // }
           
            .import{
                background: rgba($bg-gray-300, 0.15);
                border-radius:0.429rem;
                padding: 0.9rem;
            }
            
            //=============== left_side ================

            // conversation_card
            .Conversation_card{
                cursor: pointer;    
            }
             .Conversation_card.active{
                background-color: $bg-blue-100;
            }
            
            .conversation_card_inner{
                border-bottom:0.071rem solid rgba($color:$border-gray-400, $alpha: 0.2) ;
            }
            .conversation_body{
                // max-height: calc(100vh - 258.06px - 3rem - 18.38px - 30px);
                min-height:calc(100vh - 235px);
                max-height:calc(100vh - 235px);
                overflow-y: auto;
            }

            .user_info{
                display: flex;
                flex-direction: column;
                flex: auto;
            }
            
               
            // textarea
            // .outer_textarea{
            //     padding-left:1rem;
            //     padding-right: 1rem;
            //     position:absolute;
            //     bottom:-1.5rem;
            //     left: 0;
            //     right:0;
            //     width:100%;
            // }
            .upload_notes{
                // min-height: 10.143rem;
                background-color: #fff;
                border: 0.071rem solid $border-gray-600;
                border-radius:0.286rem;
                padding: 0 0.714rem;
                position: relative;
                min-height: 104px;
                max-height: 8rem;
                .send_icon{
                    position:absolute;
                    top:0.8rem;
                    right: 0;
                    z-index:100;
                }
                textarea{
                    // max-height: 4rem;
                    color:rgba($color: #050421, $alpha: 0.4);
                    // padding-left: 2.5rem;
                    padding-right: 2.5rem;
                    min-height: 5.5rem; 
                    max-height:calc(100vh - 900px);
                    overflow-y: auto;
                    background-color: transparent;
                }
            }
            .upload_media_icons{
                padding:.375rem .75rem;
            }

             //============================ right_side=======================

             // user_massage for both tab(chat and meeting)
            .user_massage{
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            // chat_body_container
            .chat_body{
                padding-top: 0rem;
                padding-bottom: 1rem;
                // min-height: calc(100vh - 229.47px - 4rem - 163px);
                // max-height:calc(100vh - 229.47px - 4rem - 163px);
                min-height: calc(100vh - 345px);
                max-height:calc(100vh - 345px);
                overflow-y:auto ;
               
            }
            
            // when chat body is empty
            .empty_chat_box{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            // meadia_uploads
            .user_uploads{
                flex-wrap: wrap;
                align-items: baseline;
            }
            .pdf_upload{
                color: #6C7A8F;
                background-color: rgba(20, 100, 220, 0.1);
                align-items: center;
                border-radius: 0.286rem;
                padding: 0.9rem 1.5rem;
                font-weight: 400;
                font-size: 0.857rem;
                cursor: pointer;
                display:flex;
                margin-right: 1rem;
                margin-bottom: 1rem;
                justify-content: space-around;
                width: 150px;
                // grid-template-columns: auto auto auto;
                // grid-gap: 10px;
                // grid-template-rows: auto;
                // grid-auto-flow: row;
            }
            .image_tag{
                width: 150px;
                height: 100px;
                background-color: blue;
                margin-right: 1rem;
                margin-bottom: 1rem;
                border-radius: 0.143rem;
                img{
                    object-fit: cover;
                    width: 150px;
                    height: 100px;
                }
            }
            

            //======================== meeting_tab================

            // meeting_body_container
            .meeting_body{
                padding-top: 3rem;
                // padding-bottom: 3rem;
                // min-height: calc(100vh - 229.47px - 4rem);
                // max-height:calc(100vh - 229.47px - 4rem);
                min-height: calc(100vh - 270px);
                max-height:calc(100vh - 270px);
                overflow-y:auto ;
               
            }
            // meeting_link
            .meeting_link_tag{
                line-height: 2rem;
            }

            // ============== Project Details tab =======================
            //left
            
            //dropedownbox
            // .dropedown_container.show{
            //     right: 0px;
            //     }
            //     .dropedown_container{
            //         .btn:focus {
            //             outline: 0;
            //             box-shadow:none;
            //         }
            //     }
            //     .dropedown_icon{
            //         display: block;
            //         margin-bottom: 0.3rem;
            //     }
            //     .dropdown-menu{
            //         border-radius: 0.571rem;
            //         box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
            //     }
            //     .dropdown-menu.show{
            //         top: -33px !important;
            //         left: -133px !important;
            //     }
            //right

            .project_details_body{
                padding-top: 1rem;
                padding-bottom: 0rem;
                min-height: calc(100vh - 229.47px - 4rem - 72px);
                max-height:calc(100vh - 229.47px - 4rem - 72px);
                overflow-y:auto ;
               
            }

            // cards
            .view_proposal_card{
                background-color: $bg-blue-200;
                border: 0px;
                box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
                border-radius: 0.429rem;
                position: relative;
                .card_tag{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            // brief
            .brief_body{
                padding-top: 1rem;
                padding-bottom: 0rem;
                min-height: calc(100vh - 229.47px - 4rem - 72px);
                max-height:calc(100vh - 229.47px - 4rem - 72px);
                overflow-y:auto ;
               
            }




            
        // ===================== body_scroll_bar ==============
       
        .body_scroll_bar::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll_bar::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll_bar::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }

        // Info Box
        .info_box{ 

            .info_box_item_outer{
                // width:50%;

                .info_box_item{
                    width:calc(100% / 2 - 10px);
                    position:relative;   

                    @media(max-width:$breakpoint-xl){
                        width:calc(100% / 2 - 0px)
                    } 

                    &:last-child{
                        padding-left:3rem;

                        @media(max-width:$breakpoint-xxl){
                            padding-left:4rem;
                        }
                        @media(max-width:$breakpoint-1400){
                            padding-left:2.5rem;
                        }
                        @media(max-width:$breakpoint-1300){
                            padding-left:2rem;
                        }
                    }   

                    &:last-child:before{
                        width:0.010rem;
                        height:100%;
                        content:'';
                        position:absolute;
                        top:0;
                        left:0rem;
                        background-color:$bg-gray-300;
                    }
                    .gst{
                        color:$text-gray-500;
                        font-size: 0.675rem;
                        padding-left:0.300rem;
                    }
                }
            }
        }

        .msg_tag{
            background-color:$bg-blue-800;
            color:$text-white;
            padding:0.200rem 0.300rem 0.300rem;
            border-radius: 0.328rem;
            font-size: 0.675rem;
        }

        // chat card
        .user_quote{ 
            margin-left:1.5rem; 
            width:80%;

            .info_box_item_outer{ 

                .info_box_item{
                    width:calc(100% / 2 - 10px);
                    position:relative;   

                    @media(max-width:$breakpoint-xl){
                        width:calc(100% / 2 - 0px)
                    } 

                    &:last-child{
                        padding-left:3rem;

                        @media(max-width:$breakpoint-xxl){
                            padding-left:2rem;
                        }
                        @media(max-width:$breakpoint-1400){
                            padding-left:2.5rem;
                        }
                        @media(max-width:$breakpoint-1300){
                            padding-left:2rem;
                        }
                    }   

                    &:last-child:before{
                        width:0.010rem;
                        height:100%;
                        content:'';
                        position:absolute;
                        top:0;
                        left:0rem;
                        background-color:$bg-gray-300;
                    }
                    .gst{
                        color:$text-gray-500;
                        font-size: 0.675rem;
                        padding-left:0.300rem;
                    }
                }
            }
        }
         
        // files tab
        .files_card{
            position: relative;
            // Custom Input
        .cstm_checkbox{
            position: absolute;
            top: 1rem;
            right: 1rem;
            text-align: right;
        }
        .cstm_checkbox input{
            padding:0;
            height:initial;
            width: initial;
            display: none;
            cursor:pointer;
        }
        .cstm_checkbox label{
            position: relative;
            cursor: pointer;
            color:$text-black;
            font-size:1.1rem;
            margin-bottom:0rem;
        }
        .cstm_checkbox label:before{
            content:'';
            background-color: transparent;
            border:0.1rem solid $text-gray-300;
            padding:11px;
            border-radius: .5rem;
            display:inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            // margin-right: 15px; 
            margin-right: 0rem;
            transition: background-color 0.3s ease-in-out;
        }
        .cstm_checkbox input:checked + label::after{
            content:'';
            display: block;
            position: absolute;
            top: 5px;
            left: 8px;
            width: 8px;
            height: 12px;
            border:solid $bg-white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);

            @media(max-width:$breakpoint-md){
                top:3px;
            }
        }
        .cstm_checkbox input:checked + label::before{
            content:'';
            background-color: $bg-blue-800;
            border-color: $bg-blue-800;
        }
        }
        // Custom Input
        .files_header_wrapper{
     
        // select drope down
        .duration_fields{
            &::before{
                content: '';
                width:1px;
                -webkit-align-self: stretch;
                -ms-flex-item-align: stretch;
                align-self: stretch;
                background-color: $bg-gray-500;
                margin-bottom: 8px;
                margin-top: 8px;
            }
        }
        .dropedown_field{

           
            &.select_dropdown{
                min-width: 19rem;
                .css-yk16xz-control,
                .css-1pahdxg-control{
                    border:0px;

                    .css-1okebmr-indicatorSeparator {
                        -webkit-align-self: stretch;
                        -ms-flex-item-align: stretch;
                        align-self: stretch;
                        background-color: rgb(187, 25, 25);
                        margin-bottom: 8px;
                        margin-top: 8px;
                        width: 0px;
                        box-sizing: border-box;
                    }
                }
                .css-1pahdxg-control{
                    box-shadow: 0px 0px 0px 0px transparent;
                }
                .css-1pahdxg-control:hover,
                .css-1pahdxg-control:focus{
                    height:46px;
                    box-shadow: 0px;
                }
            }
        }
          // search input
        .Search_input{
            min-height: 54px;
        }     
    }
        
        .card_grid {
            max-height: calc(100vh - 324px);
            overflow-y: auto;
        }
        .card_grid::-webkit-scrollbar {
            width: 0.313rem;
        }
        .card_grid::-webkit-scrollbar-track {
            background-color :$bg-white;
        }
        .card_grid::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    }
            
} 



