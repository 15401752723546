// select_Revlent_project
.on_actuals_review_confirm{
    .on_actuals_review_confirm_modal_dialog{
        max-width:calc(100vw - 100px);
        @media(min-width:$breakpoint-sm){
            max-width: calc(100vw - 150px); 
        }
        @media(min-width:$breakpoint-lg){
            max-width: calc(100vw - 1200px); 
        }
         
        .modal_top_header {
         padding: 2.5rem 0rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }
        .btm_section{
            background-color: $bg-blue-200;
        }
     
        
            
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    
    }

}