// select_Revlent_project
.select_project_type{
    .submit_proposal_modal_dialog{
        max-width:1024px;
        // @media(min-width:$breakpoint-sm){
        //     max-width: calc(100vw - 150px); 
        // }
        // @media(min-width:$breakpoint-lg){
        //     max-width: calc(100vw - 700px); 
        // }
         
        .modal_top_header {
         padding: 2.5rem 0rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }
        
        .modal-body{
            padding: 4rem 2rem;
            // tabs
            .tab_pills{
                // .nav_link_btn{
                //     padding:1rem 0.5rem;
                //     background-color: $bg-blue-200;
                //     color:$text-black-700;
                //     border-radius: 0.429rem; 
                //     margin-right:1rem;
                // }
                // .nav_link_btn.active{
                //     padding:1rem 0.5rem !important;
                //     background-color: $bg-blue-800;
                //     color:$text-blue-100;
                // }
                // .nav_pills_link{
                //     padding:1rem 3rem;
                //     background-color: $bg-blue-200;
                //     border-radius: 0.429rem;  
                //     margin-right:1rem;
                // }
    
                // .nav-link.active{
                //     padding:1rem 3rem;
                //     background-color: $bg-blue-800;
                //     color:$text-blue-100;
                // }
            }
            .tabs_content{
                max-height: calc(100vh - 374px);
            }
            // ================== checkbox =============================
            .icon_box{
                // position: relative;
                .checkbox_icon{
                    // position: absolute;
                    // top:1rem;
                    // right: 2rem;
                    display: flex;
                    float: right;
        
                    input[type=checkbox] {
                        display: none;
                        
        
                        + label {
                            display: inline-block;
                            position: relative;
                            margin-bottom: 0rem;
                            padding: 0.9rem;
                            background-color: white;
                            border: 0.08rem solid $border-gray-400;
                            border-radius: 0.482rem;
                            cursor: pointer;
                            @media (max-width:$breakpoint-md){
                                padding: 1.2rem;
                                border: 0.133rem solid $border-gray-400;
                            }
                        }
                        
                    }
                    input[type=checkbox]:checked + label {
                        background-color:$bg-blue-800;
                        color: #A4B7C6;
                    }
                    
                    input[type=checkbox]:checked + label:after {
                        position: absolute;
                        left: 0.571rem;
                        top: 0.214rem;
                        color: #fff; 
                        content: '\2714'; 
                        font-size:0.929rem;
                        @media (max-width: $breakpoint-md){
                            font-size: 1.6rem;
                            left:0.429rem;
                        }
                    } 
        
                } 
    
            }
            .search_box{
                position: relative;
                input{
                    padding-right:2rem;
                }
                .search_icon{
                    position:absolute;
                    right: 1.2rem;
                    top:1.2rem;
                }
        
            }

            .preview_image_wrapper{
                width:100px;
                height:calc(100px / 1.22);

                .preview_image_card{
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    min-height: 100%;
                    border-radius: 6px;
                }
            }
            

            // Custom Input
            .cstm_checkbox{
                position: static;
                top: unset;
                right: unset;
                text-align: right;
            }
            .cstm_checkbox input{
                padding:0;
                height:initial;
                width: initial;
                display: none;
                cursor:pointer;
            }
            .cstm_checkbox label{
                position: relative;
                cursor: pointer;
                color:$text-black;
                font-size:1.1rem;
                margin-bottom:1rem;
            }
            .cstm_checkbox label:before{
                content:'';
                background-color: transparent;
                border:0.1rem solid $text-gray-300;
                padding:11px;
                border-radius: .5rem;
                display:inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 15px; 
                transition: background-color 0.3s ease-in-out;
            }
            .cstm_checkbox input:checked + label::after{
                content:'';
                display: block;
                position: absolute;
                top: 5px;
                left: 8px;
                width: 8px;
                height: 12px;
                border:solid $bg-white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);

                @media(max-width:$breakpoint-md){
                    top:3px;
                }
            }
            .cstm_checkbox input:checked + label::before{
                content:'';
                background-color: $bg-blue-800;
                border-color: $bg-blue-800;
            }
           
        }
        
        
        // ===================end checkbox==================
        
            
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    
    }

}