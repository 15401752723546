// Font Variables
$primary-font:'Mulish', sans-serif;

// Text Color Variables
$text-white:#fff;
$text-black:#000;
$text-primary:#10C89B;
$text-primary-100:#0CBD92;

$text-black-600:#292D32;
$text-black-500:#2D2D2D;
$text-black-700:#050421;
$text-black-800:#05051F;



$text-gray-50:#FAFAFA;
$text-gray-100:#F5F5F5;
$text-gray-200:#EEEEEE;
$text-gray-300:#E0E0E0;
$text-gray-400:#BDBDBD;
$text-gray-500:#9E9E9E;
$text-gray-600:#757575;
$text-gray-700:#616161;
$text-gray-800:#424242;
$text-gray-900:#212121;
$text-gray-1000:#E1E5EB;
$text-gray-1100:#6C7A8F;
$text-gray-1200:#53637B;
$text-gray-1300:#9393AB;
$text-gray-1400:#828190;
$text-gray-1500:#C9CED7;
$text-gray-1600:#565D66;






$text-blue-gray-50:#ECEFF1;
$text-blue-gray-100:#CFD8DC;
$text-blue-gray-200:#B0BEC5;
$text-blue-gray-300:#90A4AE;
$text-blue-gray-400:#78909C;
$text-blue-gray-500:#607D8B;
$text-blue-gray-600:#546E7A;
$text-blue-gray-700:#455A64;
$text-blue-gray-800:#37474F;
$text-blue-gray-900:#263238;


$text-blue-100:#F5F7FD;
$text-blue-700:#E8F0FB;
$text-blue-800:#1464DC;

$text-green-300:#90C028;

$text-drak-green-500:#00B665;

$text-red-400:#FF7262;
$text-red-500:#E16050;

// $text-yellow-400: #D2991C;
$text-yellow-500: #D2991C;


// Background Color Variables
$bg-primary:#10C89B;
$bg-white:#fff;
$bg-black:#000;

$bg-black-100:#051227;
$bg-black-700:#050421;
$bg-black-800:#05051F;

$bg-blue-100:#EAEEFB;
$bg-blue-200:#F5F7FD;
$bg-blue-300:#F5F9FF;
$bg-blue-400:#F6F8FD;
$bg-blue-500:#E5E5E5;
$bg-blue-600:#F2F7FF;
$bg-blue-700:#E8F0FB;
$bg-blue-800:#1464DC;

$bg-navy-blue-500:#393950;


$bg-purple-300:#A259FF;

$bg-gray-100:#F5F7FD1A;
$bg-gray-200:#C9CED7;
$bg-gray-300:#C4C4C4;
$bg-gray-400:#565D66;
$bg-gray-500:#EFF1F5;
$bg-gray-600:#E1E5EB;
$bg-gray-700:#9393AB;
$bg-gray-800:#6C7A8F;
$bg-gray-1000:#333333;



$bg-green-300:#90C028;


$bg-drak-green-500:#00B665;

$bg-red-500:#E16050;


$bg-yellow-500: #D2991C;
// border color
$border-white:#fff;

$border-gray-300:#EFF1F5;
$border-gray-400:#C9CED7;
$border-gray-500:#E1E5EB;
$border-gray-600:#E8ECF4;


$border-blue-800:#1464DC;





// Media Query Screen Breakpoints
$breakpoint-1920:1920px;
$breakpoint-xxxl:1580px;
$breakpoint-xxl:1440px;
$breakpoint-1400:1400px;
$breakpoint-1300:1300px;
$breakpoint-xl:1200px;
$breakpoint-lg:1030px;
$breakpoint-md:991px;
$breakpoint-sm:767px;
$breakpoint-xs:576px;


