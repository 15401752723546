/* ===============3d slider============ */

.z-index {
    z-index: 9;
}

.deshboard-r-width {
    width: 100%;
}

#cubic-slider [type=radio] {
    display: none;
}

#cubic-slider img.img-slider {
    width: 100%;
    border-radius: 8px;
    height: 100%;
}

.slider-tittle {
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    padding: 8% 12%;
}

#cubic-slider label::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: rgb(15 14 14 / 16%);
    border-radius: 12px;
}

#cubic-slider {
    height: 16vw;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
}

#cubic-slider label {
    margin: auto;
    width: 50%;
    height: 100%;
    border-radius: 4px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
    transition: transform 0.4s ease;
}

#s1:checked~#slide4,
#s2:checked~#slide5,
#s3:checked~#slide1,
#s4:checked~#slide2,
#s5:checked~#slide3 {
    transform: translate3d(45%, 0, -300px);
}

#s1:checked~#slide5,
#s2:checked~#slide1,
#s3:checked~#slide2,
#s4:checked~#slide3,
#s5:checked~#slide4 {
    transform: translate3d(60%, 0, -400px);
}

#s1:checked~#slide1,
#s2:checked~#slide2,
#s3:checked~#slide3,
#s4:checked~#slide4,
#s5:checked~#slide5 {
    transform: translate3d(0, 0, 0);
}

#s1:checked~#slide2,
#s2:checked~#slide3,
#s3:checked~#slide4,
#s4:checked~#slide5,
#s5:checked~#slide1 {
    transform: translate3d(15%, 0, -100px);
}

#s1:checked~#slide3,
#s2:checked~#slide4,
#s3:checked~#slide5,
#s4:checked~#slide1,
#s5:checked~#slide2 {
    transform: translate3d(30%, 0, -200px);
}

/* #s1:checked ~ #slide4, #s2:checked ~ #slide5,
  #s3:checked ~ #slide1, #s4:checked ~ #slide2,
  #s5:checked ~ #slide3 { 
    transform: translate3d(-30%,0,-200px);
  }
  
  #s1:checked ~ #slide5, #s2:checked ~ #slide1,
  #s3:checked ~ #slide2, #s4:checked ~ #slide3,
  #s5:checked ~ #slide4 { 
    transform: translate3d(-15%,0,-100px);
  }
  
  #s1:checked ~ #slide1, #s2:checked ~ #slide2,
  #s3:checked ~ #slide3, #s4:checked ~ #slide4,
  #s5:checked ~ #slide5 { 
    transform: translate3d(0,0,0);
  }
  
  #s1:checked ~ #slide2, #s2:checked ~ #slide3,
  #s3:checked ~ #slide4, #s4:checked ~ #slide5,
  #s5:checked ~ #slide1 { 
    transform: translate3d(15%,0,-100px);
  }
  
  #s1:checked ~ #slide3, #s2:checked ~ #slide4,
  #s3:checked ~ #slide5, #s4:checked ~ #slide1,
  #s5:checked ~ #slide2 { 
    transform: translate3d(30%,0,-200px);
  } */
/* ================complete-brief starts================= */

.complete-brief .font-13 {
    font-size: 12px !important;
}

.complete-brief .select-btn {
    padding: 12px 10px;
    border-radius: 8px;
}

.complete-brief .pt {
    padding-top: 5px;
    padding-bottom: 10px;
}

.complete-brief .account-setting h2 .underline_4 {
    border-top: 2px solid #e7e1e1 !important;
    position: relative;
    top: -3px;
}

/* ==========complete-brief close====================*/

/* ==================dashboard ===================== */

.div-border-left {
    position: relative;
}

.div-border-left::before {
    width: 100%;
    content: '';
    height: 100%;
    width: 100%;
    border-left: 1px solid #dbdbdb;
    position: absolute;
    top: 0px;
    left: 10px;
}

.milestone {
    padding: 5px 6px;
    color: #fff;
    font-size: 10px;
    border: none;
    font-weight: bold;
    font-stretch: normal;
    position: relative;
    z-index: 0;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 700;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    transition: background-color 0.5s ease-in-out;
}

.dashborad-height {
    /* height: calc(100vh - 100px) !important; */
    height: calc(100vh - 50px) !important;
}

.sidebar-overflow {
    height: calc(100vh - 65px) !important;
    background: #fff;
}

/* .main-content .nav-tabs .nav-link:hover {
    border: none;
} */

.Dashboard-tab .custom_form .email {
    background: #fff;
}

.date-due {
    background: #067644;
    border-radius: 5px 5px 0px 0px;
    font-size: 6px;
    width: 41px;
}

.milestone-date span.date {
    font-size: 9px;
    position: relative;
    top: -9px;
}

.date-show {
    border-radius: 0px 0px 5px 5px;
    width: 41px;
    height: 42px;
}

.milestone-content span {
    font-size: 10px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.07px;
    color: #000000;
}

.milestone-content p {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.43px;
    color: #000000;
}

.Dashboard-tab .owl-next {
    right: -21px;
    transform: translateY(-85%);
}

.Dashboard-tab .owl-prev {
    left: -21px;
    transform: translateY(-85%);
}

.h-15 {
    height: 15%;
}

.Dashboard-tab .choose-underline {
    margin: 0px 0px;
}

.user-icon i {
    width: 28px;
    height: 28px;
    line-height: 18px;
    border: 2px solid #d63447;
    color: #d63447;
}

.user-icon-secondary i {
    width: 28px;
    height: 28px;
    line-height: 18px;
    border: 2px solid #cccccc;
}

.deshbard-container {
    /* overflow: hidden; */
    margin-left: 5rem;
    transition: margin-left 0.3s ease-in-out;
    -webkit-transition: margin-left 0.3s ease-in-out;
    -ms-transition: margin-left 0.3s ease-in-out;
    -moz-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
}

.deshbard-container.mini_sidebar_active {
    margin-left: 5rem;
    transition: margin-left 0.3s ease-in-out;
    -webkit-transition: margin-left 0.3s ease-in-out;
    -ms-transition: margin-left 0.3s ease-in-out;
    -moz-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
}

span.complete-check .fa-times.mr-2 {
    border-color: #ddd;
}

.Dashboard-tab .Oval:hover {
    background-color: #00b665 !important;
    color: #fff !important;
}

.deshbard-container .bg-image-creator {
    background-position: 120px 5px !important;
    min-height: 80px !important;
    border-radius: 12px !important;
}

.main-content .nav-tabs .nav-link {
    color: #53637b;
    font-weight: 700;
}

.deshbard-container .sub-tittle {
    line-height: normal;
    letter-spacing: 0.2px;

}

.py-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

span.rectangle {
    background-color: #6c7a8f;
    color: #fff;
    border-radius: 4px;
    vertical-align: middle;
    font-size: 11px;
    text-align: center;
    line-height: 15px;
    display: inline-block;
    padding: 1px 5px;
}

.sub-design {
    line-height: 2;
    letter-spacing: 0.30px;
    color: #fff;
}

.Oval {
    width: 36px;
    height: 36px;
    background-color: #eff1f8;
    border-radius: 100%;
    text-align: center;

}

.Oval i {
    line-height: 36px !important;
}

/* .deshbard-container .re-view img {
    width: 35px !important;
} */

.deshbard-container .fa,
.deshbard-container .fas {
    font-weight: 600;
}

.deshbard-container .main-btn {
    padding: 8px 5px;
    display: flex;
    align-items: center;
    /* height: 40px; */
}

span.price-circle i {
    border: 2px solid #00b665;
    border-radius: 100%;
    height: 26px;
    width: 26px;
    text-align: center;
    line-height: 23px;
}

span.due-on-date {
    padding: 5px 6px;
    color: #7a879b;
    font-size: 10px;
    border: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 700;
    border-radius: 6px;
    -webkit-border-radius: 0px 6px 6px 0px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    transition: background-color 0.5s ease-in-out;
    background: #f5f7fd;
    position: relative;
    left: -2px;
}

.switch.toggle-user {
    position: relative;
    display: inline-block;
    width: 60px;
    margin: 0px 15px 0px 0px;
    height: 26px;
}

.account-setting .availability-toggle .client {
    width: 22%;
    text-align: center;
}

.account-setting .availability-toggle .creator {
    width: 40%;
}

.availability-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.availability-toggle .switch.toggle-user {
    width: 44px;
    height: 24px;
    margin: 0 5px 0 24px;
}

.availability-toggle .slider:before {
    height: 22px;
    width: 22px;
    bottom: 1px;
    transform: translateX(-3px);
}

.availability-toggle input:checked+.slider:before {
    transform: translateX(17px);
    -webkit-transform: translateX(17px);
    -moz-transform: translateX(17px);
    -ms-transform: translateX(17px);
    -o-transform: translateX(17px);
}

.creator-busy {
    padding: 20px 15px;
}

.creator-busy .creator-busy-headline {
    padding: 0 0 15px;
}

.creator-busy label {
    font-size: 14px;
    font-weight: 600;
    color: #707d91;
    margin: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #10c89b;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider.slider-busy {
    background-color: #d63447;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}


/* input:checked + .slider {
    background-color: gray;
  } */

/* .account-setting .switch label:after {
    display:none;
} */

/* .account-setting .creator {
    color: #848484 !important;  
} */

/* .account-setting .client {
   color: #00b665;  width:auto;
} */

.role-toggle input:checked+.slider:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
}



/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.deshbard-container span.circle {
    width: 24px;
    color: #6c7a8f;
    height: 24px;
    border: 2px solid #6c7a8f;
    text-align: center;
    border-radius: 100%;
}

/* ========close================== */

.px-33 {
    padding-left: 33px;
    padding-right: 33px;
}

.py-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pl-20 {
    padding-left: 20px;
}

.deshbard-container .completed_image {
    width: 230px;
    height: calc(230px / 1.333);
    margin: auto;
    overflow: hidden;
}

.deshbard-container .completed_image img {
    border-radius: 4px;
}

.deshbard-container .completed_image .completed_project_preview {
    width: 100%;
    height: 100%;
    border: 1px solid #f0f3fa;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    position: relative;
    cursor: pointer;
}

/* .deshbard-container .completed_image {
    max-width: 188px;
    height: 140px;
    width: auto;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
}
.deshbard-container .completed_image {
    width:auto;
    max-width: 50%;
    height: 140px;
    border-radius: 4px;
    margin:auto;
    vertical-align: middle;
} */
/* ========model close=========== */
.sucess-bkg {
    background-image: url(../images/deactivate-account.jpg);
    background-size: cover;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 139px;
    border-radius: 8px 8px 0px 0px;
    -webkit-border-radius: 8px 8px 0px 0px;
    -moz-border-radius: 8px 8px 0px 0px;
    -ms-border-radius: 8px 8px 0px 0px;
    -o-border-radius: 8px 8px 0px 0px;

}

.sucess-bkg.no_collateral {
    min-height: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sucess-brief .publish-project.active,
.sucess-brief .choose-collateral.active {
    color: #fff;
}

.sucess-content {
    max-width: 585px;
    margin: 0 auto;
}

.height-auto {
    height: auto !important;
}

/* ======model close================ */

/* ============payment============== */
.top-15 {
    top: 15px !important;
}

.atm-card {
    max-width: 605px;
    border-radius: 8px;
    background-color: #14172a;
}

.ml-35 {
    margin-left: 35px;
}

.oval-btn {
    width: 12px;
    height: 12px;
    position: relative;
    background-color: #0076c9;
    border-radius: 100%;
}

.oval-btn::before {
    position: absolute;
    width: 4px;
    height: 4px;
    background: #fff;
    content: "";
    border-radius: 100%;
    top: 4px;
    left: 4px;
}

input.email.atm {
    width: 110px;
    background: #fff;
}

/* ==============contact==================== */

.contact-section .select-styled {
    background: #fff;
}

.height-vh.creator-recommendations {
    /* height: calc(100vh - 64px) !important; */
    height: calc(100vh - 0px) !important;
}

.height-vh.recommentation-overflow {
    height: calc(100vh - 100px);
}

.border-left-radius {
    border-radius: 0px 12px 12px 0px;
}

/* ====milestone============= */

.upload-icon i.fas.fa-arrow-up {
    position: absolute;
    bottom: 4px;
    left: 3px;
    font-size: 9px;
}

.send-milestone {
    max-width: 896px;
    position: absolute;
    left: 50%;
    margin-top: 50px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.send-milestone .custom_form .email {
    border: 1.5px solid #2970e5 !important;
}

span.timeline-dot::before {
    position: absolute;
    top: 8px;
    width: 6px;
    height: 6px;
    background: #fff;
    content: "";
    left: -12px;
    border-radius: 100%;
}

.milestone-close {
    position: absolute;
    top: 12px;
    right: 10px;
}

/* --profile details---- */
.model-profile {
    width: 79px;
    height: 79px;
    text-align: center;
    background-color: rgba(87, 87, 87, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -18px;
    position: relative;
}

.model-profile img {
    height: 79px;
    max-height: 79px;
}

img.img-size {
    width: 100%;
    border-radius: 8px;
}

.overflow-details {
    max-height: 100px;
}

.project-select .card-wrapper {
    position: fixed;
    width: 90%;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    bottom: 10px;
}

.project-select li {
    list-style: none;
}

/* ==========Landing page=================== */
.cardsUP {
    position: relative;
    top: -180px;
}

.services-collateral .owl-theme .owl-dots .owl-dot.active span,
.services-collateral .owl-theme .owl-dots .owl-dot:hover span {
    background: #10c89b;
}

.services-collateral .owl-theme .owl-nav [class*='owl-'] {
    font-size: 30px;
}

.services-collateral .owl-carousel .owl-prev,
.services-collateral .owl-carousel .owl-next {
    width: 3rem;
    height: 3rem;
    background: #fff !important;
    border-radius: 100% !important;
    font-size: 3rem !important;
    line-height: 1 !important;
    box-shadow: 0px 2px 10px rgb(0 0 0 / 9%);
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
}

.services-collateral .owl-carousel .owl-prev {
    left: -2.5%;
}

.services-collateral .owl-carousel .owl-next {
    right: -2.5%;
}

.services-collateral .owl-carousel .owl-prev span,
.services-collateral .owl-carousel .owl-next span {
    color: #fff;
}

.services-collateral .owl-carousel .owl-next span::after {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    font-size: 1.2rem;
    color: #000;
    padding-right: 0.75rem;
    margin-top: -0.600rem;
}

.services-collateral .owl-carousel .owl-prev span::before {
    font-family: "Font Awesome 5 Free";
    content: "\f053";
    display: inline-block;
    vertical-align: middle;
    font-weight: 900;
    font-size: 1.2rem;
    color: #000;
    padding-left: 0.75rem;
    margin-top: -0.600rem;
}

.box-logo {
    width: 296px;
    height: calc(296px / 1.3333);
    margin-right: 1%;
    margin-bottom: 5%;
    border: 1px solid #f3f2f2;
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.box-logo::before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
}

.box-logo.box-services {
    width: 265px;
    height: calc(265px / 1.3333);
    margin-right: 1%;
    margin-bottom: 1%;
}

/* .box-logo:nth-child(4n) {
    margin-right: 0px;
} */

.w-24 {
    width: 24%;
}

.logo-content {
    position: absolute;
    bottom: 0px;
    padding: 20px;
    left: 0px;
    right: 0px;
    width: 100%;
}

img.logo-img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.testimonial-content p {
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.3px;
    color: #fff;
}

.testimonial-img {
    padding-left: 40px;
}

.testimonial-img img {
    width: 450px !important;
    height: 338px;
    box-shadow: 0px 20px 30px rgba(0, 0, 0, .06);
    border-radius: 8px;
    border: 1.5px solid #eee;
    background-color: #ffffff;
    margin: auto;
    object-fit: cover;
}

.landing-input {
    width: 210px;
    height: 41px;
    background-color: #ffffff;
    border: none;
    outline: none;
    padding: 0px 15px;
}

span.landing-dot {
    position: relative;
    margin: 0px 30px;
}

span.landing-dot::before {
    width: 6px;
    height: 6px;
    background-color: #9899a0;
    position: absolute;
    top: 6px;
    content: "";
    border-radius: 100%;
    left: -15px;
}

.py-95 {
    padding-top: 95px;
    padding-bottom: 95px;
}

.footer-logo img {
    max-width: 200px;
}

.footer-link {
    padding: 0px 30px;
}

.footer-link li a {
    font-family: Muli;
    font-size: 15px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: 0px;
    color: #ffffff;
    display: block;
}

.footer-link li {
    list-style: none;
    padding-top: 10px;
}

.footer-link li:first-child {
    padding-top: 0px;
}

img.Articles-img {
    width: 100%;
    height: 165px;
    border-radius: 8px 8px 0px 0px;
}

.Articles-content {
    border-radius: 0px 0px 8px 8px;
    min-height: 190px;
}

.prev-next .owl-prev {
    transform: translateY(-80%) !important;
}

.prev-next .owl-next {
    transform: translateY(-80%) !important;
}

.author .re-view img {
    width: 35px !important;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    color: #000 !important;
}

.re-view-slider button {
    width: 40px;
    height: 40px;
    border-radius: 100% !important;
    line-height: 0 !important;
    font-size: 36px !important;
}

/* ===========services============== */
.services-banner {
    max-width: 660px;
    margin: 0 auto;
}

.services-banner p {
    padding: 0px 52px;
}

.version_two {
    padding: 100px;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.version_two .services-banner {
    text-align: left;
    max-width: 595px;
    margin: unset;
}

.landing-search .post-search {
    position: absolute;
    top: 20px;
    left: 15px;
    color: #6c7a8f;
    font-size: 13px;
}

.services-tittle h2 {
    font-size: 22px;
    margin: 0px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: inherit;
    letter-spacing: -0.75px;
    color: #161314;
}

.landing-pt-50 {
    padding-top: 130px;
}

.landing-search {
    position: relative;
    top: 20px;
}

.tool-row {
    padding-bottom: 50px;
}

/* =======service-details============= */
.pb-80 {
    padding-bottom: 80px;
}

#slider {
    height: 40vw;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
}

#slider [type=radio] {
    display: none;
}

/* ========about=============== */
.profile-header img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}

.news-column {
    width: 32%;
    padding: 40px 30px;
    margin: 0px 18px 0px;
}

.news-column p {
    padding: 20px 10px;
}

.news-column a {
    padding: 0px 10px;
}

.news-column a span i {
    transform: rotate(-45deg);
    margin-left: 3px;
}

.news-column:nth-child(1n) {
    margin-left: 0px !important;
}

.news-column:nth-child(3n) {
    margin-right: 0px !important;
}

.px-80 {
    padding-left: 80px;
    padding-right: 80px;
}

.about-content p {
    font-size: 18px;
    font-weight: 600;
    padding: 0px 56px;
}

.about-img .img-fluid {
    height: 400px;
}

.about-icon {
    width: 80px;
    margin: 0 auto 30px;
    height: 80px;
    background: #F5F7FD;
    border-radius: 100%;
}

.about-list {
    text-align: center;
    padding: 15px;
}

.mt-130 {
    margin-top: 130px;
}

/* ========blog============== */
.pt-70 {
    padding-top: 70px;
}

.blog-content p {
    font-size: 18px;
    font-weight: 600;
}

.blog-img img {
    border-radius: 8px;
}

.blog-img {
    padding: 20px 0px 30px;
}

.blog-tittle h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
}

.social-icon a {
    margin-right: 25px;
}

.articles-col {
    width: 30%;
    margin: 0px 56px 0px;
}

.articles-col img {
    width: 100%;
    height: 200px;
}

.articles-col h3 {
    font-size: 19px;
    padding-top: 20px;
}

.articles-col:nth-child(1n) {
    margin-left: 0px;
}

.articles-col:nth-child(3n) {
    margin-right: 0px;
}

.landing-select .select-arrow {
    top: 20px;
}







.card.upload-image-border.active.fade-border-color {
    border-color: #00b665;
    animation: fadeIn-border ease-in-out 10s;
    animation-delay: .1s;
}

@keyframes fadeIn-border {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.model-footer-fixed {
    position: absolute;
    left: 0;
    z-index: 99;
    right: 0;
    bottom: 0;
}

.model-profile-height {
    max-height: 330px;
}

.question-icon {
    position: relative;
}

.question-icon::before {
    position: absolute;
    top: 0px;
    width: 18px;
    height: 18px;
    content: "!";
    color: #fff;
    background: #d63447;
    right: -23px;
    text-align: center;
    border-radius: 100%;
    line-height: 18px;
}

.incomplete-brief li {
    width: 34%;
    position: relative;
}

.incomplete-brief .underline::before {
    position: absolute;
    width: 78%;
    content: "";
    /* background: #ccc;
    height:2px; */
    top: 13px;
    left: 60%;
    border-bottom: 3px solid #ccc;
}

.incomplete-brief .underline.green::before {
    border-bottom: 3px solid #10c89b;
}

/* .card.flex-card {
	max-width:32%;
    margin-right: 1.4%;
    width: 100%;
}  */
.smaller-laptop-show {
    display: none;
}

.card.flex-card {
    min-width: 350px;
    max-width: 350px;
    margin-right: 15px;
}

/* Job Section CSS */
.landing_job_wrapper {
    max-width: 1500px;
    margin: auto;
}

.landing_job_wrapper .job_wrapper .card.flex-card {
    min-width: 330px;
    max-width: 330px;
    margin-right: 15px;
}

.job_wrapper .card.flex-card {
    min-width: 305px;
    max-width: 305px;
    margin-right: 15px;
}

.prev-button {
    position: absolute;
    left: -20px;
    color: #6c7a8f;
    top: 35%;
    text-align: left;
    font-size: 25px;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.next-button {
    position: absolute;
    right: -20px;
    color: #6c7a8f;
    top: 35%;
    text-align: right;
    font-size: 25px;
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.next-button.fourCard {
    right: -20px;
}

/* .modal-dialog.active {
    max-width: 1024px !important;
} */

.modal-dialog.active .mx-width {
    max-width: 450px;
    margin: 0 auto;
}

.custom_form .modal-dialog.active .login h2 {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    padding: 34px 0px;
}

.box-content .card:first-child {
    margin-left: 1px;
}

.thumbnail-slider li {
    width: 10px;
    background: #707070dd;
    height: 10px;
    text-indent: initial;
    border-radius: 100%;
    border: none;
}

.thumbnail-slider li.active {
    width: 10px !important;
    height: 10px !important;
}

.thumbnail-slider li img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.thumbnail-slider {
    top: 100%;
    margin: 0px;
    align-items: center;
    margin-top: 35px !important;
}

.overflow-inherit {
    overflow: inherit;
}

.add {
    box-shadow: 0px 12px 10px rgba(0, 0, 0, 0.02);
}

.add img.img-fliud {
    width: 100%;
}

.add .add_title {
    color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 24px;
    font-weight: 700;
    transform: translate(-180%, -50%);
}

.inner-slider-three-d {
    position: absolute;
    top: 8px;
    margin: 0 auto;
    max-width: 100%;
    left: 7px;
    right: 7px;
}


img.innerslider {
    width: 100%;
    border-radius: 8px;
    height: 204px;
    object-fit: cover;
}

.inner-slider-three-d svg {
    width: 20px !important;
}

.inner-slider-three-d .carousel-control-next {
    opacity: 1;
}

.inner-slider-three-d .carousel-control-prev {
    opacity: 1;
}

.inner-slider-three-d .carousel-indicators li {
    width: 10px;
    bottom: -30px !important;
    height: 10px;
    border-radius: 100%;
}



.desktop-search .post-search {
    position: absolute;
    top: 0px;
    left: auto;
    right: 0px;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background: #10c89b;
    width: 130px;
    height: 50px;
    border-radius: 0px 6px 6px 0px;
    line-height: 50px;
    text-align: center;
    border: none;
}

.Popular-services .tags {
    padding: 4px 10px;
    background: #eff1f8;
    color: #54647c;
    font-weight: 700;
    font-size: 12px;
    border-radius: 5px;
    margin: 4px 10px 5px 0px;
    border: none;
}

.Popular-services .tags:last-child {
    margin-right: 0;
}

.client-tittle {
    letter-spacing: -0.35px;
    color: #cbcbcb;
}

.request-reviewed {
    background: #F5F7FD;
    padding: 30px 30px 0px;
}

/* .request-reviewed .upload-project-big-thumbnail img {
    width: 100%; 
} */

.request-bg-color .email {
    background-color: #F5F7FD !important;
}

.review-feedback {
    width: 100%;
}

.review-feedback .label {
    color: #000 !important;
}

.input-rating {
    top: 10px;
    position: absolute;
    left: 0;
    right: 0;
    cursor: pointer;
}

.request-bg-color .label {
    margin-bottom: 0;
}

.request-reviewed .upload-project-big-thumbnail img {
    object-fit: cover;
    height: 642px;
}

.request-reviewed .card {
    width: 854px;
    height: 642px;
}

.max-width-354 {
    max-width: 354px;
    min-width: 354px;
    width: 100%;
}

.progress_bar_style {
    height: 30px;
    width: 550px;
    background-color: #e0e0de;
    border-radius: 50px;
    margin-top: 53px;
}

.main-btn-cus {
    padding: 12px 15px;
    color: #fff;
    border: none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -o-border-radius: 6px;
    transition: background-color 0.5s ease-in-out;
}


div#wrapper-cu2 iframe {
    height: calc(100vh - 65px);
}

.dashboard_right_sidebar {
    position: fixed;
    top: 100px;
    right: 0;
    max-width: 22%;
}

/* .scroll_fixed_right_sidebar{
    position: -webkit-fixed;
    position: fixed;
    top: 168px;
    z-index: 1020;
    max-width: 20.7%;
    right: 0;
} */
.job_img_circle img {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin: auto;
}

.custom_checkbox_blue input {
    padding: 0;
    height: auto;
    width: auto;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.custom_checkbox_blue label:before {
    content: "";
    background-color: transparent;
    border: 2px solid #ddd;
    padding: 8px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    transition: background-color 0.3s ease-in-out;
    transition: border-color 0.1s ease-in-out;
}

.custom_checkbox_blue input:checked+label:after {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.custom_checkbox_blue input:checked+label:before {
    content: "";
    background-color: #1464dc;
    border-color: #1464dc;
}

.app-header {
    height: auto;
    z-index: 9999;
}

.app-header-inner {
    position: relative;
    background: #050421;
    height: 47px;
}

.app-branding {
    height: 60px;
    padding-top: 40px;
    margin-bottom: 40px;
    text-align: center;
}

.app-branding-mobile {
    text-align: center;
}

.sidepanel-toggler {
    padding: 0px 8px;
    font-size: 21px;
    font-weight: 700;
    color: #FFF;
    opacity: 1;
}

.avatar {
    height: 38px;
    width: 38px;
}
.progress_bar_style{
    margin: 20px auto;
}
/*jainul code start here*/

.jainul-ui {
    padding: 2.1rem;
}
.upload_notes .note-editable{
    max-height:100px!important;
}
.chat-feedback .note-editable{
    max-height:50px!important;
}
.remove-arrow::after{
    display: none!important;
}
.scrollbar_add {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 180px);
    max-height: calc(100vh - 180px);
}
.scrollbar_add::-webkit-scrollbar{
    width: 0.313rem;
}
.scrollbar_add::-webkit-scrollbar-thumb{
    border-radius: 0.375rem;
    background-color: #C9CED7;
}
.scrollbar_add::-webkit-scrollbar-track{
    background-color: transparent;
}