// select_Revlent_project
.client_reject{
    
        // max-width:calc(100vw - 100px);
        // max-width: 100vw;
        // @media(min-width:$breakpoint-sm){
        //     max-width:100vw; 
        // }
        // @media(min-width:$breakpoint-lg){
        //     max-width: calc(100vw - 1500px); 
        // }
         
        .modal_top_header {
         padding: 2.5rem 0rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }
        .btm_section{
            background-color: $bg-blue-200;
        }
      
        // ================== checkbox =============================
        .checkbox_icon{
            // position: absolute;
            // top:1rem;
            // right: 1rem;
            line-height: 0.2;

            input[type=checkbox] {
                display: none;

                + label {
                    display: inline-block;
                    position: relative;
                    padding: 0.9rem;
                    background-color: white;
                    border: 0.08rem solid $border-gray-400;
                    border-radius: 0.482rem;
                    margin-bottom: 0rem;
                    cursor: pointer;
                    @media (max-width:$breakpoint-md){
                        padding: 1.2rem;
                        border: 0.133rem solid $border-gray-400;
                    }
                }
                
            }
            input[type=checkbox]:checked + label {
                background-color:$bg-blue-800;
                color: #A4B7C6;
            }
            
            input[type=checkbox]:checked + label:after {
                position: absolute;
                left: 0.571rem;
                top: 0.9rem;
                color: #fff; 
                content: '\2714'; 
                font-size:0.929rem;
                @media (max-width: $breakpoint-md){
                    font-size: 1.6rem;
                    left:0.429rem;
                }
            } 

        } 
        // ===================end checkbox==================
        .upload_notes{
            min-height: 10.143rem;
            background-color: #fff;
            border: 0.071rem solid $border-gray-500;
            border-radius:0.426rem;
            padding: 0.714rem;
        }
            
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }

}