.profile_img{
    width: 35px;
    height: 35px;
    background-color: $text-white;
    border: 0.071rem solid $text-gray-1000;
    img{
        width:100%;
        height:100%;
        // object-fit: none;
    }
}
.proposal_list_height{
    // min-height: calc(100vh - 552px);
    // max-height: calc(100vh - 467px);
    max-height:105px;
    // overflow-y: auto!important;
    // overflow: hidden;
}
.proposal_list_height::-webkit-scrollbar {
    width: 0.313rem;
}
.proposal_list_height::-webkit-scrollbar-track {
    background-color :$bg-white;
}
.proposal_list_height::-webkit-scrollbar-thumb {
    border-radius:0.375rem;
    background-color:$border-gray-400;
}
.Dashboard-tab .tool::after {
    text-align: left;
    font-size: 0.8rem;
    background: #333333;
    line-height: 1.2rem;
    color: #EDEFF0;
    content: attr(data-tip);
    border-radius: 0.8rem;
    padding: 1em;
    transform: rotate(0deg) translateY(3.4rem) translateX(7.9rem) !important;
    max-width: 35em;
    width: auto;
    bottom: 140%;
    margin-left: auto;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}
.mb-8{
    margin-bottom: 8px;
}
.p-18{
    padding: 19px;
}