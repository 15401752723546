.worklog_upload_deliverable_wrappper{
    .btn-group{
        width: 12rem;
        height: 3.3rem;
        border-radius: 0.429rem;
        padding: 0rem;
        border: 0.1rem solid $text-gray-1000;
        input{
            z-index: 100;
            border-color: solid $text-gray-1000 ;
            border-width: 0rem 0.1rem;
            height: 3.1rem;
            min-height: 3.1rem !important;
            min-width: 2rem;
            border-radius: 0rem;
            padding: 0rem;
        }
        .btn{
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
            align-items: center;
            display: flex;
            background-color:$text-white;
        }
        .btn:nth-child(1){
            color:$text-black-600;
            border-bottom-left-radius: 0.3rem;
            border-top-left-radius: 0.3rem;
        }
        .btn:nth-last-child(1){
            color:$bg-blue-800;  
            border-bottom-right-radius: 0.3rem;
            border-top-right-radius: 0.3rem;

        }

    }
    .dropedown_input{
        input{
            &:disabled{
                background-color: $bg-white;
            }
        }
    }
}