.add_reference .cstm_tab .nav-link{
    margin-left:0!important;
}
p {
    word-break: break-word!important;
}
@media (max-width: 767px) {
    .main_page_container {
        margin-left: 0!important;
        margin-top:50px;
    }
    .footer_fixed {
        position: fixed;
    }
    .input_sm, .input_sm_height {
        width: 100%!important;
    }
    body, html {
        font-size: 14px!important;
    }
    .submit_proposal_fixed_cost .submit_proposal_container {
        padding: 0!important;
    }
    .dropedown_input {
        padding: 0!important;
    }
    .submit_proposal_heading {
        font-size: 18px!important;
    }.post-project-height {
        height: calc(100vh - 108px);
    }
    .submit_proposal_fixed_cost .submit_proposal_container .cstm_tab .nav-link,
    .cstm_tab .nav-link,
    .creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .cstm_tab .nav-link,
    .submit_proposal_on_actuals .submit_proposal_on_actuals_container .cstm_tab .nav-link {
        padding: 0.6rem 0!important;
        margin-right: 0.5rem!important;
        font-size: 13px!important;
        margin-left: 0!important;
    }
    .cstm_tab .nav-link.active {
        border-bottom: 0.186rem solid #1464DC!important;
    }
    .publish-project {
        font-size: 12px;
    }
    .mob-pb-4 {
        padding-bottom: 1rem;
    }
    .logo-icon{
        width: 28px;
    }
    .logo_text {
        width: 60%;
        margin-left: 4%;
    }
    .submit_proposal_fixed_cost .submit_proposal_container .upload_image_box .upload_image {
        width: 100%!important;
    }
    .delete_active {
        height: calc(100vh - 32rem)!important;
    }
    .tool::after {
        bottom: 75px!important;
        left: -75px!important;
    }
    .retainer_open .retainer_open_container {
        padding: 0rem!important;
    }
    .mob-pt-3{
        padding-top: 1rem!important;
    }
    .round_btn_blue_common {
        height: 35px!important;
        width: 35px!important;
    }
    .circle_btn_field.mr-2{
        margin-right: 0.2rem!important;
    }
    .retainer_open .retainer_open_container .duration_fields .price_icon {
        top: 38%!important;
        left: 25px!important;
    }
    .header_left_icon{
        position: absolute;
        left: 0;
        top:0;
    }
    .header_right_icon{
        position: absolute;
        right: 0;
        top:0;
    }
    .header_ul_wrapper .nav-link{
        white-space: pre;
    }
    .header_ul_wrapper{
        overflow: hidden;
    }
    .icon_img.collapsed {
        transform: rotate(180deg);
    }
    .proposal_heading {
        font-size:18px!important;
    }
    .sidebar {
        width: 15rem!important;
    }
    .sidebar .links_name {
        transition: 0s!important;
        opacity: 1!important;
        pointer-events: auto!important;
    }
    .hide-sidebar {
        width: 0!important;
        padding: 0px!important;
        left: -15rem!important;
    }
    .sidebar ul li a.active, .sidebar ul li a:hover {
        background: #393950;
    }
    .sidebar ul li {
        height: auto;
        width: 100%!important;
    }
    .sidebar ul li a {
        padding: 0 1rem!important;
    }
    #praposalType .modal-body{
        height: 320px;
    overflow: hidden;
    overflow-y: scroll;
    }
    .modal-title {
        font-size: 1.329rem!important;
    }
    .deliverables p,.payment_terms p{
        margin-bottom: 0.5rem!important;
    }
    .content_scrollbar {
        height: 100%!important;
        max-height: 100%!important;
    }
    .empty_chat_upload_notes textarea {
        padding: 0.7rem 5.5rem 0.5rem 2.5rem!important;
    }
    .creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .conversation_body {
        min-height: 100%!important;
        max-height: 100%!important;
    }
    .creator-profile {
        width: 30px!important;
        height: 30px!important;
    }
    .creator-profile img {
        height: 30px!important;
        max-height: 30px!important;
    }
    .creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .icon_profile_image {
        width: 30px!important;
        height: 30px!important;
    }
    .creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .upload_notes {
        min-height: 60px!important;
    }
    .milestone-chat .font-16 {
        font-size: 14px !important;
    }
    .milestone-chat .font-14 {
        font-size: 12px !important;
    }
    .font_semi_bold {
        font-size: 13px!important;
        word-break: break-word!important;
    }
    
    .text_gray_1100_w_700 {
        font-size: 14px!important;
    }
    .creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .user_quote .info_box_item_outer .info_box_item:last-child {
        padding-left: 1rem!important;
    }
    .tool::after{
        width: 16rem!important;
    }
  }

  @media (min-width:1200px) and (max-width:1400px){
    .upload_image_box .upload_box {
        min-height: 100%!important;
    }
    .project-view {
        min-height: 160px!important;
    }
    .message_outer {
        max-height: calc(100vh - 68.9px - 64.97px - 94.5px - 4rem - 320.03px)!important;
        overflow-y: auto;
    }
}
.detail-sidebar {
    overflow-y: auto;
    height: calc(100vh - 170px);
}
.client_card a {
    word-break: break-all;
}
.approve_btn{
    background: rgba(16, 200, 155, 0.1);
    color: #10C89B;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    padding: 10px 15px;
}
.approve_btn:hover{
    background: rgba(16, 200, 155, 0.1);
    color: #10C89B;
}
.profile_imagecar.slider_card img {
    width: 100%;
}
.content_scrollbar::-webkit-scrollbar,.detail-sidebar::-webkit-scrollbar {
    width: 0.313rem;
}
.content_scrollbar::-webkit-scrollbar-thumb,.detail-sidebar::-webkit-scrollbar-thumb {
    border-radius: 0.375rem;
    background-color: #C9CED7;
}
.content_scrollbar::-webkit-scrollbar-track,.detail-sidebar::-webkit-scrollbar-track {
    background-color: transparent;
}
.pdf-height {
    height: 650px!important;
}
.edit_proposal img[aria-expanded="false"] {
    transform: rotate(180deg);
    transition: transform .2s ease-in-out; 
}
.post_a_project .user-upload-content-work {
    max-width: 100%;
}
.thumb-des{
    height: 30%!important;
    bottom: 0%!important;
}
.pb-8 {
    padding-bottom: 6rem;
}
.serviece_page_cards .card .icon {
    display: flex;
    width: 7rem;
    min-height: 7rem;
    justify-content: center;
    padding: 1rem;
    align-items: center;
    border-radius: 0.804rem;
    margin: 0rem auto 1rem;
}
.serviece_page_cards .card .icon img{
    width: 60px;
    height: 60px;
}
.serviece_page_cards .card .card-title {
    font-weight: 700;
    font-size: 1.143rem;
    line-height: 1.429rem;
    color: #050421;
}
.serviece_page_cards .card .card-text {
    font-size: 1rem;
    line-height: 1.571rem;
    color: #6C7A8F;
    font-weight: 600;
    padding: 0 10px;
}
.avatar-lg{
    width: 60px;
    height:60px;
}
.avatar-lg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.user-role .bg-success:hover,.user-role .bg-success:focus{
    background-color: #10c89b !important;
}
.react-date-picker__button {
    padding:0!important;
}
.textarea[contenteditable]:empty::before {
    content: "Placeholder still possible";
    color: gray;
  }
  .avatar img {
    width: 100%;
    height: 100%;
}
.add_question {
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
}
.not-login .overflow-content.project-height {
    height: calc(100vh - 210px);
    padding-top: 20px;
    padding-bottom: 20px;
    max-height: calc(100vh - 145px);
}
.edit-icon {
    position: absolute;
    bottom: 0;
    right: 0;
}
.custom_form  .form-control {
    min-height: 46px;
    background: #FFFFFF;
    border: 1px solid #E1E5EB;
    border-radius: 6px;
}
.react-date-picker__inputGroup__divider {
    color: #757575;
}
.active-text-color {
    background: #F5F7FD;
    border-radius: 6px;
}
.active-text-color a{
    color: #1464DC!important;
}
.rejected_msg{
    background:#FFF2F2;
    padding: 15px 20px;
}
.react-date-picker__wrapper {
    text-align: left;
}
.btn_common_gray_md{
    border: 1px solid #E1E5EB;
border-radius: 6px;
}
.crd-title {
    width: 100%;
    line-height: 24px;
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}