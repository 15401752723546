.project_termination_milestone_wrapper{
    .paying_cycle_list{
        margin-left: 1.2rem;
     
            li {
                list-style: upper-alpha;
                padding-left: 1.2rem;
            }

    }
    .milestone_wrapper{
        .extend_overflow_height {
            max-height: calc(100vh - 860px);
            height: auto;
        }
        .input_sm{
            min-width: 10rem;
        }
    }
    
}