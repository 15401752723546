@import "./partials/config";   
@import "./partials/mixins";
@import "./partials/general";
@import "./partials/padding";
@import "./partials/margin";
@import "./partials/font_size";
@import "./partials/select_project_type";
@import "./partials/ask_question";
// @import "./partials/submit_proposal_fixed_cost";
@import "./partials/sidebar";
@import "./partials/submit_proposal";
@import "./partials/select_projects_type";
@import "./partials/review_confirm";
@import "./partials/sucess";
@import "./partials/retainer_open";
@import "./partials/retainer_review_confirm";
@import "./partials/submit_proposal_on_actuals";
@import "./partials/onactuals_review_confirm";
@import "./partials/view_proposal_fc";
@import "./partials/version_history";
@import "./partials//viewing_proposal_fc";
@import "./partials/view_proposal_retainer";
@import "./partials/view_proposal_on_actuals";
@import "./partials/view_proposal_list";
@import "./partials/client_view_fixed_proposal";
@import "./partials/view_brief";
@import "./partials/client_reject";
@import "./partials/client_request_change";
@import "./partials/client_version_history";
@import "./partials/client_view_proposal";
@import "./partials/client_view_proposal_retainer";
@import "./partials/client_view_proposal_on_actuals";
@import "./partials/view_brief_popup";
@import "./partials/creator_active_brief_chat_project_room";
@import "./partials/client_active_brief_chat";
@import "./partials/creator_active_brief_chat_room_empty";
@import "./partials/client_manage_participants";
@import "./partials/client_active_brief_chat_profile";
@import "./partials/shedule_meeting";
@import "./partials/upload_milestone.scss";
@import "./partials/project_end_popup";
@import "./partials/final_review";
@import "./partials/project_complete";
@import "./partials/work_log_extension_request";
@import "./partials/worklog_upload_deliverable";
@import "./partials/work_log_actual_upload_filled";
@import "./partials/retainer_extension_approved";
@import "./partials/worklog_extension_rejection";
@import "./partials/work_log_view_file";
@import "./partials/retainer_worklog_payment";
@import "./partials//client_approvepay";
@import url("./partials/_compliance.scss");
@import "./partials/client_approvepay";
@import "./partials/project_termination_milestone_check";
@import "./partials/overview_proposal_popup_recieved";
@import "./partials/approved";
@import "./partials/dashboard";
