.client_active_brief_chat{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .client_active_brief_chat_container{
        padding:2rem;
       
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
      
            .proposal_date{
                .date_input{
                    position: relative; 
                    .date_icon{
                        position:absolute;
                        top: 37%;
                        right: 8%;
                    }

                }
            }
            
            .description{
                font-size: 0.857rem;
                font-weight: 600;
                line-height: 1.071rem;
                color:$text-gray-1300;
            }

            // nav tabs
            .pills_tab_nav{
                .nav-link.active{
                  color:$text-blue-800;
                  background-color: $bg-white;
                }
                .nav-link{
                  color:$text-gray-1400;
                  font-weight: 700;
                  font-size: 0.9rem;
                  background-color: transparent;
                  padding: 1rem;
                  border-radius: 0.429rem; 
                }

            }

            // message
            // .profile_pic{
            //     position: relative;
            //     width: 50px;
            //     height: 50px;
            //     background-color:rgba($bg-blue-800, 0.05);
            //     border-radius: 50%;
            //     img{
            //         object-fit: cover;
            //         width: 60px;
                    
            //     }
            //     .active_cricle{
            //       position: absolute;
            //       top: 7px;
            //       left: 1px;     
            //     }
            // }
            
          
            // tabs
            .cstm_tab{
                border-bottom: 0rem;
            }
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1.3rem 1rem;
                margin-right: 3rem;
                font-size: 1.143rem;
                font-weight: 700;
                color:$text-gray-1300;
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 

            .profile_imagecard{
                object-fit: contain;
                aspect-ratio: 4/3;
            }
            .profile_image{
                // width: 160px;
                width: 100px;
                height: 100px;
            }
            // .info_box_item{
            //     width: 10rem;
            // }
            .card_icon {
                position: relative;
            }
            .msg_tag_btn{
                position: absolute;
                top: -0.7rem;
                right: -1rem;
            }
            .carousel-indicators .active{
                background-color: $bg-blue-800;
            }
            .carousel-indicators li{
                width: 7px;
                height: 8px;
                border-radius: 50%;
                background-color:$bg-gray-200;
                opacity: 1;
          
            }
            
      
        
    }
} 