// select_Revlent_project
.client_view_proposal{
    .client_view_proposal_modal_dialog{
        max-width:100vw;
        @media(min-width:$breakpoint-sm){
            max-width: calc(100vw - 150px); 
        }
        @media(min-width:$breakpoint-lg){
            max-width: calc(100vw - 700px); 
        }
         
        .modal_top_header {
         padding: 2.5rem 0rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }

        // cards
        .view_proposal_card{
            background-color: $bg-blue-200;
            border: 0px;
            box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
            border-radius: 0.429rem;
            position: relative;
            .card_tag{
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .deliverables{
                
            // table_deliverables 
            .table_deliverables{
                @media(min-width:$breakpoint-xxxl) {
                    overflow-x:hidden;    
                }
                tbody tr:nth-last-child(1) td:nth-last-child(1){
                    padding-left: 0rem; 
                    
                }
                tbody tr:nth-last-child(1) td:nth-last-child(1) {
                    padding-right: 0rem;
                    .number_btn{
                        padding:1rem;
                        background-color:$bg-blue-200;
                        border-top-left-radius:0.286rem;
                        border-bottom-left-radius: 0.286rem;
                    }
                    .toggle_price_input{
                        padding: 1rem;
                        background-color:$bg-blue-200;
                        border-top-right-radius: 0.286rem;
                        border-bottom-right-radius: 0.286rem;
                        // input{
                        //     max-width: 8.571rem;
                        //     height: 4rem;
                        // }
                        
                    }
                } 
            } 
            }
            // table_commmon_css
            .table{  
            thead{
                tr{
                width:100%;
                display:flex; 
                th{
                        vertical-align: middle;
                        border: none !important;
                        display:block;
                        width:100%;    
                    }
                    th:nth-child(1){
                        min-width:1rem;
                        width:4rem;
                            
                    }
                    th:nth-child(2){
                        min-width: 30.571rem;
                        width: 30.571rem;
                        }
                    th:nth-child(3), th:nth-child(4){
                        min-width: 12rem;
                        width: 12rem;
                    }
                    th:nth-child(5){
                        min-width: 9rem;
                        width: 9rem;
                    }
                
                }
            }
            tbody{
                // max-height:20rem;
                // height: 20rem;
                // overflow-y:scroll;
                display:block;
                width:100%;
                tr {
                    display:flex;
                    width:100%;
                    align-items: center;
                    th{
                        vertical-align: middle;
                        display:block;
                        width:100%; 
                    }
                    th:nth-child(1){
                        min-width:1rem;
                        width:4rem;
                            
                    }
                    th:nth-child(2){
                        min-width: 24.571rem;
                        }
                
                    td{
                        vertical-align: middle;
                        border-top: none;
                        border-bottom: none;
                        display:block;
                        width:100%;
                        
                        
                    }
                    .date_input{
                            position: relative;
                    .date_icon{
                            position: absolute;
                            top: 39%;
                            right: -1.5rem;
                            @media(max-width:$breakpoint-lg){
                                top:33%;
                            } 
                        }
                        .date_icon_1{
                            position: absolute;
                            top: 39%;
                            right: 2.4rem;
                            @media(max-width:$breakpoint-lg){
                                top:33%;
                            } 
                        }
                        .trash_icon{
                            position: absolute;
                            right: -3.143rem;
                            top: 27%;
                            @media(max-width:$breakpoint-md){
                                right: -2.857rem;
                                top: 19%;
                            }
                        }
                    }
                    .price_input{
                        position: relative;
                        .price_icon{
                            position: absolute;
                            top: 44%;
                            left: 1.5rem;
                            @media(max-width:$breakpoint-lg){
                                top: 41%;
            
                            }
                            @media(max-width:$breakpoint-md) {
                                top:38%;
                            }
                        }  
                    }
                
                    .price_input input::placeholder{
                        padding:1.429rem;
                    }  
                    
                    td:nth-child(1){
                        min-width:1rem;
                        width:4rem;  
                    }
                    
                    td:nth-child(2){
                        min-width: 30.571rem;
                        width: 30.571rem;
                    }
                    td:nth-child(3), td:nth-child(4){
                        min-width:12rem;
                        width: 12rem;
                    }
                
                    td:nth-child(5){
                        min-width:8rem;
                        width: 8rem;
                    }
                    
                }
                .toggle_dropedown{
                    width: 100%;
                    display: flex;
                    td:nth-child(1){
                        width: 4rem;
                        min-width: 4rem;
                    }
                    td:nth-child(2){
                        width: 100%;
                        display: block;
                        // toggle_box
                        .toggle_box{
                            .title{
                                padding: 0rem 2rem;
                                color: $text-gray-1400;
                                font-weight: 600;
                                font-size: 0.875rem;
                                line-height: 1.286rem;
                            }
                            .number_btn{
                                .btn-group{
                                    width: 8rem;
                                    height: 3.3rem;
                                    border-radius: 0.3rem;
                                    padding: 0rem;
                                    border: 0.1rem solid $text-gray-1000;
                                    input{
                                        z-index: 100;
                                        border-color: solid $text-gray-1000 ;
                                        border-width: 0rem 0.1rem;
                                        height: 3.1rem;
                                        min-height: 3.1rem !important;
                                        min-width: 2rem;
                                        border-radius: 0rem;
                                        padding: 0rem;
                                    }
                                    .btn{
                                        font-size: 2rem;
                                        font-weight: 500;
                                        text-align: center;
                                        align-items: center;
                                        display: flex;
                                        background-color:$text-white;
                                    }
                                    .btn:nth-child(1){
                                        color:$text-black-600;
                                        border-bottom-left-radius: 0.3rem;
                                        border-top-left-radius: 0.3rem;
                                    }
                                    .btn:nth-last-child(1){
                                        color:$bg-blue-800;  
                                        border-bottom-right-radius: 0.3rem;
                                        border-top-right-radius: 0.3rem;
            
                                    }
            
                                }
            
                            }
            
                            .toggle_price_input{
                                .price_input{
                                    position: relative;
                                    .price_icon{
                                        position: absolute;
                                        top: 36%;
                                        left: 0.7rem;
                                        @media(min-width:$breakpoint-lg){
                                            top:41%;
                                            left: 1rem;
                                        }    
                                    }
                                }
                            }
                        }
                    }
                }
            }
            
            }
        //payment_terms
        .payment_terms{
            .payment_terms_table{
                @media(min-width:$breakpoint-xxxl) {
                    overflow-x:unset;
                    min-height: 13rem;
                    height: 13rem;
                    overflow-y: initial;    
                }
                table{
                    thead{
                        th{
                            vertical-align: middle;
                            border: none !important;
                            display:block;
                            width:100%;    
                            }
                            th:nth-child(1){
                                min-width:1rem;
                                width:4rem;
                                    
                            }
                            th:nth-child(2){
                                min-width: 41.571rem;
                                width: 41.571rem;
                                }
                            th:nth-child(3){
                                min-width: 12rem;
                                width: 12rem;
                            }
                        
                    }
                    tbody{
                        min-height: 9.143rem;
                        height: 9.143rem;
                        overflow: scroll;
                        tr {
                            
                            td:nth-child(1){
                                min-width:1rem;
                                width:4rem;  
                            }
                            
                            td:nth-child(2){
                                min-width: 41.571rem;
                                width: 41.571rem;
                            }
                            td:nth-child(3){
                                min-width:12rem;
                                width: 12rem;
                            }
                            
                        }
                    }
            
            
                }    
            }
            
        }

        //attachments
        .featured_card{
            position: relative;
            .import_icon{
                position: absolute;
                top: 20px;
                right: 27px;
            }
        }
        .url_input{
            position: relative;
            padding: 0rem 0rem;
            color:$text-blue-800;
            text-decoration: underline;
            ::placeholder{
                text-decoration: none;
            }
        }
       
        .upload_notes{
            // min-height: 10.143rem;
            background-color: #fff;
            border: 0.071rem solid $border-gray-500;
            border-radius:0.426rem;
            padding: 0.714rem;
        }
         // tabs
         .cstm_tab .nav-link{
            border: 0rem;
            // border-bottom: 4px solid $bg-blue-800 ;
            padding: 1rem 1rem;
            margin-right: 3rem;
            font-size: 1.1rem;
            font-weight: 600;
            color:$text-gray-1300;
        }
        .cstm_tab .nav-link.active{
            border-bottom: 0.286rem solid $bg-blue-800 ; 
            color: $text-blue-800;
        }   
        
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    
    }

}