
@media (min-width:1900px){
	.small-desktop-hide{
		display: none;
	}
}

@media (min-width:1649px) { 
	  
	.box-logo {
		width:296px;
		height:calc(296px / 1.3333);
	}
} 
 
@media (max-width:1899px){
	.large-desktop{
		display: none;
	}
}
@media (max-width:1750px){
	.job_wrapper .card.flex-card{
		min-width: 270px;
		max-width: 270px;
	}
} 
@media (max-width:1500px){
	.box-logo {
		width:270px;
		height:calc(270px / 1.3333);
	}

	/* Job Section CSS */
	.landing_job_wrapper .job_wrapper .card.flex-card{
		min-width:305px;
		max-width:305px;
		margin-right:15px;
	}
	
}
 
@media (min-width:768px) and (max-width:1400px){
	.smaller-laptop-hide{
		display:none !important;
	}
	.smaller-laptop-show{
		display:inline-block;
	}
	.job_wrapper .card.flex-card{
		min-width:280px;
		max-width:280px;
		margin-right:15px;
	}

	/* Job Section CSS */
	.landing_job_wrapper .job_wrapper .card.flex-card{
		min-width:275px;
		max-width:275px;
		margin-right:15px;
	}
}
@media (max-width:1366px){
	 
	.request-reviewed .card {
		width: 835px;
		height: 630px;
	}
	.request-reviewed .upload-project-big-thumbnail img{
		height:630px;
	} 
}
 
@media (max-width:1300px){
	.small-laptop-card-img .re-view{
		width:30px;
		height:30px;
	}
	.small-laptop-card-img .re-view img{
		height:30px;
		max-height: 30px;
	}
	.small-laptop-card-font-12{
		font-size: 12px !important;
	}
	.small-laptop-card-font-14{
		font-size: 14px !important;
	}
	.request-reviewed .card {
		width: 785px;
		height: 590px;
	}
	.request-reviewed .upload-project-big-thumbnail img{
		height:630px;
	}
	.box-logo {
		width:242px;
		height:calc(242px / 1.3333);
	}
	.job_wrapper .card.flex-card{
		min-width:255px;
		max-width:255px;
		margin-right:15px;
	}
}
@media (max-width:1280px){
	.box-logo {
		width:215px;
		height:calc(215px / 1.3333);
	}
	.job_wrapper .card.flex-card{
		min-width:240px;
		max-width:240px;
		margin-right:15px;
	}
}
@media (max-width:1030px){
	.ipadPro-px-15{
		padding-left:15px !important;
		padding-right: 15px !important;
	}

	.ipadPro-px-5{
	   padding-left:5px !important;
	   padding-right: 5px !important;
   }
   .ipadPro-mb-0{
	   margin-bottom: 0px !important;
   }
   .ipadPro-w{
		-ms-flex: 0 0 50% !important;
		flex: 0 0 50% !important;
		max-width: 58% !important;
   }
   .request-reviewed .card{
	   width:604px;
	   height:450px;
   }
   .ipad-pro-flex-wrap{
		flex-wrap:wrap;
	} 
	.box-logo {
		width:200px;
		height:calc(200px / 1.3333);
	}  
	.creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .user_company_profile_image {
		background-color: #F5F7FD;
		width: 25px!important;
		height: 25px!important;
	}
	.creator_active_brief_chat_project_room .creator_active_brief_chat_project_room_container .user_profile_image {
		width: 35px!important;
		height: 35px!important;
	}
	.text_black_500_xl_w_700 {
		font-size: 1.1rem!important;
	}
}
@media (min-width:993px) and (max-width:1199px){
	.owl-prev {
	  left: -25px !important;	
	}
	.owl-next {
	  right: -25px !important;	
	}
	.incomplete-brief .underline::before {
		width: 68%;
		left: 65%;
	}
	.question-icon::before {
		top: -3px;	
	}

	.next-button {
		right: -13px;
	}

	.prev-button {
		left: -13px;
	}
} 
@media (max-width:992px){

	/* Loader Progress Bar */
	.progress_bar_style{ 
        width:100%; 
		height:25px;
    }

	.switch.toggle-user{
		margin: 0px 15px 0px 15px;
	} 
	
	/* button.navbar-toggler {
		display: none;
	} */
    nav.navbar.navbar-expand-lg.navbar-light{
		height: 52px;
	}
	/* .navbar-brand{
		width: 100%;
	} */
    nav.navbar.navbar-expand-lg.navbar-light {
		text-align: center;
	}
    .mob_get_verify .mob-w-75 {
		max-width: 215px;
	}

	.mobile-ipad-show .select-styled {
		background-color: #fff !important;
		border: 1.5px solid #e8ecf4 !important;
		border-radius: 8px !important; padding: 8px 12px !important;
		-webkit-border-radius: 8px !important;
		text-transform: capitalize;
	}


	.mobile-ipad-show  .custom_form .email {
		background: #fff !important;
	}

	.height .select {
		height: 40px;
	}

	.contact-section .account-setting-dropdown .select-class {
	   left:22px !important; 
	}

	.contact-section.custom_form.creator-recommendations{
		height:auto !important;
	}

	.contact-section .recommentation-overflow {
		height: auto !important;
	}

	.payment-section .col-sm-8 {
		-ms-flex: 0 0 100% !important;
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}

	.payment-section .col-sm-4 {
		-ms-flex: 0 0 100% !important;
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
	.ipad-bloack{
		display: block !important;
	}

	.mask-content h3 {
		font-size: 23px; 
	}

	.mask-content {
		padding: 20px 30px;
	}

    .mob-overflow-inherit{
     overflow: inherit !important;
	}
	.mob-pb-30{
		padding-bottom: 30px !important;
	}
	.mob-pt-50 {
		padding-top: 50px;
	}
	.filter_dropdown_icon .select-arrow{
		top:15px;
	}  

	/* ========landing page================== */
	.services-banner {
		text-align:left !important;
	}
	.slider-position{
		position: relative;
		left:-35px;
	}

	.landing-headline h1 {
		font-size: 28px;
	}

	.landing-pt-50 {
		padding-top:25px;
	}
	
	.testimonial-img {
		padding-left:0px !important; 
	}
	.testimonial-img img {
		width:100% !important;
		height:286px;
		object-fit:cover;
	}
	/* button.owl-prev {
		display: none !important;
	}

	button.owl-next {
		display: none !important;
	} */

	.testimonial-content p {
		font-size: 22px;
		padding-top: 20px;
	}
    .author p {
		padding: 0px;
	}

	.creators_mobile_section .owl-dots {
		display:block !important;
	}

	.owl-dot {
		margin: 10px 3px 5px;
	}

    /* .owl-dot.active{
		background-color: #141727 !important;
	} */

	.landing-pd {
		padding: 10px 15px 30px;
	}

	.pt-130 {
		padding-top: 80px !important;
	}
    .watch-idea h2 {
		font-size: 28px;
	}

	.bg-dark-header{
		background: #141727 !important;
	}

	.ipad-pb-0{
		padding-bottom: 0px !important;
	}

   .about-img .img-fluid {
		width: 100%;
		height: auto;
	}

	.pb-80 {
		padding-bottom: 30px !important;
	}

	.ipad-mt-70{
		margin-top:70px;
	}

	#cubic-slider label {
		width: 65% !important;
	}

	#cubic-slider {
		height: 45vw !important;
	}

	.ipad-bg-white{
		background:#fff !important;
	}
	.add .add_title {
		color: #000;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 18px;
		font-weight: 700;
		transform: translate(-170%,-50%);
	} 
	.mobile-x{
		overflow:hidden;
		overflow-x: auto;
	}
	.mobile-x::-webkit-scrollbar {
		width: 5px;
		height:2px;
		display:none;
	} 
	.mobile-x::-webkit-scrollbar-track {
		background: #f1f1f1; 
	} 
	.mobile-x::-webkit-scrollbar-thumb {
		background: #b7babc; 
	} 
	.mobile_overflow{
		display:flex;
	}
	.mobile_overflow .card.flex-card{
		min-width:40%;
	}
	.mobile_overflow .min_width_card{
		min-width:45%;
	}
	.text_dots{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis; 
		max-width: 200px;
	}
	.job_wrapper .card.flex-card{
		min-width:225px;
		max-width:225px;
		margin-right:15px; 
	}
}	
@media (max-width: 767px){
	.complete-brief .mob-mb-15 {
	-ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
	}

	.complete-brief .complete-brief .pt {
		padding-top: 18px;
	} 
	.complete-brief .pt {
		padding-top:0px; 
	}
	
    .complete-brief .card-wrapper {
		 margin-bottom: 0px;
	}

	.sucess-bkg {
	   min-height: 180px;
	} 

	.complete-project .rating ul li:before {
		left: 2px !important;
	}
	.mob-bg-light-input{
		background-color: #f8fafe !important;
	}

	.payment-section .account-setting-dropdown .select-class {
		left:22px !important; 
	 } 

	 .project-deatils .col-sm-4 {
		-ms-flex: 0 0 100% !important;
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}

	.project-deatils .col-sm-8 {
		-ms-flex: 0 0 100% !important;
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}

	.news-column {
		margin: 0px 0px 20px !important;
	}

	.d-coontent {
		padding: 10px 10px !important;
	} 
	.request-reviewed{
		padding:30px 15px 0px;
	}
	.request-reviewed .card{
		width:100%;
		height:230px;
	}
	.request-reviewed .upload-project-big-thumbnail img{
		height:230px;
	}
	.request-reviewed .upload-thumbnail .upload-thumb img.active{
		height:54px;
	}
	.add img.img-fliud {
		width: 100%;
		min-height:54px;
	} 
	.add .add_title {
		color: #000;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 14px;
		font-weight: 700;
		transform: translate(-100%,-50%);
	}
	.model-profile-height{
		max-height: 120px; 
	}
	.mobile_overflow .card.flex-card{
		margin-right:5%;
	}
	.mobile_overflow .card.flex-card{
		min-width:75%;
	}
	.mobile_overflow .min_width_card{
		min-width:75%;
	} 
	.testimonial-img img {
		width:100% !important;
		height:250px;
		object-fit:cover;
	}
	/*===== Footer Section ======*/
	.footer-logo img{
		max-width:150px;
	}
	.footer-link{
		padding:0px 50px 0 0;
	}
	.job_wrapper .card.flex-card{
		min-width:100%;
		max-width:100%;
		margin-right:15px;
		margin:auto;
	}
	.mob-ml-3{
		margin-left:5px!important;
	}
}  
@media (min-width: 768px) and (max-width: 992px) {
	.complete-brief .col-sm-2 {
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width:25%;
	}

	.news-column {
		width: 48% !important;
		margin: 0px 27px 27px !important;
	}

	.news-column:nth-child(2n) {
		margin-right: 0px !important;
	}
	
	.request-reviewed .card {
		width: 100% !important;
	}

	/*=== Footer section ======*/
	.footer-link{
		padding:0px 30px 0 0;
	}
	
}
@media (max-width: 700px){
	.mob-border-radius{
		border-radius: 0px !important;
	}
} 
@media (max-width: 576px){
	.project-deatils .main-btn {
		padding: 10px 12px !important;
	}

	.py-95 {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.add .add_title {
		color: #000;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 14px;
		font-weight: 700;
		transform: translate(-110%,-50%);
	}
} 
@media (min-width:576px) and (max-width: 767px) {

	.mob-col-50 {
		-ms-flex: 0 0 50% !important;
		flex: 0 0 50% !important;
		max-width: 50% !important;
	}
}
@media (max-width:392px){
	.request-reviewed .card {
		width: 100%;
		height: 190px;
	}
	.add .add_title {
		color: #000;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 14px;
		font-weight: 700;
		transform: translate(-95%,-50%);
	}
	.mobile-show{
		display:inline-block !important;
	}
	.smaller-laptop-show{
		display: none!important;
	}
	.new_flex-card {
		min-width: 94%!important;
		max-width: 94%!important;
		margin: 0 auto!important;
	}
	.next-button {
		right: -6px
	}
	.prev-button {
		left: -6px
	}
} 

@media (max-width:991px){
	.review-ipad-px-0{
		padding-left: 0px !important;
		padding-right:0px !important;
	}
}

@media (max-width: 767px){
    .request-mobile .col-sm-8 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .request-mobile .col-sm-4 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .mob-border-none{
        border:none !important;
    }
	.deshbard-container {
        margin-left: 0!important;
        margin-top:50px;
    }
    .screen-small-col-18 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .screen-small-col-22 {
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
	.landing_job_wrapper .job_wrapper .card.flex-card {
		min-width: 100%!important;
		max-width: 100%!important;
	}
	.next-button {
		right: -6px!important;
	}

	.prev-button {
		left: -6px!important;
	}
	.new_flex-card {
		margin: 0 auto!important;
	}
}


