.shedule_meeting{
    .input_box{
        border: 1px solid #E1E5EB;
        border-radius: 0.429rem;
        // min-height: 3.286rem;
        // height: 3.286rem;
        padding: 0.3rem;
        width: 100%;
        height: auto;
        column-gap: 10px;
        row-gap: 10px;
        flex-wrap:wrap ;
        line-height: 2.5rem;
    }
    .input_outer{
        input{
            position: relative;
            
        }
        .input_icon{
            position: absolute;
            top: 55%;
            right: 2rem;
        }
        
    }

    // dropedown
    .dropedown_input{
        position: relative;
    }
    .dropdown_menu{
        position: absolute;
        width: 100%;
        left: 0;
        max-width:calc(100% - 1.5rem - 1rem);
        margin: auto;
        transform: translate(1.2rem, 0rem); 
        z-index:1000;
        overflow: hidden;
        max-height: 300px;
        overflow-y: auto;
        border-radius: 0.482rem;
        box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.08);

        .dropdown_inner{
            min-height: auto;
            height: auto;
            overflow-y: auto;
            border-radius: 0.482rem;
            border: 0.071rem solid $text-gray-1000;
            
        }
        .dropdown_item{
            position: relative;

            input,.user_input{
                border:0;
                // border-bottom: 0.071rem solid $text-gray-1000;
                min-height:4rem;
                height:4rem;
                border-radius:0.429rem;
                padding:1rem;
                background-color: #fff;
                column-gap:0.714rem;
                li{
                    list-style-type: disc;
                    padding: 0px 26px 0px 0px;
                    &:first-child{
                        list-style-type: none;
                    }
                }
            }
        }
    }  


    // Custom Input
    .cstm_checkbox{
        position: absolute;
        top: 1rem;
        right: 0rem;
    }
    .cstm_checkbox input{
        padding:0;
        height:initial;
        width: initial;
        display: none;
        cursor:pointer;
    }
    .cstm_checkbox label{
        position: relative;
        cursor: pointer;
        color:$text-black;
        font-size:1.1rem;
        margin-bottom:1rem;
    }
    .cstm_checkbox label:before{
        content:'';
        background-color: transparent;
        border:0.1rem solid $text-gray-300;
        padding:11px;
        border-radius: 6px;
        display:inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 15px; 
        transition: background-color 0.3s ease-in-out;
    }
    .cstm_checkbox input:checked + label::after{
        content:'';
        display: block;
        position: absolute;
        top: 5px;
        left: 8px;
        width: 8px;
        height: 12px;
        border:solid $bg-white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media(max-width:$breakpoint-md){
            top:3px;
        }
    }
    .cstm_checkbox input:checked + label::before{
        content:'';
        background-color: $bg-blue-800;
        border-color: $bg-blue-800;
    }
}
