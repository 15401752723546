@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@300;400;500;700;900&display=swap'); 

body {
  background-color: #f5f7fd;
}

/*-- Loader Css --*/
.loader-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader {
  width: 35px;
  height: 35px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #eee;
  border-bottom-color: rgba(0, 0, 0, 0.4);
  border-left-color: #eee;
  border-right-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader 1s ease-in-out infinite;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.btn-loader-wrapper {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-loader {
  width: 17px;
  height: 17px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  margin-left: 10px;
  border: 5px solid;
  border-top-color: #eee;
  border-bottom-color: rgba(0, 0, 0, 0.4);
  border-left-color: #eee;
  border-right-color: rgba(0, 0, 0, 0.4);
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader 1s ease-in-out infinite;
}
.btn-loader.resume_upload{
  width:30px;
  height:30px;
  margin-left:0px;
}
.btn-loader.profile_creator_loader{
  width:17px;
  height:17px; 
  margin: 0px 10px;
} 
@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
.common-wrapper{
  width:100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* add margin */
  margin: auto; 
}
.common-wrapper.common-password{
  width:85px;
}
.common-wrapper.common-create{
  width:70px;
}
.common-wrapper.account-settings{
  width:80px;
}
.common-wrapper.common-profile{
  width:35px;
  height:40px;
}
.onBoarding-loader .common-wrapper{
  width:130px;
}
.common-loader{
  width: 22px;
  height: 22px;
  margin: 0px auto 0;
  border: solid 5px #f3f3f5;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1.0s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}



/*-- Desktop & mobile -- */
.mob_get_verify,
.mob_dashboard_background_image,
.mob_dashboard_content {
  display: none;
}

.mob_display_none,
.mob_display_none_off {
  display: block;
}

.mobile-footer-fixed,
.mobile-header-fixed {
  display: none;
}
.mobile-footer-fixed.show{
  display:block;
}

.mobile-edit-collateral {
  display: none;
}

.mobile-show {
  display: none;
}

.desktop-ipad-ipad-pro-show {
  display: block;
}

.desktop-ipad-show {
  display: block;
}
.navbar-toggler{
  display:none;
}
/*-- Modify css -- */
.main-header .navbar-light .navbar-nav .nav-link {
  color: #fff;
}

.main-header .navbar-light .navbar-nav .nav-link:hover {
  color: #10c89b;
}

.main-header .navbar-light .navbar-nav .nav-link.active {
  color: #10c89b;
}

.main-header.upload-project-wrapper .navbar-brand {
  flex-grow: 1;
}

.main-header.upload-project-wrapper .navbar-expand-lg {
  justify-content: space-between;
}

.main-header.upload-project-wrapper .navbar-nav .nav-link.upload-project-image {
  padding: 8px 15px;
}
.main-header .navbar-toggler-icon{
  background-image:url(../images/menu.png);
  display:none;
}
.navbar-toggler:focus, .navbar-toggler:hover{
  outline:none;
}
.select-class.top_class{
    top:44px;
}
.country_dropdown {
  top: 70px;
}
.dashboard_new_box{
  padding:3.5px 7px;
  font-size:9px;
  margin-left:10px;
  letter-spacing: 0.8px;
}
/*-- Profile Css -- */
.pX_pY_30 {
  padding: 30px 20px;
} 
.user-profile-blank {
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
}
.user-profile-blank img {
  width: auto;
  height: 35px;
  max-height: 35px;
  border-radius: 0px;
}
.profile-content {
  padding: 20px 0px;
}

.profile-content .profile_img {
  margin-top: -50px;
}

.profile-content .profile_img img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
} 
.card-wrapper {
  margin-bottom: 30px;
} 
.card-details {
  margin-bottom: 20px;
} 
.card {
  border-color: #eae9e9;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
} 
.card-body {
  padding: 0;
} 
.profile-inner .dropdown-item {
  border-bottom: 1px solid #f3f2f2;
  font-size: 12px;
  padding: 0.8rem 0.8rem;
  white-space: inherit;
}

.profile-inner .dropdown-menu.dropdown-list-menu {
  padding: 0;
  top: 9px !important;
  left: -92px !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-inner .dropdown-menu-arrow {
  top: -18px;
  left: 122px;
  width: 0;
  height: 0;
  position: relative;
}
.profile-inner.show {
  right: 0px;
}
.profile-inner .dropdown-menu.outer-nav-wrap {
  min-width: 12rem;
  left: -60px;
  padding: 0;
}

.profile-inner .dropdown-menu-arrow.nav-arrow {
  top: -18px;
  left: 95px;
}

.profile-inner .dropdown-menu-arrow:before,
.profile-inner .dropdown-menu-arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.profile-inner .dropdown-menu-arrow:after {
  bottom: -18px;
  right: -8px;
  border-bottom-color: #fff;
}

.profile-inner .dropdown-menu-arrow:before {
  bottom: -17px;
  right: -8px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}

.profile-inner .creator-tax .tax-icon {
  cursor: pointer;
  padding: 10px 11px;
  border-radius: 50%;
  color: #848484;
  transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;
  -moz-transition: background-color 0.3s ease;
  -ms-transition: background-color 0.3s ease;
  -o-transition: background-color 0.3s ease;
}

.profile-inner .creator-tax .tax-icon:hover {
  background: rgb(238, 238, 238, 0.6);
}

.profile-inner .creator-tax .tax-icon.active {
  transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
}

.profile_title {
  font-size: 26px;
  color: #000;
  font-weight: 800;
  margin: 0;
}

.profile-subTitle {
  color: #6C7A8F;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  line-height: 1;
}

.p-border {
  padding: 1.25rem 1.25rem 1.2rem;
  border-bottom: 1px solid #f3f2f2;
}

.profile-list ul li a {
  padding: 15px 20px;
  display: block;
  border-bottom: 1px solid #f3f2f2;
  font-size: 14px;
  color: #6C7A8F;
  font-weight: 700;
}

/* .profile-list ul li a.active-text-color {
  color: #000;
  font-weight: 700;
} */

.profile-list ul li:last-child a {
  border-bottom: none;
}

.profile-help h2,
.profile-help h3 {
  color: #fff;
  font-size: 20px;
}

.profile-help button {
  padding: 10px 15px;
  background-color: #fff;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-color: #fff;
  margin-top: 15px;
  border: none;
}

.profile-help button:focus {
  outline: none;
}

.bg-image {
  background-image: url(../images/profile-details.png);
  background-size: 217px;
  background-position: 145px 174px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 300px;
  border-radius: 2px 12px 12px 0px;
  margin-top: -1px;
  margin-bottom: -1px;
}

.bg-image-creator { 
  min-height: 80px;
  border-radius: 12px;
  background-size: 180px;
  background-position: center right;
}

.profile-headline {
  padding: 15px 20px;
  border-bottom: 1px solid #f3f2f2;
}

.profile-headline h2 {
  font-size: 20px;
  font-weight: 700;
  margin: 0;
} 
.profile-headline {
  padding: 15px 20px;
  border-bottom: 1px solid #f3f2f2;
}
/* 
.profile-headline h2 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
} */

.profile-user-image{
  width: 110px;
  height: 110px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  text-align: center;
  border: solid 1px #ffffff;
  background-color: rgba(87, 87, 87, 0.15);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.profile-user-image img {
  width: auto;
  max-height: 100px;  
}
.basic_profile {
  width: 71px;
  height: 71px;
  text-align: center;
  border: solid 1px #ffffff;
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.basic_profile.chat_user{
  width:45px;
  height:45px;
}
.basic_profile.chat_user img{
  width: auto;
  height:45px;
  max-height:45px; 
}
.company-icon.basic_profile{
  width:45px;
  height:45px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -ms-border-radius: 4px;
}
.company-icon.basic_profile img{
  width:auto;
  max-height:100%; 
}

.social_images .basic_profile{
  background:transparent;
  width:35px;
  height:35px;
  margin:0px 8px 8px 0px;
} 
.social_images .basic_profile img {
  width: auto;
  height: auto;
  max-height: 50px;
  border-radius: 36px;
} 
.basic_profile img {
  width: auto;
  height: 71px;
  max-height: 71px;
  border-radius: 0px;
} 
.clients_popup {
  width: 80px;
  height: 80px;
  text-align: center;
  border: solid 6px #ffffff;
  background-color: #f4f4f4;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients_popup img {
  width: auto;
  height: auto;
  max-height: 80px;
  border-radius: 0px;
}
.client_div:hover .client_remove{
  right:40px;
  opacity: 1;
}
.client_remove{
  position: absolute;
  top:44px;
  right:0px;
  opacity: 0;
  overflow: hidden;
  cursor: pointer;
  transition:0.5s all;
  -webkit-transition:0.5s all;
  -moz-transition:0.5s all;
  -ms-transition:0.5s all;
  -o-transition:0.5s all;
}
.client_remove i{
  font-size:18px;
}
.profile-user p {
  color: #848484;
  font-size: 16px;
  font-weight: 700;
}

.verified {
  position: absolute;
  top: 40px;
  right: 25px;
  color: #00b665;
  font-weight: 700;
  font-size: 16px;
} 
.verified-again {
  position: absolute;
  top: 46px;
  right: 25px;
  color: #fcac44;
  font-weight: 700;
  font-size: 12px;
}

.connect h2 {
  font-size: 16px;
  font-weight: 700;
}

.connect .fa-check {
  border: 1.5px solid #00b665;
  font-size: 10px;
  border-radius: 50px;
  padding: 5px;
}

.connect .fa-linkedin-in {
  color: #fff;
  background: #0098d9;
  padding: 4px 5px 5px;
  border-radius: 5px;
  font-size: 13px;
  margin: 0px 3px;
}

.connect h2 {
  font-size: 16px;
  font-weight: 700;
}

.connect .fa-check {
  border: 1.5px solid #00b665;
  font-size: 10px;
  border-radius: 50px;
  padding: 5px;
}

.connect .fa-linkedin-in {
  color: #fff;
  background: #0098d9;
  padding: 4px 5px 5px;
  border-radius: 5px;
  font-size: 13px;
  margin: 0px 3px;
}

.connect p {
  color: #b2acac;
  font-weight: 600;
}

.select {
  cursor: pointer;
  display: flex;
  position: relative;
  font-size: 16px;
  color: #fff;
  width: 100%;
  height: 50px;
  align-items: center;
}

.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f5f7fd;
  padding: 11px 12px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  color: #6b6b6b;
  border: 2px solid #ecf6fa;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
}

.wrapper-dropdown .select-styled {
  background: #fff;
  color: #c5c5c5;
  font-weight: 500;
}

.select-options {
  display: none;
  position: absolute;
  top: 120%;
  right: 0;
  left: 0;
  z-index: 9999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #fff;
  color: #6b6b6b;
  border: 1px solid #e8e3e3;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.07);
  font-size: 13px;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: auto;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.select-options.right-option {
  left: 15px;
}
.select-options.upload_type{
  top:100%;
}
.edit-location .select-options {
  left: 15px;
  max-height: 135px;
}

.select-options.position-top {
  top: 115%;
}

.select-options.select-position {
  top: 85%;
}
.select-options.select-top100{
  top:100%;
}
.select-options.select-top70{
  top:70%;
}

.select-options li {
  font-weight: 600;
  margin: 0;
  padding: 12px 0;
  text-indent: 15px;
  border-bottom: 1px solid #f3f2f2;
  -moz-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  -webkit-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
  color: #6b6b6b;
  -ms-transition: all 0.15s ease-in;
}

.select-options li:hover {
  background: #f8fafe;
}

.select-options li[rel='hide'] {
  display: none;
}

.select-arrow {
  position: absolute;
  top: 47px;
  right: 34px;
  color: #888;
  font-size: 13px;
}
.select-arrow.without-label{
  top:20px;
}
.select-options::-webkit-scrollbar {
  width: 4px;
}

.select-options::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.select-options::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.select-options::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/*-- Account Setting -- */
.account-setting h2 {
  font-size: 16px;
  font-weight: 700;
  position: relative;
}

.account-setting h2 .underline {
  width: 70%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_1 {
  width: 82%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_2 {
  width: 100%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_3 {
  width: 77%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_4 {
  width: 75%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting .custom_form .form-input {
  font-size: 14px;
  transition: border 0.1s ease-in-out;
  -webkit-transition: border 0.1s ease-in-out;
  -moz-transition: border 0.1s ease-in-out;
  -ms-transition: border 0.1s ease-in-out;
  -o-transition: border 0.1s ease-in-out;
}

.account-setting .custom_form .form-input:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #80bdff;
}

.account-setting .account-client {
  background-image: url(../images/account-client.png);
  background-size: 280px;
  background-position: -40px 10px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 120px;
  border-radius: 2px 12px 12px 0px;
  margin-top: -1px;
  margin-bottom: -1px;
  -webkit-border-radius: 2px 12px 12px 0px;
  -moz-border-radius: 2px 12px 12px 0px;
  -ms-border-radius: 2px 12px 12px 0px;
  -o-border-radius: 2px 12px 12px 0px;
}

.account-setting h2 .underline {
  width: 70%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_1 {
  width: 82%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_2 {
  width: 100%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_3 {
  width: 77%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting h2 .underline_4 {
  width: 75%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

.account-setting .custom_form .form-input {
  font-size: 14px;
  transition: border 0.1s ease-in-out;
  -webkit-transition: border 0.1s ease-in-out;
  -moz-transition: border 0.1s ease-in-out;
  -ms-transition: border 0.1s ease-in-out;
  -o-transition: border 0.1s ease-in-out;
}

.account-setting .custom_form .form-input:focus {
  outline: none;
  box-shadow: none;
  border: 2px solid #80bdff;
}  
.account-setting .account-creator {
  background-image: url(../images/account-creator.png);
  background-size: 280px;
  background-position: 8px 5px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 120px;
  border-radius: 2px 12px 12px 0px;
  margin-top: -1px;
  margin-bottom: -1px;
  -webkit-border-radius: 2px 12px 12px 0px;
  -moz-border-radius: 2px 12px 12px 0px;
  -ms-border-radius: 2px 12px 12px 0px;
  -o-border-radius: 2px 12px 12px 0px;
}

.account-setting .switch input[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.account-setting .switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: #00b665;
  display: block;
  border-radius: 100px;
  position: relative;
}

.account-setting .switch .account-switch:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 5px;
  width: 25px;
  height: 25px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.account-setting .switch input:checked + .account-switch {
  background: #10c89b;
}

.account-setting .switch input:checked + .account-switch:after {
  left: calc(100% - 30px);
}  
.account-setting .client {
  font-weight: 700;
  color: #848484;
  font-size: 15px;
  width: 25%;
}

.account-setting .switch-div {
  width: 28%;
}

.account-setting .creator {
  font-weight: 700;
  color: #848484;
  font-size: 15px;
  width: 34%;
}

.accunt-setting div.active,
.account-setting div.active {
  color: #10c89b;
}
.account-deactivated {
  background-image: url(../images/deactivate-account.jpg);
  background-size: contain;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 150px;
  margin-left: 0.5px;
  border-radius: 8px 8px 0px 0px;
  -webkit-border-radius: 8px 8px 0px 0px;
  -moz-border-radius: 8px 8px 0px 0px;
  -ms-border-radius: 8px 8px 0px 0px;
  -o-border-radius: 8px 8px 0px 0px;
}

.account-deactivated h2 {
  font-size: 20px;
  color: #fff;
}

.account-setting-dropdown .select-class {
  left: 22px;
  top: 42px;
}

.account-setting-profile-dropdown .select-class {
  left: 8px;
  top: 42px;
}
.next-prev{
  position:absolute;
  top:50%; 
  transform:translate(-50%,-50%);
  transition:0.5s all ease-in-out; 
}
.next-prev.left{
  left: -50px;
  background: rgba(0,0,0,.5);
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  opacity:0;
}
.next-prev.right{
  right: -100px;
  background: rgba(0,0,0,.5);
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  opacity:0;
}
.next-prev .fa-angle-left{ 
  color:#fff;
  font-size: 25px;
  cursor: pointer;
  padding: 10px 15px 10px 10px;
}
.next-prev .fa-angle-right{
  color:#fff;
  font-size: 25px;
  cursor: pointer;
  padding: 10px 10px 10px 15px;
}
.user-work-section:hover .next-prev.left {
  left:15px;
  opacity:1;
} 
.user-work-section:hover .next-prev.right {
  right:-22px;
  opacity:1;
} 
.upload-project-big-thumbnail .next-prev.left{
  left:0px;
}
.next-prev.right{
  right:-30px;
}
.upload-project-big-thumbnail:hover .next-prev.left{
  left:18px;
  opacity:1;
}  
.upload-project-big-thumbnail:hover .next-prev.right{
  right:-18px;
  opacity:1;
}
.project_prev{
  position:absolute;
  top:50%;
  left:125px; 
  transform:translate(-50%,-50%);
  transition:0.5s all ease-in-out;
  cursor: pointer;
}
.project_next{
  position:absolute;
  top:50%;
  right:50px; 
  transform:translate(-50%,-50%);
  transition:0.5s all ease-in-out;
  cursor: pointer;
} 
.project_prev i,
.project_next i{
  font-size:18px;
  color:#fff; 
  border:1px solid #eee;
  border-radius: 36px;
  width:50px;
  height:50px;
  line-height: 48px;
  text-align: center;
  transition:0.3s all ease-in-out;
}
.project_prev i:hover,
.project_next i:hover{
  background: rgba(255,255,255,1);
  color:#000;
}
.profile-creator {
  background-image: url(../images/deactivate-account.jpg);
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 100.1%;
  min-height: 80px;
  margin-top: -0.5px;
  margin-left: 0px;
  border-radius: 8px 8px 0px 0px;
  -webkit-border-radius: 8px 8px 0px 0px;
  -moz-border-radius: 8px 8px 0px 0px;
  -ms-border-radius: 8px 8px 0px 0px;
  -o-border-radius: 8px 8px 0px 0px;
}

.profile-creator.edit-collateral-modal {
  margin-left: 0px;
}

.profile-creator h2 {
  font-size: 20px;
  color: #fff;
}
.video_iframe{
  width:600px;
  height:450px;
  margin:auto;
} 
.fullscreen_video{
  width:100%;
  height:100vh;
  margin:auto;
}
.account-notification .select {
  width: 70%;
  height: 35px;
  line-height: 10px;
  justify-content: flex-end;
}

.account-notification .select-styled,
.account-notification .select-options li {
  color: #000;
  font-weight: 600;
  font-size: 13px;
}

.account-notification .select-arrow {
  top: 12px;
  right: 14px;
}

.account-email-alert {
  border-bottom: 1px solid #f3f2f2;
}

.account-email-alert:last-child {
  border: none;
}  
.view-more span {
  cursor: pointer;
}

/*-- Dashboard setting css -- */
.dashboard {
  background-image: url(../images/dashboard-submit.jpg);
  background-size: cover;
  background-position: 0px -20px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 250px;
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
}

.dashboard-content .dashboard-welcome {
  padding: 30px;
}

.dashboard-content .dashboard-welcome h2 {
  font-size: 25px;
}

.dashboard-welcome .text-light-color {
  color: #959595;
}

.dashboard-welcome .text-light-color.active {
  color: #000 !important;
}

.dashboard-welcome .fa-icon {
  color: #959595;
}

.dashboard-welcome .fa-icon.active {
  color: #00b665 !important;
}
.dashboard-welcome img {
  filter: brightness(0.2) contrast(0.1);
  vertical-align: unset;
}
.dashboard-welcome .img-fliud.active {
  color: #00b665 !important;
  filter: brightness(1) contrast(1);
}

.user-height-section {
  min-height: 418px;
}

.user-application {
  border: 2px solid #e9e7e7;
  border-radius: 50%;
  padding: 30px;
  border-left-width: 0px;
  border-bottom-width: thin;
  border-top-width: thin;
  position: relative;
  width: 230px;
  height: 230px;
  margin: auto;
}

.timeline-item {
  padding: 10px 10px 15px;
  position: relative;
}

.timeline-item h1 {
  font-size: 17px;
  font-weight: 600;
  color: #848484;
}

.timeline-item h1.active {
  font-size: 20px;
  font-weight: 700;
  color: #000 !important;
}

.timeline-item-dot-1 {
  width: 8px;
  height: 8px;
  top: 23px;
  right: 36px;
  display: block;
  position: absolute;
  border-radius: 50%;
  content: '';
  background: gray;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.timeline-item-dot-1.active {
  width: 12px;
  height: 12px;
  background: #00b665;
  right: 32px;
}

.timeline-item-dot-2 {
  top: 148px;
  right: 1px;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  border-radius: 50%;
  content: '';
  background: gray;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.timeline-item-dot-2.active {
  right: 3px;
  top: 63px;
  width: 12px;
  height: 12px;
  background: #00b665;
}

.timeline-item-dot-2.reveiw-complete {
  top: 65px;
  right: 4px;
}

.timeline-item-dot-3 {
  top: 196px;
  right: 34px;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  border-radius: 50%;
  content: '';
  background: gray;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.timeline-item-dot-3.profile-under-review {
  top: 162px;
  right: 8px;
}

.timeline-item-dot-3.active {
  right: -6px;
  top: 118px;
  background: #00b665;
  width: 12px;
  height: 12px;
}  
.clients-logo img {
  max-width: 70%;
}

/*-- Date Css -- */
.input-group-append .btn-outline-secondary {
  background: #f5f7fd !important;
  border: 2px solid #ecf6fa !important;
  border-left: 0 !important;
}

.input-group-append .btn-outline-secondary:focus {
  box-shadow: none !important;
  color: #6b6b6b !important;
}

.gj-picker-bootstrap {
  width: 28% !important;
}

.gj-picker-bootstrap table {
  width: 100% !important;
}

.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
  border-radius: 36px !important;
  -webkit-border-radius: 36px !important;
  -moz-border-radius: 36px !important;
  -ms-border-radius: 36px !important;
  -o-border-radius: 36px !important;
}

.gj-datepicker-bootstrap [role='right-icon'] button .gj-icon,
.gj-datepicker-bootstrap [role='right-icon'] button .material-icons {
  top: 12px !important;
  left: 5px !important;
}

/*-- collaterals offered --*/
.overflow-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6c7a8f;
  max-width: 80px;
}
.onBoarding-height {
  height: calc(100vh - 0px);
  padding-bottom: 0px !important;
  overflow: hidden;
}
.card.bg-purple.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.onboarding_style_questions .content {
  display:flex; 
  flex-wrap: wrap;
  grid-template-columns: unset;
  grid-gap: unset;
  padding: 0px; 
}
.onboarding_style_questions .content li{
  width:100%;
  max-width: 170px;
  display:inline-block;
  margin-right:20px;
  margin-bottom:20px;
  box-sizing: border-box;
}
.onboarding_style_questions .content .deadline{
  height:128px;
}
.onboarding_style_questions.xl_size .content li{
  max-width:200px;
}
.onboarding_style_questions.xl_size .content .deadline{
  height:150px;
}
.company_content .content{
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
  padding:0px;
}
.company_content .content .deadline{
  height:135px;
}
.web-links{
  margin-left:120px;
  margin-top:5px;
  display:block;
}
.web-links-modal{
  margin-left:115px;
  margin-top:5px;
  display:block;
}
.collaterals .sub_title:nth-child(2):before {
  background-color: #fff;
}

.collaterals .sub_title:nth-child(2):after {
  background-color: #fff;
}

.collacteral::after {
  content: '';
  width: 10px;
  height: 35px;
  border-left: 1px solid #ffffff;
  position: absolute;
  left: -6px;
  top: -6px;
  opacity: 0.4;
}

.tabs-icon::after {
  content: '';
  width: 10px;
  height: 35px;
  border-left: 1px solid #9b9b9b;
  position: absolute;
  left: 52px;
  top: -6px;
  opacity: 0.4;
}

.modal-tab.tabs-icon::after {
  left: 30px;
}

.collaterals i {
  cursor: pointer;
}

.card .wrapper-form.active {
  box-shadow: none !important;
}

.wrapper-form {
  background: #f5f7fd;
  border: 0!important;
  border-radius: 4px;
}
.wrapper-form .input-icon {
  position: absolute;
  top: 35px;
  left: 18px;
}

.work-experience .post-search.rupees {
  top: 19px;
  left: 18px;
}

.wrapper-form .work-experience .email {
  padding-left: 35px;
}

.edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
  right: 320px;
}

.wrapper-form .input-icon.icon-right {
  right: 395px;
  left: unset;
}

.wrapper-form.active {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.experience-section .email {
  border-color: #e1edf1;
  border-radius: 8px;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-radius: 8px;
}
.onboarding_dropdown_wrapper .price_input{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; 
}
.onboarding_dropdown_option .dropdown-menu{
  padding:0;
}
.onboarding_dropdown_option .dropdown.show{
  right:0;
}
.onboarding_dropdown_option .dropdown-toggle{
  background: #f8fafe;
  border: 1.5px solid #e8ecf4;
  height:50px;
  color:#000;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left:0;
  font-size:14px;
}
.onboarding_dropdown_option .btn-secondary:not(:disabled):not(.disabled).active, 
.onboarding_dropdown_option .btn-secondary:not(:disabled):not(.disabled):active, 
.onboarding_dropdown_option .show>.btn-secondary.dropdown-toggle{
  color:#000 !important;
  background: #f8fafe !important;
  border-color: #e8ecf4 !important;
}
.onboarding_dropdown_option .dropdown-toggle:focus,
.onboarding_dropdown_option .dropdown-togggle:active{
  box-shadow: none !important;
  outline:none;
  color:#000 !important;
  background: #f8fafe !important;
  border-color:#e8ecf4 !important;
}
.onboarding_dropdown_option .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.onboarding_dropdown_option .dropdown-item{
  border-bottom: 1px solid #f3f2f2;
  font-size: 12px;
  padding: 0.8rem 0.8rem;
  white-space: inherit
}
.experience-section .input-text-left {
  background: transparent;
  border: 1.5px solid #e1edf1;
  border-right: none;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.experience-section .input-text-right {
  background: transparent;
  border: 1.5px solid #e1edf1;
  border-left: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}
.experience-section .input-group-prepend {
  margin-left: 0x;
  margin-right: -4px;
}
.experience-section .input-group-append {
  margin-left: -4px;
}
.experience-section .input-text-left:hover,
.experience-section .input-text-right:hover {
  background-color: #e9ecef;
}

/*-- Multi select -- */
.multi-select .chosen-container-multi {
  width: 100% !important;
}

.multi-select .chosen-choices {
  font-size: 14px !important;
  height: 48px !important;
  background: #f5f7fd !important;
  border: 2px solid #ecf6fa !important;
  color: #000 !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.multi-select .search-choice {
  padding: 10px 25px 10px 12px !important;
  font-size: 13px !important;
  background: #eff1f8 !important;
  border-color: #cbd3df !important;
  margin-top: 5px !important;
  border-radius: 6px !important;
}

.multi-select
  .chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close {
  top: 10px !important;
  right: 5px !important;
}

.multi-select
.chosen-container-multi
.chosen-choices
li.search-field
input[type='text'] {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 42px !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important;
}

.multi-select .chosen-container .chosen-results li {
  padding: 15px !important;
}
.price_wrapper .price_input{
  padding-left:28px;
}
.price_wrapper .price_input::placeholder{
  font-weight: 700;
}
.price_wrapper .work-experience .post-search.rupees{
  top:18px;
  left:32px;
  font-size:14px;
}
.price_wrapper .price_icon{
  color:#6c7a8f;
}
/*-- Web links --*/
.link img{
  width: 25px;
  height:25px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-file {
  border: 2px dashed #eee;
  color: gray;
  background-color: white;
  padding: 50px 20px 65px;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.upload-btn-wrapper input[type='file'] {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  min-height: 300px;
}


/*-- profile creator --*/
.rating ul li {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #848484;
  position: relative;
}

.rating ul li:before {
  content: '';
  height: 100%;
  width: 100%;
  border-left: 1px solid #dbdbdb;
  position: absolute;
  top: 0px;
  left: -40px;
}

.rating ul li:nth-child(1):before {
  display: none;
}

.main-content {
  background-color: #fff;
}
.main-content .project_nav_tabs{
   margin:0 50px 0px 30px;
   display:flex;
   flex-wrap: initial;
   white-space: nowrap;
   overflow:hidden;
}
.main-content .prev_tabs{
  position: absolute;
  top: 5px;
  left: 0px;
}
.main-content .prev_tabs i{
  width: 30px;
  height: 35px; 
  line-height: 35px; 
  padding-left:10px;
  color: #000;
  font-size: 16px;
  border-top-right-radius: 36px;
  border-bottom-right-radius: 36px;
  cursor: pointer;
} 
.main-content .next_tabs i{
  width: 30px;
  height: 35px; 
  line-height: 35px; 
  padding-left:10px;
  color: #000;
  font-size: 16px;
  border-top-left-radius: 36px;
  border-bottom-left-radius: 36px;
  cursor: pointer;
}
.main-content .next_tabs{
  position: absolute;
  right: 0px;
  top: 5px;
}
.main-content .nav-tabs::-webkit-scrollbar {
  width: 5px;
  height:5px;
} 
.main-content .nav-tabs::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
.main-content .nav-tabs::-webkit-scrollbar-thumb {
  background: #b7babc; 
} 
.main-content .nav-tabs .nav-item.show .nav-link,
.main-content .nav-tabs .nav-link.active {
  background: transparent;
  font-weight: 800;
  color: #000;
  border: none;
  border-bottom: 2px solid #00b665;
}

.main-content .nav-tabs .nav-link {
  color: #848484;
  font-weight: 700;
  padding: 12px 15px; 
} 
.main-content .nav-tabs .nav-link:hover,
.main-content .nav-tabs .nav-link:focus {
  border: 1px solid transparent;
  outline:none;
  /* border-bottom: 2px solid #00b665; */
  color: #000; 
} 
.client-image {
  position: absolute;
  opacity: 0;
}
.content {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 36px;
  padding: 30px;
}

.content .team {
  padding: 6px;
  background: #fff;
  border: 1px solid #f0eaea;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, .05); */
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.multi-shot {
  position: relative;
  transition: 3s all;
}

.content-link {
  position: relative;
} 
.project-view{ 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 240px;
    border-radius: 6px;
} 

.content-over {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.09);
  height: 98%;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  opacity: 0;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}
.content-over.show {
  right: 0px !important;
}
.account-setting .switch-div {
  width: 28%;
}

.account-setting .creator {
  font-weight: 700;
  color: #848484;
  font-size: 15px;
  width: 34%;
}

.account-deactivated {
  background-image: url(../images/deactivate-account.jpg);
  background-size: contain;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 150px;
  margin-left: 0.5px;
  border-radius: 8px 8px 0px 0px;
  -webkit-border-radius: 8px 8px 0px 0px;
  -moz-border-radius: 8px 8px 0px 0px;
  -ms-border-radius: 8px 8px 0px 0px;
  -o-border-radius: 8px 8px 0px 0px;
}

.account-deactivated h2 {
  font-size: 20px;
  color: #fff;
}

.account-setting-dropdown .select-class {
  left: 22px;
  top: 42px;
}

.account-setting-profile-dropdown .select-class {
  left: 8px;
  top: 42px;
}
.account-notification .select {
  width: 70%;
  height: 35px;
  line-height: 10px;
  justify-content: flex-end;
}

.account-notification .select-styled,
.account-notification .select-options li {
  color: #000;
  font-weight: 600;
  font-size: 13px;
} 
.account-email-alert {
  border-bottom: 1px solid #f3f2f2;
}

.account-email-alert:last-child {
  border: none;
}

.tax-account {
  background-image: url(../images/tax-account.png);
  background-size: cover;
  background-position: 0px -82px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.tax-account h2 {
  font-size: 30px;
  color: #000;
  font-weight: 600;
}
.tax-account .tax_amount{
  padding:40px 30px;
}
.tax-account .fa-rupee-sign{
  font-size:25px;
}
.tax-account .sub_title:last-child {
  cursor: pointer;
}

.view-more span {
  cursor: pointer;
}

/*-- Dashboard setting css -- */
.w-220 {
  width: 220px;
  min-width: 220px;
}
.onboarding-left {
  width: 199px;
  height: 76px;
}
.dashboard {
  background-image: url(../images/dashboard-submit.jpg);
  background-size: cover;
  background-position: 0px -20px;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 250px;
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
}

.dashboard-content .dashboard-welcome {
  padding: 30px;
}
.dashboard-welcome .sub-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6c7a8f;
  max-width: 130px;
}
.basicDetails-height .selected-tags {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.04px;
  color: #707d92;
  border-radius: 7px;
  border: solid 1px #cbd3df;
  background-color: #eff1f8;
}
.selected-tags.common-tags {
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.04px;
    color: #1464DC;
}
.basicDetails-height .label {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal; 
  font-size: 15px;
  color: #707e92;
}
.basicDetails-height .sub_title {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.07px;
  color: #6c7a8f;
}
.dashboard-content .dashboard-welcome h2 {
  font-size: 25px;
}

.dashboard-welcome .text-light-color {
  color: #6c7a8f;
}

.dashboard-welcome .text-light-color.active {
  color: #000 !important;
}

.dashboard-welcome .fa-icon {
  color: #6c7a8f;
}

.dashboard-welcome .fa-icon.active {
  color: #00b665 !important;
}

.user-height-section {
  min-height: 418px;
}

/* .user-application {
  border: 2px solid #e9e7e7;
  border-radius: 50%;
  padding: 50px;
  border-left-width: 0px;
  border-bottom-width: thin;
  border-top-width: thin;
  position: relative;
  width: 230px;
  height: 230px;
  margin: auto;
} */

.timeline-item {
  padding: 10px 10px 15px;
  position: relative;
}

.timeline-item h1 {
  font-size: 17px;
  font-weight: 600;
  color: #848484;
}

.timeline-item h1.active {
  font-size: 20px;
  font-weight: 700;
  color: #000 !important;
}

.timeline-item-dot-1 {
  width: 8px;
  height: 8px;
  top: 23px;
  right: 36px;
  display: block;
  position: absolute;
  border-radius: 50%;
  content: '';
  background: gray;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.timeline-item-dot-1.active {
  width: 12px;
  height: 12px;
  background: #00b665;
  right: 32px;
}

.timeline-item-dot-2 {
  top: 148px;
  right: 1px;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  border-radius: 50%;
  content: '';
  background: gray;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.timeline-item-dot-2.active {
  right: 3px;
  top: 63px;
  width: 12px;
  height: 12px;
  background: #00b665;
}

.timeline-item-dot-2.reveiw-complete {
  top: 65px;
  right: 4px;
}

.timeline-item-dot-3 {
  top: 196px;
  right: 34px;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  border-radius: 50%;
  content: '';
  background: gray;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.timeline-item-dot-3.profile-under-review {
  top: 162px;
  right: 8px;
}

.timeline-item-dot-3.active {
  right: -6px;
  top: 118px;
  background: #00b665;
  width: 12px;
  height: 12px;
}

.timeline-item-dot-3.temporarily.active {
  background-color: #E9CB02;
}

.clients-logo img {
  max-width: 70%;
}

/*-- Date Css -- */
.input-group-append .btn-outline-secondary {
  background: #f5f7fd !important;
  border: 2px solid #ecf6fa !important;
  border-left: 0 !important;
}

.input-group-append .btn-outline-secondary:focus {
  box-shadow: none !important;
  color: #6b6b6b !important;
}

.gj-picker-bootstrap {
  width: 28% !important;
}

.gj-picker-bootstrap table {
  width: 100% !important;
}

.gj-picker-bootstrap table tr td.selected.gj-cursor-pointer div {
  border-radius: 36px !important;
  -webkit-border-radius: 36px !important;
  -moz-border-radius: 36px !important;
  -ms-border-radius: 36px !important;
  -o-border-radius: 36px !important;
}

.gj-datepicker-bootstrap [role='right-icon'] button .gj-icon,
.gj-datepicker-bootstrap [role='right-icon'] button .material-icons {
  top: 12px !important;
  left: 5px !important;
}

/*-- collaterals offered --*/
.education_modal .sub_title:nth-child(2):after,
.education_tab .sub_title:nth-child(2):after{
  display:none;
}

.card.bg-purple.active {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.collaterals .sub_title:nth-child(2):before {
  background-color: #fff;
}

.collaterals .sub_title:nth-child(2):after {
  background-color: #fff;
}

.collacteral::after {
  content: '';
  width: 10px;
  height: 35px;
  border-left: 1px solid #ffffff;
  position: absolute;
  left: -6px;
  top: -6px;
  opacity: 0.4;
}

.tabs-icon::after {
  content: '';
  width: 10px;
  height: 35px;
  border-left: 1px solid #9b9b9b;
  position: absolute;
  left: 52px;
  top: -6px;
  opacity: 0.4;
}

.modal-tab.tabs-icon::after {
  left: 30px;
}

.collaterals i {
  cursor: pointer;
}

.card .wrapper-form.active {
  box-shadow: none !important;
  border-top:none;
}
.wrapper-form .email {
  background: #fff;
  padding-left: 40px;
  border: 1.5px solid #e1edf1;
}

.wrapper-form .input-icon {
  position: absolute;
  top: 35px;
  left: 18px;
}

.work-experience .post-search.rupees {
  top: 19px;
  left: 18px;
}

.wrapper-form .work-experience .email {
  padding-left: 35px;
}

.edit-collateral-form-modal .wrapper-form .input-icon.icon-right {
  right: 320px;
}

.wrapper-form .input-icon.icon-right {
  right: 395px;
  left: unset;
}

.wrapper-form.active {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.experience-section .email {
  border-color: #e1edf1;
  border-radius: 8px;
}

.experience-section .input-text-left {
  background: transparent;
  border: 1.5px solid #e1edf1;
  border-right: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.experience-section .input-text-right {
  background: transparent;
  border: 1.5px solid #e1edf1;
  border-left: none;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.experience-section .input-text-left:hover,
.experience-section .input-text-right:hover {
  background-color: #e9ecef;
}

/*-- Multi select -- */
.multi-select .chosen-container-multi {
  width: 100% !important;
}

.multi-select .chosen-choices {
  font-size: 14px !important;
  height: 48px !important;
  background: #f5f7fd !important;
  border: 2px solid #ecf6fa !important;
  color: #000 !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.multi-select .search-choice {
  padding: 10px 25px 10px 12px !important;
  font-size: 13px !important;
  background: #eff1f8 !important;
  border-color: #cbd3df !important;
  margin-top: 5px !important;
  border-radius: 6px !important;
}

.multi-select
  .chosen-container-multi
  .chosen-choices
  li.search-choice
  .search-choice-close {
  top: 10px !important;
  right: 5px !important;
}

.multi-select
  .chosen-container-multi
  .chosen-choices
  li.search-field
  input[type='text'] {
  padding-left: 10px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 42px !important;
  font-weight: 500 !important;
  font-family: 'Poppins', sans-serif !important;
}

.multi-select .chosen-container .chosen-results li {
  padding: 15px !important;
}
.custom_url .add-collateral-underline {
  width: 82%;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-file {
  border: 2px dashed #eee;
  color: gray;
  background-color: white;
  padding: 50px 20px 65px;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

.upload-btn-wrapper input[type='file'] {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  min-height: 300px;
}

/*-- profile creator --*/
.rating ul li {
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #848484;
  position: relative;
}

.rating ul li:before {
  content: '';
  height: 100%;
  width: 100%;
  border-left: 1px solid #dbdbdb;
  position: absolute;
  top: 0px;
  left: -40px;
}

.rating ul li:nth-child(1):before {
  display: none;
}

.main-content {
  background-color: #fff;
}

.main-content .nav-tabs .nav-item.show .nav-link,
.main-content .nav-tabs .nav-link.active {
  background: transparent;
  font-weight: 800;
  color: #000;
  border: none;
  border-bottom: 2px solid #00b665;
}
.main-content .nav-tabs .nav-item:focus{
  outline:none;
  border:none;
}
.main-content .nav-tabs .nav-link {
  color: #848484;
  font-weight: 700;
  padding: 12px 15px;
}

/* .main-content .nav-tabs .nav-link:hover {
  border: 1px solid #f5f7fd;
  border-bottom: 2px solid #00b665;
  color: #000;
} */
.under_review {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #eee;
  font-size: 15px;
  font-weight: 600;
  width: 75%;
  text-align: center;
  background: rgba(0,0,0,0.7);
  padding: 10px;
  border-radius: 36px;
}
.style_name{ 
  color:#000;
  font-size:14px;
  font-weight:700;
  margin-bottom:0px;
  text-align: center;
  width:100%; 
  line-height: 20px;
  margin:15px auto;
  text-transform: capitalize;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 230px; */
}
.content {
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 36px;
  padding: 30px;
}

.content .team {
  padding: 6px;
  background: #fff;
  border: 1px solid #f0eaea;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, .05); */
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.multi-shot {
  position: relative;
  transition: 3s all;
}

.content-link {
  position: relative;
}

.content-link picture {
  width: 100%;
  height: 207px;
  background: #f5f7fd;
  display: inline-block;
  overflow: hidden;
  border-radius: 6px;
  text-align: center;
}

.content-img img {
  border-radius: 6px;
  width: 100%;
  height: 240px;
  object-fit:cover;
  max-width: 100%;
}

.content-over {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.09);
  height: 98%;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  opacity: 0;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.hover-text {
  padding: 10px;
  display: block;
  position: absolute;
  bottom: 1px;
  font-weight: 500;
  /* background-image: linear-gradient( 178.1deg, rgba(60,55,106,.03) 8.5%, rgba(0,0,0,.45) 82.4% ); */
  width: 100%;
  border-radius: 5px;
  color: #fff;
}

.multi-shot:hover .content-over {
  opacity: 1;
}

.profile-inner .custom_form .dropdown-menu-arrow {
  left: 138px;
}

.profile-inner .custom_form .dropdown-menu.dropdown-list-menu {
  left: -112px !important;
}

.content-over.portfolio.project_under_review{
  opacity: 1;
}

.content-over.portfolio .dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: #000;
}

.content-over.portfolio {
  text-align: right;
  padding: 6px;
  cursor:pointer;
}

.custom_form .dropdown_btn.portfolio .dropdown_icon {
  background: #fff;
}

/*-- right sidebar --*/
.right_sidebar .content-over.portfolio {
  padding: 8px;
}
/* 
.right_sidebar .dropdown-menu {
  top: -65px !important;
  left: -90px !important;
  border-radius: 36px !important;
  -webkit-border-radius: 36px !important;
  -moz-border-radius: 36px !important;
  -ms-border-radius: 36px !important;
  -o-border-radius: 36px !important;
  padding: 0px;
} */

.profile-inner .right_sidebar .dropdown-item {
  border-radius: 36px;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  -ms-border-radius: 36px;
  -o-border-radius: 36px;
}
.client-content .basic_profile{
   width:50px;
   height:50px;
   border-radius: 4px;
   -webkit-border-radius: 4px;
   -moz-border-radius: 4px;
   -ms-border-radius: 4px;
   -o-border-radius: 4px;
}
.client-content .basic_profile img{
  height:50px;
  max-height:50px;
}
.under_review_search i {
  position: absolute;
  bottom: -10px;
  right: 30px;
  color: #fff;
}

.tags {
  padding: 12px 10px;
  background: #eff1f8;
  border: 0px solid #d0d7e2;
  color: #1464DC;
  font-weight: 600;
  font-size: 13px;
  border-radius: 5px;
  margin: 4px 10px 5px 0px;
}

/*-- Edit Collateral Form Modal -- */
.edit-collateral-form-modal .tabs-form {
  margin-top: -4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.add-collateral-underline {
  width: 84%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

/*-- Work section -- */
.user-work-section {
  text-align: center;
  height: 443px;
  max-width:590px;
  margin: 30px auto 15px;
  overflow: hidden;
  border-radius: 12px; 
  position:relative;
  background:#fff;
}

.user-work-section img { 
  width:auto;
  height: 443px;
  margin: auto;
  border-radius: 12px;
}
.user-work-section .project_big_image{
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
  min-height:443px;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 12px;
}
.fullscreen{
    background-color: rgba(0,0,0,.5);
    font-size: 14px;
    color: #fff;
    position: absolute;
    z-index: 4;
    line-height: 32px;
    padding: 0 1em;
    border-radius: 8px;
    top: 20px;
    right: 20px;
    display: inline-block;
    opacity: 0;
    -webkit-transition: opacity .25s ease-in-out;
    -o-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    -webkit-transition-delay: 0;
    -o-transition-delay: 0;
    transition-delay: 0;
}
.view_project{
  text-align: right;
}
.view_project span{
  background-color:#3454d1;
  font-size: 14px;
  color: #fff;  
  padding: 0.3em 0.8em;
  border-radius: 4px; 
  font-size:13px;
  cursor: pointer;
  display: inline-block;
}
.user-work-section:hover .fullscreen{
  opacity:1;
}
.user-work-thumbnail {
  padding: 15px;
}

.user-work-thumbnail img {
  width: 100px;
  height: 70px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

/* .user-work-thumbnail .thumb-img {
  display: inline-block;
  border: 1.5px solid #d7d3d3;
  padding: 2px;
  border-radius: 12px;
  margin-right: 10px;
}

.user-work-thumbnail .thumb-img.active {
  border: 1.5px solid #5fdde5;
} */

.user-work-edit i {
  border: 1px solid #e4e4e4;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  line-height: 42px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.published {
  font-size: 9px;
  color: #848484;
  border: 1.5px solid #848484;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  border-radius: 100%;
  margin: 4px 4px 4px 0px;
}

/*-- Upload a project --*/
.header_fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 110;
}

.choose-underline {
  width: 100px;
  height: 2px;
  background: #ccc;
  margin: 0px 10px;
}
.choose-underline.active{
  background: #00b665;
}
.choose-collateral .sticky-note i {
  transform: rotate(270deg);
}

.complete-check i {
  padding: 4px;
  font-size: 12px;
  border: 2px solid #00b665;
  box-sizing: border-box;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.choose-collateral,
.publish-project {
  color: #ccc;
}

.publish-project.active,
.choose-collateral.active {
  color: #000;
}

.publish-project.active .fa-users,
.choose-collateral.active .fa-sticky-note {
  color: #00b665;
}

.choose-title p {
  color: #6c7a8f;
}

.user-upload-content-work {
  background-color: #9e41df;
  background-position: center right;
  background-size: 130px;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
  cursor: pointer;
  aspect-ratio: 4 / 3;
}
.service-ratio{
  aspect-ratio: 4 / 3;
}
.inActive{
  opacity:0.5;
}
.serv-title{
  color: #050421;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}
.custom_form .form-control::placeholder {
  font-size: 13px;
  font-family: "Mulish", sans-serif;
}
.serv-price{
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #050421;
}
.user-upload-content-work.active {
  opacity: 1;
}
.job_wrapper .user-upload-content-work:after{
  z-index:0;
}
.user-upload-content-work:after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 1003;
  background: #1464DC;
  color:#FFF;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 6px;
  font-size: 12px;
  text-align: center;
  display: none;
  cursor: pointer;
}

.user-upload-content-work.active:after {
  display: block;
}

.user-upload-content-text {
  position: absolute;
  bottom: 0;
  word-break: break-word;
}

.upload-a-project {
  background-image: url(../images/upload-a-project.png);
  background-position: 100% 30%;
  background-size: 160px;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.hover-text {
  padding: 10px;
  display: block;
  position: absolute;
  bottom: 1px;
  font-weight: 500;
  /* background-image: linear-gradient( 178.1deg, rgba(60,55,106,.03) 8.5%, rgba(0,0,0,.45) 82.4% ); */
  width: 100%;
  border-radius: 5px;
  color: #fff;
}

.multi-shot:hover .content-over {
  opacity: 1;
}

.profile-inner .custom_form .dropdown-menu-arrow {
  left: 138px;
}

.profile-inner .custom_form .dropdown-menu.dropdown-list-menu {
  left: -112px !important;
}

.content-over.portfolio .dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f9fa;
  color: #000;
}

.content-over.portfolio {
  text-align: right;
  padding: 6px;
} 
.right_sidebar .dropdown-menu {
  top: -80px !important;
  left: -85px !important;
  border-radius: 36px !important;
  -webkit-border-radius: 36px !important;
  -moz-border-radius: 36px !important;
  -ms-border-radius: 36px !important;
  -o-border-radius: 36px !important;
  padding: 0px;
}

.profile-inner .right_sidebar .dropdown-item {
  border-radius: 36px;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  -ms-border-radius: 36px;
  -o-border-radius: 36px;
}

.under_review_search i {
  position: absolute;
  bottom: -10px;
  right: 30px;
  color: #fff;
}

/* .tags {
  padding: 8px 10px;
  background: #eff1f8;
  border: 1px solid #d0d7e2;
  color: #54647c;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
  margin: 4px 10px 5px 0px;
} */

/*-- Edit Collateral Form Modal -- */
.edit-collateral-form-modal .tabs-form {
  margin-top: -4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.add-collateral-underline {
  width: 84%;
  display: inline-block;
  margin-left: 5px;
  border-top: 1.5px solid #e7e1e1;
}

/*-- Work section -- */
/* .user-work-section {
  text-align: center;
} */

/* .user-work-section img {
  max-width: 95%;
  height: 300px;
  margin: auto;
  border-radius: 12px;
} */

.user-work-thumbnail {
  padding: 15px 5px 15px 15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: center; */
  margin:0px 30px;
}

.user-work-thumbnail img {
  width: auto;
  height: 75px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.user-work-thumbnail img.active{
  padding:2px;
  height:72px;
}
.user-work-thumbnail .thumb-img {
  display: inline-block;
  border: 1.5px solid #d7d3d3;
  /* padding: 2px; */
  border-radius: 12px;
  margin-right: 10px;
  width: 100px;
  height: 75px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  box-sizing: border-box;
}

.user-work-thumbnail .thumb-img.active {
  border: 1.5px solid #10c89b;
  padding:1px;
}

.user-work-edit i {
  border: 1px solid #e4e4e4;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  line-height: 42px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.more_thumb{
  width:auto;
  height:auto;
  padding:2px 8px;
  background:#10c89b;
  color:#fff;
  border-radius: 5px;  
  font-size: 12px;
}
.published {
  font-size: 9px;
  color: #848484;
  border: 1.5px solid #848484;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 18px;
  border-radius: 100%;
  margin: 4px 4px 4px 0px;
}

/*-- Upload a project --*/
.header_fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 110;
}

/* .choose-underline {
  width: 100px;
  height: 2px;
  background: #ccc;
  margin: 0px 10px;
}

.choose-collateral .sticky-note i {
  transform: rotate(270deg);
}

.complete-check i {
  padding: 4px;
  font-size: 12px;
  border: 2px solid #00b665;
  box-sizing: border-box;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
} */

.post_a_project_tabs {
  pointer-events: none;
}

.choose-collateral,
.publish-project {
  color: #ccc;
}

.publish-project.active,
.choose-collateral.active {
  color: #000;
}

.publish-project.active .fa-users,
.choose-collateral.active .fa-sticky-note {
  color: #00b665;
}

.choose-title p {
  color: #6c7a8f;
}
.user-upload-content-work.active {
  opacity: 1;
}
.user-upload-content-work.active:after {
  display: block;
}
.upload-image-border {
  border: 2px dashed rgba(20, 100, 220, 0.5);
}
.uploading-image input[type='file'] {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 80%;
  opacity: 0;
  FONT-WEIGHT: 100;
  min-height: auto;
  transform: translate(-50%, -85%);
  z-index: 1000;
  height: 100%;
}

.uploading-image .upload-file {
  height: 363px;
  overflow: hidden;
  cursor: default !important;
}

.uploading-image .optional_or {
  position: relative;
}

.uploading-image .optional_text {
  position: relative;
  background: #fff;
  padding: 1px 6px;
}

.uploading-image .optional_or:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1.5px solid #eee;
  width: 18%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.uploading-image .upload-thumbnail {
  -webkit-display: grid;
  -moz-display: grid;
  -ms-display: grid;
  -o-display: grid;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 15px 36px;
}
.uploading-image .card {
  height: 530px;
  width:604px;
  margin:auto;
  overflow: hidden;
}
.uploading-image .upload-file {
  height: 450px;
  overflow: hidden;
  cursor: default !important;
}

.uploading-image .optional_or {
  position: relative;
}

.uploading-image .optional_text {
  position: relative;
  background: #fff;
  padding: 1px 6px;
}

.uploading-image .optional_or:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1.5px solid #eee;
  width: 18%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.uploading-image .upload-thumbnail {
  -webkit-display: grid;
  -moz-display: grid;
  -ms-display: grid;
  -o-display: grid;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 15px 35px;
}
.uploading-image.review_milestone_uploading .upload-thumbnail {
  grid-gap:2px 5px;
}
.add_image_url_wrapper .css-1uccc91-singleValue{
  color: #495057 !important;
}

.preview_image_upload{
  background-repeat: no-repeat;
  background-position: center center;
  background-size:contain;
  min-height:450px;
  vertical-align: middle;
}

.uploading-image .upload-thumbnail .upload-thumb.active {
  border: 1.5px solid #00b665;
  padding: 1px;
  box-sizing: border-box;
} 
.upload_image_thumbnail{
    display:block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 65px;
    /* vertical-align: middle; */
    height: 100%;
    border-radius: 10px;
    cursor: pointer;
}
.upload-thumb.active .upload_image_thumbnail{
  border-radius: 12px;
}
.uploading-image .upload-thumbnail .upload-thumb {
  display: inline-block;
  border: 1.5px solid #d7d3d3;
  border-radius: 12px;
  margin-right: 10px;
  margin-bottom: 15px;
  /* width: 100px;
  height: 75px; */
  overflow:hidden;
  text-align: center;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}
.uploading-image .upload-thumbnail .upload-thumb.upload_milestone{
  width:100px;
  height:75px;
}

.uploading-image .upload-thumbnail .upload-thumb img {
  width:auto;
  height: 75px;
  max-width:100%;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.uploading-image .upload-thumbnail .upload-thumb img.active {
  height: 68px;
}

.upload-delete {
  position: absolute;
  top: 2px;
  z-index: 100;
  color: red;
  right: 6px;
  opacity: 0;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.uploading-image .upload-thumb:hover .upload-delete {
  opacity: 1;
}

.upload-delete_big {
  position: absolute;
  font-size: 20px;
  top: 15px;
  z-index: 100;
  color: red;
  right: 20px;
  opacity: 0;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.set_thumbnail_button {
  position: absolute;
  font-size: 20px;
  top: 14px;
  z-index: 100;
  color: red;
  right: 50px;
  opacity: 0;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.set_thumbnail_button .main-btn{
  padding:8px;
}
.set_thumbnail_button .complete-check i{
  border-color:#fff;
  font-size:9px;
  padding:3px;
}
.crop_image_button {
  position: absolute;
  font-size: 20px;
  top: 14px;
  z-index: 100;
  color: red;
  right: 185px;
  opacity: 0;
  transition: 0.2s ease -in -out;
  -webkit-transition: 0.2s ease -in -out;
  -moz-transition: 0.2s ease -in -out;
  -ms-transition: 0.2s ease -in -out;
  -o-transition: 0.2s ease -in -out;
}
.crop-image {
  min-height: 400px;
}
.spinner-wrapper {
  height:330px;
  display:flex;
  align-items:center;
  justify-content: center;
}
.uploading-image .upload-project-big-thumbnail:hover .upload-delete_big {
  opacity: 1;
}

.uploading-image .upload-project-big-thumbnail:hover .set_thumbnail_button {
  opacity: 1;
}

.uploading-image .upload-project-big-thumbnail:hover .crop_image_button {
  opacity: 1;
}
/* 
.uploading-image .upload-thumbnail .upload-thumb {
  display: inline-block;
  border: 1.5px solid #d7d3d3;
  border-radius: 12px;
  margin-right: 10px;
  margin-bottom: 15px;
  width: 90px;
  height: 67px;
  margin:auto;
  text-align: center;
  background-color: #fff;
  position: relative;
  cursor: pointer;
}

.uploading-image .upload-thumbnail .upload-thumb img {
  width: auto;
  height: 67px;
  max-width:100%;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.uploading-image .upload-thumbnail .upload-thumb img.active {
  height: 60px;
} */

.upload-delete {
  position: absolute;
  top: 2px;
  z-index: 100;
  color: red;
  right: 6px;
  opacity: 0;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.uploading-image .upload-thumb:hover .upload-delete {
  opacity: 1;
}

.uploading-image .upload-thumb .file-name {
  position: absolute;
  left: 0;
  bottom: -18px;
  right: 0;
  white-space: nowrap;
  width: 100%;
  word-break: unset;
}

.uploading-image .custom_form .email {
  height: 40px;
  background-color: #fff;
}

.select-btn {
  padding: 5px 25px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  border: 1.5px solid #c2cbd7;
  display: inline-block;
  border-radius: 4px;
  font-weight: 600;
  color: #53637b;
  cursor: pointer;
}

.select-btn.active {
  color: #2870e5;
  border-color: #2870e5;
}

.footer_fixed {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index:0;
}

/* .upload-project-big-thumbnail {
  text-align: center; 
  width: 484px;
  height: 363px;
  max-width: 484px;
  margin: auto;
} */

.upload-project-big-thumbnail img {
  max-width: 100%;
  height: 400px;
  margin: auto;
  border-radius: 12px;
  width: auto;
  overflow: hidden;
}
.upload-project-big-thumbnail .preview_big_image_upload{
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100%;
  vertical-align: middle;
}
.creator-recommended-slider .upload-project-big-thumbnail .preview_image_upload{
  min-height:363px;
  overflow: hidden;
  border-radius: 12px;
}
.creator-recommended-slider .upload-project-big-thumbnail{
  text-align: center; 
  width: 100%;
  height: 363px;
  max-width: 484px;
  margin: auto;
  overflow:hidden;
}
.upload-project-submit{
  width:250px;
  height:187px;
  border-radius: 8px;
  overflow:hidden; 
  margin: -55px auto;
}
/* .upload-project-submit img {
  width: auto;
  min-height: 150px;
  border-radius: 8px;
} */
.submit_project_img{
    background-size: cover;
    min-height: 176px;
    max-width: 100%;
    margin: auto;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.05);
    background-color:#fff;
}
.have-more-work {
  border-radius: 10px;
}

/*-- Progress Bars --*/
.progress-card {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rect-auto,
.c100.p51 .slice,
.c100.p52 .slice,
.c100.p53 .slice,
.c100.p54 .slice,
.c100.p55 .slice,
.c100.p56 .slice,
.c100.p57 .slice,
.c100.p58 .slice,
.c100.p59 .slice,
.c100.p60 .slice,
.c100.p61 .slice,
.c100.p62 .slice,
.c100.p63 .slice,
.c100.p64 .slice,
.c100.p65 .slice,
.c100.p66 .slice,
.c100.p67 .slice,
.c100.p68 .slice,
.c100.p69 .slice,
.c100.p70 .slice,
.c100.p71 .slice,
.c100.p72 .slice,
.c100.p73 .slice,
.c100.p74 .slice,
.c100.p75 .slice,
.c100.p76 .slice,
.c100.p77 .slice,
.c100.p78 .slice,
.c100.p79 .slice,
.c100.p80 .slice,
.c100.p81 .slice,
.c100.p82 .slice,
.c100.p83 .slice,
.c100.p84 .slice,
.c100.p85 .slice,
.c100.p86 .slice,
.c100.p87 .slice,
.c100.p88 .slice,
.c100.p89 .slice,
.c100.p90 .slice,
.c100.p91 .slice,
.c100.p92 .slice,
.c100.p93 .slice,
.c100.p94 .slice,
.c100.p95 .slice,
.c100.p96 .slice,
.c100.p97 .slice,
.c100.p98 .slice,
.c100.p99 .slice,
.c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie,
.c100 .bar,
.c100.p51 .fill,
.c100.p52 .fill,
.c100.p53 .fill,
.c100.p54 .fill,
.c100.p55 .fill,
.c100.p56 .fill,
.c100.p57 .fill,
.c100.p58 .fill,
.c100.p59 .fill,
.c100.p60 .fill,
.c100.p61 .fill,
.c100.p62 .fill,
.c100.p63 .fill,
.c100.p64 .fill,
.c100.p65 .fill,
.c100.p66 .fill,
.c100.p67 .fill,
.c100.p68 .fill,
.c100.p69 .fill,
.c100.p70 .fill,
.c100.p71 .fill,
.c100.p72 .fill,
.c100.p73 .fill,
.c100.p74 .fill,
.c100.p75 .fill,
.c100.p76 .fill,
.c100.p77 .fill,
.c100.p78 .fill,
.c100.p79 .fill,
.c100.p80 .fill,
.c100.p81 .fill,
.c100.p82 .fill,
.c100.p83 .fill,
.c100.p84 .fill,
.c100.p85 .fill,
.c100.p86 .fill,
.c100.p87 .fill,
.c100.p88 .fill,
.c100.p89 .fill,
.c100.p90 .fill,
.c100.p91 .fill,
.c100.p92 .fill,
.c100.p93 .fill,
.c100.p94 .fill,
.c100.p95 .fill,
.c100.p96 .fill,
.c100.p97 .fill,
.c100.p98 .fill,
.c100.p99 .fill,
.c100.p100 .fill {
  position: absolute;
  border: 0.09em solid #000000;
  width: 0.82em;
  height: 0.82em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill,
.c100.p51 .bar:after,
.c100.p51 .fill,
.c100.p52 .bar:after,
.c100.p52 .fill,
.c100.p53 .bar:after,
.c100.p53 .fill,
.c100.p54 .bar:after,
.c100.p54 .fill,
.c100.p55 .bar:after,
.c100.p55 .fill,
.c100.p56 .bar:after,
.c100.p56 .fill,
.c100.p57 .bar:after,
.c100.p57 .fill,
.c100.p58 .bar:after,
.c100.p58 .fill,
.c100.p59 .bar:after,
.c100.p59 .fill,
.c100.p60 .bar:after,
.c100.p60 .fill,
.c100.p61 .bar:after,
.c100.p61 .fill,
.c100.p62 .bar:after,
.c100.p62 .fill,
.c100.p63 .bar:after,
.c100.p63 .fill,
.c100.p64 .bar:after,
.c100.p64 .fill,
.c100.p65 .bar:after,
.c100.p65 .fill,
.c100.p66 .bar:after,
.c100.p66 .fill,
.c100.p67 .bar:after,
.c100.p67 .fill,
.c100.p68 .bar:after,
.c100.p68 .fill,
.c100.p69 .bar:after,
.c100.p69 .fill,
.c100.p70 .bar:after,
.c100.p70 .fill,
.c100.p71 .bar:after,
.c100.p71 .fill,
.c100.p72 .bar:after,
.c100.p72 .fill,
.c100.p73 .bar:after,
.c100.p73 .fill,
.c100.p74 .bar:after,
.c100.p74 .fill,
.c100.p75 .bar:after,
.c100.p75 .fill,
.c100.p76 .bar:after,
.c100.p76 .fill,
.c100.p77 .bar:after,
.c100.p77 .fill,
.c100.p78 .bar:after,
.c100.p78 .fill,
.c100.p79 .bar:after,
.c100.p79 .fill,
.c100.p80 .bar:after,
.c100.p80 .fill,
.c100.p81 .bar:after,
.c100.p81 .fill,
.c100.p82 .bar:after,
.c100.p82 .fill,
.c100.p83 .bar:after,
.c100.p83 .fill,
.c100.p84 .bar:after,
.c100.p84 .fill,
.c100.p85 .bar:after,
.c100.p85 .fill,
.c100.p86 .bar:after,
.c100.p86 .fill,
.c100.p87 .bar:after,
.c100.p87 .fill,
.c100.p88 .bar:after,
.c100.p88 .fill,
.c100.p89 .bar:after,
.c100.p89 .fill,
.c100.p90 .bar:after,
.c100.p90 .fill,
.c100.p91 .bar:after,
.c100.p91 .fill,
.c100.p92 .bar:after,
.c100.p92 .fill,
.c100.p93 .bar:after,
.c100.p93 .fill,
.c100.p94 .bar:after,
.c100.p94 .fill,
.c100.p95 .bar:after,
.c100.p95 .fill,
.c100.p96 .bar:after,
.c100.p96 .fill,
.c100.p97 .bar:after,
.c100.p97 .fill,
.c100.p98 .bar:after,
.c100.p98 .fill,
.c100.p99 .bar:after,
.c100.p99 .fill,
.c100.p100 .bar:after,
.c100.p100 .fill {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 50px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  background-color: rgba(255, 255, 255, 0.2);
}

.c100 *,
.c100 *:before,
.c100 *:after {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 3.5em;
  line-height: 3.5em;
  font-size: 0.3em;
  font-weight: 700;
  color: #fff;
  display: block;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.c100:after {
  position: absolute;
  top: 0.05em;
  left: 0.05em;
  display: block;
  content: ' ';
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  width: 0.9em;
  height: 0.9em;
  -moz-transition-property: all;
  -o-transition-property: all;
  -webkit-transition-property: all;
  transition-property: all;
  -moz-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p1 .bar {
  -moz-transform: rotate(3.6deg);
  -ms-transform: rotate(3.6deg);
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}

.c100.p2 .bar {
  -moz-transform: rotate(7.2deg);
  -ms-transform: rotate(7.2deg);
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}

.c100.p3 .bar {
  -moz-transform: rotate(10.8deg);
  -ms-transform: rotate(10.8deg);
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}

.c100.p4 .bar {
  -moz-transform: rotate(14.4deg);
  -ms-transform: rotate(14.4deg);
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}

.c100.p5 .bar {
  -moz-transform: rotate(18deg);
  -ms-transform: rotate(18deg);
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}

.c100.p6 .bar {
  -moz-transform: rotate(21.6deg);
  -ms-transform: rotate(21.6deg);
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}

.c100.p7 .bar {
  -moz-transform: rotate(25.2deg);
  -ms-transform: rotate(25.2deg);
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}

.c100.p8 .bar {
  -moz-transform: rotate(28.8deg);
  -ms-transform: rotate(28.8deg);
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}

.c100.p9 .bar {
  -moz-transform: rotate(32.4deg);
  -ms-transform: rotate(32.4deg);
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}

.c100.p10 .bar {
  -moz-transform: rotate(36deg);
  -ms-transform: rotate(36deg);
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}

.c100.p11 .bar {
  -moz-transform: rotate(39.6deg);
  -ms-transform: rotate(39.6deg);
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}

.c100.p12 .bar {
  -moz-transform: rotate(43.2deg);
  -ms-transform: rotate(43.2deg);
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}

.c100.p13 .bar {
  -moz-transform: rotate(46.8deg);
  -ms-transform: rotate(46.8deg);
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}

.c100.p14 .bar {
  -moz-transform: rotate(50.4deg);
  -ms-transform: rotate(50.4deg);
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}

.c100.p15 .bar {
  -moz-transform: rotate(54deg);
  -ms-transform: rotate(54deg);
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}

.c100.p16 .bar {
  -moz-transform: rotate(57.6deg);
  -ms-transform: rotate(57.6deg);
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}

.c100.p17 .bar {
  -moz-transform: rotate(61.2deg);
  -ms-transform: rotate(61.2deg);
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}

.c100.p18 .bar {
  -moz-transform: rotate(64.8deg);
  -ms-transform: rotate(64.8deg);
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}

.c100.p19 .bar {
  -moz-transform: rotate(68.4deg);
  -ms-transform: rotate(68.4deg);
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}

.c100.p20 .bar {
  -moz-transform: rotate(72deg);
  -ms-transform: rotate(72deg);
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}

.c100.p21 .bar {
  -moz-transform: rotate(75.6deg);
  -ms-transform: rotate(75.6deg);
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}

.c100.p22 .bar {
  -moz-transform: rotate(79.2deg);
  -ms-transform: rotate(79.2deg);
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}

.c100.p23 .bar {
  -moz-transform: rotate(82.8deg);
  -ms-transform: rotate(82.8deg);
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}

.c100.p24 .bar {
  -moz-transform: rotate(86.4deg);
  -ms-transform: rotate(86.4deg);
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}

.c100.p25 .bar {
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.c100.p26 .bar {
  -moz-transform: rotate(93.6deg);
  -ms-transform: rotate(93.6deg);
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}

.c100.p27 .bar {
  -moz-transform: rotate(97.2deg);
  -ms-transform: rotate(97.2deg);
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}

.c100.p28 .bar {
  -moz-transform: rotate(100.8deg);
  -ms-transform: rotate(100.8deg);
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}

.c100.p29 .bar {
  -moz-transform: rotate(104.4deg);
  -ms-transform: rotate(104.4deg);
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}

.c100.p30 .bar {
  -moz-transform: rotate(108deg);
  -ms-transform: rotate(108deg);
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}

.c100.p31 .bar {
  -moz-transform: rotate(111.6deg);
  -ms-transform: rotate(111.6deg);
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}

.c100.p32 .bar {
  -moz-transform: rotate(115.2deg);
  -ms-transform: rotate(115.2deg);
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}

.c100.p33 .bar {
  -moz-transform: rotate(118.8deg);
  -ms-transform: rotate(118.8deg);
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}

.c100.p34 .bar {
  -moz-transform: rotate(122.4deg);
  -ms-transform: rotate(122.4deg);
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}

.c100.p35 .bar {
  -moz-transform: rotate(126deg);
  -ms-transform: rotate(126deg);
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}

.c100.p36 .bar {
  -moz-transform: rotate(129.6deg);
  -ms-transform: rotate(129.6deg);
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}

.c100.p37 .bar {
  -moz-transform: rotate(133.2deg);
  -ms-transform: rotate(133.2deg);
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}

.c100.p38 .bar {
  -moz-transform: rotate(136.8deg);
  -ms-transform: rotate(136.8deg);
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}

.c100.p39 .bar {
  -moz-transform: rotate(140.4deg);
  -ms-transform: rotate(140.4deg);
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}

.c100.p40 .bar {
  -moz-transform: rotate(144deg);
  -ms-transform: rotate(144deg);
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}

.c100.p41 .bar {
  -moz-transform: rotate(147.6deg);
  -ms-transform: rotate(147.6deg);
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}

.c100.p42 .bar {
  -moz-transform: rotate(151.2deg);
  -ms-transform: rotate(151.2deg);
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}

.c100.p43 .bar {
  -moz-transform: rotate(154.8deg);
  -ms-transform: rotate(154.8deg);
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}

.c100.p44 .bar {
  -moz-transform: rotate(158.4deg);
  -ms-transform: rotate(158.4deg);
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}

.c100.p45 .bar {
  -moz-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}

.c100.p46 .bar {
  -moz-transform: rotate(165.6deg);
  -ms-transform: rotate(165.6deg);
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}

.c100.p47 .bar {
  -moz-transform: rotate(169.2deg);
  -ms-transform: rotate(169.2deg);
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}

.c100.p48 .bar {
  -moz-transform: rotate(172.8deg);
  -ms-transform: rotate(172.8deg);
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}

.c100.p49 .bar {
  -moz-transform: rotate(176.4deg);
  -ms-transform: rotate(176.4deg);
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}

.c100.p50 .bar {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100.p51 .bar {
  -moz-transform: rotate(183.6deg);
  -ms-transform: rotate(183.6deg);
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}

.c100.p52 .bar {
  -moz-transform: rotate(187.2deg);
  -ms-transform: rotate(187.2deg);
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}

.c100.p53 .bar {
  -moz-transform: rotate(190.8deg);
  -ms-transform: rotate(190.8deg);
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}

.c100.p54 .bar {
  -moz-transform: rotate(194.4deg);
  -ms-transform: rotate(194.4deg);
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}

.c100.p55 .bar {
  -moz-transform: rotate(198deg);
  -ms-transform: rotate(198deg);
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}

.c100.p56 .bar {
  -moz-transform: rotate(201.6deg);
  -ms-transform: rotate(201.6deg);
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}

.c100.p57 .bar {
  -moz-transform: rotate(205.2deg);
  -ms-transform: rotate(205.2deg);
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}

.c100.p58 .bar {
  -moz-transform: rotate(208.8deg);
  -ms-transform: rotate(208.8deg);
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}

.c100.p59 .bar {
  -moz-transform: rotate(212.4deg);
  -ms-transform: rotate(212.4deg);
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}

.c100.p60 .bar {
  -moz-transform: rotate(216deg);
  -ms-transform: rotate(216deg);
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}

.c100.p61 .bar {
  -moz-transform: rotate(219.6deg);
  -ms-transform: rotate(219.6deg);
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}

.c100.p62 .bar {
  -moz-transform: rotate(223.2deg);
  -ms-transform: rotate(223.2deg);
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}

.c100.p63 .bar {
  -moz-transform: rotate(226.8deg);
  -ms-transform: rotate(226.8deg);
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}

.c100.p64 .bar {
  -moz-transform: rotate(230.4deg);
  -ms-transform: rotate(230.4deg);
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}

.c100.p65 .bar {
  -moz-transform: rotate(234deg);
  -ms-transform: rotate(234deg);
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}

.c100.p66 .bar {
  -moz-transform: rotate(237.6deg);
  -ms-transform: rotate(237.6deg);
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}

.c100.p67 .bar {
  -moz-transform: rotate(241.2deg);
  -ms-transform: rotate(241.2deg);
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}

.c100.p68 .bar {
  -moz-transform: rotate(244.8deg);
  -ms-transform: rotate(244.8deg);
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}

.c100.p69 .bar {
  -moz-transform: rotate(248.4deg);
  -ms-transform: rotate(248.4deg);
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}

.c100.p70 .bar {
  -moz-transform: rotate(252deg);
  -ms-transform: rotate(252deg);
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}

.c100.p71 .bar {
  -moz-transform: rotate(255.6deg);
  -ms-transform: rotate(255.6deg);
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}

.c100.p72 .bar {
  -moz-transform: rotate(259.2deg);
  -ms-transform: rotate(259.2deg);
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}

.c100.p73 .bar {
  -moz-transform: rotate(262.8deg);
  -ms-transform: rotate(262.8deg);
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}

.c100.p74 .bar {
  -moz-transform: rotate(266.4deg);
  -ms-transform: rotate(266.4deg);
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}

.c100.p75 .bar {
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c100.p76 .bar {
  -moz-transform: rotate(273.6deg);
  -ms-transform: rotate(273.6deg);
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}

.c100.p77 .bar {
  -moz-transform: rotate(277.2deg);
  -ms-transform: rotate(277.2deg);
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}

.c100.p78 .bar {
  -moz-transform: rotate(280.8deg);
  -ms-transform: rotate(280.8deg);
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}

.c100.p79 .bar {
  -moz-transform: rotate(284.4deg);
  -ms-transform: rotate(284.4deg);
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}

.c100.p80 .bar {
  -moz-transform: rotate(288deg);
  -ms-transform: rotate(288deg);
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}

.c100.p81 .bar {
  -moz-transform: rotate(291.6deg);
  -ms-transform: rotate(291.6deg);
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}

.c100.p82 .bar {
  -moz-transform: rotate(295.2deg);
  -ms-transform: rotate(295.2deg);
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}

.c100.p83 .bar {
  -moz-transform: rotate(298.8deg);
  -ms-transform: rotate(298.8deg);
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}

.c100.p84 .bar {
  -moz-transform: rotate(302.4deg);
  -ms-transform: rotate(302.4deg);
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}

.c100.p85 .bar {
  -moz-transform: rotate(306deg);
  -ms-transform: rotate(306deg);
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}

.c100.p86 .bar {
  -moz-transform: rotate(309.6deg);
  -ms-transform: rotate(309.6deg);
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}

.c100.p87 .bar {
  -moz-transform: rotate(313.2deg);
  -ms-transform: rotate(313.2deg);
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}

.c100.p88 .bar {
  -moz-transform: rotate(316.8deg);
  -ms-transform: rotate(316.8deg);
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}

.c100.p89 .bar {
  -moz-transform: rotate(320.4deg);
  -ms-transform: rotate(320.4deg);
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}

.c100.p90 .bar {
  -moz-transform: rotate(324deg);
  -ms-transform: rotate(324deg);
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}

.c100.p91 .bar {
  -moz-transform: rotate(327.6deg);
  -ms-transform: rotate(327.6deg);
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}

.c100.p92 .bar {
  -moz-transform: rotate(331.2deg);
  -ms-transform: rotate(331.2deg);
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}

.c100.p93 .bar {
  -moz-transform: rotate(334.8deg);
  -ms-transform: rotate(334.8deg);
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}

.c100.p94 .bar {
  -moz-transform: rotate(338.4deg);
  -ms-transform: rotate(338.4deg);
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}

.c100.p95 .bar {
  -moz-transform: rotate(342deg);
  -ms-transform: rotate(342deg);
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}

.c100.p96 .bar {
  -moz-transform: rotate(345.6deg);
  -ms-transform: rotate(345.6deg);
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}

.c100.p97 .bar {
  -moz-transform: rotate(349.2deg);
  -ms-transform: rotate(349.2deg);
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}

.c100.p98 .bar {
  -moz-transform: rotate(352.8deg);
  -ms-transform: rotate(352.8deg);
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}

.c100.p99 .bar {
  -moz-transform: rotate(356.4deg);
  -ms-transform: rotate(356.4deg);
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}

.c100.p100 .bar {
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.c100:hover {
  cursor: default;
}

.c100.white-bar .bar,
.c100.white-bar .fill {
  border-color: #fff !important;
}

/*-- Post a project --*/
.complete-brief {
  /* height: calc(100vh - 120px); */
  height: calc(100vh - 58px);
  padding-bottom: 0px !important;
  overflow: hidden;
}
/* .post-project-height {
  height: calc(100vh - 115px);
  padding-bottom: 0px !important;
  overflow: hidden;
} */
.post-project-height {
  height: calc(100vh - 58px);
  padding-bottom: 0px !important;
  overflow: hidden;
}
.not-login {
  height: calc(100vh - 130px);
  padding-bottom: 0px !important;
  overflow: hidden;
}
.position-button {
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  z-indeX:10;
}
.post-search {
  position: absolute;
  top: 18px;
  left: 15px;
  color: #6c7a8f;
  font-size: 13px;
}

.post-search.rupees {
  top: 46px;
  left: 11px;
}

/* .post_a_project .user-upload-content-work {
  background-size: cover;
  height: 200px;
  max-width:328px;
} */
.post-project-enquire{ 
  background:#eee;
  border-radius: 12px;
  padding: 58px 15px;
  text-align: center;  
}
.post-project-enquire h1{
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom:15px;
}
.ui-slider {
  position: relative;
  height: 14px;
  border-radius: 10px;
  text-align: left;
  margin: 30px 0 10px 0;
}

.range_slider {
  right: 0;
  height: 8px;
  background-color: #eaeaea;
}

.range_slider > div > .inverse-left {
  position: absolute;
  left: 0;
  height: 8px;
  background-color: #eaeaea;
}

.range_slider > div > .inverse-right {
  position: absolute;
  right: 0;
  height: 8px;
  background-color: #eaeaea;
}

.ui-slider-range {
  position: absolute;
  left: 0;
  height: 8px;
  background-color: #00b665;
}

.ui-slider-handle {
  position: absolute;
  top: -12px;
  z-index: 2;
  height: 32px;
  width: 32px;
  text-align: left;
  margin-left: -11px;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  background-color: #00b665;
  border-radius: 50%;
  outline: none;
  color: #fff;
  text-align: center;
  padding-top: 7px;
  letter-spacing: 1px;
  font-weight: 800;
  font-size: 12px;
  content: '|||';
}

.range_slider > input[type='range'] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 8px;
  top: -2px;
  width: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  cursor: pointer;
}

.range_slider > input[type='range']::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div.range_slider > input[type='range']::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

.range_slider > input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

.range_slider > input[type='range']:focus {
  outline: none;
}

div.range_slider > input[type='range']::-ms-thumb {
  pointer-events: all;
  width: 32px;
  height: 32px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

.range_slider > input[type='range']::-moz-range-thumb {
  pointer-events: all;
  width: 32px;
  height: 32px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

.range_slider > input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  width: 32px;
  height: 32px;
  border-radius: 0px;
  border: 0 none;
  background: red;
  -webkit-appearance: none;
}

.range_slider > input[type='range']::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

.range_slider > input[type='range']::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

.range_slider > input[type='range']::-ms-tooltip {
  display: none;
}

.post-a-icon i {
  color: #0f60e2;
  border: 1.5px solid #0f60e2;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 31px;
  cursor: pointer;
}
.service_desktop_prev{
    position: absolute;
    top:50%;
    left: -20px;
    transform: translate(-50%,-50%);
    background: #e8ecf4;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    text-align:center;
    cursor: pointer;
}
.service_desktop_next{
  position: absolute;
  top:50%;
  right: -50px;
  transform: translate(-50%,-50%);
  background: #e8ecf4;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  line-height: 30px;
  text-align:center;
  cursor: pointer;
}
.service_desktop_prev i,
.service_desktop_next i{
  font-size: 15px;
}


.service-icon-next,
.service-icon-prev { 
  cursor: pointer;
  display:none;
} 
.service-icon-prev i,
.service-icon-next i {
  width: 44px;
  height: 50px;
  background: #f8fafe;
  color: #000;
  text-align: center;
  border-radius: 10px;
  line-height: 48px;
  font-size: 16px;
  transition:0.3s all;
}
.service-icon-prev i:hover,
.service-icon-prev i:focus,
.service-icon-next i:hover,
.service-icon-next i:focus {
  background-color:#e8ecf4;
}
.deadline {
  width: 100%;
  height: 200px;
  border: 1px solid #f0f3fa;
  border-radius: 10px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.deadline:after {
  color: #fff;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 100;
  background: #1464DC;
  width: 25px;
  height: 25px;
  line-height: 26px;
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  /* border: 1.5px solid #fff;
  border-top-width: 0;
  border-right-width: 0px; */
  display: none;
  cursor: pointer;
}
.deadline.fadeout{
  filter: brightness(0.2) contrast(0.1);
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}
.deadline.fadeout.active{
  filter: brightness(1) contrast(1);
  border-color:#f0f3fa;
}
.deadline.active {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}

.deadline.active:after {
  display: block;
}

.deadline-project .content {
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  padding: 5px 0px;
}
.deadline-project .deadline {
  border-color:#e4e4e5;
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  font-weight: 600;
  padding:15px;
  padding-top:67%;
  height:250px;
  text-align: center;
  background-position: center 20px;
  background-size:110px;
  flex-direction: column;
}
.webkit-line-show-3{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

/*-- Creator Recommendations --*/
.recommentation-overflow {
  height: calc(100vh - 190px);
}

.creator-recommendations .overflow-content {
  max-height: unset;
}

.creator-recommendations {
  height: calc(100vh - 120px);
  overflow: hidden;
}

.upload-thumbnail .owl-item  {
  width: 100px !important;
}
.upload-thumbnail .owl-theme {
  width: 500px;
}

.creator-recommendations .upload-thumbnail .upload-thumb img {
  /* width: auto;
  height: 64px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer; */
  width: auto;
  height: 75px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

.creator-recommendations .upload-thumbnail .upload-thumb.active {
  border: 1.5px solid #00b665;
  /* padding: 2px; */
  box-sizing: border-box;
  /* transform: scale(1.1); */
  /* margin-left: 7px;
  margin-right: 18px; */
}

.creator-recommendations .upload-thumbnail .upload-thumb {
  /* display: inline-block;
  border: 1.5px solid #d7d3d3;
  border-radius: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 90px;
  height: 67px;
  text-align: center;
  background-color: #fff;
  position: relative;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all; */
  display: inline-block;
  border: 1.5px solid #d7d3d3;
  /* padding: 2px; */
  border-radius: 12px;
  margin-right: 10px;
  width: 100px;
  height: 75px;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
}

.creator-recommendations .upload-thumbnail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.creator-recommended-slider .upload-project-big-thumbnail img {
  width: auto;
  height: 363px;
  overflow: hidden;
} 

.creator-recommendations .upload-thumbnail .upload-thumb img.active {
  padding: 2px;
  height: 72px;
}

.creator-recommended-slider h2 {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.17px;
  color: #000000;
}

.creator-recommended-slider .creator-tags {
  padding: 5px 15px;
  text-align: center;
  font-size: 13px;
  border: 1.5px solid #e3e7ef;
  display: inline-block;
  border-radius: 6px;
  font-weight: 500;
  color: #7e8a9d;
  cursor: pointer;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.creator-recommended-slider .creator-tags:last-child {
  margin-right: 0px;
}

.recommendation-bottom {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.recommendation-bottom.creator-selected-button {
  bottom: 0px;
  background: #f5f7fd;
  padding-bottom: 10px;
}

.creator-profile{
  width: 40px;
  height: 40px;
  text-align: center; 
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.creator-profile img { 
  height: 100%;
  width: 100%;
  object-fit: cover; 
}

.div-border {
  position: relative;
}

.div-border:before {
  content: '';
  height: 100%;
  width: 100%;
  border-left: 1px solid #dbdbdb;
  position: absolute;
  top: 0px;
  left: -40px;
}

.worked-with {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #858e9a;
  padding: 20px 15px 0px;
}

.worked-with .company-icon {
  margin: 5px;
  display: inline-block;
} 
.recommendation-card .card {
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ms-transition: 0.2s all;
  -o-transition: 0.2s all;
}

.recommendation-card .card.active {
  border: 2px solid #00b665;
}

/* .more-view-details {
  display: none;
} */

.more-view-details-artist {
  display: none;
}

.creator-selected-proceed {
  padding-left: 17px !important;
  padding-right: 22px !important;
}

.creator-selected-proceed button {
  box-shadow: 0px -5px 19px rgba(0, 0, 0, 0.2);
}
.creator-selected span {
  position: absolute;
  top: 0;
  right: 15px;
}

/*-- Creator Mobile Slider --*/
.creatorSliderImage .carousel-item img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  max-width: 100%;
  overflow: hidden;
  height: 180px;
}

.creatorSliderImage .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
}

.creatorSliderImage .carousel-control-next,
.creatorSliderImage .carousel-control-prev {
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  width: 35px;
  height: 35px;
  padding: 10px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  margin: 0px 12px;
}

.creatorSliderImage .carousel-control-next:hover,
.creatorSliderImage .carousel-control-prev:hover {
  background: rgba(0, 0, 0, 0.3);
}

.mobile-footer-recommendation {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -10px;
  background-color: #fff;
}

.dont-like .bor-bottom:last-child {
  border: none;
}

#changeYourBudget .email {
  height: 34px;
}

#changeYourBudget .post-search.rupees {
  top: 39.5px;
}

/*-- Creator Recommendations --*/
.chat-screen-overflow {
  height: calc(100vh - 64px);
  max-height: unset;
}

.chat-screen-height {
  height: calc(100vh - 64px);
  overflow: hidden;
}

.chatting-box-overflow {
  height: calc(100vh - 126px);
}

.chat-screen-leftbar h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 0.73;
  letter-spacing: -0.23px;
  color: #000;
}
.chat_notify{
  background: #1060e2;
  padding: 2px 6px;
  display: inline;
  border-radius: 36px;
  color: #fff;
  font-size: 12px;
}
.chat-screen-leftbar .dropdown-menu {
  top: 10px !important;
  left: 130px !important;
}

.chat-box-status {
  opacity: 0.5;
}

.chat-box-status.active {
  opacity: 1;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.06);
}

.chat-box-status .chat-username {
  position: relative;
}

.chat-box-status .chat-username:after {
  color: #00b665;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f111';
  position: absolute;
  top: 5px;
  right: -12px;
  z-index: 10;
  background: #fff;
  font-size: 9px;
  opacity: 0;
}

.chat-box-status.active .chat-username:after {
  opacity: 1;
}

.one-more-creator {
  width: 30px;
  height: 30px;
  background: #fff;
  line-height: 2.5;
  text-align: center;
  border-radius: 100%;
  color: #707d91;
  font-size: 12px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.04);
}

.chat-box-issue {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 20px 20px 8px 15px;
  z-index: 2;
}

.chat-box-issue .bg-image-creator { 
  background-size:145px;
  background-position: bottom right;
  border-radius: 12px;
  min-height: 85px;
}

.chat-box-issue .profile-help {
  padding: 22px 20px 0px;
}

.chat-box-issue .profile-help h2 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 17px;
}

.chat-box-issue .profile-help h3 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

.chat-box-section h2 {
  line-height: 1;
}
.chat-box-header-icon .dropdown.show{
  right:0px;
}
.chat-search-box.dropdown{
  text-align:center;
  padding:0 20px 0 0;
}
.chat-box-header-icon .chat-search-icon{
  max-width:50%;
}
.chat-box-header-icon .brief-icon{
  max-width:80%;
}
.chat-box-header-icon .proposal_icon{
  max-width:44%;
}
.chat-box-header-icon .chat-google-calendar{
  max-width:40%;
}
.chat-box-header-icon .chat-files-icon{
  max-width:78%;
}
.chat-box-header-icon .chat-search-box .field-name{
  font-size:12px;
  font-weight:700;
  color:#333;
}
.chat-box-header-icon span i {
  color: #9b9b9b;
  /* padding: 0px 12px; */
  font-size: 15px;
  cursor: pointer;
}

.chat-header {
  box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 100;
}

/* .chat-box-header-icon span i {
  color: #9b9b9b;
  padding: 12px;
  font-size: 15px;
} */

.chat-header {
  box-shadow: 0px 5px 12px -1px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 100;
}
.render_milestone_review .upload-files{
  min-width:60px;
  max-width:70px; 
  padding:10px;
}
.render_milestone_review .upload-files img{
  width:100%;
  height:auto;
  margin:auto;
  max-width:40%;
}
.render_milestone_review .upload-files:hover .remove-file{
  opacity:0;
}
.upload-files {
  min-width: 245px;
  max-width: 250px;
  position: relative;
  cursor: pointer;
}
.upload-files h2{
  word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    max-width: 170px;
    background: #F5F7FD;
}
.preview-fileupload {
  /* position: absolute; */
  position:static;
  width: 100%;
  bottom: 110px;
  /* background: #fff; */
  left: 0;
  right: 0;
  /* padding: 20px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09); */
  display: none;
}
.preview-fileupload .inner-preview-fileupload{
  background: #f8fafe;
  border-radius: 0px 0px 12px 12px;
  padding: 12px 12px 20px;
  box-sizing: border-box;
  border: 1.5px solid #e8ecf4;
  /* border-bottom: transparent; */
  border-top:transparent;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
} 
.preview-fileupload .inner-preview-fileupload::-webkit-scrollbar {
  width: 8px;
} 
.preview-fileupload .inner-preview-fileupload::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
.preview-fileupload .inner-preview-fileupload::-webkit-scrollbar-thumb {
  background: rgb(187, 187, 187); 
} 
.fileupload-download {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 3px 6px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  color: #fff;
  opacity: 0;
  transition: 0.2s all;
}
.upload-files:hover .fileupload-download {
  opacity: 1;
}
.remove-file {
  position: absolute;
  right: -5px;
  top: -5px;
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  line-height: 16px;
  opacity: 0;
  cursor: pointer;
  transition: 0.2s all;
}
.remove-file i {
  font-size: 10px;
}
.upload-files:hover .remove-file {
  opacity: 1;
}
.videoPlayer{
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: 450px;
  width: 100%;
}
.videoPlayer .reactplayer{
  width: 100% !important;
  height: 340px !important;
  margin-top:-5px;
}
.chat-header .custom-padding {
  padding: 10px 25px 12px;
}

.input-chat {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  padding: 20px;
  background: #fff;
}

.input-chat .input-chat-field {
  position: relative;
  font-family: 'FontAwesome';
  font-family: 'Muli', sans-serif;
  border-top-right-radius:0 !important;
  border-bottom-right-radius:0 !important;
  border-right:0px !important;
}

.input-chat .input-chat-field:before {
  color: #00b665;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f111';
  position: absolute;
  top: 5px;
  right: 0px;
  z-index: 1003;
  background: #fff;
  font-size: 9px;
}

.input-chat .input-chat-link {
  position: absolute;
  z-index: 10;
  right: 75px;
  top: 40px;
  color: #848484;
}

.input-chat .input-chat-location {
  position: absolute;
  z-index: 12;
  right: 42px;
  top: 40px;
  color: #848484;
}
.feedback_input{
  background:#fff;
  cursor: pointer;
  border:1.5px solid #e8ecf4;
  border-left:0 !important;
  border-top-left-radius:0;
  border-bottom-left-radius:0;
  padding-left:0px !important;
}
.feedback_input.userchat_icon{
  padding-left:15px !important;
  color:#666;
}
.chat-start-day-status {
  position: absolute;
  top: -8px;
  width: 50px;
}

.project-date-star:after {
  color: #848484;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f621';
  position: absolute;
  top: 0px;
  right: -10px;
  z-index: 1003;
  background: #fff;
  font-size: 8px;
}

.project-delivery-date:before {
  content: '';
  position: absolute;
  left: -30px;
  top: 0;
  width: 100%;
  height: 100%;
  border-left: 1.5px solid #eee;
}

.table-wrap .table td,
.table th {
  border-top: none;
  padding: 1rem;
}

.table-wrap .table thead th {
  border-top: none;
  border-bottom: 1.5px solid #dee2e6;
  color: #6f7d91;
  font-weight: 600;
}

.table-wrap .table tbody tr {
  border-bottom: 1.5px solid #dee2e6;
  color: #6f7d91;
  vertical-align: middle;
}

.table-wrap .table tbody tr:last-child {
  border-bottom: none;
} 
.view-proposal-footer {
  padding: 20px 30px;
}

.non-refundable {
  border: 1.5px solid #dee2e6;
}

.main-btn.non-btn {
  padding: 2px 5px;
  border: 1px solid #6f7d91 !important;
}

.custom_table .row-border {
  border-bottom: 1px solid #e6e4e4;
} 
.custom_table .row-border:last-child {
  border-bottom: none;
}

.chat-dropdown .dropdown-menu-arrow.nav-arrow {
  top: -10px;
  left: 95px;
  width: 0;
  height: 0;
  position: relative;
}

.chat-dropdown .dropdown-menu-arrow:before {
  bottom: -10px;
  right: -93px;
  border-bottom-color: rgba(0, 0, 0, 0.06) !important;
}

/*=== Google Calander CSS ===*/
.google-calendar-dropdown .dropdown-menu-arrow:before { 
  border-bottom-color: rgba(0, 0, 0, 0.08) !important;
} 
.chat-search-box.google-calling-box .dropdown-menu{
  min-width: 30em;
  max-width: 30em;
  height:auto;
}
.chat-dropdown .chat-search-box .dropdown-menu.google-calendar-dropdown{
  left:-245% !important;
  border-radius:10px;
  -webkit-border-radius:10px;
  -moz-border-radius:10px;
  -ms-border-radius:10px;
  -o-border-radius:10px;
}
.google-calling-box .google-custom-calendar .form-control.email{
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.google-calling-box .google-input-time{
  background: #f8fafe;
  border: 1px solid #e8ecf4;
  box-sizing: border-box;
  border-radius: 6px;
  padding:0px;
  height:50px;
  display:flex;
  align-items: center; 
  justify-content:space-between;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
} 
.google-calling-box .google-input-time .google-time-1,
.google-calling-box .google-input-time .google-time-2{
  width:45%;
}
.google-calling-box .google-input-time .google-time-1 .select__control,
.google-calling-box .google-input-time .google-time-2 .select__control{
  border:none;
  background:transparent;
} 
.google-calling-box .basic-single{
  border:none !important;
}
.google-calling-box .basic-single .select__single-value{
  font-weight:600 !important;
  font-size:13px;
}
.google-calling-box .select__placeholder{
  font-weight:600 !important;
  color:#000 !important;
  font-size:13px;
}
.google-calling-box .select__value-container{
  padding:2px 8px !important;
}
.google-calling-box .select__indicator{
  padding:8px 4px 8px 0 !important;
}
.google-calling-box .google-meeting-members .google-confirm-icons .google-checked,
.google-calling-box .google-meeting-members .google-confirm-icons .google-cancel{
  position: absolute;
  bottom: 0;
  left: 16%;
  max-width:7%;
}
.google-calling-box .google-meeting-members .google-confirm-icons .google-user-profile{
  width:35px;
  height:35px;
  overflow: hidden;
}
.google-calling-box .google-meeting-members .google-confirm-icons .google-user-profile img{
  min-height: 35px;
  border-radius: 100%;
}
.google-calling-box .google-calendar-footer{
  border-radius: 10px;
  background: #fff;
  box-shadow: 4px 0px 7px rgba(0,0,0,15%);
  padding:15px 25px; 
}
.google-calling-box .google-calendar-footer .main-btn{
  padding:10px 15px;
}
.google-calling-box .google-calendar-footer .google-meeting-link{
  padding-top:10px;
  font-size:13px;
  font-weight:500;
  color:#3454d1;
}
.chat-dropdown .chat-search-box .google-calendar-dropdown .dropdown-menu-arrow:before, 
.chat-dropdown .chat-search-box .google-calendar-dropdown .dropdown-menu-arrow:after{
  right: -270px !important;
}

.chat-dropdown .chat-search-box .search_bar_dropdown .dropdown-menu-arrow:before, 
.chat-dropdown .chat-search-box .search_bar_dropdown .dropdown-menu-arrow:after{
  right: -130px !important;
}

.chat-dropdown .dropdown-menu-arrow:after {
  bottom: -11px;
  right: -93px;
  border-bottom-color: #fff !important;
}

.chat-dropdown .dropdown-menu-arrow:before,
.chat-dropdown .dropdown-menu-arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
}

.chat-dropdown .dropdown-menu {
  top: 12px !important;  
  padding: 0px !important;
  border: 1px solid #eee !important;
  min-width:180px;
} 
.chat-dropdown .dropdown-menu.outer-nav-wrap{
  transform: translate3d(-165px, 28px, 0px) !important;
}
.chat-dropdown .dropdown-menu.outer-nav-wrap.three_dots{
  transform: translate3d(-178px, 28px, 0px) !important;
}
.chat-dropdown .dropdown-item {
  padding: 10px 25px !important;
  font-weight: 500 !important;
  color: #000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #eee !important;
}

.chat-dropdown .dropdown-item:focus,
.chat-dropdown .dropdown-item:hover {
  color: #007bff !important;
}

/*-- Timeline Due Date --*/
.timeline-due-date .timeline {
  position: relative;
}

.timeline-due-date .timeline__group {
  position: relative;
}

.timeline-due-date .timeline__group:not(:first-of-type) {
  margin-top: 4rem;
}

.timeline-due-date .due-date {
  font-size: 6px;
  padding: 5px 10px;
  background: #bcb9b9;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: block;
  margin-top: -5px;
  letter-spacing: 0.4px;
}

.timeline-due-date .due-date.active {
  background: #05844b;
}

.timeline-due-date .timeline__year {
  padding: 0.5rem 1.5rem;
  color: #00b665;
  background-color: #00b665;
  position: absolute;
  left: 0;
  top: 0;
}

.timeline-due-date .timeline__box {
  position: relative;
}

.timeline-due-date .vertical-line {
  position: absolute;
  left: 25px;
  bottom: -30px;
  z-index: 1;
  border-left: 2px solid #ddd;
  display: block;
  width: 5px;
  height: 30%;
  border-radius: 5px;
}

.timeline-due-date .vertical-line.advance-payment {
  bottom: -24px;
  height: 40%;
}

.timeline-due-date .timeline__date {
  min-width: 50px;
  position: absolute;
  left: 0;
  box-sizing: border-box;
  text-align: center;
  background-color: #cdcdcd;
  color: #fff;
  border-radius: 6px;
  padding: 5px 0px;
}

.timeline-due-date .timeline__date.active {
  background-color: #00b665;
}

.timeline-due-date .timeline__day {
  padding-top: 6px;
  font-size: 18px;
  font-weight: 700;
  display: block;
  line-height: 18px;
  letter-spacing: 1px;
}

.timeline-due-date .timeline__month {
  padding-bottom: 2px;
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}

.timeline-due-date .timeline__content {
  color: #707d91;
}

.timeline-due-date .timeline__content.active {
  color: #000;
}
.milestone-deliverable.deliverable_images{
  overflow: hidden;
  width:210px !important;
  height: calc(210 / 1.3333);
  width: 100%;
  display: block;
  margin: auto;
  border-radius: 10px;
}
.milestone-deliverable.deliverable_images .send_deliverable{
  height:166px;
  vertical-align:center;
  background-size: cover;
  background-position:center;
  background-repeat:no-repeat;
}
.milestone-extra-images{
  text-align: left; 
  padding:10px 15px 0px;
  border-radius: 8px;
  font-weight: 700; 
  font-size:13px;
  color: rgb(52, 84, 209);
  cursor: pointer;
}
.milestone-hover {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.milestone-hover .download-image,
.milestone-hover .message-chat {
  padding: 6px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
}

.milestone-hover .download-image:hover,
.milestone-hover .message-chat:hover {
  background: rgba(0, 0, 0, 0.5);
}

.milestone-deliverable:hover .milestone-hover {
  opacity: 1;
}

.milestone-headline {
  position: relative;
}

.milestone-headline:after {
  color: #fff;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  position: absolute;
  top: -2px;
  right: -22px;
  z-index: 120;
  background: #00b665;
  width: 17px;
  height: 17px;
  line-height: 15px;
  border-radius: 100%;
  font-size: 8px;
  text-align: center;
  border: 1.5px solid #fff;
  display: none;
  cursor: pointer;
}

.milestone-headline.active:after {
  display: block;
}
.reviewMilestone_rupee{
  position: absolute;
  top:14px;
  left:15px;
  font-size: 13px;
}
.download_invoice {
  border-radius: 4px;
  padding: 4px 6px;
  color: #333;
  border: 1.5px solid #00b665;
  position: relative;
  font-size: 12px;
}

.download_invoice:after {
  color: #00b665;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f058';
  position: absolute;
  top: -7px;
  right: -7px;
  z-index: 0;
  background: #fff;
}
.submit-milestone-iframe{
  width:600px;
  height:calc(600px / 1.333);
  border:none;
}
/*-- Info Tooltip --*/
/* .tooltip_text {
  max-width:50%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  background: #000;
  border-radius: 4px;
  color: #fff;
  padding: 5px 8px;
  font-size: 12px;
  opacity: 0;
  transition: 0.2s all;
  transform: translate(46%, -100%);
}
.tooltip_text.average_time {
  left: -25%;
}
.tooltip_text:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #000;
  position: absolute;
  bottom: -6px;
  z-index: 150;
  left: 50%;
  transform: translateX(-50%);
}
.info_icon:hover .tooltip_text {
  opacity: 1;
  transform: translate(46%, -130%);
  transition: 0.2s all;
} */

/*-- Tooltip css --*/
.tool{
  cursor:pointer;
}
.tool::before,
.tool::after {
    /* left: 50%; */
    opacity: 0;
    position: absolute;
    z-index: -100;
} 
.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100; 
}
.tool::before {
  border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: #333333 transparent transparent transparent;
    bottom: -7px!important;
    content: "";
    transform: rotate(0deg) translateY(-1.5rem) translateX(1.2rem) !important;
    transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.65s 0.5s;
} 
.tool:hover::before,
.tool:focus::before { 
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
}
.tool::after {
  text-align: left;
  font-size: 0.8rem;
  background: #333333;
  line-height: 1.2rem;
  color: #EDEFF0;
  content: attr(data-tip);
  border-radius: 0.8rem;
  padding: 1em;
  transform: rotate(0deg) translateY(3.4rem) translateX(7.9rem) !important;
  width: 35em;
  min-width: 5.625rem;
  bottom: 71px!important;
  left: -40px;
  margin-left: -12.75em;
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
  transform:  scale(.6) translateY(50%);  
}
.tool.keywords::after{
  bottom:120%;
}
.profile_selection_tool .tool::after{
  bottom:120%;
}
.creator-cost .tool::after{
  margin-left: -6.75em;
}
.tool:hover::after,
.tool:focus::after  {
  transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
} 
.earning_info .tool::after {
  bottom:114%;
}
.info_voice .tool::before{
  margin-left: 4em;
}
.info_voice .tool::after{
  top:-50px;
  bottom:unset;
  margin-left: -13.75em;
}
.tool.pre-line::after{
  white-space: pre-line;
}
.tool.collateral-tool::before{
  bottom:45%; 
  margin-left: 1.2em;
  border-width: 1em 0.75em 0.75em 0.75em;
  transform: rotate(90deg);
}
.tool.collateral-tool::after{
  bottom:-40%;
  margin-left: 1.75em;
}

/*===== Second Tooltip Css ===*/
.info [tooltip] {
  position: relative;
}

.info [tooltip]::before,
.info [tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}

.info [tooltip]::before {
  content: '';
  border: 5px solid transparent;
  z-index: 1001;
}

.info [tooltip]::after {
  content: attr(tooltip);
  font-family: Helvetica, sans-serif;
  text-align: center;
  white-space: normal;
  overflow: hidden;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000;
}

.info [tooltip]:hover::before,
.info [tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
.info [tooltip='']::before,
.info [tooltip='']::after {
  display: none !important;
}

/* FLOW: UP */
.info [tooltip]:not([flow])::before,
.info [tooltip][flow^='up']::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}

.info [tooltip]:not([flow])::after,
.info [tooltip][flow^='up']::after {
  bottom: calc(100% + 5px);
}

.info [tooltip]:not([flow])::before,
.info [tooltip]:not([flow])::after,
.info [tooltip][flow^='up']::before,
.info [tooltip][flow^='up']::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
.info [tooltip]:not([flow]):hover::before,
.info [tooltip]:not([flow]):hover::after,
.info [tooltip][flow^='up']:hover::before,
.info [tooltip][flow^='up']:hover::after,
.info [tooltip][flow^='down']:hover::before,
.info [tooltip][flow^='down']:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

.info [tooltip][flow^='left']:hover::before,
.info [tooltip][flow^='left']:hover::after,
.info [tooltip][flow^='right']:hover::before,
.info [tooltip][flow^='right']:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

/*-- View Milestones --*/
.chat-modal-section {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.chat-modal-section .chatting-box-overflow {
  /* height: auto; */
  /* height: calc(100vh - 100px); */
  max-height: calc(100vh - 200px); 
}

.chat-modal-section .chat-header {
  box-shadow: none !important;
  border-bottom: 1.5px solid #ddd;
}

.chat-modal-section .chat-header .row {
  padding: 15px 20px;
}

.chat-modal-section .input-chat {
  background-color: #f5f7fd;
  bottom: 0px;
  padding: 5px 20px;
  border-bottom-right-radius: 12px;
}

.chat-modal-section .input-chat .input-chat-location {
  top: 25px;
} 
.chat-modal-section .version-profile{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50px;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 4px;
} 

.milestone-image-details {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  bottom: -50px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px 5px 10px 10px;
  padding: 20px;
  opacity: 0;
  transition: 0.2s ease-in-out;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -ms-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
}

.milestone-received i {
  color: #fff;
  font-size: 12px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  margin: 0px 2px;
  cursor: pointer;
}

.view-milestone-images .user-work-section:hover .milestone-image-details {
  bottom: 0px;
  opacity: 1;
}

.chatting-box-overflow #creatorSliderImage .version_image { 
  height: 98px;
  width:100%; 
  margin: auto;
  overflow: hidden; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 98px;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 8px;
} 
.chatting-box-overflow #creatorSliderImage .owl-carousel .owl-nav{
  margin:0;
}
.chatting-box-overflow #creatorSliderImage .owl-carousel .owl-nav button.owl-next, 
.chatting-box-overflow #creatorSliderImage .owl-carousel .owl-nav button.owl-prev{
  font-size:40px;
  display:none; 
} 
.chatting-box-overflow #creatorSliderImage .owl-theme .owl-dots .owl-dot span{
  width:8px;
  height:8px; 
}
.chatting-box-overflow #creatorSliderImage  .owl-theme .owl-dots .owl-dot.active span, 
.chatting-box-overflow #creatorSliderImage  .owl-theme .owl-dots .owl-dot:hover span{
  background-color:#00b665;
}
.chatting-box-overflow #creatorSliderImage .owl-prev{
  left:-22px !important;
}
.chatting-box-overflow #creatorSliderImage .owl-next{
  right:-25px !important;
} 
.chatting-box-overflow #creatorSliderImage .carousel-indicators {
  bottom: -8px !important;
}

.chatting-box-overflow #creatorSliderImage .carousel-indicators li {
  background-color: #c1c1c1 !important;
}

.chatting-box-overflow #creatorSliderImage .carousel-indicators .active {
  background-color: #000 !important;
}

.chatting-box-overflow .carousel-control-next-icon,
.chatting-box-overflow .carousel-control-prev-icon {
  background-image: none;
}

.chatting-box-overflow .carousel-control-prev-icon:before {
  color: #c1bebe;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f053';
  position: absolute;
  top: 0px;
  left: -10px;
  z-index: 1003;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.chatting-box-overflow .carousel-control-next-icon:after {
  color: #c1bebe;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f054';
  position: absolute;
  top: 0px;
  right: -7px;
  z-index: 1003;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.chatting-box-overflow .carousel-control-prev-icon:hover:before,
.chatting-box-overflow .carousel-control-next-icon:hover:after {
  color: #848484;
}

.chatting-box-overflow #creatorSliderImage .carousel-control-next:hover,
#creatorSliderImage .carousel-control-prev:hover {
  background-color: transparent;
}
 
.iteration_div .input-text-left {
  background: #f8fafe;
  border: 1.5px solid #e1edf1;
  border-right: none;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}
.iteration_div .input-text-right {
  background: #f8fafe;
  border: 1.5px solid #e1edf1;
  border-left: none;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  cursor: pointer;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}
.iteration_div .input-group-append {
  margin-left: -4px;
}
.iteration_div .input-group-prepend {
  margin-left: 0px;
  margin-right: -4px;
}
.iteration_div .input-group{
  width:85%;
}
.iteration_div .input-group input{
  height:40px;
}
.iteration_div .input-group.active{
  opacity:0.5;
}
/*-- Feedback Modal --*/
.feedback-profile img {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
}

.feedback-creator .rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feedback-creator .rating span {
  margin: 0px 1px;
}

.feedback-creator .feedback-better:before {
  content: '';
  position: absolute;
  top: 35px;
  left: 0;
  width: 22%;
  height: 2px;
  background-color: #6c7a8f;
}

.feedback-creator .feedback-better:after {
  content: '';
  position: absolute;
  top: 35px;
  right: 0;
  width: 22%;
  height: 2px;
  background-color: #6c7a8f;
}

.feedback-creator .custom_checkbox label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.feedback-creator .custom_checkbox label:before {
  margin-right: 0px;
  width: 10px;
  height: 10px;
}

.feedback-creator .custom_checkbox input:checked + label:after {
  top: 3px;
  left: unset;
  right: 7px;
}

.chat-search-overlay {
  width: 100%;
  height: calc(100vh - 126px);
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 85;
}

.chat-search-box .dropdown-menu,
.mobile-show .chat-search-box .dropdown-menu  {
  min-width: 21.32em;
  max-width: 21.32em;
}

.mobile-show .chat-search-box .dropdown-menu {
  top: -230px;
  left: -35px;
}

.chat-search-box .dropdown-menu {
  height: calc(100vh - 125px);
}

.chat-dropdown .chat-search-box .dropdown-menu {
  top: 22px !important;
  left: -66% !important;
  border-radius: 0px;
} 
.chat-dropdown .chat-search-box .dropdown-menu.search_bar_dropdown{
  left:-66% !important;
}

.chat-dropdown .chat-search-box .dropdown-menu-arrow:before,
.chat-dropdown .chat-search-box .dropdown-menu-arrow:after {
  right: -22px !important;
}

.chat-dropdown .chat-search-box .dropdown-menu-arrow:before,
.chat-dropdown .chat-search-box .dropdown-menu-arrow:after {
  right: -22px !important;
}

.chat-dropdown .chat-search-box .dropdown-menu-arrow:before,
.chat-dropdown .chat-search-box .dropdown-menu-arrow:after {
  border-width: 8px 9px;
}

.chat-dropdown .chat-search-box .dropdown-menu-arrow.nav-arrow {
  left: 104px;
}
.chat-dropdown .chat-search-box .dropdown-menu-arrow.nav-arrow.search-arrow {
  left: 214px;
}

.chat-dropdown .chat-search-box .dropdown-item {
  white-space: inherit;
}

.chat-dropdown .chat-search-box .dropdown-item {
  padding: 10px 18px !important;
}

.chat-search-box .clear-searchbar {
  position: absolute;
  top: 16px;
  right: 18px;
  color: #8e8686;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
}

.chat-dropdown .chat-search-box.message-box .dropdown-menu {
  left: -100px !important;
}

.chat-dropdown .chat-search-box.message-box .dropdown-menu-arrow:before,
.chat-dropdown .chat-search-box.message-box .dropdown-menu-arrow:after {
  right: -135px !important;
}
.message-folder .tabs-folder {
  padding: 8px 5px;
} 
.chat-dropdown .chat-search-box.message-box .dropdown-menu-arrow:before,
.chat-dropdown .chat-search-box.message-box .dropdown-menu-arrow:after {
  right: -182px !important;
}

.message-folder .tabs-folder {
  padding: 8px 5px;
}

.message-folder .tabs-folder {
  text-decoration: none;
  padding: 7px 15px;
  color: #000;
  border: none;
  font-size: 13px;
  display: block;
  text-align: center;
  width: 100%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  cursor: pointer;
  letter-spacing: normal;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: background-color 0.1s all;
}

.message-folder .tabs-folder.active {
  background-color: #1060e2;
  color: #fff;
}

.file-type-folder .select {
  margin-top: -5px;
  height: auto;
  color: #000;
  font-size: 14px;
}

.file-type-folder .select-styled {
  background-color: transparent;
  border: none;
  padding: 0px 12px;
  color: #000;
  font-size: 12px;
  left: -15px;
}

.file-type-folder .select-options {
  top: 24px;
  left: -45px;
  min-width: 10em;
  max-width: 30em;
}

.file-type-folder .select-arrow {
  font-size: 12px;
  color: #000;
  top: -8px;
  right: 10px;
  padding: 12px 10px;
}

.file-folder-images .content {
  padding: 0px 10px;
  list-style: none;
  /* display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  grid-gap: 10px; */
}
.file-folder-images .content a{
  margin-bottom:15px;
}
.file-folder-images .onboarding_style_questions .content li{
  width:135px;
  max-width: 135px;
  height:calc(135px / 1.333);
  margin-right:15px;
}
.file-folder-images  .onboarding_style_questions .content .deadline{
  height:98px;
} 

.links-file img {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: auto;
  height: 70px;
  margin: auto;
  overflow: hidden;
}

.links-wrapper {
  transition: 0.3s all;
}

.links-wrapper:hover {
  background-color: #f4f6fc;
  cursor: pointer;
}

.submit-proposal { 
  margin-right:10px;
  cursor: pointer;
  transition: 0.1s all;
}

.submit-proposal:hover {
  background-color: #eff1f8;
}

.creator-cost .project-delivery-date:before {
  left: 0;
}

/*-- creator-proposal modal --*/
.creator-proposal .custom_form .email {
  height: 40px;
  font-weight: 700;
}

.add_milestone {
  padding-left: 100px;
}

.add_milestone .add-collateral-underline {
  width: 86%;
  border-width: 1px;
}

.creator-proposal .remove-icon {
  opacity: 0;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: absolute;
  top:8px;
  right:-25px;

}

.creator-proposal:hover .remove-icon {
  opacity: 1;
}

.pick-a-date {
  position: absolute;
  right: 10px;
  top: 10px;
}

.pick-a-date.set-quote {
  top: 44px;
  right: 15px;
}

.tabs-icon.milestone-icon::after {
  height: 84px;
}

.breakdown-number {
  width: 20px;
  height: 20px;
  background: #6c7a8f;
  color: #fff;
  border-radius: 100%;
  display: block;
  font-size: 10px;
  line-height: 20px;
}

/*-- Notification dropdown --*/
.profile-inner.notification-dropdown .dropdown-menu.outer-nav-wrap {
  min-width: 22rem;
  max-width: 22rem;
  left: -250px;
  max-height: 400px;
  overflow: hidden;
}

.profile-inner.notification-dropdown .dropdown-menu-arrow:before,
.profile-inner.notification-dropdown .dropdown-menu-arrow:after {
  right: -185px;
}

.notification-dropdown-height {
  height: calc(100vh - 450px);
}

.notification-dropdown .view-all {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/*== Rejecting Modal css ==== */
.reject-profile .custom_checkbox label {
  padding-left: 30px;
}

.reject-profile .custom_checkbox label:before {
  position: absolute;
  left: 0;
}

.reject-profile .custom_checkbox input:checked + label:after {
  top: 4px;
}

/*-- Request a call ---*/
.request-a-call .active {
  background-color: #1060e2;
  color: #fff;
  border-color: #1060e2;
}

.request-a-call span:nth-child(1) {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.request-a-call span:nth-child(4) {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.reject-profile .custom_checkbox label {
  padding-left: 30px;
}
.reject-profile .custom_checkbox label:before {
  position: absolute;
  left: 0;
}
.reject-profile .custom_checkbox input:checked + label:after {
  top: 4px;
}

/*-- Full screen view ---*/
.fullscreen_modal .modal-lg, .modal-xl{
  max-width:100%;
  width:100%;
  margin:0px auto;
 }
.fullscreen_modal .modal-content{
   border-radius:0px;
   height:100vh; 
   overflow: hidden;
 }
 .fullscrreen_wrapper{
   padding:8px 15px;
 }
.fullscreen_modal .row{
  border-bottom: 2px solid #f2f2f2;
  align-items: center;
 }
.fullscreen_modal .user-work-thumbnail{
   padding:15px 0 10px; 
   text-align: center;
   margin:0px 65px 0px 0px;
   -webkit-box-sizing: border-box; 
   box-sizing: border-box;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  display: inline-block; 
 }
.custom_form.fullscreen_modal .close_icon{
  width: 42px;
  height: 42px;
  background: #f3f3f4;
  border-radius: 8px;
  color: #0d0c22;
  text-align: center;
  top:38px;
  right:28px;
 }
 .custom_form.fullscreen_modal .close_icon img{
  margin-top: -10px;
  filter: brightness(0);
  width: 28px;
 }
 .fullscreen_modal .user-work-thumbnail .thumb-img.active{
   border:2px solid #10c89b; 
 }
 .fullscreen_modal .user-work-thumbnail img.active{
  height:71px;
 }
 .full_screen_view{
  padding: 20px 40px;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  -webkit-overflow-scrolling: touch; 
 }
 .full_screen_view img{
   border-radius:12px;
 }
 .fullscreen_header{
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center; 
  flex-grow: 1;
 }
 .fullscreen_thumb{
  display: flex;
  align-items: center; 
  justify-content: flex-end;
  -ms-flex-positive: 1;
  flex-grow: 1;
 }
 .full-w-100{
   width:100%;
 }

 /*====== Calling modal =====*/
 .calling_modal .modal-dialog{
   width:300px; 
 }
 .calling-image{
   display:flex;
   align-items: center;
   justify-content:center;
   margin:15px 0;
 }
 .calling_buttons{
   display:flex;
   align-items:center;
   justify-content:space-between;
   width:45%;
   margin:auto;
 }
 .calling_buttons.active{
   width:20%;
 }
 .calling_buttons span i{
   width:45px;
   height:45px;
   text-align: center;
   line-height: 45px;
   background:#00b665;
   color:#fff;
   border-radius:100%; 
   transform: rotate(80deg);
   cursor: pointer;
 }
 .calling_buttons span:last-child i{
   background:#bf0606;
   transform: rotate(225deg);
 }
 .calling_buttons span:first-child.active{
    display: none;
 }

 /*======= Kick off milestone project ====*/
 .kick_off_project .modal-dialog{
  width:500px; 
}
.kick_off_project .kick_off_rotate .profile-user-image{
   margin:-50px 8px 25px;
   box-sizing: border-box;
   border:2px solid #fff;
}
.kick_off_project .kick_off_rotate .profile-user-image:first-child{
   transform: rotate(-15deg);
}
.kick_off_project .kick_off_rotate .profile-user-image:last-child{
  transform: rotate(15deg);
}
.kick_off_star{
  background:url("./../images/star-bg.png") repeat;
  background-position: center;
  background-size:cover;
  min-height:150px;
  border-radius:12px;
}
.kick_off_project .cash_icon .profile-user-image{
  background:#e7ebf4;
  margin:auto;
}
.kick_off_project .cash_icon .profile-user-image img{
  max-width:62px;
}
.kick_off_project .cash_amount{
  position:relative;
}
.kick_off_project .cash_amount::after{
  color: #fff;
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f00c';
  position: absolute;
  top: 12px;
  right: -30px;
  z-index: 120;
  background: #00b665;
  width: 22px;
  height: 22px;
  line-height: 20px;
  border-radius: 100%;
  font-size: 10px;
  text-align: center;
  border: 1.5px solid #fff;
  display: block;
  cursor: pointer;
}
.milestone_project span.due-on-date{
  background:#ebeef8;
  color:#000;
  font-weight:800;
  font-size:12px;
}
.milestone_complete img{
  max-width:60%;
}

/*===== project complete ======*/ 
.project_completed{
  width: 250px;
  height: 187px;
  border-radius: 8px;
  overflow: hidden;
  margin: -115px auto;
}
.project_completed_img{
  background-size: cover;
  min-height: 176px;
  max-width: 100%;
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 2px 10px rgba(0,0,0,0.05);
  background-color: #fff;
}

/*===== Set Thumbnail =====*/ 
.ribbon {
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #10c89b;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #10c89b;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;  
  text-transform: uppercase;
  text-align: center;
} 
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -20px;
  top: 25px;
  transform: rotate(-45deg);
}
.ribbon-top-left span i{
  position:relative;
  left:58px;
  top:14px;
  transform:rotate(45deg);
  font-size:14px;
  color:#fff;
}
.advance_info{
  background: #3454d1;
  width: 25px;
  height: 25px;
  color: #fff;
  text-align: center;
  line-height: 25px;
  border-radius: 100%;
  font-size: 10px;
}
.collateral_underReview i{
  width:30px;
  height:30px;
  background:#fff;
  line-height:30px;
  text-align:center;
  border-radius:100%;
  font-size:13px;
  position: absolute;
  z-index: auto;
  right: 0;
  top: 50%;
  transform: translate(-10px, -50%);
}
.collateral_message{
  padding: 10px 20px;
  background: #fff;
  border-radius: 36px;
  position: absolute;
  color: #000;
  top: -38px;
  right: 5px;
  font-weight: 700;
  opacity: 0; 
  box-shadow:0px 10px 20px rgba(0,0,0,0.2);
  -webkit-transition:0.3s all;
  -moz-transition:0.3s all;
  -o-transition:0.3s all;
  -ms-transition:0.3s all;
  transition:0.3s all;
}
.collateral_message::before{
  content:''; 
  width: 0; 
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  right: 15px;
  bottom: -5px;
}
.collateral_underReview:hover .collateral_message{
  opacity: 1; 
}

.milestone_noted li{
  padding-left:25px;
  position:relative;
} 
.milestone_noted li::before{
  content:'-';
  width:20px;
  height:20px;
  color:#000;
  font-size:15px;
  position:absolute;
  top:0px;
  left:5px;
} 
/*==== Custom Tooltip Profile Selection ======*/
.tooltip_custom{
  position:relative;
}
.tooltip_custom::after{
  content:attr(data-point);
  color:#f3f2f2;
  font-size:20px;
  position:absolute; 
  bottom:140%;
  left:50%; 
  border-radius:8px;
  min-width:90px;
  background:#000;
  padding:10px;
  text-align: center;
  display:none; 
  font-size:12px;
  font-weight:500;
  text-transform: capitalize;
  width: 15em;
  word-break: break-word;
  opacity:0;  
  z-index:999;
}
.tooltip_custom .down_icon{
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; 
  border-top: 5px solid #000;
  position: absolute;
  top:0px;
  left:50%;
  transform:translate(-50%, 0);
  opacity:0
}
.tooltip_custom:hover::after,
.tooltip_custom:hover .down_icon{
  opacity:1;
  transform: translate(-50%, 0);
  bottom:100%;
  display: inline-block;
}

/* empty state css */
.chat-empty-state{
  align-items: center;
  height:calc(100vh - 60px);
}
.creatorEmptyState{
  text-align: center;
  padding:4em;
}
.creatorEmptyState img{
  max-width:30%;
  margin:auto;
}
.profile_selection_prev,
.profile_selection_next{
  position:absolute;
  top:30%; 
  transform:translate(-50%,0%);
}
.profile_selection_prev{
  left:0;
}
.profile_selection_next{
  right:0;
}

/*=== Select Dropdown CSS ===*/ 
.padding-zero .select__value-container{
  padding:0px !important; 
}
.select__value-container{
  padding:6px 13px !important; 
}
.basic-multi, .basic-single{
  border: 1.5px solid #e8ecf4 !important;
  border-radius: 6px !important;
}
.creator_profile_time_price .basic-multi,
.creator_profile_time_price .basic-single {
  height:48px; 
  background: #f8fafe;
}
.creator_profile_time_price .select__control{
  background: #f8fafe;
}
.creator_profile_time_price .basic-single .select__single-value{
  font-weight: 500 !important;
}
.basic-single .select__single-value{
  font-weight: 700 !important;
}
.css-1rhbuit-multiValue{
  font-size: 15px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: 0.04px !important;
  border-radius: 7px !important;
  border: solid 1px #cbd3df !important;
  background-color: #eff1f8 !important;
  padding:10px 13.5px !important;
  margin:4px 4px !important;
}
.select__multi-value__label{
  color: #707d92 !important;
  font-size: 100% !important;
  padding:0px !important;
}

.select__multi-value__remove{
  padding: 0 !important;
  margin-left: 0.25rem !important ;
}
.select__multi-value__remove svg{
  width:18px !important;
  height:18px !important;
  cursor: pointer;
}
.select__multi-value__remove svg path{
  color:#686b70;
}
.select__multi-value__remove:hover {
  background:transparent !important;
} 
.select__placeholder{
  /* padding-left:13px !important; */
  font-weight: 600 !important;
  color:#aeb3be !important;
}
.select__menu-list::-webkit-scrollbar {
  width: 4px;
}

.select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.select__menu-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.select__menu-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.select__menu-list .select__option{
  color:#6b6b6b;
  font-weight:600;
}

.select__menu-list .select__option.select__option--is-selected, .select__option.select__option--is-focused {
  background-color: #fff;
}
.select__menu-list .select__option.select__option--is-focused {
  background-color: #DEEBFF;
}

/* summernote css */
/* .note-toolbar {  
  position: absolute;  
  bottom: -40px; 
  border-bottom: none;  
} */

.note-statusbar{
  display: none;
}
.chat-summernote{
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
  background-color: #fff;
  width: 70%;
  margin-left: auto;
  padding:15px 20px 20px;
}
.chat-summernote .note-editable p{ 
  margin-bottom:0px !important;
  background-color: transparent !important;
}
.chat-summernote .note-editor.note-frame .note-placeholder, 
.chat-summernote .note-editor.note-airframe .note-placeholder{
  padding:14px;
}
.chat-summernote .note-editor.note-frame .note-editing-area .note-editable, 
.chat-summernote .note-editor.note-airframe .note-editing-area .note-editable{
  padding:14px;
  border: 1.5px solid #e8ecf4;
  border-radius: 8px;
  padding-right:20%;
  background-color: #f8fafe;
} 
.chat-summernote.chat-final-milestone .note-editor.note-frame .note-editing-area .note-editable, 
.chat-summernote.chat-final-milestone .note-editor.note-airframe .note-editing-area .note-editable{
  padding-right: 30%;
} 
.chat-summernote.open-preview-fileupload .note-editor.note-frame .note-editing-area .note-editable, 
.chat-summernote.open-preview-fileupload .note-editor.note-airframe .note-editing-area .note-editable{
  /* border-radius: 0px 0px 8px 8px;
  border-top:transparent; */
  border-radius:8px 8px 0 0;
  border-bottom:transparent;
}
.note-editor.note-frame, .note-editor.note-airframe{
  border:none !important;
}
.note-editor.note-frame .note-editing-area .note-editable::-webkit-scrollbar {
  width: 8px;
} 
.note-editor.note-frame .note-editing-area .note-editable::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
.note-editor.note-frame .note-editing-area .note-editable::-webkit-scrollbar-thumb {
  background: #b7babc; 
} 
.note-editor.note-airframe .note-editing-area .note-editable::-webkit-scrollbar {
  width: 8px;
} 
.note-editor.note-airframe .note-editing-area .note-editable::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
.note-editor.note-airframe .note-editing-area .note-editable::-webkit-scrollbar-thumb {
  background: #b7babc; 
} 
.submit_btn{
  position: absolute;
  right: 4.5%;
  z-index: 100;
  top:30%;
}
.chat-feedback .note-editable p{ 
  margin-bottom:0px !important;
  background-color:transparent !important;
}
.chat-feedback .note-editor.note-frame .note-editing-area .note-editable, 
.chat-feedback .note-editor.note-airframe .note-editing-area .note-editable{
  background-color: #fff;
  min-height: 0px;
  padding-right:10%;
}
.chat-feedback .submit_btn {
  right: 4.5%;
  top: 25%;
}
.version-history p{
  background-color: transparent !important;
} 
.submit-milestone-summernote .note-editor.note-frame .note-editing-area .note-editable, 
.submit-milestone-summernote .note-editor.note-airframe .note-editing-area .note-editable{
  padding:14px;
  border: 1.5px solid #e8ecf4;
  border-radius: 8px; 
  background-color: #f8fafe;
} 
.submit-milestone-summernote .note-editor.note-frame .note-placeholder, 
.submit-milestone-summernote .note-editor.note-airframe .note-placeholder{
  padding: 14px;
}
.submit-milestone-summernote .note-editable p{
  margin:0;
}
.submit-milestone-summernote.error{
  border:1px solid #d63447;
  border-radius: 6px;
} 
.submit-milestone-summernote.attachment_open .note-editor.note-frame .note-editing-area .note-editable, 
.submit-milestone-summernote.attachment_open .note-editor.note-airframe .note-editing-area .note-editable{
  /* border-bottom:none; */
  border-radius:8px 8px 0px 0px;
}
.add_image_url_wrapper .form-control{
  height:42px;
}
.add_image_url_wrapper .css-yk16xz-control{
  height:42px;
}
.review_attachment_icon{
  position: absolute;
  top: 24%;
  right: 1%;
}
.client_view_attachment .upload-files:hover .remove-file{
  opacity:0;
}

/*== Common Onboarding ==*/
.basic-single.simple.onboarding{
  background: #f8fafe !important;
  padding: 5px 0px;
}
.basic-single.simple.onboarding .select__control{
  background: #f8fafe;
}
.basic-single.simple.onboarding .select__single-value{
  font-weight: 500 !important;
  color:#000;
}
.chat_left_user_html p > div > p{
  margin-bottom:0px;
}
.basic-multi.onboarding-select .select__control,
.basic-single.onboarding-select .select__control{
  /* background:#f8fafe; */
  min-height:46px;
}
.upload_url{
  height:450px;
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
  flex-direction: column;
  background-color: #f5f7fd;
}
.upload_url.artist-url{
  height:560px;
}
.upload_url .ext-url{
  background: #e5ecff;
  padding: 8px 12px;
  border-radius: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width:400px;
  display: block;
  margin: 0 auto;

}
.artist-card {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  max-width: 100%;
}
.admin-project{
  padding:0;
}
.admin-project .project-delivery-date:before{
  display:none;
}

/* react-file-viewer */
.pg-viewer-wrapper .document-container{
  width: 100%;
  padding: 1rem;
}

.pg-viewer-wrapper{
  overflow: hidden;
  overflow-y: unset;
}
.pg-viewer-wrapper .pg-viewer{
  width: 100%;
}
.vertical-scroll{
  overflow-y: auto;
  overflow-x: hidden;
}

/* landing creator card */
.landing-creator-card{
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  vertical-align: middle;
  height: 100%;
}

/* render html of custom summernote */
.render-html p{
  margin-bottom: 0;
}

.auto-available-checkbox{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-top:12px;
}

/*-- Chat Lightbox modal --*/
.custom_form.lightbox-modal .modal-content{
  height:calc(100vh - 65px); 
}
.custom_form.lightbox-modal .modal-body{
  overflow:hidden; 
}
.lightbox-modal .lightbox-profile-image{
  width:45px;
  height:45px;
  line-height:45px;
  overflow:hidden;
}
.lightbox-modal .lightbox-profile-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.lightbox-modal .lightbox-profile-headline{
  padding-left:15px;
}
.lightbox-modal .lightbox-download-icon  i{
  font-size:24px;
  color:#707e92;
  padding-right:5px;
}
.lightbox-modal .lightbox-big-preview{
  text-align: center;
  width: 680px;
  height: calc(680px / 1.333);
  margin: 30px auto 15px;
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  background: #fff; 
}
.lightbox-modal .lightbox-big-image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 12px;
}
.lightbox-modal .lightbox_prev{
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translate(-50%,-50%);
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
.lightbox-modal .lightbox_next{
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(-50%,-50%);
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
.lightbox-modal .lightbox_prev i, 
.lightbox-modal .lightbox_next i {
  font-size: 18px;
  color: #fff;
  border: 1px solid #eee;
  border-radius: 36px;
  width: 50px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  transition: 0.3s all ease-in-out;
}
.lightbox-modal .lightbox_prev i:hover, 
.lightbox-modal .lightbox_next i:hover {
  background: rgba(255,255,255,1);
  color: #000;
}

/* ===== User Survey CSS ===== */
.user-survey{
  padding:20px 0px;
}
.user-survey .btn-scale{
  min-width: 44px;
  width:calc(86% / 11);
  text-align: center;
  font-weight:700;
  border:solid #000;
  border-width:1px 1px 1px 1px;
  border-radius:0;
  color:#000; 
  padding:12px 10px;
  margin:5px;
}
.user-survey .btn-scale:last-of-type{
  border-width: 1px;
}
.user-survey .btn-scale:focus{
  outline:none;
  box-shadow: none;
}  
.survey-radio [type="radio"]:checked,
.survey-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.survey-radio [type="radio"]:checked + label,
.survey-radio [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #333;
  font-weight: 500;
}
.survey-radio [type="radio"]:checked + label:before,
.survey-radio [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.survey-radio [type="radio"]:checked + label:after,
.survey-radio [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #10c89b;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.survey-radio [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.survey-radio [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*==== Active Brief ====*/
.active_brief_page .active_brief_tabs{
  padding:20px 40px;
}
.active_profile_wrapper{
  display:flex;
  align-items:center;
}
.active_profile_wrapper .active_profile{
  padding:6px 20px;
  box-shadow:0 5px 10px rgba(0,0,0,0.08);
  border-radius:6px;
}
.active_profile_wrapper .active_profile .profile_img{
  width: 50px;
  height: 50px;
  text-align: center; 
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_img_wrapper .profile_img img {
  border-radius: 50% !important;
}
.active_profile_wrapper .active_profile .profile_img img {
  width: auto;
  height: 60px;
  max-height: 60px;
  border-radius: 0px; 
  object-fit:cover;
}
.active_profile_wrapper .active_profile .active_profile_headline{
  padding-left:10px;
} 

.active_profile_wrapper .dropdown{
  background: #DBF7F0; 
  border-radius:6px;
  right:0;
  margin-left:30px;
}
.active_profile_wrapper .dropdown.active_brief_dropdown{
  background:transparent;
}
.active_profile_wrapper .dropdown > a{
  padding:10px 15px;
  display:block;
  position:relative;
}
.active_profile_wrapper .dropdown .dropdown-menu{
  left:-100% !important;
  top:40% !important;
  transform: translate3d(-55px, 28px, 0px) !important;
  padding:0;
  border:1px solid #eee;
}
.active_profile_wrapper .dropdown.inside_profile_dropdown .dropdown-menu{
  transform: translate3d(-78px, 28px, 0px) !important;
  min-width: 12rem;
}
.active_profile_wrapper .dropdown .dropdown-menu .dropdown-item{
  padding:10px 20px;
  font-weight:500;
  font-size:14px;
  border-bottom:1px solid #eee;
} 
.active_profile_wrapper .dropdown .dropdown-menu .dropdown-item:last-of-type{
  border-bottom:none;
}
.active_profile_wrapper .dropdown-menu-arrow:before, 
.active_profile_wrapper .dropdown-menu-arrow:after{
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 7px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: -10;
}
.active_profile_wrapper .dropdown-menu-arrow:after{
  top: -13px;
  right: 20%;
  border-bottom-color: #fff !important;
}
.active_profile_wrapper .dropdown-menu-arrow:before {
  top: -14px;
  right:20%;
  border-bottom-color: rgba(0, 0, 0, 0.06) !important;
}
.active_profile_wrapper .active_brief_dropdown .dropdown-menu-arrow:after,
.active_profile_wrapper .active_brief_dropdown .dropdown-menu-arrow:before{
  right:50%;
}
.active_brief_cards{
  height:100%;
}
.active_brief_cards .card{
  border-radius:6px;
  box-shadow: 0px 2px 12px #0000000F;
  border:none;
  height:100%;
}
.active_brief_cards .card .card-body{
  padding:20px;
  text-align: center;
  cursor: pointer;
}
.active_brief_cards .card-body .new_message{
  display:flex;
  align-items: center;
  justify-content:center;
  border-bottom: 1px solid #414141;
  padding-bottom: 12px;
}
.active_brief_cards .card-body .profile{
  width: 30px;
  height: 30px;
  text-align: center; 
  /* border:1px solid #eee;
  background-color: rgba(87, 87, 87, 0.15); */
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-right:10px;
} 
.active_brief_cards .card-body .profile img{
  width:auto;
  height: 30px;
  max-height:30px;
  border-radius: 0px;  
  object-fit:cover;
} 
.active_brief_cards .card-body .user_profile{
  padding:20px 0;
  text-align:center;
}
.active_brief_cards .card-body .user_profile h3{
  padding:12px 0 2px;
}
.active_brief_cards .card-body .user_profile .profile{
  width: 60px;
  height: 60px;
  margin:auto;
}
.active_brief_cards .card-body .user_profile .profile img{
  width:auto;
  height: 70px;
  max-height:70px;
  border-radius: 0px;  
  object-fit:cover; 
} 
.active_brief_cards .card-body .user_client,
.active_brief_cards .card-body .user_client .client_list{
  display:flex;
  align-items:center;
  justify-content: center;
} 
.active_brief_cards .card-body .client_list .tooltip_custom .down_icon{
  top:48px;
  left:45%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000;
  border-top:0px solid transparent;
}
.active_brief_cards .card-body .client_list .tooltip_custom .client_img:hover::after, 
.active_brief_cards .card-body .client_list .tooltip_custom .client_img:hover .down_icon{
  bottom:115%;
  pointer-events:none;
}
.active_brief_cards .card-body .client_list .tooltip_custom::after{
  bottom:-98%;
  pointer-events:none;
}
.active_brief_cards .card-body .user_client .client_list .client_img{
  width: 45px;
  height: 45px;
  text-align: center; 
  border:1px solid #eee;
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;  
  margin-right:8px;
}
.active_brief_cards .card-body .user_client .client_list img{
  width:auto;
  height:50px;
  object-fit:cover; 
}
.active_brief_cards .card-body .project_slider{
  padding:20px 0 0;
  position:relative;
}
.active_brief_cards .card-body .project_slider .project-list{
  display: block;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 6px;
  width: 370px;
  height: calc(370px / 1.333);
  max-width: 100%;
}
.active_brief_cards .card-body .project_slider .project-list .project-preview {
  background-repeat: no-repeat !important;
  background-position: center center !important;
  background-size: cover !important;
  vertical-align: middle;
  height: 100%;
}
.active_brief_cards .card-body .project_slider .owl-next,
.active_brief_cards .card-body .project_slider .owl-prev{
  position:absolute;
  width: 35px;
  height: 35px;
  background:#fff !important;
  border-radius: 100%;
  font-size: 35px !important;
  line-height: 1 !important;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 9%);
  display:block !important;
}
.active_brief_cards .card-body .project_slider .owl-prev{
  left:5%;
  top:52%;
}
.active_brief_cards .card-body .project_slider .owl-next{
  right:5%;
  top:53%;
}
.active_brief_cards .card-body .project_slider .owl-carousel .owl-prev span, 
.active_brief_cards .card-body .project_slider .owl-carousel .owl-next span{
  margin-top:-6px;
  display:block;
}    
.active_brief_cards .card-body .btn-brief{
  padding: 12px 15px; 
  color: #333;
  border:1px solid #707070;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: 0.2s ease-in-out;
  display:flex;
  align-items: center;
  margin:auto;
} 
.active_cards_collateral .active_collateral_image{
  width:70px;
  height:calc(70px / 1.333);
  border-radius:8px;
  background-position: center;
  background-size:cover;
} 
/*==== Active Brief Modal Css ====*/
.activeBrief_Modal .modal-dialog{
  margin:0rem 0 0rem auto;
  max-width:1000px;
}
.modal.activeBrief_Modal.fade .modal-dialog{
  -webkit-transform: translate(100%,0px);
  transform: translate(100%,0px);
}
.modal.activeBrief_Modal.show .modal-dialog{
  -webkit-transform:translate(0%,0px);
  transform: translate(0%,0px);
}
.activeBrief_Modal .modal-content{
  height: 100vh;
  max-height: 100vh;
  border-radius: 0;
  border: none;
}
.activeBrief_Modal .modal-header{
  background:#2D2D2D;
  color:#fff;
  box-shadow: 0px 2px 12px #0000000F;
  padding:15px;
  border-top-left-radius:0;
  border-top-right-radius:0;
}
.activeBrief_Modal .modal-title{
  display:flex;
  align-items:center;
}
.activeBrief_Modal .left_icon,
.activeBrief_Modal .right_icon{
  font-size:24px;
  color:#FFF;
}
.chat-summernote .tooltip_custom::after,
.activeBrief_Modal .tooltip_custom::after{
  text-transform: inherit;
  pointer-events: none;
}
.activeBrief_Modal .Numbers{
  margin:0 8px;
  font-weight:600; 
  font-size:24px;
  color: #FFF;
}
.activeBrief_Modal .profile{
  width: 82px;
  height: 82px;
  text-align: center; 
  border:1px solid #eee;
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; 
  margin-right:20px;
  margin-bottom:10px;
}
.activeBrief_Modal .profile img{
  width:auto;
  height: 82px;
  max-height:82px;
  border-radius: 0px;  
  object-fit:cover; 
}  
.activeBrief_Modal .shortlist_button .btn{
  color:#fff;
  font-weight: 500;
  border:1px solid #42D3AF;
  background-color:#42D3AF;
  height:35px;
  padding:2px 25px;
} 
.activeBrief_Modal .shortlist_button .btn:focus{
  outline:none;
  box-shadow: none;
}
.activeBrief_Modal .btn-brief{
  padding: 10px 15px;
  color: #333;
  border: 1px solid #707070;
  font-weight: 800;
  font-size:13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: 0.2s ease-in-out;
  display: flex;
  align-items: center; 
}
.activeBrief_Modal .modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 5px;
} 
.activeBrief_Modal .modal-dialog-scrollable .modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
.activeBrief_Modal .modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
  background: #b7babc; 
} 
.activeBrief_Modal .modal-body{
  background:#FAFAFA;
  padding:30px;
}
.share_btn > span{
  background:#10c89b;
  display:inline-block;
  padding:7px 10px;
  border-radius: 6px;
  color:#fff;
  margin:10px auto 0;
  cursor:pointer;
}
.shareModal .share_url{
  background:#f5f7fd;
  padding:30px 15px;
  border-radius:6px;
}
/*=== Brief Services CSS ===*/
.briefServices .content{
  display:flex;
  grid-gap:15px;
  align-items:center; 
  flex-wrap:wrap;
}
.briefServices .content li{
  width:calc(100% / 3 - 10px);
}
.briefServices .content .project-view{
  min-height:185px;
}

/*=== Reviews CSS ===*/
.stop_icon{
  text-align:center;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 4px;
}
.stop_icon img{
  max-width: 17%;
  filter: contrast(0);
}
.awards_stop_icon img{
  max-width:15%;
}
.review_stop_icon img{
  max-width:6%;
}
.client-rejected{
  min-height: calc(100vh - 220px);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .review_wrapper .rev#e5e5e5ails{ 
  padding:12px;
  border-radius:5px;
  border: 1px solid #E5E5E5;
  margin-bottom:15px;
  margin-right: 2px;
}  */
.review_wrapper.common_height .item{
  min-height:140px;
}
.review_wrapper.common_height .review_details{
  padding-bottom:15px;
}
.review_wrapper .review_details .stars img{
  width:30px !important;
}
.review_wrapper .owl-theme .owl-dots .owl-dot span{
  width:8px;
  height:8px;
  background: transparent;
  border: 1px solid #000;
  margin:5px 4px;
}
.review_wrapper .owl-carousel{
  position:relative;
}
.review_wrapper .owl-theme .owl-dots .owl-dot.active span, 
.review_wrapper .owl-theme .owl-dots .owl-dot:hover span{
  background:#10c89b;
  border-color:#10c89b;
}
.review_wrapper .owl-next, 
.review_wrapper .owl-prev{
  position: absolute;
  width: 35px;
  height: 35px;
  background: #fff !important;
  border-radius: 100% !important;
  font-size: 35px !important;
  line-height: 1 !important;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 9%);
  display: block !important;
}
.review_wrapper .owl-next{
  right: -1% !important;
  bottom: -5%;
}
.review_wrapper .owl-prev{
  left: -1% !important;
  bottom: -5%;
}
.review_wrapper .owl-carousel .owl-prev span, 
.review_wrapper .owl-carousel .owl-next span{
  margin-top: -6px;
  display: block;
}

/*=== Clients CSS ===*/
.client_wrapper .clients_details{
  padding:9px 12px;
  border-radius:5px;
  border: 1px solid #E5E5E5;
  margin-bottom:15px;
}
.client_wrapper .clients_details:last-of-type{
  margin-bottom:0;
}
.client_wrapper .clients_details .client_img{
  width: 30px;
  height: 30px;
  text-align: center; 
  border:1px solid #eee;
  background-color: rgba(87, 87, 87, 0.15);
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;  
  margin-right:10px;
}
.client_wrapper .clients_details .client_img img{
  width:auto;
  height: 40px;
  max-height:40px;
  border-radius: 0px;  
  object-fit:cover; 
}  

/*=== Page Not Found CSS ===*/
.page_not_found{
  height:calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_not_found .page_inner{
  width:100%;
  background:#fff;
  border-radius:10px;
  box-shadow:0 2px 10px rgba(0,0,0,0.05);
  padding:50px 30px;
  text-align:center;
} 
.page_not_found .page_inner h3{
  font-size:60px;
  font-weight:800;
  margin:30px 0;
}
.page_not_found .page_inner .sub_text{
  font-size:18px;
  font-weight:600;
  padding:0 20px;
  margin:30px 0;
}

/* Call Confirmed */
.call_confirmed{
  display:flex;
  align-items: center;
  justify-content: center;
  height:100vh;
  width:100%; 
}
.call_confirmed .call_wrapper{
  max-width:600px;
  width:100%;
  border-radius: 10px;
  margin:auto;
  background-color:#fff;
  padding:40px;
}
.call_confirmed .call_wrapper h1{
  font-size:1.75rem;
  font-weight: 800;
}
.call_confirmed .call_wrapper .notes{
  width:80%;
  margin:auto;
}
.call_confirmed .call_wrapper i{
  font-size:35px;
  color:#10c89b;
  margin-bottom: 10px;
}
.active_brief_buttons .disabled{
  background-color: #dfdfdf !important;
  color: #6b6b6b !important;
  font-weight: 500;
  cursor:no-drop;
}

/* New Profile Selection CSS */
.profile_selection_wrapper{
  padding:3rem 0;
}
.profile_selection_wrapper .profile_title{
  padding-bottom:2rem;
}
.profile_selection_wrapper .profile_title h1{
  font-size:30px;
  color:#11C89B;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}
.profile_selection_wrapper .profile_title p{
  font-size:16px;
  font-weight:500;
  font-family: 'Poppins', sans-serif;
  color:#292929;
}
.profile_selection_wrapper .profile_title h2{
  font-size:20px;
  color:#3454D1;
  font-family: 'Poppins', sans-serif;
}
.profile_selection_wrapper .card_details{
  padding:20px;
}
.profile_selection_wrapper .card_details P{
  color: #9393AB;
  font-size: 12px!important;
  font-weight: 600!important;
}
.profile_selection_wrapper .card_profile{
  width:50px;
  height:50px;
  overflow: hidden;
  margin-bottom:8px;
  border-radius: 100%;
}
.profile_selection_wrapper .card_profile img{
  width:100%;
  height:100%;
  object-fit: cover;
}
.profile_selection_wrapper .card_details .price{
  font-weight: 700;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}
.profile_selection_wrapper .card_details .per_project{
  color:#3454D1;
}
.profile_selection_wrapper .card_details .client_works h5{
  font-size:14px;
  font-weight:500;
  font-family: 'Poppins', sans-serif;
} 
.profile_selection_wrapper .card_details .client_logo img{ 
  object-fit: cover;
  width:32px;
  height:32px;
  overflow:hidden;
  border-radius: 100%;
  margin-right:8px;
}
.profile_selection_wrapper .card_details .client_logo img:last-child{
  margin-right:0;
}
.profile_selection_wrapper .card_details .reject_button .btn {
  border:1px solid #AD0000;
  background-color:#FDEDED;
  color:#AD0000;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  height:30px;
  line-height: 20px;
  padding:3px 15px 10px;
  font-size:12px;
  border-radius: 5px;
}
.profile_selection_wrapper .card_details .invite_button .btn {
  border:1px solid #10C89B;
  background-color:#10C89B;
  color:#ffffff;
  font-weight: 700;
  padding: 7px 25px;
  font-size: 14px;
  border-radius: 5px;
}
.profile_selection_wrapper .card_details .invite_button .btn:focus,
.profile_selection_wrapper .card_details .mobile_view_profile .btn:focus,
.profile_selection_wrapper .card_works .view_profile .btn:focus{
  outline:none;
  box-shadow: none;
}

.profile_selection_wrapper .card_details .mobile_view_profile .btn{
  color:#10C89BC1;
  font-weight: 500;
  font-size:12px;
  font-family: 'Poppins', sans-serif;
  display:none;
}
.profile_selection_wrapper .card-body{
  display:flex; 
  justify-content:space-between;
  overflow:hidden;
  border-radius: 15px;
}
.profile_selection_wrapper .card_details{
  width:40%;
}
.profile_selection_wrapper .card_works{
  width:60%;
  position:relative;
}
.profile_selection_wrapper .card_works img{
  width:350px;
  max-width:100%;
  height:calc(350px / 1.5);
  object-fit:cover;
}
.profile_selection_wrapper .card_works .view_profile{
  padding:0px;
}
.profile_selection_wrapper .card_works .view_profile .btn{
  color:#1464DC;
  font-weight: 700;
  font-size:13px;
  margin-top:10px;
} 
.profile_selection_wrapper .card_works .new_profile_prev i,
.profile_selection_wrapper .card_works .new_profile_next i{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 6px;
  border: 1.5px solid #fff;
  font-size: 1.2rem;
  line-height: 25px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px rgb(0 0 0 / 9%);
  background-color:#fff;
  display:flex;
  align-items:center;
  justify-content:center;
  color: #9398bb;
}
.profile_selection_wrapper .card_works .new_profile_prev i{
  position:absolute;
  top:35%;
  left:4%;
} 
.profile_selection_wrapper .card_works .new_profile_next i{
  position:absolute;
  top:35%;
  right:4%;
}
.profile_selection_wrapper .profile_selection_loader{
  width:260px;
  height:calc(350px / 1.5);
  object-fit:cover;
  display:flex;
  align-items: center;
  justify-content: center;
}
.profile_selection_wrapper .proceed_btn{
  padding: 10px 15px;
  background-color: #10c89b;
  color: #fff !important;
  border: none;
  font-size:14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  transition: background-color 0.5s ease-in-out;
}
.profile_loader_wrapper{
  margin-left:5rem;
}
.screen-not-responsive .modal_fade_bg {
  background-color: #EAEEFB;
}
.show-div{
display: block;
}