// select_Revlent_project
.review_confirm{
    .review_confirm_modal_dialog{
        width:calc(100vw - 10px);
        max-width:800px;

        @media(min-width:$breakpoint-sm){
            width: calc(100vw - 150px); 
            max-width:800px;
        }
        
        @media(min-width:$breakpoint-lg){
            width: calc(100vw - 700px); 
            max-width:800px;
        }
         
        .modal_top_header {
         padding: 2.5rem 0rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }
        .btm_section{
            background-color: $bg-blue-200;
        }
         // tootltip
         .tool::before {
            border-style: solid;
            border-width: 1em 0.75em 0 0.75em;
            border-color: $bg-gray-1000 transparent transparent transparent;
            bottom: 100%;
            content: "";
            margin-right: -50em;
            transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.65s 0.5s;
            transform:rotate(0deg) translateY(-7.1rem) translateX(44.9rem);
                @media(max-width:$breakpoint-md){
                    transform: rotate(270deg) translateY(4rem) translateX(-12.5rem); 
                }
        }
        .tool::after{
            text-align:left;
            font-size:1.2rem;
            background: $bg-gray-1000;
            bottom: 94%;
            color: #EDEFF0;
            content: attr(data-tip);
            border-radius: 0.8rem;
            margin-left:-0.9em;
            padding: 1em;
            transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
            transform:scale(0.7) translateY(390%) translateX(-2.688rem);
            width: 30em;
            min-width:5.625rem;
                @media(max-width:$breakpoint-md){
                    transform: scale(0.7) translateY(263%) translateX(-1.688rem);   
                }
        }
        
       
        
            
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    
    }

}