*{
    margin:0;
	padding:0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
body{
	font-family: 'Muli', sans-serif;
	font-size:14px;
    font-weight:normal;
    color:#000;  
}
h1,h2,h3,h4,h5,h6,p,button{
    font-family: 'Muli', sans-serif;
}
a{
    text-decoration: none;
    color:#1464DC;
}
a:hover{
	text-decoration: none;
}
a:not([href]):hover{
    color:#1464DC;
}
a.text-success:focus, a.text-success:hover{
    color:#10c89b !important;
}
ul,ol,li{
    list-style: none;
    margin:0;
}
button:focus{
    outline:none;
}
/*-- Text color -- */
.text-primary{
    color:#3454d1 !important;
}
.text-success{
    color:#10c89b !important;
}
.text-danger{
    color:#d63447 !important;
}
.text-white{
    color:#fff !important;
}
.text-black{
    color:#333 !important;
}
.text-dark-black{
    color:#000 !important;
}
.text-secondary{
    color: #9b9b9b !important;        
}
.text-secondary.active{
    color: #000 !important;        
}
.text-light-gray{
    color: #959595 !important;
}
.text-third{
    color: #707d91 !important;
}
.text-four{
    color:#54647c !important;
}
.text-dark-gray{
    color:#6c7a8f !important;
}
.text-sub{
    color:#6f7d91 !important;
}
.text-sub-2{
    color:#6b7a90 !important;
}
.text-sub-3{
    color:#cbcbcb !important;
}
.text-sub-4{
    color:#767a81 !important;
}
.text-sub-5{
    color:#a8afb9 !important;
}
.text-sub-6{
    color:#707e92 !important;
}
.text-sub-7{
    color:#838e9b !important;
}
.text-sub-8{
    color:#b0b8c5 !important;
}
.text-sub-9{
    color:#ccc !important;
}
.text-secondary-light{
    color:#6c7b90 !important;
}
.text-lighter{
    color: #d2d3d4 !important;
}
.text-blue{
    color:#0f60e2 !important
}
.text-sub-5{
    color: #aeb3be !important;
}
.text-color-purple{
    color:#9e41df !important;
}
.text-yellow{
    color:#fcac44 !important;
}
/*-- line height --*/
.line-height-normal{
    line-height: normal !important;
}
.line-height-14{
    line-height: 1.4 !important;
}
.line-height-16{
    line-height: 1.6 !important;
}
.line-height-17{
    line-height: 1.57 !important;
}
.line-height-20{
    line-height: 2 !important;
}

/*-- Letter spacing --*/
.letter-spacing-normal{
    letter-spacing: normal !important;
}
.letter-spacing-01{
    letter-spacing: -0.1px !important;
}
.letter-spacing-02{
    letter-spacing: -0.2px !important;
}
.letter-spacing-05{
    letter-spacing: -0.05px !important;
}
.letter-spacing-015{
    letter-spacing: -0.15px !important;
}
.letter-spacing-023{
    letter-spacing: -0.23px !important;
}
.letter-spacing-046{
    letter-spacing: -0.46px !important;
}
.letter-spacing-053{
    letter-spacing: -0.53px !important;
}
.letter-spacing-056{
    letter-spacing: -0.56px !important;
}


/*-- Background color -- */
.bg-tranparent{
    background:transparent !important;
}
.bg-dashboard{
    background:#f5f7fd !important;
}
.bg-light-white{
    background:#f3f2f2 !important;
}
.bg-white{
    background-color:#fff !important;
}
.bg-success{
    background-color: #10c89b !important;
}

.bg-orange{
    background: #ef9630;
}
.bg-yellow{
    background-color:#fcac44 !important;
}
.bg-secondary{
    background-color: #dfdfdf !important;
    color: #6b6b6b !important;
    font-weight: 500;
}
.main-btn.bg-secondary:hover{
    background-color: #dfdfdf !important; 
}

.bg-light-gray{
    background-color:#eff1f8 !important;
    color: #54647c !important;
}
.bg-dark-gray{
    background-color:#e1e5ec !important;
}
.bg-light{
    background-color: #ebebeb  !important;
    color: #6b6b6b !important;
    font-weight: 500;
}
.bg-danger{
    background-color:#E16050 !important;
}
button.bg-danger:hover{
    background-color:#E16050 !important;
}
.bg-purple{
    background-color: #9e41df !important;
}
.bg-offer{
    background-color:#ec544a !important;
}
.bg-black{
    background-color:#000 !important;
}
.bg-landing-header{
    background-color:#2D2D2D !important;
}
.bg-light-sky{
    background-color: #F5F7FD !important; 
}
.bg-light-input{
    background-color: #f8fafe !important;
}
.bg-extra-light{
    background-color:#eff1f8 !important;
}
.bg-gray-mode{
    background-color: #e4e7ef !important;
}
.bg-gray-morelight{
    background-color: #f6f7f8 !important;
}
.bg-light-blue{
    background-color: #f4f6fc !important;
}
.bg-dark-blue{
    background-color: #040421 !important;
}
.bg-blue{
    background-color: #3554d1 !important;
}
/*-- Border error color -- */
.border-error{
    border:1px solid #d63447 !important;
}
.border-top-none{
    border-top:none !important;
}
.border-bottom-none{
    border-bottom:none !important;
}
.bg-primary{
   background:  #1060e2 !important;
}

/*-- Border radius -- */
.border-top-left-radius{
    border-top-left-radius: 12px;  
}
.border-bottom-left-radius{
    border-bottom-left-radius: 12px;
}
.border-bottom-right-radius{
    border-bottom-right-radius: 12px;
}
.border-top-right-radius{
    border-top-right-radius: 12px;
}
.border-radius-100{
    -webkit-border-radius: 100%;
    -ms-border-radius: 100%;
    -moz-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100% !important;
}
.border-radius-4{
    border-radius:4px;
}
.border-radius-8{
    border-radius:8px;
}
.border-radius-10{
    border-radius:10px;
}
.border-radius-36{
    border-radius: 36px;
}
/*-- box shodow color -- */
.box-shadow-none{
    box-shadow:none !important;
}
.box-shadow-highlight{
    box-shadow: 0 2px 10px rgb(0 0 0 / 20%) !important;
}
.box-shadow-box{
    box-shadow: 0px 20px 30px rgba(0,0,0,.06);
}
.box-shadow-light{
    box-shadow: 0px 2px 10px rgba(0,0,0,0.03);
}
.disabled{
    opacity: 0.6;
}
.closeIcon{
    display:none;
}
.hidden{
    display:none !important;
}
.ipad-pro-mobile-show{
    display:none !important;
}
.mobile-show{
    display:none !important;
}
.desktop-hidden,
.desktop_ipadPro_display_none,
.desktop_ipadpro_ipad_none,
.ipad-only-show{
    display:none;
}
.mobile-ipad-show{
    display:none;
}
.mobile-ipad-none,
.ipad-only-hide{
    display:block;
}
.mobile-only-show{
    display:none;
} 

/*-- Overflow hidden --*/
.overflow-hidden{
    overflow:hidden !important;
}
/*-- Font size -- */
.font-50{
    font-size:50px !important;
}
.font-35{
    font-size:35px !important;
}
.font-30{
    font-size:30px !important;
}
.font-28{
    font-size:28px !important;
}
.font-26{
    font-size:26px !important;
}
.font-24{
    font-size:24px !important;
}
.font-22{
    font-size:22px !important;
}
.font-20{
    font-size:20px !important;
}
.font-19{
    font-size:19px !important;
}
.font-18{
    font-size:18px !important;
}
.font-16{
    font-size:16px !important;
}
.font-15{
    font-size:15px !important;
}
.font-14{
    font-size:14px !important;
}
.font-13{
    font-size:13px !important;
}
.font-12{
    font-size:12px !important;
}
.font-11{
    font-size:11px !important;
}
.font-10{
    font-size:10px !important;
}
.font-9{
    font-size: 9px !important;
}
.font-5{
    font-size: 5px !important;
}
.sub-text{
    font-size:12px;
    color: #959595;
}
.font-weight-400{
    font-weight:400 !important;
}
.font-weight-500{
    font-weight: 600 !important;
}
.font-weight-600{
    font-weight: 700 !important;
}
.font-weight-700{
    font-weight:700 !important;
}
.font-weight-800{
    font-weight:800 !important;
}
.font-weight-bold{
    font-weight:bold !important;
}

/*-- Font style --*/
.font-normal{
    font-style:normal !important;
}
.font-family-sans{
    font-family: sans-serif;
}
.dropdown-toggle::after{
    display:none;
}

/*-- Padding css -- */
.py-2{
    padding-top:2px !important;
    padding-bottom:2px !important; 
}
.self_py_2{
    padding-top:2px !important;
    padding-bottom:2px !important; 
}
.py-8{
    padding-top:8px !important;
    padding-bottom:8px !important; 
}
.pt-15{
    padding-top:15px !important;
}
.pt-20{
    padding-top:20px !important;
}
.pt-25{
    padding-top:25px !important;
}
.py-10{
    padding-top:10px !important;
    padding-bottom:10px !important; 
}
.py-12{
    padding-top:12px !important;
    padding-bottom:12px !important; 
}
.py-15{
    padding-top:15px !important;
    padding-bottom:15px !important; 
}
.py-20{
    padding-top:20px !important;
    padding-bottom:20px !important; 
}
.px-5{
    padding-left:5px !important;
    padding-right:5px !important;
}
.px-15{
    padding-left:15px !important;
    padding-right:15px !important;
}
.px-20{
    padding-left:20px !important;
    padding-right:20px !important;
}
.px-25{
    padding-left:25px !important;
    padding-right:25px !important;
}
.px-30{
    padding-left:30px !important;
    padding-right:30px !important;
}
.px-40{
    padding-left:40px !important;
    padding-right:40px !important;
}
.px-70{
    padding-left:70px !important;
    padding-right:70px !important;
} 
.py-20{
    padding-top:20px !important;
    padding-bottom:20px !important;
}
.py-25{
    padding-top:25px !important;
    padding-bottom:25px !important;
}
.pt-5{
    padding-top:5px !important;
}
.pt-10{
    padding-top:10px !important;
}
.pt-15{
    padding-top:15px !important;
}
.pt-30{
    padding-top:30px !important;
}
.pt-50{
    padding-top:50px !important;
}
.pt-80{
    padding-top:80px !important;
}
.pt-100{
    padding-top:100px !important;
}
.pt-130{
    padding-top:130px !important;
}
.pt-150{
    padding-top:150px !important;
}
.py-30{
    padding-top:30px !important;
    padding-bottom:30px !important;
}
.py-80{
    padding-top:80px !important;
    padding-bottom:80px !important;
}
.py-100{
    padding-top:100px !important;
    padding-bottom:100px !important;
}
.py-11-percentage{
    padding-top:11% !important;
    padding-bottom:11% !important;
}
.pt-py{
    padding:40px 40px 0px !important;
}
.pt-py-15{
    padding:15px 40px 0px !important;
}
.pl-10{
    padding-left:10px !important;
}
.pl-12{
    padding-left:12px !important;
}
.pl-20{
    padding-left:20px !important;
}
.pl-25{
    padding-left:25px !important;
}
.pl-30{
    padding-left:30px !important;
}
.pl-35{
    padding-left:35px !important;
}
.pl-40{
    padding-left:40px !important;
}
.pl-50{
    padding-left:50px !important;
}
.pl-58{
    padding-left:58px !important;
}
.pr-10{
    padding-right:10px !important;
}
.pr-15{
    padding-right:15px !important;
}
.pr-20{
    padding-right:20px !important;
}
.pr-25{
    padding-right:25px !important;
}
.pr-30{
    padding-right:30px !important;
}
.pr-35{
    padding-right:35px !important;
}
.pr-40{
    padding-right:40px !important;
}

.pb-10{
    padding-bottom: 10px !important;
}
.pb-15{
    padding-bottom: 15px !important;
}
.pb-20{
    padding-bottom: 20px !important;
}
.pb-25{
    padding-bottom: 25px !important;
}
.pb-30{
    padding-bottom: 30px !important;
}
.pb-40{
    padding-bottom:40px !important;
}
.pb-100{
    padding-bottom:100px !important;
}
.pb-50{
    padding-bottom:50px !important;
}
.pb-130{
    padding-bottom:130px !important;
}
.pb-160{
    padding-bottom:160px !important;
}
.pb-230{
    padding-bottom:230px !important;
}

/*-- Margin Class --*/
.ml-05{
    margin-left:0.5px !important;
}
.ml-15{
    margin-left:15px !important;
}
.ml-35{
    margin-left:35px !important;
}
.ml-195{
    margin-left:195px !important;
}
.mt-05{
    margin-top:-0.5px !important;
}
.mt-12{
    margin-top:-12px !important;
}
.mt-50{
    margin-top:50px !important;
}
.mt-100{
    margin-top:100px !important;
}
.mb-10{
    margin-bottom:10px !important;
}
.mb-15{
    margin-bottom:15px !important;
}
.mb-45{
    margin-bottom:45px !important;
}
.mb-50{
    margin-bottom:50px !important;
}
.mb-55{
    margin-bottom:55px !important;
}
.mr-15{
    margin-right:15px !important;
}
.mb-20{
    margin-bottom:20px !important;
}
.mb-25{
    margin-bottom:25px !important;
}
.mb-30{
    margin-bottom:30px !important;
}
.mb-36{
    margin-bottom:36px !important;
}
.mb-60{
    margin-bottom:60px !important;
}
.mb-70{
    margin-bottom:70px !important;
}
.mb-80{
    margin-bottom:80px !important;
}
.my-20{
    margin-top:20px !important;
    margin-bottom:20px !important;
}
.my-30{
    margin-top:30px !important;
    margin-bottom:30px !important;
}
.mt-mr-mb-8{
    margin-top:8px !important;
    margin-right:10px !important;
    margin-bottom:8px !important;
}
.mt-55{
    margin-top:-55px;
}
.mt-50{
    margin-top:50px !important;
}
/*-- border bottom -- */
.border-none{
    border:none !important;
}
.bor-bottom{
    border-bottom:1px solid #eee;
}
.bor-top{
    border-top:1px solid #eee;
}
.border-top-1{
    border-top: 1px solid #dee2e6;
}
.border-bottom-1{
    border-bottom:1px solid #e6e4e4;
}
.border-left-1{
    border-left: 1px solid #dee2e6;
}
.border-bottom-2{
    border-bottom:2px solid #f3f2f2;
}
.border-1{
    border:1.5px solid #eee;
}
.border-right{
    border-right:1.5px solid #dee2e6;
}
.border-yellow{
    border: solid 1.5px #ef9630;
}

.border-top-0-10 {
    border-radius: 0px 0px 10px 10px !important;
}
  
.border-bottom-0-10 {
    border-radius: 10px 10px 0px 0px !important;
}
  
.background-white {
    background: #fff;
}
 
/*-- box shadow -- */
.bg-shadow-light{
    box-shadow: 0px 2px 10px rgba(0,0,0,.06) !important;
}
.bg-shadow{
    box-shadow: 0px 10px 30px rgba(0,0,0,.09) !important;
}
.bg-shadow-top{
    box-shadow: 0px -4px 20px rgba(0,0,0,.06) !important;
} 

/*-- quotes css --*/
.quotes{
    quotes:"‘";
    font-size: 12px;
    color: #9393AB;
    font-weight: 600;
    line-height: 15px;
}

/*-- Cursor pointer -- */
.pointer-none{
    pointer-events:none;
}
.cursor-pointer{
    cursor: pointer;
}
.cursor-default{
    cursor: default !important;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
 
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/*-- Header Section Css -- */
.main-header.bg-custom {
    background: #050421; 
}
.main-header.bg-landing{
    background:#f5f7fd;
}
.main-header .navbar-light .navbar-brand img{
    width:140px;
}
/*-- user Image blank class --*/
.user-profile-blank{
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 100%;  
} 
.main-header .navbar-nav .nav-link.nv-btn{
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-o-border-radius: 6px;
	transition: background-color 0.5s ease-in-out;
} 
.main-btn{
    padding:12px 15px;
    background-color: #10c89b;
    color:#fff;
    border:none;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-o-border-radius: 6px;
	transition: background-color 0.5s ease-in-out;
}
.main-btn:focus, 
.outline-btn:focus{
    outline:none;
}
.main-btn i{
    margin-left:5px;
} 
.outline-btn{
    border: 1px solid #ddd; 
    box-sizing: border-box;
    margin-right: 10px;
    padding: 12px 25px;
    color: #000;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-o-border-radius: 6px;
	transition: background-color 0.4s ease-in-out;
} 
.main-header .navbar-light .navbar-nav .nav-link{
    color: #fff;
    padding: 12px 15px;
}
.main-header.main-header-landing .navbar-light .navbar-nav .nav-link{
    color: #fff;
    padding: 12px 25px;
    font-size: 13px;
    font-weight: bold;
}
.main-header.main-header-landing .navbar-light .navbar-nav .nav-link:hover,
.main-header.main-header-landing .navbar-light .navbar-nav .nav-link:active,
.main-header.main-header-landing .navbar-light .navbar-nav .nav-link:focus{
    color:#10c89b;
}
.main-header.bg-landing .post_project{
    padding-left:40px !important;
}
.main-header.bg-landing .post_project::before{
    position: absolute;
    top: 50%;
    left: 2px;
    content: '';
    width: 100%;
    height: 18px;
    border-left: 1.5px solid #a9a9ad;
    transform: translateY(-10px);
}  
.main-header .nav-item i{
    font-size:16px;
}

/*-- Banner Section -- */
.banner-image img{
	max-width:100%;
}

/*-- SignIn & SignUp Modal -- */ 
.d_block{
    display:block;
} 
.custom_form .close_icon{
	position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    opacity: 1;
    font-size: 30px;
    z-index: 100;
    cursor: pointer;
    background:transparent;
}
.custom_form .modal-content{
	border-radius: 12px;
}
.custom_form .modal-body{
	padding:0px;
} 
.custom_form .remove-pd{
	padding:0px;
}
.custom_form .mob_background_image{
    display:none;
}
.custom_form .remove-pd{
    display: block;
}
.custom_form .login-background{
    background-image: url(../images/login_background_new.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 550px;
    border-radius: 2px 12px 12px 0px;
    margin-top: -1px;
    margin-bottom: -1px;
    background-color:#050421;
    -webkit-border-radius: 2px 12px 12px 0px;
    -moz-border-radius: 2px 12px 12px 0px;
    -ms-border-radius: 2px 12px 12px 0px;
    -o-border-radius: 2px 12px 12px 0px;
}
.custom_form .forget-background{
	background-image: url(../images/forget.png);
    background-size: 136%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 450px;
    border-radius: 2px 12px 12px 0px;
    margin-top: -1px;
    margin-bottom: -1px;
    background-color:#F3F3F5;
}
.custom_form .forget-background.forget-single-input{	
    min-height:390px;	
    background-position: -20px 0px;	
    background-size: cover;
}
.custom_form .user_bg{
    background:#040421;
}
.custom_form .user_modal{
    background:#fff;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}
.custom_form .login{
    padding:30px; 
}
.custom_form .login .react-tel-input input{
    width: 100%;
    font-size: 14px;
    height: 50px;
    padding-left: 41px;
    background: #f8fafe;
    border: 1.5px solid #e8ecf4;
    color: #000;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.custom_form .forget-padding{
    padding:30px 50px;
}
.custom_form .login h2{
    width:90%;
	font-size: 30px;
    font-weight:bold;
    padding: 34px 0px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.6px;
}
.custom_form .login .padding_top_bottom{
    padding:20px 0px;
}
.custom_form .dropdown_btn .indian-rup{
    border-radius: 4px;
    padding: 7px 10px;
    color: #333;
    border: 1.5px solid #00b665;
    position: relative;
}
.custom_form .dropdown_btn .indian-rup:after{ 
    color: #00b665;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f058';
    position: absolute;
    top: -7px;
    right: -7px;
    z-index: 10;
    background: #fff;
}
.custom_form .addCardImage{
    position: absolute;
    top:14px;
    right:55px;
    opacity: .3;
    filter: grayscale(1);
}
.custom_form .addCardImage:last-child{
    right:14px;
} 
.max-w-1600{
    max-width:1600px !important;
}
.max-w-1440{
    max-width:1440px !important;
}
.max-w-65{
    max-width: 65%;
}
.w-230{
    width:230px !important;
}
.w-270{
    width:270px !important;
}
.w-4{
    width:4% !important;
}
.w-5{
    width:5% !important;
}
.w-8{
    width:8%;
}
.w-10{
    width:10% !important;
}
.w-15{
    width:15% !important;
} 
.w-16{
    width:16% !important;
}
.w-20{
    width:20% !important;
}
.w-23{
    width:23% !important;
}
.w-25{
    width:25% !important;
}
.w-30{
    width:30% !important;
}
.w-35{
    width:35% !important;
}
.w-40{
    width:40% !important;
}
.w-45{
    width:45% !important;
}
.w-48{
    width:48% !important;
}
.w-50{
    width:50% !important;
}
.w-60{
    width:60% !important;
}
.w-65{
    width:65% !important;
}
.w-70{
    width:70% !important;
}  
.w-75{
    width:75% !important;
}
.w-80{
    width:80% !important;
}
.w-85{
    width:85% !important;
}
.w-90{
    width:90% !important;
}
.w-92{
    width:92% !important;
}
.w-93{
    width:92% !important;
}
.w-95{
    width:95% !important;
}
.w-96{
    width:96% !important;
}
.w-98{
    width:98% !important;
}
.w-130{
    width:130% !important;
}
.h-8{
    height:8px !important;
}
.h-30{
    height:30px !important;
}
.h-40{
    height:40px !important;
}
.h-46{
    height:46px;
}
.h-50{
    height:50px !important;
}
.min-h-100{
    height:100px !important;
}
.min-h-120{
    height:100px !important;
}
.min-h-100-percentage{
    min-height:100% !important;
}
.h-50{
    height:50px !important;
}
.min-h-100-percentage{
    min-height:100% !important;
}
.h-100{
    height:100% !important;
}
.h-p-100{
    height:100% !important;
}
.min-h-60{
    min-height:60px !important;
}
.min-h-80{
    min-height:80px !important;
}
.min-h-150{
    min-height: 150px;
}
.min-h-165{
    min-height: 165px;
} 
.custom_Modal_Box .modal-lg, .custom_Modal_Box .modal-xl {
    max-width: 1024px;
}
.forget_Modal_Box .modal-lg, .custom_Modal_Box .modal-xl {
    max-width: 800px;
}
.form-control:focus{
    outline: none;
    box-shadow: none;
}
.custom_form .label{
    font-weight: 600;
    color: #9393AB;
    font-size: 13px;
}
.custom_form .email{
    font-size: 14px;
    height: 50px;
    padding-left: 15px;
    background: #f8fafe;
    border: 1.5px solid #e8ecf4;
    color: #000;
    font-weight:500;
    border-radius:6px;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
    -ms-border-radius:6px;
    -o-border-radius:6px;
}
.custom_form .email:focus{
 	outline:none;
 	box-shadow: none;
}
.custom_form .email::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aeb3be;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Muli', sans-serif;
}
  
.custom_form .email::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #aeb3be;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Muli', sans-serif;
}
  
.custom_form .email::-ms-input-placeholder { /* Microsoft Edge */
    color: #aeb3be;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Muli', sans-serif;
}
.custom_form .option{
    color: #bcbbbb;
    transition:color 0.2s ease;
    -webkit-transition:color 0.2s ease;
    -moz-transition:color 0.2s ease;
    -ms-transition:color 0.2s ease;
    -o-transition:color 0.2s ease;
}
.custom_form .option i{
    cursor: pointer;
}
.custom_form .option i:hover{
    color: #333;
}
.custom_form .dropdown_btn .dropdown_icon{
    border: 1px solid #cecece;
    padding: 10px;
    border-radius: 50%;
    color: #848484;
    margin-left: 10px;
    cursor: pointer;
}
.justify-content-unset{
    justify-content: unset !important;
} 
.custom_form .get_start i{
	margin-left: 5px;
}
.custom_form .social_icon{
	padding: 60px 0px;
}
.custom_form .social_icon p{
    font-size: 18px;
    font-weight: 500;
    margin-bottom:0px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}
.margin_left_container{
    margin-left:19.5rem;
}

/* .social_desktop_none{
    display:none;
}  */
.social_platform{ 
    padding-right:15px;
    border-right: none; 
    font-weight: 800;
    font-size: 14px;
    display: inline-block;
    cursor: pointer;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border: 1px solid #f3f2f2;
    padding: 10px 15px;
    border-radius: 5px;
}
.social_platform:nth-child(2){
    border-right:none;
}
.social_platform .google_icon{
    cursor: pointer;
    width: 22px;
    margin-right: 6px;
    margin-top:-5px;
} 
.dot{
    position: relative;
}
.dot:before{
    content: '';
    height: 5px;
    width: 5px;
    background-color: #848484;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 3px;
}
.reCaptcha p{
	font-size: 13px;
    color: #585b63;
    padding: 0px 10px;
    opacity: 0.6;
}
.dflex{
	display: flex !important;
    align-items: center;
    justify-content: space-between;  
}
.here{
	font-size: 14px;
    color: #959595; 
}
.select-class{
    width: 50px;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 10px;
    color: #333;
    display: flex;
    align-items: center;
    font-size: 14px;
    z-index: 1000;
}
.select-class.top{
    top:32px;
}
.select-class i{
    padding-left:5px;
}
.select-class .icon{
    transform: rotate(-180deg);
    margin-left: 5px;
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
}
.custom_form .pl-extra{
    padding-left:50px;
} 
.country_dropdown{
    position: absolute;
    top: 40px;
    width: 15%;
    background-color: #edf9fe;
    display: none;
    overflow-x: auto;
    text-align: center;
    z-index: 1001;
    border-radius: 2px;
    -moz-box-shadow: 0 3px 5px 0 #dcdcdc;
    -webkit-box-shadow: 0 3px 5px 0 #dcdcdc;
    box-shadow: 0 3px 5px 0 #dcdcdc;
    max-height: 170px;
    border: 1px solid #edf9fe;
}
.country_dropdown.options{
    display: none;
    top: 55px;
    max-height: 135px;
    width: 12%;
} 
.country_dropdown::-webkit-scrollbar {
    width: 6px;
} 
.country_dropdown::-webkit-scrollbar-track {
    background: #f9f9f9; 
}

.country_dropdown::-webkit-scrollbar-thumb {
    background: #888; 
}
.country_dropdown .allcountries li{
    cursor: pointer;
}  
.country_dropdown .allcountries li:hover{
    background-color: #00b665;
    color:#fff;
}  
.radio_custom [type="radio"]:checked,
.radio_custom [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.radio_custom [type="radio"]:checked + label,
.radio_custom [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.radio_custom [type="radio"]:checked + label:before,
.radio_custom [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
.radio_custom [type="radio"]:checked + label:after,
.radio_custom [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #10c89b;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.radio_custom [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.radio_custom [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.custom_checkbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.custom_checkbox label {
    position: relative;
    cursor: pointer;
}
.custom_checkbox .password_screen{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
}

.custom_checkbox label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ddd; 
    padding: 8px;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 8px;
    transition: background-color 0.3s ease-in-out;
    transition: border-color 0.1s ease-in-out;
}
.custom_checkbox input:checked + label:before {
    content: '';
    background-color:#10c89b;
    border-color:#10c89b;

}
.custom_checkbox input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 5px;
    left: 7px;
    width: 6px;
    height: 11px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.custom_checkbox.custom_after input:checked + label:after {
    content: ''; 
    top: 6px; 
}
.sub_title{
	font-size: 12px;
    color: #9393AB;
    position: relative;
}
.sub_title a{ 
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #aeb3be;
}
.sub_title a:hover{
	text-decoration: none;
}
.sub_title:nth-child(2):before{
    content: '';
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 6px;
    left: 10px; 
}
.sub_title:nth-child(2):after{
    content: '';
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 6px;
    right: 10px; 
}

.sub_title.is-not-active:nth-child(2):after{
    display: none;
}

.sub_dflex{
	display:flex;
	align-items: center;
}
.overflow-content{ 
    max-height: 548px;
    overflow: hidden;
    overflow-y: auto;
}
.overflow-content.collacteral_height{
    /* max-height:calc(100vh - 470px); */
    max-height:calc(100vh - 415px);
}
.overflow-content.overflow-lightbox{
    max-height:calc(100vh - 100px);
    padding-bottom:0px;
}
.overflow-content.review_proposal{
    height:calc(100vh - 175px);
    max-height: calc(100vh - 175px);
    padding-bottom: 0px;
    margin-bottom:14px;
}
.overflow-content.full_screen_view{
    height:calc(100vh - 110px);
    max-height: calc(100vh - 110px);
    padding-bottom: 100px;
}
.overflow-content.incoming-height{
    max-height: calc(100vh - 120px);
    overflow: hidden;
    overflow-y: auto
}
.overflow-content.incoming-max-height{
    height:calc(100vh - 204px);
    max-height:calc(100vh - 204px);
}
.overflow-content.incoming-max-height.hide-buttons{
    height:calc(100vh - 140px);
    max-height:calc(100vh - 140px);
}
/* .overflow-content.project-height{
    height:calc(100vh - 215px);
    padding-top:20px;
    padding-bottom:20px;
    max-height: calc(100vh - 215px);
} */
.overflow-content.project-height{
    height:calc(100vh - 145px);
    padding-top:20px;
    padding-bottom:20px;
    max-height: calc(100vh - 145px);
}
.overflow-content.basicDetails-height{
    /* height:calc(100vh - 250px);
    max-height: calc(100vh - 250px); */
    height:calc(100vh - 180px);
    max-height: calc(100vh - 180px);
    padding-bottom:70px; 
}
.overflow-content.onBoarding-category{
    height:calc(100vh - 65px); 
    max-height: calc(100vh - 65px);
}
.overflow-content.project-details-height{
    height:calc(100vh - 165px);
    padding-bottom:40px;
    max-height: calc(100vh - 165px);
}
.overflow-content.company-height{
    height:calc(100vh - 160px);
    padding-bottom:40px;
    max-height: calc(100vh - 160px);
}
/* .overflow-content.company-height{
    height:calc(100vh - 160px);
    padding-bottom:40px;
    max-height: calc(100vh - 160px);
} */
/* .overflow-content.company-height{
    height:calc(100vh - 235px);
    padding-bottom:40px;
    max-height: calc(100vh - 235px);
} */
/* .overflow-content.budget-height{
    height:calc(100vh - 215px);
    padding-top:20px;
    padding-bottom:40px;
    max-height: calc(100vh - 215px);
} */
.overflow-content.project-height{
    height:calc(100vh - 145px);
    padding-top:20px;
    padding-bottom:40px;
    max-height: calc(100vh - 145px);
}
.overflow-content.budget-deadline{
    height:calc(100vh - 215px);
    padding-top:20px;
    padding-bottom:40px;
    max-height: calc(100vh - 215px);
}
/* .overflow-content.type-height{
    height:calc(100vh - 245px);
    padding-top:20px;
    padding-bottom:40px;
    max-height: calc(100vh - 245px);
} */
.overflow-content.type-height{
    height:calc(100vh - 170px);
    padding-top:20px;
    padding-bottom:40px;
    max-height: calc(100vh - 170px);
}
.overflow-content.project-category{
    height:calc(100vh - 140px); 
    max-height: calc(100vh - 140px);
}
.overflow-content.mob-milestone-modal{
    /* max-height:530px;  */
    max-height: calc(100vh - 100px);
}
.overflow-content.chat-height{
    height:calc(100vh - 245px);
    max-height:unset;
} 
.overflow-content.chat-height.chatting-box-overflow {
    /* height:calc(100vh - 185px); */
    /* for  summernote development */
    height:calc(100vh - 185px);
    padding-bottom:10px;
}
.overflow-content.chat-height.post-box-overflow {
    height:calc(100vh - 170px);
    max-height:600px;
}
.overflow-content.inComingBrief{
    height:calc(100vh - 105px);
    max-height:calc(100vh - 105px);
} 
.overflow-content.user-work-content{
    /* max-height:650px;*/
    min-height: 600px;
    max-height:calc(100vh - 35px);
    height:calc(100vh - 35px);
    background-color: #fff;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
} 
.overflow-content.basic-height{
    max-height:552px;
} 
/* .overflow-content.edit-collateral-height{
    min-height:500px;
} */
.overflow-content.edit-work-height{
    max-height:500px;
}
.overflow-content.chat-modal-height{
    max-height:450px;
}
.overflow-content.folder-modal-height{
    max-height:90%;
}
.overflow-content.chat-modal-height.creator-feedback{
    max-height:550px;
}
.overflow-content.chat-milestone-modal-height{
    max-height:550px;
    padding-bottom: 40px;
    margin-bottom:30px;
}
.overflow-content::-webkit-scrollbar {
  width: 5px;
} 
.overflow-content::-webkit-scrollbar-track {
  background: #f1f1f1; 
} 
.overflow-content::-webkit-scrollbar-thumb {
  background: #b7babc; 
} 
.user{
	display:flex;
	align-items: center;
}
.user img{
	width:100px;
	padding-right:10px;
}
.user_details p{
	margin: 0;
    color: #6b6f77;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
}
.user_details a{
    font-weight: 500;
    font-size:16px;
}
.user_details a:hover{
	text-decoration: none;
}
.user_details .verify-digit{
	font-size: 16px;
}
.user_details .verify_text{
    color: #333;
    padding-top: 2px;
} 
.join_user img{
    width:160px;
} 
.custom_form .join-content h2{
    padding-top: 10px !important;
    font-size: 30px !important;
    width:100%;
}
.join-content p{
    padding: 5px 0px 0px;
    font-size: 15px;
    color: #999393;
    font-weight: 500;
}

/*-- review img css -- */
/* .recommendation img{
    border-radius: 8px;
    width: 100%;
    height: 100px;
} */
.review_box{
    display: flex !important;
    align-items: center;
    width:100%;

}
.recommendation .recommendation_image{ 
    border-radius: 8px;
    width:134px;
    height:calc(134px / 1.333);
    overflow: hidden;
}
.recommendation .recommendation_image img{
    height:100%;
    object-fit:cover;
}
.recommendation .recommendation_image.recommendation_selection{
    width:80px;
    height:calc(80px / 1.333);
}
.rating .fa-star{
    color:#c3c0c0;
}
.re-view{
    width: 35px;
    height: 35px;
    text-align: center; 
    background-color: rgba(87, 87, 87, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
} 
.re-view img{
    width: auto;
    height: 35px;
    max-height: 35px;
    border-radius: 0px;
}
.ex-perience img{
    width: 25px;
    height: 25px; 
}
.profile_experience{
    width: 25px;
    height: 25px;
    text-align: center;
    border: solid 1px #ffffff;
    box-sizing: border-box;
    background-color:#fff;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
}
.profile_experience img{
    width: auto;
    height: 25px;
    max-height: 25px;
    border-radius: 0px;
}
.review{
    width: 25px;
    height: 25px;
    text-align: center; 
    background-color: rgba(87, 87, 87, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.review img{
    width: auto;
    height: 25px;
    max-height: 25px;
    border-radius: 0px;
}
.tools img{
    width:20px;
    height:20px;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
}
.under-review{
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

/*-- Select tags -- */
.selected-tags{
    background: #eff1f8;
    color: #54647c;
    font-size:12px;
    border-radius: 5px;
    margin:4px;
    display: inline-block;
}
.selected-tags i{
    cursor: pointer;
}
.select-tags{
    font-size: 14px; 
    background: #f8fafe;
    border: 1.5px solid #e8ecf4;
    color: #000;
    font-weight: 500;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;  
}
.tags-input{
    border:none !important;
    background:transparent !important;
    height:auto !important;
}    
    

/*-- Landing Page Css --*/
.landing-pd{
    padding: 70px 100px 0px;
}
.landing-headline h1{ 
    font-size: 3.5em;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.68px;
    color: #161314;
}
.landing-headline p{ 
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.35px;
    color: #6c6f76;
    margin:20px 0px 8px;
}
.landing-headline button{
    background-color: #10c89b;
    font-size: 21px; 
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: #ffffff;
    padding:15px 35px;
    border-radius:8px;
}
.landing-banner img{
    width:422px;
    height:302px;
}
.watch-idea{
    text-align: center;
}
.watch-idea h2{ 
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -1.05px;
    color: #161314; 
}
.watch-idea p{ 
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.35px;
    color: #6c6f76;
}
.box-content .card{
    border-radius: 8px;
    border-color:#eceaea;
}
/* .box-content .card.active{
    border-color:#eceaea;
} */
.mask{
    width: 100%;
    height: auto; 
    position: relative;
    overflow: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;  
} 
.mask-img{ 
    width:100%;
    height:239px;
    border-radius: 0px 0px 8px 8px;
}
.creator_1{
    background-image:url('./../images/idea11.png');
    background-size:cover;
    background-position:center;
    background-repeat: no-repeat;
    min-height:400px;
}
.creator_1.creator_2{
    background-image:url('./../images/idea22.png');
}
.creator_1.creator_3{
    background-image:url('./../images/idea33.png');
}
.mask-content{
    padding:40px;
}
.mask-content h3{
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    margin-bottom:10px;
}
.sub-text-title{
    margin: 0;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.48;
    letter-spacing: 0.12px; 
    color: #6b6f77;
}
.expect-content h3{
    font-size: 26px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom:10px;
}
.expect-icon{
    margin-top:8px;
    color:#00b665;
    font-size:17px;
}

/*-- Image Slider --*/
.creators_mobile_section .worked-with .company-icon img{
    width:20px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
}
.img-area{ 
    display: block;
    overflow: hidden; 
    margin:0 auto;
    border-radius: 6px;
    width: 370px;
    height: calc(370px / 1.333);
    max-width: 100%;
}
.img-area img{
    width:100%;
    height: 100%;
}
.profile_card{
    background-color: #fff;
    padding:8px;
    text-align:center; 
    border:1px solid #f0f3fa;
    border-radius: 10px; 
}
.creators_mobile_section .owl-dots{
    text-align: center;
}
.creators_mobile_section .owl-dot{
    display:inline-block !important;
    height:10px !important;
    width:10px !important;
    background-color: #ddd !important;
    opacity: 0.8;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    margin:50px 3px 5px;
}
.creators_mobile_section .owl-dot.active{
    background-color:#00b665 !important;
}
.creators_mobile_section .owl-theme .owl-dots .owl-dot span{
    display:none !important;
} 
.creators_mobile_section .owl-nav.disabled{
    display:block !important;
}
.creators_mobile_section .owl-dots.disabled{
    display:none !important;
}
.creators_mobile_section .owl-dots{
    display:none !important;
}
.creators_mobile_section .owl-carousel .owl-nav button.owl-next, 
.creators_mobile_section .owl-carousel .owl-nav button.owl-prev, 
.creators_mobile_section .owl-carousel button.owl-dot{
    font-size:50px;
    color:#b3b3b3 !important;
    cursor: pointer;
}
.creators_mobile_section .owl-prev{
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-100%);
}
.creators_mobile_section .owl-nav button:focus{
    outline:none !important;
}
.creators_mobile_section .owl-next{
    position: absolute;
    right: -50px;
    top: 50%;
    transform: translateY(-100%);
}
.featured-creators .owl-next,
.featured-creators .owl-prev{
    transform: translateY(-75%);
}
.creators_mobile_section .owl-theme .owl-nav [class*=owl-]:hover{
    background-color: transparent !important;
    color:#000 !important;
}
#owl-demo .owl-nav{
    display:none;
}
#owl-demo .owl-theme .owl-dots{
    margin-top:35px;
}
#owl-demo.re-view-slider button {
    width: auto;
    height: auto;
    border-radius: unset !important;
    line-height: 0 !important;
    font-size: 36px !important;
} 
#owl-demo.re-view-slider .owl-theme .owl-dots .owl-dot.active span, 
#owl-demo.re-view-slider .owl-theme .owl-dots .owl-dot:hover span{
    background-color:#00b665 !important;
}
#trusted-slider.owl-theme .owl-dots .owl-dot.active span, 
#trusted-slider.owl-theme .owl-dots .owl-dot:hover span{
    background-color:#00b665 !important;
}
/* .feature-img{
    width:60px !important;
    height:60px !important;
    border-radius: 100%;
    background: #fff;
    padding: 2px; 
    margin-top:10px;
    overflow: hidden;
}  */
.feature-img .feature_creator_img{
    width: 60px;
    height: 60px;
    text-align: center; 
    background-color: rgba(87, 87, 87, 0.15);
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:10px;
}
.feature-img .feature_creator_img img{
  width: auto;
  height: 60px;
  max-height: 60px; 
  object-fit: cover;
}
.feature-details h2{
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: -0.62px;
    color: #000000;
    margin-top:2px;
}
.feature-details span{
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.92;
    letter-spacing: -0.36px;
    color: #6c6f76;
} 
/*====== service page =====*/
/* .trusted.dashboard_trusted img{
    filter: grayscale(1) brightness(0.9) contrast(0.5);
} */
.trusted img{
    max-width:100px;
}
.trusted .boat-img{
    max-width:100px;
}
/* .landing-banner-image{
    background-color:#fff;
    background-image:url('./../images/landing_top_banner.svg');
    background-position:center -40px;
    background-size:100%; 
    background-repeat: no-repeat;
    position: relative;
} */
.landing-page-banner{
    position:relative;
    z-index: 3;
}
.landing-page-banner-cards{
    position:relative;
    z-index: 1;
}
.landing-page-video video{
    width:100%;
    position: absolute;
    top:-106px;
    transform: scale(1.1);
    z-index:1;
}
.all_service_banner{
    background-color:#fff;
    background-image:url('./../images/all_services.png');
    background-position:center;
    background-size:100%;
    min-height:500px;
    background-repeat: no-repeat;
    position: relative;
}
.all_service_banner.main_banner{
    background-image:url('./../images/main_banner.svg');
}
.all_service_banner.footer_banner{ 
    background-image:url('./../images/footer_banner.svg');
    background-size: 80%;
    min-height: 450px;
}
.landing_prev,
.landing_next{
    position: absolute; 
    top: 50%;
    transform: translate(0%, -100%);
    font-size: 20px;
    color: #b3b3b3;
    cursor: pointer;
}
.landing_prev:hover,
.landing_next:hover{
    color:#000;
}
.landing_prev{
    left:-1%;
}
.landing_next{
    right:1%;
}
.expert_creators .nav{
    justify-content:center;
}
.expert_creators .nav-tabs{
    border:none;
}
.expert_creators .nav-link{
    padding: 0.8rem 1.6rem;
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    color:#a2a6ac;
    box-sizing: border-box;
}
.expert_creators .nav-tabs .nav-item.show .nav-link, 
.expert_creators .nav-tabs .nav-link.active{
    color: #000;
    background-color: #fff;
    border-color: #fff;
    font-weight:700;
    box-shadow: 0px 2px 10px rgba(0,0,0,0.05);
    border-bottom: 3px solid #10c89b;
    box-sizing: border-box;
} 
.contact_page{
    width:100%;
    height:calc(100vh - 70px);
}

/*--- Privary policy page -----*/
.privacy_points{
    padding: 0px 20px;
}
.privacy_points li{ 
    padding-left: 3px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 15px;
    list-style-type: disc;
    line-height: 1.6;
} 
.privacy_points.extra_points li{
    margin-bottom:5px;
}

/*===== Brands Trust CSS =====*/
.brands-trust{
    padding:0 20px;
    margin-bottom:25px;
}
.brands-trust p{
    margin-bottom:25px;
}
.brands-trust .underline{
    border-bottom:2px solid #eee;
}

/* ===== Services CSS ===== */
.parent-category-menu{
    overflow: hidden;
    position: relative;
    max-width: 1300px;
    margin:auto;
}
.parent-category-inner{
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
}
/* width */
.parent-category-inner::-webkit-scrollbar {
    width: 10px;
    height:8px;
}
  
  /* Track */
.parent-category-inner::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
  /* Handle */
.parent-category-inner::-webkit-scrollbar-thumb {
    background: #888; 
}
  
  /* Handle on hover */
.parent-category-inner::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
.parent-category-inner .category-menu{ 
    padding-bottom: 20px;
    margin: auto;
    max-width: 100%;  
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.parent-category-menu .category-menu-search{
    display: block; 
    margin-bottom:20px;
}
.parent-category-menu .category-menu-search .search-field{
    display: inline-block;
    width: 0%;
    opacity: 0;
    margin-right: 0;
    transition: all .4s;
}
.parent-category-menu .category-menu-search .search-field.active{
    width:100%;
    opacity:1;
    /* max-width: 360px; */
    max-width:100%;
}
.parent-category-menu .category-menu-search .search-field .search-field-input{
    font-size: 19px; 
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0; 
    border-radius: 5px;
    -webkit-appearance: none;
    border: 2px solid #e6e6e6;
    padding: 22px 35px 22px 20px;
    background: #fff;
    width:100%;
}
.parent-category-menu .category-menu-search .search-field .search-field-input:focus{
    outline:none;
}
.parent-category-menu .category-menu-search .search-field .search-field-input::placeholder{
    font-weight: 700;
}
.parent-category-menu .category-menu-search-button{ 
    padding: 23px 30px;
    border-radius: 5px;
    background: #e6e6e6;
    margin-left:10px;
    margin-right: 10px;
    border: 2px solid #e6e6e6;
    position: relative;
    top: -2px;
} 
.parent-category-menu .category-menu-search-button i{
    font-size: 18px;
}
.parent-category-menu .category-menu-search-button:hover,
.parent-category-menu .category-menu-search-button.active{
    background-color:#10c89b;
    border-color:#10c89b;
}
.parent-category-menu.category-menu-search-button:hover i,
.parent-category-menu .category-menu-search-button.active i{
    color:#fff;
}
.category-menu .category-menu-item{
    display:inline-block;
    margin-right: 16px;
    padding: 18px 30px;
    border-radius: 5px;
    border: 2px solid #e6e6e6;
    background-color: #efefef;
    color:#000;
    font-size:18px;
    font-weight:700;
    transition:0.3s all ease;
}
.category-menu .category-menu-item:last-child{
    margin-right:0;
}
/* .category-menu .category-menu-item:hover,
.category-menu .category-menu-item.active{
    background-color:#10c89b;
    border-color:#10c89b;
    color:#fff;
} */
.services_card{
    display: flex;
    flex-wrap: wrap;
    align-items: center; 
    justify-content:center;
}
.services_card a{
    margin-right:1%;
    margin-bottom:0.5%;
}
.services_card .service-box-logo{
    width: 360px;
    height: calc(360px / 1.3333);
    margin-right: 1%;
    margin-bottom: 1%;
    border: 1px solid #f3f2f2;
    box-sizing: border-box;
    position: relative;
    background:#f3f2f2;
    border-radius: 10px;
    display:inline-block;
} 
.custom-overlay .back-modal {
    z-index: 99999;
}