// select_Revlent_project
.view_brief_popup{
    .view_brief_popup_modal_dialog{
        max-width:100vw;
        @media(min-width:$breakpoint-sm){
            max-width: calc(100vw - 150px); 
        }
        @media(min-width:$breakpoint-lg){
            max-width: calc(100vw - 900px); 
        }
         
        .modal_top_header {
         padding: 2.5rem 2.7rem 1.5rem;
        }
        .modal-title{
            font-size: 1.429rem;
            font-weight: 800;
            color:$text-black-700;
            line-height: 1.714rem;
        }   
        .close_btn{
            opacity:1.5;
        }

        .proposal_header{
            padding:1rem 1rem; 
            min-height: 4.9rem;
        }
  
        .proposal_date{
            .date_input{
                position: relative; 
                .date_icon{
                    position:absolute;
                    top: 37%;
                    right: 8%;
                }

            }
        }
        // cards
        .view_proposal_card{
            background-color: $bg-blue-200;
            border: 0px;
            box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
            border-radius: 0.429rem;
            position: relative;
            .card_tag{
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        .description{
            font-size: 0.857rem;
            font-weight: 600;
            line-height: 1.071rem;
            color:$text-gray-1300;
        }
        
        .url_input{
            position: relative;
            padding: 0rem 0rem;
            color:$text-blue-800;
            text-decoration: underline;
            ::placeholder{
                text-decoration: none;
            }
        }
       
        .import{
            background: rgba($bg-gray-300, 0.15);
            border-radius:0.429rem;
            padding: 0.9rem;
        }
        .project_description {
          .description{
                color: rgba($text-black-700, 0.5);
                font-size: 1rem;
                font-weight: 600;
          }
            
        }
        // tabs
        .cstm_tab .nav-link{
            border: 0rem;
            // border-bottom: 4px solid $bg-blue-800 ;
            padding: 1rem 1rem;
            margin-right: 3rem;
            font-size: 1.1rem;
            font-weight: 600;
            color:$text-gray-1300;
        }
        .cstm_tab .nav-link.active{
            border-bottom: 0.286rem solid $bg-blue-800 ; 
            color: $text-blue-800;
        } 
        
        // body_scroll_bar
        .body_scroll::-webkit-scrollbar {
            width: 0.313rem;
        }
        .body_scroll::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .body_scroll::-webkit-scrollbar-thumb {
            border-radius:0.375rem;
            background-color:$border-gray-400;
        }
    
    }

}