.client_version_history{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .client_version_history_container{
        padding:2rem;
       
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
      
            .proposal_date{
                .date_input{
                    position: relative; 
                    .date_icon{
                        position:absolute;
                        top: 37%;
                        right: 8%;
                    }

                }
            }
            // description
            .description{
                font-size: 0.857rem;
                font-weight: 600;
                line-height: 1.071rem;
                color:$text-gray-1300;
            }
            // list
            .history_list{
                .text_gray:hover{
                    color: $text-gray-1200;
                }
                .list_item{
                    padding-top:2rem;
                    padding-bottom: 2rem;
                }
            }  
            

            // leftside
            .user_profile{
                img{
                    object-fit: cover;
                    width: 100px;
                }
            }
            
            //dropedownbox
            .dropedown_container.show{
            right: 0px;
            }
            .dropedown_container{
                .btn:focus {
                    outline: 0;
                    box-shadow:none;
                } 
            }
            .dropedown_icon{
                display: block;
                margin-bottom: 0.3rem;
            }
            .dropdown-menu{
                border-radius: 0.571rem;
                box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
            }
            .dropdown-item{
                padding:0.6rem 1rem;
            }
            .dropdown-menu.show{
                top: -33px !important;
                left: -133px !important;
            }
          
        
    }
} 