.version_history{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .version_history_container{
        padding:2rem;
            // header
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
            // description
            .description{
                font-size: 0.857rem;
                font-weight: 600;
                line-height: 1.071rem;
                color:$text-gray-1300;
            }
            // list
            .history_list{
                .text_gray:hover{
                    color: $text-gray-1200;
                }
                .list_item{
                    padding-top:2rem;
                    padding-bottom: 2rem;
                }
            }
            //dropedownbox
            // .dropedown_container.show{
            // right: 0px;
            // }
            // .dropedown_container{
            //     .btn:focus {
            //         outline: 0;
            //         box-shadow:none;
            //     }
            // }
            // .dropedown_icon{
            //     display: block;
            //     margin-bottom: 0.3rem;
            // }
            // .dropdown-menu{
            //     border-radius: 0.571rem;
            //     box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
            // }
            // .dropdown-menu.show{
            //     top: -33px !important;
            //     left: -133px !important;
            // }
           // body_scroll_bar
                .body_scroll::-webkit-scrollbar {
                    width: 0.313rem;
                }
                .body_scroll::-webkit-scrollbar-track {
                    background-color: transparent;
                }
                .body_scroll::-webkit-scrollbar-thumb {
                    border-radius:0.375rem;
                    background-color:$border-gray-400;
                }
                        
    }
} 