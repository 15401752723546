



@media screen and (min-width: 1160px) {
    .plus{
        position:absolute;
            top:14.5rem;
             right:1rem
    }
    
  }

 
@media screen and (min-width: 1078px) and (max-width: 1160px) {
    .plus{
        position:absolute;
            top:15.5rem;
             right:1rem
    }
    
  }
 
  @media screen and (min-width: 1032px) and (max-width: 1078px) {
    .plus{
        position:absolute;
            top:16.9rem;
             right:1rem
    }
    
  }
  @media screen and (min-width: 794px) and (max-width: 1031px) {
    .plus{
        position:absolute;
            top:14.2rem;
           right: 1rem;
    }
    
  }

  @media screen and (min-width: 768px) and (max-width: 793px) {
    .plus{
        position:absolute;
            top:15.4rem;
           right: 1rem;
    }
    
  }


  @media screen and (min-width: 644px) and (max-width: 767px) {
    .plus{
        position:absolute;
            top:14.4rem;
           right: 1rem;
    }
    
  }




  @media screen and (min-width: 568px) and (max-width: 644px) {
    .plus{
        position:absolute;
            top:15.5rem;
           right: 1rem;
    }
    
  }
  @media screen and (min-width: 394px) and (max-width: 568px) {
    .plus{
        position:absolute;
            top:15.5rem;
           right: 1rem;
    }
    
  }
  @media screen and (min-width: 356px) and (max-width: 394px) {
    .plus{
        position:absolute;
            top:16.8rem;
           right: 1rem;
    }
    
  }





  @media screen and (min-width: 208px) and (max-width: 356px) {
    .plus{
        position:absolute;
            top:18rem;
           right: 1rem;
    }
    
  }
 
 
 
 
