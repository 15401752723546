.worklog_actual_upload_filled_wrappper{
    .cstm_checkbox{
        position: absolute;
        top: 1rem;
        left: 1rem !important;
    }

     // dropedown
     .dropedown_input{
        position: relative;
        cursor: pointer;
       
        .dropedown_icon{
            position:absolute;
            top:42%;
            // right:1.429rem;
            right:2rem;
            &.error_icon{
                // top:30%;
                top:42%;
            }
        }
        .dropdown_menu{
            position: absolute;
            width: 100%;
            left: 5px;
            max-width: 92%;
            margin: auto;
            transform: translate(0.875rem, 0rem); 
            z-index:1000;
            overflow: hidden;
            max-height: 300px;
            overflow-y: auto;

            .dropdown_inner{
                min-height: 400px;
                height: 400px;
                overflow-y:auto;
                border-radius: 0.482rem;
                border: 0.071rem solid $text-gray-1000;
                padding: 1rem;
                background-color: $bg-white;
            }
            .dropdown_item{
                position: relative;
                cursor: pointer;
                &:hover{
                    background-color: $bg-blue-200;
                }
               

                input, p{
                    border:0;
                    border-bottom: 0.071rem solid $text-gray-1000;
                    min-height:5rem;
                    height:5rem;
                    padding:1rem;
                    padding-left: 4rem;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        } 
    }
}