.retainer_open{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .retainer_open_container{
        padding:2rem;
            // left_side
            // .proposal_header{
            //     padding:1rem 1rem; 
            //     min-height: 4.9rem;
            // }
      
            .proposal_date{
                .date_input{
                    position: relative; 
                    .date_icon{
                        position:absolute;
                        top: 37%;
                        right: 8%;
                    }

                }
            }
            .description{
            font-size: 0.857rem;
            font-weight: 600;
            line-height: 1.071rem;
            color:$text-gray-1300;
            }
            .dropedown_field{
                position: relative;
                .down_icon{
                    position: absolute;
                    top: 50%;
                    right: 10%;
                    @media(max-width:$breakpoint-xl){
                            top: 44%;
                    }
                }

                &.select_dropdown{
                    min-width: 11rem;

                    .css-yk16xz-control,
                    .css-1pahdxg-control{
                        height:46px;
                        border-color:#E1E5EB;
                        font-size: 1rem;
                        font-weight: 500;
                    }
                    .css-1pahdxg-control:hover,
                    .css-1pahdxg-control:focus{
                        height:46px;
                    }
                }
            }
           
            .input_field{
                // padding-left: 2.5rem;
                padding-left: 3rem;
                @media(max-width:$breakpoint-xl){
                    padding-left: 1.5rem;
                }
            }
            .duration_fields{
                position: relative;
                .price_icon{
                    position: absolute;
                    top: 40%;
                    left: 20%;

                    &.error_price_icon{
                        top:40%;
                    }
                    @media(max-width:$breakpoint-xl){
                            position: absolute;
                            top: 40%;
                            left: 20%;
                      
                    }
                }

            }
            
            .number_btn{
                .btn-group{
                    width: 8rem;
                    height: 3.5rem;
                    border-radius: 0.3rem;
                    padding: 0rem;
                    border: 0.1rem solid $text-gray-1000;
                    border-radius: 0.3rem;
                    @media(max-width:$breakpoint-xl){
                        height: 3rem;  
                    }
                    input{
                        z-index: 100;
                        border-color: solid $text-gray-1000 ;
                        border-width: 0rem 0.1rem;
                        height: 3.5rem;
                        min-height: 3.5rem;
                        min-width: 2rem;
                        border-radius: 0rem;
                        padding: 0rem;
                        @media(max-width:$breakpoint-xl){
                            min-height: 2.8rem;
                            height: 2.8rem;
                        }
                    }
                    .btn{
                        font-size: 2rem;
                        font-weight: 500;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        background-color:$text-white;
                    }
                    .btn:nth-child(1){
                        color:$text-black-600; 
                    }
                    .btn:nth-last-child(1){
                        color:$text-blue-800;  
                    }

                }

            }
            .circle_btn{
                color: #fff;
                background-color: $bg-blue-800;
            }
            // payment_terms
            // tootle_btn on/off   
            .toggle_btn{
                .switch {
                    position: relative;
                    display: inline-block;
                    width: 2.429rem;
                    height: 1.571rem;
                }
                .switch input { 
                    opacity: 0;
                    width: 0;
                    height: 0;
                }
                .slider {
                    position: absolute;
                    cursor: pointer;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color:$text-gray-1300;
                    -webkit-transition: .4s;
                    transition: .4s;    
                }
                .slider:before {
                    position: absolute;
                    content: "";
                    height: 1.143rem;
                    width: 1.143rem;
                    left: 0.143rem;
                    bottom: 0.214rem;
                    background-color:$text-white;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
                input:checked + .slider {
                    background-color: $bg-blue-800;
                }
                
                input:focus + .slider {
                    box-shadow: 0 0 0.071rem $bg-blue-800;
                }
                
                input:checked + .slider:before {
                    -webkit-transform : translateX(1.071rem);
                    -ms-transform : translateX(1.071rem);
                
                }
                /* Rounded sliders */
                .slider.round {
                    border-radius: 2.429rem;
                }
                
                .slider.round:before {
                    border-radius: 50%;
                }
            }
            .advance_toggle_btn{
                line-height: 0rem;
                p{
                    color:$bg-blue-800;
                    font-size: 1rem;
                }
            }
           
            
            // add_refrence
            //attachments
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1rem 0rem;
                margin-right: 3rem;
                font-size: 1.1rem;
                font-weight: 600;
                color:rgba($color:$text-black-700, $alpha:0.5);
                cursor: pointer;
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 
         
            .upload_image_box{
                .upload_image{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    background-color: #F6F8FD;
                    // margin-right: 1rem;
                    // width: 12rem;
                    // aspect-ratio: 4/3;
                    // min-width:8.5rem;
                    min-height:6.7rem;
                    object-fit: cover;
                    border-radius: 0.286rem;
                    position: relative;
                    @media(max-width:$breakpoint-xs){
                        margin-bottom: 1.5rem;
                    }
                    .image{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right:0;
                        bottom:0;
                        width: 100%;
                        height:100%;
                        object-fit: cover;
                    
                    }
                }
                .upload_image_blank{
                    border: 0.2rem dashed $border-blue-800;
                }
                .upload_image:nth-last-child(1){
                    background-color: $bg-blue-200; 
                    justify-content: center;
                }
                // .upload_trash_icon{
                //     position: absolute;
                //     top:17%;
                //     left:17%;

                 //}
            }
            .url_input{
                position: relative;
                padding: 0rem 3.2rem;
                color:$text-blue-800;
                // text-decoration: underline;
                ::placeholder{
                    text-decoration: none;
                }
            }
            //upload attachment hover
            .delete_active{
                display:none;
            }
            .upload_image_delete:hover .delete_active{
                display:flex;
                cursor: pointer;
            }
            // add_milestone
            .add_on_milestone{
                
                color:$bg-blue-800;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.289rem;
                .add_on_milstone_icon{
                    margin-right:0.8rem;
                    font-size: 1.5rem;
                }
            }
            .input_content{
                position:absolute;
                top:34%;
                left:0%;
                &.error_input_image{
                    top:15px;
                }
            }
            .add_url button{
                background: transparent;
                border: none;
                position: absolute;
                top: 32%;
                right: 2%;
                font-size: 1rem;
                color:$text-blue-800;
                &.error_button{
                    top:15px;
                }
            }
            .upload_notes{
                min-height: 10.143rem;
                background-color: #fff;
                border: 0.071rem solid $border-gray-500;
                border-radius:0.426rem;
                padding: 0.714rem;
                ::placeholder{
                    color: rgba($color: #53637B, $alpha: 0.5);
                }
            }
            // right_side

            // tootltip
            // .tool::before {
            //     border-style: solid;
            //     border-width: 1em 0.75em 0 0.75em;
            //     border-color: $bg-gray-1000 transparent transparent transparent;
            //     bottom: 100%;
            //     content: "";
            //     margin-right: -50em;
            //     transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.65s 0.5s;
            //     transform:rotate(90deg) translateY(-8rem) translateX(1.5rem);
            //         @media(max-width:$breakpoint-md){
            //             transform: rotate(270deg) translateY(4rem) translateX(-12.5rem); 
            //         }
            // }
            // .tool::after{
            //     text-align:left;
            //     font-size:1.2rem;
            //     background: $bg-gray-1000;
            //     bottom: 94%;
            //     color: #EDEFF0;
            //     content: attr(data-tip);
            //     border-radius: 0.8rem;
            //     margin-left:-0.9em;
            //     padding: 1em;
            //     transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
            //     transform:scale(0.7) translateY(87%) translateX(-1.988rem);
            //     width: 17em;
            //     min-width:5.625rem;
            //         @media(max-width:$breakpoint-md){
            //             transform: scale(0.7) translateY(263%) translateX(-1.688rem);   
            //         }
            // }

            
            
   
    }
} 