.client_appprovepay_wrapper{
    .payment_profile_card {
      background-color: $bg-blue-200;
    }
    .total_details_card{
      background-color: $bg-blue-200;
      .amount_tag{
        min-height: 5rem;
      }
    }
   
}