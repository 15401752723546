.retainer_worklog_payment_wrapper{
    .paying_cycle_list{
        margin-left: 1.2rem;
     
            li {
                list-style: upper-alpha;
                padding-left: 1.2rem;
            }

    }
    .email_grouped{
        margin-top:-1rem;
        .email_group_prepend{
            .input-group-text{
                // border-bottom-left-radius: 0.429rem !important;
                // border-top-left-radius: 0.429rem !important;
                background-color: $bg-blue-600;
                border-radius: 0.429rem !important;
            }
        
        }
    }
}
