.finalreviewrapper {
    .finalreview_inner {
        padding: 0rem 2.7rem;
    }

    .feedback_header {
        .feedback_header_inner {
            padding: 1.7rem 1.5rem;
            position: relative;
            color: $text-gray-1300;
            font-weight: 700;
            font-size: 1.143rem;

            &:first-child {
                &::after {
                    content: '';
                    background-image: url("../../upgrade-images/creator_active_brief_chat_project_room/next.png");
                    background-repeat: no-repeat;
                    background-size: auto;
                    width: 20%;
                    height: 100%;
                    font-size: 9px;
                    position: absolute;
                    right: 0px;
                    top: 0px;

                }
            }

        }
    }

    .feedback_header_inner.active {
        color: $text-blue-800;
    }

    .feedback_header_inner.completed {
        color: $text-drak-green-500;
    }

    .star {
        width: 40px;
        height: 40px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            ;
        }
    }

    // Custom Input
    .check_box {
        position: relative;

        .cstm_checkbox {
            position: absolute;
            top: 0rem;
            right: 0rem;
        }

        .cstm_checkbox input {
            padding: 0;
            height: initial;
            width: initial;
            display: none;
            cursor: pointer;
        }

        .cstm_checkbox label {
            position: relative;
            cursor: pointer;
            color: $text-black;
            font-size: 1.1rem;
            margin-bottom: 1rem;
        }

        .cstm_checkbox label:before {
            content: '';
            background-color: transparent;
            border: 0.1rem solid $text-gray-300;
            padding: 11px;
            border-radius: 0.429rem;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 0px;
            transition: background-color 0.3s ease-in-out;
        }

        .cstm_checkbox input:checked+label::after {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 8px;
            width: 8px;
            height: 12px;
            border: solid $bg-white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);

            @media(max-width:$breakpoint-md) {
                top: 3px;
            }
        }

        .cstm_checkbox input:checked+label::before {
            content: '';
            background-color: $bg-blue-800;
            border-color: $bg-blue-800;
        }
    }

    //==================== end checkbox ====================================
}