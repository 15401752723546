/*===== Google Font =====*/
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    outline:none;
    list-style: none;
    text-decoration: none;
}

/*===== Global Variable =====*/
@import "./variable"; 

/*===== General CSS =====*/
body,html{
    font-family: $primary-font;
    font-size: 14px;

    @media (max-width:$breakpoint-md){
        font-size: 10px;
    }
} 
h1,h2,h3,h4,h5,h6,p,button{
    font-family: $primary-font;
}
.btn.focus, .btn:focus{
    box-shadow: none;
    outline:none;
}
.error_message{
    margin-bottom:0;
    font-weight:500;
    color:red;
    font-size: 1rem;
}
.file_input{
    position: absolute; 
    opacity: 0;
    min-height: 100%;
    cursor: pointer;
}
.file_input_upload{
    position: absolute; 
    opacity: 0;
    height: 109px;
    width:118px;
    cursor: pointer;
}
.cursor_pointer{
    cursor: pointer;
}