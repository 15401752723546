// Common Classes For The Whole Website
.btn-common{
    @include button();
}
.btn_common_sm{
    @include button($size:0.75rem 2rem);
}
.btn_common_md{
    @include button($size:0.75rem 4rem);
}
.btn_common_lg{
    @include button($size:0.75rem 5rem);
}

// btn white
.btn-common-white{
    @include button($textColor: $text-blue-800, $backgroundColor: $bg-white);
}
// btn blue
.btn-common-blue{
    @include solid_button($textColor:$text-blue-800, $backgroundColor:$bg-blue-100);
}

// btn linnk
.btn-link-text-gray-1500{
    @include button_link($text-gray-1500);
}
.btn-link-text-red-500{
    @include button_link($textColor: $text-red-500, $backgroundColor: $bg-white);
}
.btn-link-text-primary{
    @include button_link($text-primary);
}
// btn danger
.btn-common-danger{
    @include button($textColor: $text-white, $backgroundColor: $bg-red-500);
}
.btn_common_danger_sm{
    @include button($size:0.75rem 2rem,$textColor: $text-white, $backgroundColor: $bg-red-500);
}
.btn_common_md_danger{
    @include button($size:0.75rem 4rem,$textColor: $text-white, $backgroundColor: $bg-red-500);
}
// btn gray
.btn_common_gray{
    @include button($textColor: $text-white, $backgroundColor: $bg-gray-200);
}
.btn_common_gray_md{
    @include button( $size:0.75rem 4rem, $textColor: $text-gray-1300, $backgroundColor: $bg-white);
}
// btn outline
.btn_outline{
    @include button_outline()
}
.btn_outline_sm{
    @include button_outline(0.75rem 2rem);
}
.btn_outline_md{
    @include button_outline($size:0.75rem 4rem)
}
.btn_outline_lg{
    @include button_outline();
    padding:0.75rem 5rem;
}
.btn_reject_outline{
    @include button_outline($size:0.75rem 1rem,$textColor: $text-red-500, $backgroundColor:$bg-white, $bdr_color:$border-white,$hoverbackgroundColor:$bg-red-500,$hovertextColor:$text-white,$f_size:1rem, $weight:700);
    padding:0.75rem 5rem;
}
// main_page_container
.main_page_container{
    @include main_page();
}

// font_style
.font_th{
    @include font();
}
// white
.font_lg_white{
    @include font($size:1.286rem, $fontcolor:$text-white, $weight:700);
}
.font_xs_white_w_700{
    @include font($size:0.714rem, $fontcolor:$text-white, $weight:700); 
}
.font_xxs_white{
    @include font($size:0.3rem, $fontcolor:$text-white, $weight:700); 
}
.font_xs_white_w_600{
    @include font($size:0.714rem, $fontcolor:$text-white, $weight:600); 
}


// black
.text_black_lg_600{
    @include font($size:1.143rem, $fontcolor:$text-black, $weight:600); 
}
// text-yellow-500
.text_yellow_md_500{
    @include font($size:1rem, $fontcolor:$text-yellow-500, $weight:600);
}
.text_yellow_sm_600{
    @include font($size:0.857rem, $fontcolor:$text-yellow-500, $weight:600);
}
// text-drak_green
.text_dark_green_500_sm_w_500{
    @include font($size:0.857rem, $fontcolor:$text-drak-green-500, $weight:500);
}


// Text Color Classes

// text-black
.text-black{
    color:$text-black;
}
.text-black-700{
    color:$text-black-700;
}
.text-black-800{
    color:$text-black-800;
}
.text-black-500{
    color:$text-black-500
}

// text-gray
.text-gray-500{
    color:$text-gray-500;
}
.text-gray-1200{
  color:$text-gray-1200;
}
.text-gray-1300{
    color:$text-gray-1300;
}
.text-blue-800{
    color:$text-blue-800;
}
.text_primary-200{
    color:$text-primary !important;
}
.text-primary-100{
    color:$text-primary-100
}
.text-gray-1100{
    color:$text-gray-1100;
}
// text red
.text-red-500{
    color:$text-red-500;
}



// bg color
.bg-gray-500{
    background-color:$bg-gray-500
}
.bg-blue-200{
    background-color:$bg-blue-200;
}




// text-black-700
.text_black_xxl_w_800{
    @include font($size:1.714rem, $fontcolor:$text-black-700, $weight:800);
}
.text_black_lg{
    @include font($size:1.429rem, $fontcolor:$text-black-700, $weight:700);
}
.text_black_md{
    @include font($size:1.143rem, $fontcolor:$text-black-700, $weight:700);
}
.font_semi_bold{
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:700);
}
.text_black_xs{
    @include font($size:0.857rem, $fontcolor:$text-black-700, $weight:700);
}
.text_black_700_w_600{
    @include font($size:1.143rem, $fontcolor:$text-black-700, $weight:600);
}
.font_semi_bold_w_600{
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:600);
}
.text_black_sm{
    @include font($size:0.9rem, $fontcolor:$text-black-700, $weight:600);
}
.text_black_xs_w_600{
    @include font($size:0.857rem, $fontcolor:$text-black-700, $weight:600);
}

.text_black_xs_w_500{
    @include font($size:0.857rem, $fontcolor:$text-black-700, $weight:500);
}
.font_semi_bold_w_400{
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:400);
}
.font_text-black-700_md_w_400{
    @include font($size:0.857rem, $fontcolor:$text-black-700, $weight:400);
}
//text-black-500
.text_black_500_xl_w_700{
    @include font($size:1.286rem, $fontcolor:$text-black-500, $weight:700); 
}
.text_black_500_lg{
    @include font($size:1.143rem, $fontcolor:$text-black-500, $weight:700); 
}
.text_black_500_sm_w_600{
    @include font($size:0.857rem, $fontcolor:$text-black-500, $weight:600); 
}
// primary
.text_highlight_md{    
    @include font($size:1.143rem, $fontcolor:$text-primary, $weight:700);
}
.text_highlight{    
    @include font($size:1rem, $fontcolor:$text-primary, $weight:700);
}
.text_highlight_xs{    
    @include font($size:0.714rem, $fontcolor:$text-primary, $weight:700);
}
.text_highlight_w_600{    
    @include font($size:1rem, $fontcolor:$text-primary, $weight:600);
}
.text_highlight_sm_w_600{    
    @include font($size:0.857rem, $fontcolor:$text-primary, $weight:600);
}
// primary-100
.text_primary_100_w_600{    
    @include font($size:1rem, $fontcolor:$text-primary-100, $weight:600);
}
// gray
.text_gray_lg_w_800{
    @include font($size:2rem, $fontcolor:$text-gray-1200, $weight:800);
}
.text_gray_md_w_700{
    @include font($size:1.143rem, $fontcolor:$text-gray-1200, $weight:700);
}
.text_gray{
    @include font($size:1rem, $fontcolor:$text-gray-1200, $weight:700);   
}
.text_gray_sm{
    @include font($size:0.9rem, $fontcolor:$text-gray-1200, $weight:700);
}
.text_gray_xs_w_700{
    @include font($size:0.857rem, $fontcolor:$text-gray-1200, $weight:700);
}
.text_gray_md_w_600{
    @include font($size:1.143rem, $fontcolor:$text-gray-1200, $weight:600);
}
.text_gray_w_600{
    @include font($size:1rem, $fontcolor:$text-gray-1200, $weight:600);
}
.text_gray_xs_w_600{
    @include font($size:0.857rem, $fontcolor:$text-gray-1200, $weight:600);
}
.text_gray_xxs_w_600{
    @include font($size:0.714rem, $fontcolor:$text-gray-1200, $weight:600);
}
.text_gray_w_500{
    @include font($size:1rem, $fontcolor:$text-gray-1200, $weight:500);
}
.text_gray_md_w_500{
    @include font($size:1.143rem, $fontcolor:$text-gray-1200, $weight:500);
}
.text_gray_xs_w_400{
    @include font($size:0.857rem, $fontcolor:$text-gray-1200, $weight:400);
}

.big_text{
    @include font($size:1.143rem, $fontcolor:$text-black, $weight:800)
}
// text-gray-1300

.text_light_gray_lg_w_700{
    @include font($size:1.143rem, $fontcolor:$text-gray-1300, $weight:700);
}
.text_light_gray_md_w_700{
    @include font($size:1rem, $fontcolor:$text-gray-1300, $weight:700);
}
.text_light_gray{
    @include font($size:0.857rem, $fontcolor:$text-gray-1300, $weight:700);
}
.text_light_gray_lg_w_600{
    @include font($size:1.143rem, $fontcolor:$text-gray-1300, $weight:600);
}
.text_light_gray_md{
    @include font($size:1rem, $fontcolor:$text-gray-1300, $weight:600);
}
.text_light_gray_sm{
    @include font($size:0.9rem, $fontcolor:$text-gray-1300, $weight:600);
}
.text_light_gray_xs{
    @include font($size:0.857rem, $fontcolor:$text-gray-1300, $weight:600);
}
.text_light_gray_xs_w_500{
    @include font($size:0.857rem, $fontcolor:$text-gray-1300, $weight:500);
}
.text_light_gray_xs_w_400{
    @include font($size:0.857rem, $fontcolor:$text-gray-1300, $weight:400);
}
.text_light_gray_xxs_w_600{
    @include font($size:0.714rem, $fontcolor:$text-gray-1300, $weight:600);
}
.text_light_gray_xxs_w_400{
    @include font($size:0.857rem, $fontcolor:$text-gray-1300, $weight:400);
}

// text-gray-1100
.text_gray_1100_lg_w_700{
    @include font($size:1.143rem, $fontcolor:$text-gray-1100, $weight:700); 
}
.text_gray_1100_w_700{
    @include font($size:1rem, $fontcolor:$text-gray-1100, $weight:700); 
}
.text_gray_1100_w_700_sm{
    @include font($size:0.857rem, $fontcolor:$text-gray-1100, $weight:700); 
}
.text_gray_1100_w_600_sm{
    @include font($size:0.857rem, $fontcolor:$text-gray-1100, $weight:600); 
}
.text_gray_1100_w_600{
    @include font($size:1rem, $fontcolor:$text-gray-1100, $weight:600); 
}
.text_gray_1100_w_600_xsm{
    @include font($size:0.857rem, $fontcolor:$text-gray-1100, $weight:600); 
}
.text_gray_1100_w_500_sm{
    @include font($size:0.857rem, $fontcolor:$text-gray-1100, $weight:500); 
}

.text_gray_1100_w_400_sm{
    @include font($size:0.857rem, $fontcolor:$text-gray-1100, $weight:400); 
}
// text_gray_1400
.text_text-gray-1400{
    @include font($size:1rem, $fontcolor:$text-gray-1400, $weight:600);
}
// text-gray-1500
.text_text-gray-1500_sm{
    @include font($size:0.875rem, $fontcolor:$text-gray-1500, $weight:600);
}
.text_text-gray-1500_md{
    @include font($size:1rem, $fontcolor:$text-gray-1500, $weight:600);
}
.text_text-gray-1500_sm_400{
    @include font($size:0.875rem, $fontcolor:$text-gray-1500, $weight:400);
}

// blue
.blue_text_heighlight{
    @include font($size:1rem, $fontcolor:$text-blue-800, $weight:700);
}
.blue_text_sm{
    @include font($size:0.875rem, $fontcolor:$text-blue-800, $weight:700);
}
.blue_text_md_w_700{
    @include font($size:1rem, $fontcolor:$text-blue-800, $weight:700);
}
.blue_text_md_w_600{
    @include font($size:1rem, $fontcolor:$text-blue-800, $weight:600);
}
.blue_text_sm_w-600{
    @include font($size:0.875rem, $fontcolor:$text-blue-800, $weight:600);
}
.blue_text_sm_w-400{
    @include font($size:0.875rem, $fontcolor:$text-blue-800, $weight:400);
}
// red
// text-red-500
.text-red-500_md_w_600{
    @include font($size:1rem, $fontcolor:$text-red-500, $weight:600);
}
.text-red-500_sm_w_600{
    @include font($size:0.875rem, $fontcolor:$text-red-500, $weight:600);
}
// text-red-400
.text-red-400_sm_w_700{
    @include font($size:1rem, $fontcolor:$text-red-400, $weight:700);
}
// border
.br_border-gray-1000{
    @include border($size:0.091rem, $color:$text-gray-1000);
 }
.br_btm{
    @include border_bottom();
 }
 .br_btm_border-gray-500{
    @include border_bottom($size:0.071rem, $color:$border-gray-500);
 }
 .br_btm_border-gray-300{
    @include border_bottom($size:0.071rem, $color:$border-gray-300);
 }
 .br_btm_border-gray-1000{
    @include border_bottom($size:0.071rem, $color:$text-gray-1000);
 }
 .br_tp{
     @include border_top();
 }
 .br_tp_text-gray-1000{
    @include border_top($size:0.071rem, $color:$text-gray-1000);
}


// table_style
// table_td_font
.td_font{
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:600);
}
// table_border_btm
.table_br_btm{
    @include border_bottom($size:0.071rem, $color:$text-gray-1000);
 }
 .table_br_tp{
    @include border_top($size:0.071rem, $color:$text-gray-1000);
 }
// input_small_size
.input_sm{
    @include from_input_sm();
}
.input_sm_height{
    @extend .input_sm;
    @include input_sm_height;
}
// input_midium_size
.input_md{
    @include from_input_md();
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:600);
    @media(max-width:$breakpoint-xl){
        min-height: 3rem;
        height: 3rem;
    }
}
.input_md_width{
    @include input_md_width;
    @extend .input_md;
}

// end_table_style

// modal_style
// modal_header_font
.header_font{
    @include font($size:1.25rem, $fontcolor:$text-black-700, $weight:700);
}
// modal title
.main_modal_title{
    @include modal_title();
}
// modal_close_btn
.modalclose-btnicon{
    @include modalclose-btn-icon();
}
.modal_fade_bg{
    @include modal_bg();     
}
.modal_content_box{
    @include modal_box();
}
// modal_box_inner_title
.title_commn{
    @include box_title();
    @media(max-width:1200px){
        @include box_title($bgcolor:$bg-blue-200, $size:1.4rem 1rem)
    }
}
.small_title_commn{
    @include box_title($size:0.8rem 2rem);
    @media(max-width:1200px){
        @include box_title($bgcolor:$bg-blue-200, $size:0.8rem 1rem)
    }
}
// scroll_bars
.content_scrollbar{
    @include content_overflow();
}
.body_scroll{
    @include modal_body_scroll(450px);
}
.scroll_bar_body{
    @include scrollbars();
}
// .chat_body_scroll{
//     @include modal_body_scroll(auto, $mxheight:calc(100vh - 440px));
// }
.textarea_body_scroll{
    @include modal_body_scroll(5.5rem, $mxheight:calc(100vh - 900px));
}

// featured_project_style
// featured project next and previous btn bg
.featured_btn_bg{
    @include btn_bg_box();
}
//tag_btn
.tag_btn_common{
    @include tag_btn();
}
.tag_btn_primary{
    @include tag_btn($color_text:$text-primary,$color:$bg-white, $opacity:10%,$size: 0.529rem 0.789rem, $radius:0.429rem);  
}
.tag_btn_green_common{
    @include tag_btn($color_text:$text-green-300,$color:$bg-green-300, $opacity:10%,$size: 0.429rem 0.789rem);  
}
.tag_btn_dark_green_common{
    @include tag_btn($color_text:$text-drak-green-500,$color:$bg-drak-green-500, $opacity:10%,$size: 0.429rem 0.789rem);  
}
.tag_btn_gray{
    @include tag_btn($color_text:$text-gray-1200,$color:$bg-blue-100, $opacity:100%,$size: 0.429rem 0.789rem, $radius:0.429rem 0rem 0.429rem 0rem);
}
.tag_btn_gray_common{
    @include tag_gray_btn($color_text:$text-gray-1300,$color:$bg-white, $opacity:100%,$size: 0.2rem 0.789rem, $fsize:0.857rem);
}
.tag_btn_blue_200{
    @include tag_btn($color_text:$text-black-700,$color:$bg-blue-200, $opacity:100%,$size: 0.2rem 0.789rem, $fsize:0.857rem);
}

// badge
.badge_blue_800{
    @include badge_btn();
}
.badges_gray_1300{
    @include badge_gray_1300_btn();
}
 
.round_btn_blue_common{
    @include tag_round_btn_common();  
}
.round_btn_blue_common.active{
    @include tag_round_btn_common($textcolor:$text-white,$color:$bg-blue-800,$opacity:100%, $radius:50%, $size:1.429rem 1.429rem,$height:45px, $width:45px,$bdrwidth:1px, $bordercolor:$border-gray-500);  
}

// pills_tag_btn
.pills_tag_btn_green{
    @include pills_tag_btn();
}
.pills_tag_btn_yellow{
    @include pills_tag_btn($text-yellow-500,$bg-yellow-500);
}
.pills_tag_btn_gray{
    @include pills_tag_btn($text-gray-1200,$bg-gray-400);
}
.pills_tag_btn_danger{
    @include pills_tag_btn($text-red-500,$bg-red-500);
}


// featured_upload_box
.upload_box{
    @include upload_image_box();
}
.upload_meliston_box{
    @include upload_milestone_image_box()
}
// feature_icon_box
.upload_icon{
    @include upload_icon_box();
}
.upload_icon_bg_blue_200{
    @include upload_icon_box($bgcolor:$bg-blue-200, $bgopacity:100%, $size:1.786rem 1.071rem, $bdr_size:0px, $color:$border-white,$opacity:100%, $radius:0.714rem );
}
.card_icon{
    @include upload_icon_box($bgcolor:$bg-white,$bgopacity:100%, $size:1rem 1rem, $bdr_size:0rem, $color:$border-white,$opacity:100%,$radius:0.714rem);
}
.card_icon_blue-200{
    @include upload_icon_box($bgcolor:$bg-blue-200,$bgopacity:100%, $size:1.2rem 1.2rem, $bdr_size:0rem, $color:$border-white,$opacity:100%,$radius:0.429rem);
}
.import_icon{
    @include upload_icon_box($bgcolor:$bg-white,$bgopacity:100%, $size:1rem 1rem, $bdr_size:0.071rem, $color:$border-blue-800,$opacity:30%,$radius:0.429rem);
}
// featured_upload_sub_title
.upload_sub_title{
    @include textarea_placeholder();
}
// end_featured project

// attachmenst_style
// upload_plus_icon
.plus_icon{
    @include font($size:1.5rem, $fontcolor:$bg-blue-800, $weight:600)
}
// end_attachments

// project_proposal_right_p
// table_td_font
.proposal_right_P_font{
    @include font($size:1rem, $fontcolor:$text-gray-1400, $weight:600);
}
// proposal_right_span
.prposal_right_span{
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:600);
}


// total_milstone_price
.prposal_price_span{
    @include font($size:1rem, $fontcolor:$text-black-700, $weight:700);
}
//proposal recive milestone msg
.payment_msg_font{
    @include font($size:0.857rem, $fontcolor:$text-gray-1300, $weight:600);
}
// crad_text
.card_font{
    @include font($size:1.143rem, $fontcolor:$text-black-700, $weight:700);
}
//end_proposal_right
// end

// side_bar
.upload_list_item{
    @include font($size:1rem, $fontcolor:$text-primary, $weight:700);
}
.upload_list_item:hover{
    @include font($size:1rem, $fontcolor:$text-primary, $weight:700);
}
.side_bar_link{
    @include font($size:1rem, $fontcolor: $text-gray-1300, $weight:600);
    
}
.side_bar_link:hover{
    @include font($size:1rem, $fontcolor:$text-primary, $weight:600)
}
.side_bar_nav_active{
    @include  active_nav_item();
    padding:0.7rem;
}
.side_bar_active_link{
    @include font($size:1rem, $fontcolor:$text-white, $weight:600);
}
.side_bar_active_link:hover{
    @include font($size:1rem, $fontcolor:$text-primary, $weight:600);
}



// end_side_bar
// submit_proposal
.submit_proposal_heading{
   @include font($size:2rem, $fontcolor:$text-black-700, $weight:800);
}
.proposal_heading{
    @include font($size:2rem, $fontcolor:$text-black-700, $weight:800);
}
.page_card{
    @include page_card_box();
    @include font($size:1.143rem, $fontcolor:$text-black-700, $weight:700);
}
.dark_page_card{
    @include page_card_box($bg_color:$bg-black-700, $opacity:80%, $color:$text-white, $radius: 0.429rem); 
}
.edit_proposal_text{
    @include font($size:1rem, $fontcolor:$bg-blue-800, $weight:700);
}

// proposal_title
.proposal_date_sub_title{
    @include font($size:0.875rem, $fontcolor:$text-gray-1300, $weight:600);
}

// end_submit_proposal
// add refrence
.sub_title_commn{
    @include font($size:0.875rem, $fontcolor:$text-gray-1300, $weight:700);
}
.refrence_tab_active{
    @include tab_active();
    @include font($size:1.1rem, $fontcolor:$text-blue-800, $weight:600)   
}

.refrence_nav_tab{
    @include nav_link_tab();
    @include font($size:1.1rem, $fontcolor:$text-gray-1300, $weight:600);
}
.tag_btn_tab_active{
    @include tag_btn_tab($color_text:$text-white,$color:$bg-blue-800, $opacity:100%, $size:0rem 0.1rem,$fweight:700, $fsize:0.714rem,$mheight:1.5rem, $mwidth:1.5rem );
}
.tag_btn_tab{
    @include tag_btn($color_text:$text-gray-1300,$color:$bg-gray-500, $opacity:100%, $size:0rem 0.5rem,$fweight:600, $fsize:1rem );
}
.discription_common{
    @include discription();
}
.delete_active{
    @include delete_active_box();
}
.trash_box{
    @include bg_box();  
}
.trash_box_trasparent{
    @include trash_transparent();  
}
.image_title{
    @include Upload_image_title();
}

// anchor
.anchor_text{
    @include anchor_text();
}
// anchor_primary
.anchor_primary_text{
    @include anchor_text($color:$text-primary,$weight:700, $size:1rem, $decoration:none,$h_decoration:underline,$h_color:$text-primary);
}

// anchor_text_blue_800
.anchor_text_sm_w_700{
    @include anchor_text($color:$text-blue-800,$weight:700, $size:0.857rem, $decoration:none,$h_decoration:underline,$h_color:$text-blue-800);
}
.anchor_blue_800{
    @include anchor_text($color:$text-blue-800,$weight:700, $size:0.857rem, $decoration:none,$h_decoration:underline,$h_color:$text-blue-800);
}
.anchor_blue_md_w_600{
    @include anchor_text($color:$text-blue-800,$weight:600, $size:1rem, $decoration:none,$h_decoration:underline,$h_color:$text-blue-800);
}
.anchor_blue_600_underline{
    @include anchor_text($color:$text-blue-800,$weight:600, $size:0.857rem, $decoration:underline,$h_decoration:underline,$h_color:$text-blue-800);
}
// anchor_text_red_500
.anchor_text_dander_md_w_700{
    @include anchor_text($color:$text-red-500,$weight:700, $size:1rem, $decoration:none,$h_decoration:underline,$h_color:$text-red-500);
}
// anchor_text_red_400
.anchor_text_dander_md_w_700{
    @include anchor_text($color:$text-red-400,$weight:700, $size:1rem, $decoration:none,$h_decoration:none,$h_color:$text-red-400);
}
//pills_tabs

// .pils_tabs_active{
//     @include pils_tabs();
//     @include font($size:0.875rem, $fontcolor:$text-blue-100, $weight:600);
// }

.nav_link_btn{
    @include nav_link_btn_common();
}
.nav_pills_link{
    @include nav_pills_link_common();
}

.nav_pills_link_white{
    @include nav_pills_link_common($size:1rem 2rem, $bg_color:$bg-white, $active_bg_color:$bg-blue-800, $active_color:$text-white);
}
.nav_link_btn_white{
    @include nav_link_btn_common($size:1rem 0.5rem,$bg_color:$bg-white, $color:$text-black-700,$active_bgcolor:$bg-white,$active_color:$text-blue-100);
}

//select project  search
.input_search{
    @include from_input_md($min_h:3.5rem, $height:3.5rem, $radius:0.429rem, $size:1rem);
    @include font($size:0.875rem, $fontcolor:$text-gray-1300, $weight:600)
}
// active online  circle

.active_blue_sm_circle{
 @include online_circle();
}
// profile_image
.profile_image{
    @include profile_image_commn();
}
.profile_image_20{
    @include profile_image_commn($width:20px, $height:20px, $object:cover, $radius:50%);
}
.profile_image_25{
    @include profile_image_commn($width:25px, $height:25px, $object:cover, $radius:50%);
}

.profile_image_40{
    @include profile_image_commn($width:40px, $height:40px, $object:cover, $radius:50%);
}
.profile_image_40_outline{
    @extend .profile_image_40;
    @include profile_image_outline();
}
.profile_image_50{
    @include profile_image_commn($width:50px, $height:50px, $object:cover, $radius:50%);
}
.profile_image_60{
    @include profile_image_commn($width:60px, $height:60px, $object:cover, $radius:50%);
}
.profile_image_70{
    @include profile_image_commn($width:70px, $height:70px, $object:cover, $radius:50%);
}
.profile_image_100{
    @include profile_image_commn($width:100px, $height:100px, $object:cover, $radius:50%);
}
.profile_image_120{
    @include profile_image_commn($width:120px, $height:120px, $object:cover, $radius:50%);
}
// card profile image
.cards_profile_image_25{
    @include cards_profile_image_commn();
}
.cards_profile_image_object_none{
    @include cards_profile_image_commn(25px,25px,$text-gray-1000,none, 50%,);
    
}
// sidebar_login_user_profile
.login_user_profile_image{
    @include profile_image_commn($width:30px, $height:30px, $object:cover, $radius:50%);
}
// detail_side_bar
.detail_sidebar_profile_image{
    @include profile_image_commn($width:60px, $height:60px, $object:cover, $radius:50%); 
}
// danger_container
.danger_container{
    @include danger_container_commn();
}

.msg_tag{
    background-color:$bg-blue-800;
    color:$text-white;
    padding:0.200rem 0.300rem 0.300rem;
    border-radius: 0.328rem;
    font-size: 0.675rem;
    width: 37px;
    text-align: center;
}

//=========================== utilites ================================
// Headline Font Size

.h1{
    font-size:4rem;
}
h2{
  font-size:3rem;
}

h3{
    font-size:2.5rem;
}

h4{
    font-size: 2rem;
}
h5{
    font-size: 1.5rem ;
}
h6{
    font-size: 1rem;
}



// font-weight
.fw_100{
    font-weight: 100;
}
.fw_200{
    font-weight: 200;
}
.fw_300{
    font-weight: 300;
}
.fw_400{
    font-weight: 400;
}
.fw_500{
    font-weight: 500;
}
.fw_600{
    font-weight: 600;
}
.fw_100{
    font-weight: 100;
}
.fw_700{
    font-weight: 700;
}
.fw_800{
    font-weight: 800;
}
.fw_900{
    font-weight: 900;
}
// line heigth
.line_height_12{
    line-height: 0.857rem;
}
.line_height_15{
    line-height: 1.071rem;
}
.line_height_18{
    line-height: 1.286rem;
}
.line_height_20{
    line-height: 1.429rem;
}
.line_height_28{
    line-height: 2rem;
}

// overflow
.overflow_y_auto{
    overflow-y: auto;
}
.overflow_hidden{
    overflow: hidden;
}
// column_gap
.column_gap_8{
    column-gap: 0.571rem;
}
.column_gap_10{
    column-gap: 0.714rem;
}
.column_gap_15{
    column-gap: 1.071rem;
}
.column_gap_20{
    column-gap: 1.429rem;
}
// row_gap
.row_gap_15{
    row-gap:1.071rem;
}

// border radius
.br_4{
    border-radius: 0.286rem;
}
.br_6{
    border-radius: 0.429rem;
}
// border dotted
.br_dotted{
    border: 0.143rem dashed rgba(20, 100, 220, 0.5);
}
// object fit
.object-contain{
    object-fit: contain;
}
.object-none{
    object-fit:none;
}
// strike
.strike{
 text-decoration: line-through;
}
// word break
.break-word{
    word-break: break-word;
}
// cursor
.cursor-pointer{
    cursor: pointer;
}
.pointer-event-none{
    pointer-events: none;
}
// list-style
.li_auto{
    list-style: auto;
}
.li_disc{
    list-style: disc;
}

// text-overflow
.text_overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
//=========================== end utilites ================================

// ================================== Common Css ===============================

// ============ modal popup ===================
//  ============ modal popoup width =============
.popup_modal_dialog{
   width:100vw; 
    max-width: 1400px;
    @media(min-width:$breakpoint-sm){
       width: calc(100vw - 150px);
        max-width: 1024px; 
    }
    @media(min-width:$breakpoint-lg){
        width: calc(100vw - 900px); 
        max-width: 1300px;
    }
}
.popup_modal_dialog_lg{
    width:100vw;
    max-width: 1400px;

    @media(min-width:$breakpoint-sm){
        width: calc(100vw - 150px); 
        max-width: 1024px;
    }
    @media(min-width:$breakpoint-lg){
        width: calc(100vw - 200px); 
        max-width: 1300px;
    }
}
.popup_modal_dialog_md{
    max-width: 100vw;
    @media(min-width:$breakpoint-lg){
        width: calc(100vw - 200px);
        max-width: 800px;
    }
    
}

.popup_modal_dialog_sm{
    max-width: 100vw;
    @media(min-width:$breakpoint-lg){
        width: calc(100vw - 200px);
        max-width: 600px;
    }
    
}

.popup_modal_dialog_xsm{
    width:100vw;
    max-width: 800px;

    @media(min-width:$breakpoint-sm){
        width: calc(100vw - 150px); 
        max-width: 550px;
    }
    @media(min-width:$breakpoint-lg){
        width: calc(100vw - 200px); 
        max-width: 550px;
    }
}

// ============= Modal Popup Height =================
.common_overflow_height{
    // min-height: 550px;
    height: calc(100vh - 230px);
    overflow-y: auto;
}
.common_overflow_height::-webkit-scrollbar {
    width: 0.313rem;
}
.common_overflow_height::-webkit-scrollbar-track {
    background-color :transparent;
}
.common_overflow_height::-webkit-scrollbar-thumb {
    border-radius:0.375rem;
    background-color:$border-gray-400;
}
.extend_overflow_height{
    @extend .common_overflow_height;
    @include overflow_height();
}

// ============= Modal Popup Height sm =================
.common_overflow_sm_height{
    height: calc(100vh - 215px);
    overflow-y: auto;
}
.common_overflow_sm_height::-webkit-scrollbar {
    width: 0.313rem;
}
.common_overflow_sm_height::-webkit-scrollbar-track {
    background-color :$bg-white;
}
.common_overflow_sm_height::-webkit-scrollbar-thumb {
    border-radius:0.375rem;
    background-color:$border-gray-400;
}
// ============= Modal Popup Height xsm =================
.common_overflow_xsm_height{
    // min-height: 550px;
    max-height: calc(100vh - 771px);
    overflow-y: auto;
}
.common_overflow_xsm_height::-webkit-scrollbar {
    width: 0.313rem;
}
.common_overflow_xsm_height::-webkit-scrollbar-track {
    background-color :$bg-white;
}
.common_overflow_xsm_height::-webkit-scrollbar-thumb {
    border-radius:0.375rem;
    background-color:$border-gray-400;
}

//  ============ modal header title close button =============
.modal_top_header {
padding: 2.5rem 2.7rem 1.5rem;
}
.modal-title{
    font-size: 1.429rem;
    font-weight: 800;
    color:$text-black-700;
    line-height: 1.714rem;
}   
.close_btn{
    opacity:1.5;
}

// ============ end modal popup ===================

// ============= new table css ===================
.table_toggle{
    padding: 1rem;
    background-color: #F5F7FD;
    border-top-left-radius: 0.286rem;
    border-bottom-left-radius: 0.286rem;

    .btn-group{
        width: 8rem;
        height: 3.3rem;
        border-radius: 0.3rem;
        padding: 0rem;
        border: 0.1rem solid $text-gray-1000;
        input{
            z-index: 100;
            border-color: solid $text-gray-1000 ;
            border-width: 0rem 0.1rem;
            height: 3.1rem;
            min-height: 3.1rem !important;
            min-width: 2rem;
            border-radius: 0rem;
            padding: 0rem;
        }
        .btn{
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
            align-items: center;
            display: flex;
            background-color:$text-white;
        }
        .btn:nth-child(1){
            color:$text-black-600;
            border-bottom-left-radius: 0.3rem;
            border-top-left-radius: 0.3rem;
        }
        .btn:nth-last-child(1){
            color:$bg-blue-800;  
            border-bottom-right-radius: 0.3rem;
            border-top-right-radius: 0.3rem;

        }

    }

    .title{
        
        padding: 0rem 2rem;
        color: #828190;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.286rem;

    }
    .price_input{
        position: relative;
        // input{
        //     padding-left: 25px;
        // }
        .price_icon {
            position: absolute;
            top: 41%;
            left: 1rem;
        } 
        input{
            padding-left:35px;
        } 
    }
}
.price_input{
    position: relative;
    .price_icon{
        position: absolute;
        // top: 44%;
        // left: 1.2rem;
        top: 38%;
        left: 1.2rem;
        @media(max-width:$breakpoint-lg){
            top: 41%;

        }
        @media(max-width:$breakpoint-md) {
            top:38%;
        }
        &.error_price_icon{
            top:28%;
        }
    }  
    input{
        padding-left:35px;
    }
}
.add_on_milestone{
                
    color:$bg-blue-800;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.289rem;
    .add_on_milstone_icon{
        margin-right:0.8rem;
        font-size: 1.5rem;
    }
    }
// ============= end new table css ===================

// footer
.proposal_footer{
    padding:1rem 1.5rem; 
    min-height: 4.9rem;
}
// header
.proposal_header{
    padding:1rem 1.5rem; 
    min-height: 4.9rem;
}
.end_cycle_topheadres{
    padding:1.5rem 1.5rem; 
    min-height: 4.9rem;
}
// project_description
.project_description {
    .description{
          color: rgba($text-black-700, 0.5);
          font-size: 1rem;
          font-weight: 600;
    }
}

.client_logo_image{
    width: 30px;
    height: 30px;
    margin-right: 0.3rem;
    line-height: 1.286rem;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }
} 

// slider_image
.slider_image{   
    width: auto;
    height: 225px;
    border-radius: 11px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 0.429rem;
        object-fit: cover;
    }

    .profile_selection_loader{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
// slider_nav
.slider_card{
    position: relative;
    .card_slider_nav_pre{
        position: absolute;
        top: calc(50% - 5px );
        left: 0.9rem;
        background-color: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #9393AB;
        cursor: pointer;
        
    }
    .card_slider_nav_next{
        position: absolute;
        top: calc(50% - 5px );
        right: 0.9rem;
        background-color: #fff;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: #9393AB;
        cursor: pointer;
    }
}
//==================== common sidebar ====================
//dark card dropedownbox
.dropedown_container.show{
    right: 0px;
}
.dropedown_container{
    .btn:focus {
        outline: 0;
        box-shadow:none;
    }
    .dropedown_icon{
        display: block;
        margin-bottom: 0.3rem;
    }
    .dropdown-item{
        padding:0.6rem 1.5rem;
    }
    .dropdown-menu{
        border-radius: 0.571rem;
        box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
    }
    .dropdown-menu.show{
        top: -4px !important;
        left: -85px !important;
        position: absolute;
        transform: translate3d(-72px, 38px, 0px) !important;
        will-change: transform;
    }
}

//==================== end common sidebar ====================

// =================== main form filed dropedown ===============
.dropedown_field{
    position: relative;
    .down_icon{
        position: absolute;
        top: 50%;
        right: 10%;
        @media(max-width:$breakpoint-xl){
                top: 44%;
        }
    }

    &.select_dropdown{
        min-width: 11rem;

        .css-yk16xz-control,
        .css-1pahdxg-control{
            height:46px;
            border-color:#E1E5EB;
            font-size: 1rem;
            font-weight: 500;
        }
        .css-1pahdxg-control:hover,
        .css-1pahdxg-control:focus{
            height:46px;
        }
    }
}
// =================== end main form filed dropedown ===============
// =================== main form filed number btn ===============
.css-2613qy-menu{
    position:absolute;
    z-index:100;
}
.number_btn{
    .btn-group{
        width: 8rem;
        height: 4rem;
        border-radius: 0.3rem;
        padding: 0rem;
        border: 0.1rem solid $text-gray-1000;
        border-radius: 0.3rem;
        overflow: hidden;
        &.btn-group-lg{
            width: 12rem;  
        }
        @media(max-width:$breakpoint-xl){
            height: 3rem;  
        }
        &.btn-group-h-3{
            height: 3.8rem; 
        }
        input{
            z-index: 100;
            border-color: solid $text-gray-1000 ;
            border-width: 0rem 0.1rem;
            height: 3.8rem;
            min-height: 3.8rem;
            min-width: 2rem;
            border-radius: 0rem;
            padding: 0rem;
            @media(max-width:$breakpoint-xl){
                min-height: 2.8rem;
                height: 2.8rem;
            }
        }
        .btn{
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
            align-items: center;
            display: flex;
            background-color:$text-white;
        }
        .btn:nth-child(1){
            color:$text-black-600; 
        }
        .btn:nth-last-child(1){
            color:$text-blue-800;  
        }

    }

}
// =================== main form filed number btn ===============
//==================== files tab ==============================
// cards
.card_top_img{
    width: auto;
    height: 215px;
   
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-right-radius: 0.714rem;
        border-top-left-radius: 0.714rem;
    }
}
.cardbody_img{
    width: 40px;
    height: 40px;
    border: 0.036rem solid  rgba($border-gray-400, 0.5);
    border-radius: 6px;
    margin-right: 1rem;
    img{
        width:100%;
        height: 100%;
        object-fit: contain;
        padding: 0.5rem;
    }
}
.card_profile_image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    line-height: 18px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.card_company_profile_image{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    line-height: 18px;
    img{
        width: 100%;
        height: 100%;
        object-fit: none;
    }
}
.card_username{
    margin-right: 2rem;
}
.card_list ul li{
    list-style: disc;
    // color:$text-gray-1300;
    &::marker{
        font-size: 0.875rem;
    }
}
//==================== end files tab ==============================
//====================  checkbox ====================================
// Custom Input
 .cstm_checkbox{
    position: absolute;
    top: 1rem;
    right: 0rem;
}
.cstm_checkbox input{
    padding:0;
    height:initial;
    width: initial;
    display: none;
    cursor:pointer;
}
.cstm_checkbox label{
    position: relative;
    cursor: pointer;
    color:$text-black;
    font-size:1.1rem;
    margin-bottom:1rem;
}
.cstm_checkbox label:before{
    content:'';
    background-color: transparent;
    border:0.1rem solid $text-gray-300;
    padding:11px;
    border-radius: 100%;
    display:inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px; 
    transition: background-color 0.3s ease-in-out;
}
.cstm_checkbox input:checked + label::after{
    content:'';
    display: block;
    position: absolute;
    top: 5px;
    left: 8px;
    width: 8px;
    height: 12px;
    border:solid $bg-white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    @media(max-width:$breakpoint-md){
        top:3px;
    }
}
.cstm_checkbox input:checked + label::before{
    content:'';
    background-color: $bg-blue-800;
    border-color: $bg-blue-800;
}
//==================== end checkbox ====================================
// ============== empety screen ==============
.chat_body{
              
    padding-top: 3rem;
    padding-bottom: 3rem;
    // min-height: calc(100vh - 229.47px - 4rem - 163px);
    // max-height:calc(100vh - 229.47px - 4rem - 163px);
    min-height: calc(100vh - 325px);
    max-height:calc(100vh - 325px);
    overflow-y:auto ;
    
  }
  .empty_chat_box{
    display: flex;
    align-items: center;
    justify-content: center; 
}
 // teatarea
 .outer_textarea{
    padding-left:1rem;
    padding-right: 1rem;
}
.empty_chat_upload_notes{
    min-height: 6.5rem;
    background-color: #F5F7FD;
    border: 1px solid #E8ECF4;
    border-radius: 0.286rem;
    padding: 0;
    position: relative;
    .send_icon{
        position:absolute;
        top:0.8rem;
        right: 0;
        z-index:100;
    }
    .dander_icon{
        position:absolute;
        top: 0.4rem;
        left: 15px;
    }
    textarea{
        // max-height: 4rem;
        color:rgba($color: #050421, $alpha: 0.4);
        padding: 0.7rem 2.5rem;
        overflow-y: auto;
        background-color: transparent !important;
        
    }
}
.upload_media_icons{
    padding:.375rem .75rem;
}
//==========  end empty screen  ============

//=========== overview active Proposal ===============
.active_proposal_body,.rejected_body{
    min-height: calc(100vh - 94.5px - 64.97px - 70px - 4rem);
}
//=========== overview active Proposal ===============

//=============== url ============
.url_input{
    position: relative;
    padding: 0rem 3.2rem;
    // padding: 0rem 0rem;
    color:$text-blue-800;
    background-color: transparent;
    // text-decoration: underline;
    ::placeholder{
        text-decoration: none;
    }
}

.input_content{
    position:absolute;
    top:34%;
    left:0%;

    &.error_input_image{
        top:15px;
    }
} 
.add_url button{
    background: transparent;
    border: none;
    position: absolute;
    top: 32%;
    right: 2%;
    font-size: 0.857rem;
    font-weight: 700;
    color:$text-blue-800;
    &.error_button{
        top:15px;
    }
}
//================= end url===============

//=============== scrollbar ================

// modal_scroll_bar
.modal_scroll::-webkit-scrollbar {
    width: 0.313rem;
}
.modal_scroll::-webkit-scrollbar-track {
    background-color :$bg-white;
}
.modal_scroll::-webkit-scrollbar-thumb {
    border-radius:0.375rem;
    background-color:$border-gray-400;
}
// ===================== body_scroll_bar ==============
       
.body_scroll_bar::-webkit-scrollbar {
    width: 0.313rem;
}
.body_scroll_bar::-webkit-scrollbar-track {
    background-color: transparent;
}
.body_scroll_bar::-webkit-scrollbar-thumb {
    border-radius:0.375rem;
    background-color:$border-gray-400;
}
// ========== end scroll ===================

// ================= tooltip ==================
 // tootltip
 .tool::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: $bg-gray-1000 transparent transparent transparent;
    bottom: 100%;
    content: "";
    margin-right: -50em;
    transform: rotate(0deg) translateY(-1.5rem) translateX(-0.2rem) !important;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26), opacity .65s .5s;
    
}
.tool::after{
    text-align:left;
    font-size:0.8rem;
    background: $bg-gray-1000;
    line-height: 1.2rem;
    // bottom: 94%;
    color: #EDEFF0;
    content: attr(data-tip);
    border-radius: 0.8rem;
    // margin-left:-0.9em;
    padding: 1em;
    transform: rotate(0deg) translateY(3.4rem) translateX(7.9rem)!important;
    width: 35em;
    min-width:5.625rem;
        // @media(max-width:$breakpoint-md){
        //     transform: scale(0.7) translateY(263%) translateX(-1.688rem);   
        // }

    bottom: 140%;
    margin-left: -12.75em;
    transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s; 
}
// ============= end tottltip ===============

//=============== client =================
// client_overview
.client_details{
    line-height: 20px;
    
    li{
        list-style: disc;
        color:$text-gray-1100;
        .location, .rating{
            line-height: 20px;
            align-items: center;
        }
    }

}


// .client_details li:first-child{
//     list-style: none;
//  }
.client_details li{
    list-style: none;
 }
 .client_details li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: center !important;
    font-size: 0.875rem;
 }
// ================ end_client ===========
//========== upload_notes ===============
.upload_notes{
    // min-height: 10.143rem;
    background-color: #fff;
    border: 0.071rem solid $border-gray-500;
    border-radius:0.426rem;
    padding: 0.714rem;
    color:rgba($text-black-700, 0.5);
    &:focus{
        color:rgba($text-black-700, 0.5) !important;
    }
}
// ========= end_upload_notes ===========
//========== textarea ===============
.textarea_gray_500{
    min-height: 10.143rem;
    background-color: #fff;
    border: 0.071rem solid $border-gray-500;
    border-radius:0.426rem;
    padding: 0.714rem;
    color:rgba($text-black-700, 0.5);
    &:focus{
        color:rgba($text-black-700, 0.5) !important;
    }
}
// ========= end_textarea ===========
// ====================== request_popup_teatarea =================
.request_textarea{
    min-height: 12rem;
    overflow-y: auto;
    color:$text-gray-1300;
    font-size: 1rem;
    font-weight: 600;
    border: 0.071rem solid $border-gray-500;
    border-radius: 0.429rem;
    &:focus{
        color:$text-gray-1300; 
        border: 0.071rem solid $border-gray-500; 
    }

}
// ====================== end request_popup_teatarea =================
//================= notification ===================
.notify{
    .active_cricle{
      position: absolute;
      top: 18px;
      left: 1px;     
    }
}
.message_outer{
    max-height: calc(100vh - 68.9px - 64.97px - 94.5px - 4rem - 211.03px);
    overflow-y: auto;
}
//=================end  notification ===================
//=================chat custom tabs ===================
           
.cstm_tab .nav-link{
    border: 0rem;
    // border-bottom: 4px solid $bg-blue-800 ;
    padding: 1rem 0rem;
    margin-right: 1rem;
    margin-left:1rem;
    font-size: 1.143rem;
    font-weight: 600;
    color:rgba($color: $text-gray-1300, $alpha: 100);
    cursor: pointer;
}
.add_reference .cstm_tab .nav-link{
    margin-left:0!important;
}
.cstm_tab .nav-link.active{
    border-bottom: 0.286rem solid $bg-blue-800 ; 
    color: $text-blue-800;
} 
//=================end chat custom tabs ===================
//================= refrence tabs =========================
.import{
    p{
        display: flex;
        align-items: center;
        span{
            overflow-wrap: break-word;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
            width: calc(100% - 24px - 1rem);
            display: block;
            white-space: nowrap;
            overflow: hidden;
        }

    }
    }

//================= end refrence tabs =========================
//================= attachmnet tabs =========================
.upload_image_box{
    // column-gap: 20px;
    .upload_image {
        width: 100%;
        aspect-ratio: 4/3;
        height:100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

.upload_image_thumbnail {
    grid-column-gap: 20px;
    column-gap: 20px;

    .img_thumbnail{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background-color: #F6F8FD;
        width: 12rem;
        aspect-ratio: 4/3;
        min-width: 8.5rem;
        object-fit: cover;
        border-radius: 0.5rem;
        position: relative;
        overflow: hidden;
        transition: display 0.3 ease-in-out;
        -webkit-transition: display 0.3 ease-in-out;
        -ms-transition: display 0.3 ease-in-out;
        -moz-transition: display 0.3 ease-in-out;
        -o-transition: display 0.3 ease-in-out;

        .small_image_preview{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .delete_active{
            display: flex;
            transform: translateY(100%);
            transition: transform 0.3s ease-in-out;
        }

        &:hover .delete_active{ 
            transform: translateY(0%);
            transition:transform 0.3s ease-in-out;
            cursor: pointer;
        }
    }
}

//================= end attachmnet tabs =========================
// 
.tab_pills{
    // width: calc(100% - 18.98px - 18 
    // margin-left: 20px;
    // margin-right: 20px;
    overflow: hidden;
    // overflow-x: auto;
}

//================= client active brief Project details card =========================
.nav_pills_outer{
    position: relative;
    .pre_li{
        position: absolute;
        left: 0px;

    }
    .next_li{
        position: absolute;
        right: 0px;
    }
}

.project_tags{
    column-gap:0.714rem;
}
.project_details_card{
    border-bottom-left-radius: 0.69rem;
    border-bottom-right-radius: 0.69rem;
}
.project_details_card_img{
    width: auto;
    aspect-ratio: 16/9;
    background-color: blue;
    border-top-right-radius: 0.69rem;
    border-top-left-radius: 0.69rem;
    img{
        width: 100%;
        height: 100%;
        object-fit:cover ;
    }
}
.project_details_tab_content_inner{
    overflow-y: auto;
    height: calc(100vh - 94.49px - 68.98px - 45.98px - 1rem - 1.5rem - 4rem);
}
.user_profile_outer{
    width: 100%;
    aspect-ratio: 19/6;
    position: relative;
    img{
        width:100%;
        height: 100%;
    }
    .user_profile_inner{
        position: absolute;
        left: calc(50% - 3.571rem);
        bottom: -3rem;
        .profile_edit_bg{
            position: absolute;
            width: 30px;
            height: 30px;
            background-color: #EFF1F5;
            border-radius: 50%;
            right: -1px;
            bottom: 11px;
            border: 0.071rem solid $bg-white;
            img{
                width: 100%;
                height: 100%;
                object-fit: scale-down;
            }
        }
    }
}
.user_discription{
    p{
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;
    }

}

//================= end client active brief Project details card =========================

// ======================== timeline =======================
.timelines {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.timeline-item .icons {
    position: absolute;
    top: 0px;
    left: -1.6em;
    transform: translate(3.5px, 0em);
    margin: 0px;
    &:last-child{
        // transform: translate(3.5px, 1em);
        top: 3px;
    }
    .circle{
        // background-color: #00B665;
        background-color: #fff;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 0px solid #E1E5EB;
        // border: 3px solid #fff;
        // box-shadow: 0 0 0 2px #00B665;
        box-shadow: 0 0 0 2px #E1E5EB;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        // color: #fff;
        color: #9393AB;
        display: flex;
        align-items: center;
        justify-content: center;
        
        span{
            font-size: 15px;
            line-height: 0rem;
        }
    }
    .approved{
        background-color: #00B665; 
        border: 3px solid #fff;
        box-shadow: 0 0 0 2px #00B665;
        color: #fff;
    }
    .process{
        background-color: #ffff; 
        border: 3px solid #fff;
        box-shadow: 0 0 0 2px #00B665;
        color:#000;
        font-weight: 600;

    }
    .dashed{
        border: 1px dashed $border-blue-800;
        box-shadow: 0 0 0;
        background-color: $bg-blue-100;
    }
}
.timeline_header{
   padding: 0.01em 0 0em 3em;  
   min-height: 48px;
}
.timeline-items {
  margin: 0em;
  padding: 0.01em 0 3em 2em;
//   border-left: 1px solid #fe4747;
}

.timeline-item {
//   margin-top: -0.3em;
//   border-left: 1px solid #fe4747;
  margin-bottom: 2em;
  padding: 0em 0 0em 0em;
  &:last-child .timeline_inner_item {
    border-left: 0px;
  }
}
.timeline_inner_item{
//   border-left: 1px solid #00B665;
  padding: 0.01em 0 0.5em 3em; 
  margin: 0px !important;
  border-left: 1px solid #EAEEFB;
  &:last-child{
    padding: 0.01em 0 3em 3em; 
    // border-left:0px;
  }
 
}
.inner_item_active{
    border-left: 1px solid #00B665;
    margin: 0px !important;
}
.inner_item_dashed{
    border-left: 1px dashed rgba($border-blue-800, 0.5);
    margin: 0px !important;
}
.timeline-item > * {
  margin: 1em 0;
}

// aleart
.alert_outer{
    // padding: 1rem;
    background: rgba(225, 96, 80, 0.1);
    border-radius: 6px;
    width:40px;
    height:40px;
    object-fit: cover;
    img{
        width:100%;
        height:100%;
    }
}
// ======================== end timeline =======================

// ====================== milestone_noted_checked =================
.milestone_noted_checked li{
 position: relative;
 padding-left: 2.4rem;
    &::before{
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        left: 8px;
        width: 8px;
        height: 12px;
        border: solid $bg-drak-green-500;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}
// ====================== milestone_noted_checked =================

// =========================== review_card=============================
.review_card_wrapper{
    .review_user ul li{
        list-style: disc;
        // color: $text-gray-1100;
        margin-left: 2rem;
    &:first-child{
        list-style: none;
        margin-left: 0rem;
    }
    }
    p{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
}
// =========================== end review_card=============================
//========================== upload milestone =============================
.upload_milestone_bg{
    background-color:$bg-blue-200; 
    & .white_bg{
        background-color: $bg-white;
    }
}//========================== end upload milestone =============================

 // =============================tootle_btn on/off    =====================
 .toggle_btn{
    .switch {
        position: relative;
        display: inline-block;
        width: 2.429rem;
        height: 1.571rem;
    }
    .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color:$text-gray-1300;
        -webkit-transition: .4s;
        transition: .4s;    
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 1.143rem;
        width: 1.143rem;
        left: 0.143rem;
        bottom: 0.214rem;
        background-color:$text-white;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked + .slider {
        background-color: $bg-blue-800;
    }
    
    input:focus + .slider {
        box-shadow: 0 0 0.071rem $bg-blue-800;
    }
    
    input:checked + .slider:before {
        -webkit-transform : translateX(1.071rem);
        -ms-transform : translateX(1.071rem);
    
    }
    /* Rounded sliders */
    .slider.round {
        border-radius: 2.429rem;
    }
    
    .slider.round:before {
        border-radius: 50%;
    }
    }
     // =============================end tootle_btn on/off    =====================

    .show_iteration{
        background-color: $bg-blue-700;
        border-radius: 0rem 0rem 0.429rem 0.429rem;
    }



    // dropedown
    .dropedown_input{
        position: relative;
        .dropedown_icon{
            position:absolute;
            top:40%;
            right:1.429rem;

            &.error_icon{
                top:30%;
            }
        }
        .dropdown_menu{
            position: absolute;
            width: 100%;
            left: 0;
            max-width: 96%;
            margin: auto;
            transform: translate(0.875rem, 0rem); 
            z-index:1000;
            overflow: hidden;
            max-height: 300px;
            overflow-y: auto;

            .dropdown_inner{
                min-height: auto;
                height: auto;
                overflow-y:auto;
                border-radius: 0.482rem;
                border: 0.071rem solid $text-gray-1000;
            }
            .dropdown_item{
                position: relative;

                input{
                    border:0;
                    border-bottom: 0.071rem solid $text-gray-1000;
                    min-height:4rem;
                    height:4rem;
                    border-radius:0.429rem;
                    padding:1rem;
                }
            }
        } 
    }
   // upload image
    .uploading-image {
        .card {
            height: calc(100% / 1.5);
            min-height:530px;
            width: 100%;
            margin: auto;
            overflow: hidden;
        }
        .preview_image_upload {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            min-height: 650px;
            vertical-align: middle;
        }
    }

    // extension approver
    .end_cycle_topheadres {
        position: relative;
        .close_btn{
            position: absolute;
            top:1rem;
            right:1.5rem;
        }
    }
    // payment card
    .payment_card{
        background-color:$bg-white;
        border: 0.071rem solid $border-gray-500;
        border-radius: 0.429rem;
        padding: 1rem;
        &.active{
            background-color: $bg-blue-200;
            border:0.071rem solid $border-blue-800;
        }
    }

    // total amount details
    .total_details_card{
        background-color: $bg-blue-200;
        .amount_tag{
          min-height: 5rem;
        }
      }
    
    // border right 
    .border_left{
        position: relative;
        margin-left: 1rem;
        &::before{
            position: absolute;
            top:0px;
            left: 0px;
            width:0.071rem;
            height:100%;
            content:"";
            background-color:$bg-gray-600;
        }
         
    }
    .border_right{
        position: relative;
        // margin-left: 1rem;
        &::before{
            position: absolute;
            top:0px;
            right: 0px;
            width:0.071rem;
            height:100%;
            content:"";
            background-color:$bg-gray-600;
        }
         
    }


    // dashboard
    .new_flex-card{
        min-width: 300px;
        max-width: 300px;
        margin-right: 15px;
    }
    .text_light_green{
        color:#90C028;
    }
    .light-gray {
        color: #D0D6E7;
    }