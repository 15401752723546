.view_proposal_on_actuals{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .view_proposal_on_actuals_container{
        padding:2rem;
       
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
      
            .proposal_date{
                .date_input{
                    position: relative; 
                    .date_icon{
                        position:absolute;
                        top: 37%;
                        right: 8%;
                    }

                }
            }
            .circle_btn{
                color: #fff;
                background-color: $bg-blue-800;
            }
            // cards
            .view_proposal_card{
                background-color: $bg-blue-200;
                border: 0px;
                box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
                border-radius: 0.429rem;
                position: relative;
                height: 8.9rem;
                .card_tag{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            .description{
                font-size: 0.857rem;
                font-weight: 600;
                line-height: 1.071rem;
                color:$text-gray-1300;
            }
            
            // tootle_btn on/off   
            .toggle_btn{
            .switch {
                position: relative;
                display: inline-block;
                width: 2.429rem;
                height: 1.571rem;
            }
            .switch input { 
                opacity: 0;
                width: 0;
                height: 0;
            }
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color:$text-gray-1300;
                -webkit-transition: .4s;
                transition: .4s;    
            }
            .slider:before {
                position: absolute;
                content: "";
                height: 1.143rem;
                width: 1.143rem;
                left: 0.143rem;
                bottom: 0.214rem;
                background-color:$text-white;
                -webkit-transition: .4s;
                transition: .4s;
            }
            input:checked + .slider {
                background-color: $bg-blue-800;
            }
            
            input:focus + .slider {
                box-shadow: 0 0 0.071rem $bg-blue-800;
            }
            
            input:checked + .slider:before {
                -webkit-transform : translateX(1.071rem);
                -ms-transform : translateX(1.071rem);
            
            }
            /* Rounded sliders */
            .slider.round {
                border-radius: 2.429rem;
            }
            
            .slider.round:before {
                border-radius: 50%;
            }
            }
            // table_deliverables 
            .table_deliverables{
                @media(min-width:$breakpoint-xxxl) {
                    overflow-x:hidden;    
                }
                tbody tr:nth-last-child(1) td:nth-last-child(1){
                    padding-left: 0rem; 
                    
                }
                tbody tr:nth-last-child(1) td:nth-last-child(1) {
                    padding-right: 0rem;
                    .number_btn{
                        padding:1rem;
                        background-color:$bg-blue-200;
                        border-top-left-radius:0.286rem;
                        border-bottom-left-radius: 0.286rem;
                    }
                    .toggle_price_input{
                        padding: 1rem;
                        background-color:$bg-blue-200;
                        border-top-right-radius: 0.286rem;
                        border-bottom-right-radius: 0.286rem;
                        // input{
                        //     max-width: 8.571rem;
                        //     height: 4rem;
                        // }
                        
                    }
                } 
            } 
            // table_commmon_css
            .table{  
                thead{
                    tr{
                    width:100%;
                    display:flex; 
                    th{
                            vertical-align: middle;
                            border: none !important;
                            display:block;
                            width:100%;    
                        }
                        th:nth-child(1){
                            min-width:1rem;
                            width:4rem;
                                
                        }
                        th:nth-child(2){
                            min-width: 33.571rem;
                            width: 33.571rem;
                            }
                        th:nth-child(3), th:nth-child(4){
                            min-width: 12rem;
                            width: 12rem;
                        }
                        th:nth-child(5){
                            min-width: 15rem;
                            width: 15rem;
                        }
                    
                    }
                }
                tbody{
                    // max-height:20rem;
                    // height: 20rem;
                    // overflow-y:scroll;
                    display:block;
                    width:100%;
                    tr {
                        display:flex;
                        width:100%;
                        align-items: center;
                        th{
                            vertical-align: middle;
                            display:block;
                            width:100%; 
                        }
                        th:nth-child(1){
                            min-width:1rem;
                            width:4rem;
                                
                        }
                        th:nth-child(2){
                            min-width: 24.571rem;
                            }
                    
                        td{
                            vertical-align: middle;
                            border-top: none;
                            border-bottom: none;
                            display:block;
                            width:100%;
                            
                            
                        }
                        .date_input{
                                position: relative;
                        .date_icon{
                                position: absolute;
                                top: 39%;
                                right: -1.5rem;
                                @media(max-width:$breakpoint-lg){
                                    top:33%;
                                } 
                            }
                            .date_icon_1{
                                position: absolute;
                                top: 39%;
                                right: 2.4rem;
                                @media(max-width:$breakpoint-lg){
                                    top:33%;
                                } 
                            }
                            .trash_icon{
                                position: absolute;
                                right: -3.143rem;
                                top: 27%;
                                @media(max-width:$breakpoint-md){
                                    right: -2.857rem;
                                    top: 19%;
                                }
                            }
                        }
                        .price_input{
                            position: relative;
                            .price_icon{
                                position: absolute;
                                top: 44%;
                                left: 1.5rem;
                                @media(max-width:$breakpoint-lg){
                                    top: 41%;
                
                                }
                                @media(max-width:$breakpoint-md) {
                                    top:38%;
                                }
                            }  
                        }
                    
                        .price_input input::placeholder{
                            padding:1.429rem;
                        }  
                        
                        td:nth-child(1){
                            min-width:1rem;
                            width:4rem;  
                        }
                        
                        td:nth-child(2){
                            min-width: 33.571rem;
                            width: 33.571rem;
                        }
                        td:nth-child(3), td:nth-child(4){
                            min-width:12rem;
                            width: 12rem;
                        }
                    
                        td:nth-child(5){
                            min-width:15rem;
                            width: 15rem;
                        }
                        
                    }
                    .toggle_dropedown{
                        width: 100%;
                        display: flex;
                        td:nth-child(1){
                            width: 4rem;
                            min-width: 4rem;
                        }
                        td:nth-child(2){
                            width: 100%;
                            display: block;
                            // toggle_box
                            .toggle_box{
                                .title{
                                    padding: 0rem 2rem;
                                    color: $text-gray-1400;
                                    font-weight: 600;
                                    font-size: 0.875rem;
                                    line-height: 1.286rem;
                                }
                                .number_btn{
                                    .btn-group{
                                        width: 8rem;
                                        height: 3.3rem;
                                        border-radius: 0.3rem;
                                        padding: 0rem;
                                        border: 0.1rem solid $text-gray-1000;
                                        input{
                                            z-index: 100;
                                            border-color: solid $text-gray-1000 ;
                                            border-width: 0rem 0.1rem;
                                            height: 3.1rem;
                                            min-height: 3.1rem !important;
                                            min-width: 2rem;
                                            border-radius: 0rem;
                                            padding: 0rem;
                                        }
                                        .btn{
                                            font-size: 2rem;
                                            font-weight: 500;
                                            text-align: center;
                                            align-items: center;
                                            display: flex;
                                            background-color:$text-white;
                                        }
                                        .btn:nth-child(1){
                                            color:$text-black-600;
                                            border-bottom-left-radius: 0.3rem;
                                            border-top-left-radius: 0.3rem;
                                        }
                                        .btn:nth-last-child(1){
                                            color:$bg-blue-800;  
                                            border-bottom-right-radius: 0.3rem;
                                            border-top-right-radius: 0.3rem;
                
                                        }
                
                                    }
                
                                }
                
                                .toggle_price_input{
                                    .price_input{
                                        position: relative;
                                        .price_icon{
                                            position: absolute;
                                            top: 36%;
                                            left: 0.7rem;
                                            @media(min-width:$breakpoint-lg){
                                                top:41%;
                                                left: 1rem;
                                            }    
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
                }
            //attachments
            .featured_card{
                position: relative;
                .import_icon{
                    position: absolute;
                    top: 20px;
                    right: 27px;
                }
            }
            .url_input{
                position: relative;
                padding: 0rem 0rem;
                color:$text-blue-800;
                text-decoration: underline;
                ::placeholder{
                    text-decoration: none;
                }
            }
           
            .upload_notes{
                min-height: 10.143rem;
                background-color: #fff;
                border: 0.071rem solid $border-gray-500;
                border-radius:0.426rem;
                padding: 0.714rem;
            }
            // tootltip
            .tool::before {
                border-style: solid;
                border-width: 1em 0.75em 0 0.75em;
                border-color: $bg-gray-1000 transparent transparent transparent;
                bottom: 100%;
                content: "";
                margin-right: -50em;
                transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26), opacity 0.65s 0.5s;
                transform:rotate(0deg) translateY(-8.5rem) translateX(1.5rem);
                    @media(max-width:$breakpoint-md){
                        transform: rotate(270deg) translateY(4rem) translateX(-12.5rem); 
                    }
            }
            .tool::after{
                text-align:left;
                font-size:1.2rem;
                background: $bg-gray-1000;
                bottom: 94%;
                color: #EDEFF0;
                content: attr(data-tip);
                border-radius: 0.8rem;
                margin-left:-0.9em;
                padding: 1em;
                transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
                transform:scale(0.7) translateY(87%) translateX(-1.688rem);
                width: 30em;
                min-width:5.625rem;
                    @media(max-width:$breakpoint-md){
                        transform: scale(0.7) translateY(263%) translateX(-1.688rem);   
                    }
            }
            // tabs
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1rem 1rem;
                margin-right: 3rem;
                font-size: 1.1rem;
                font-weight: 600;
                color:$text-gray-1300;
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 
            //dropedownbox
            .dropedown_container.show{
            right: 0px;
            }
            .dropedown_container{
                .btn:focus {
                    outline: 0;
                    box-shadow:none;
                } 
            }
            .dropedown_icon{
                display: block;
                margin-bottom: 0.3rem;
            }
            .dropdown-menu{
                border-radius: 0.571rem;
                box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
            }
            .dropdown-item{
                padding:0.6rem 1rem;
            }
            .dropdown-menu.show{
                top: -33px !important;
                left: -133px !important;
            }
          
        
    }
} 