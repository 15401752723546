.approved_wrapper{
    .payment_profile_card {
            background-color: $bg-blue-200;
        }
    
        .total_details_card {
            background-color: $bg-blue-200;
    
            .amount_tag {
                min-height: 5rem;
            }
        }
    
        .rate_input {
            position: relative;
    
            .rate_icon {
                position: absolute;
                // top: 44%;
                top: 37%;
                right: 1.5rem;
    
                @media(max-width:$breakpoint-lg) {
                    top: 41%;
    
                }
    
                @media(max-width:$breakpoint-md) {
                    top: 38%;
                }
            }
        }
    
        .rate_input input::placeholder {
            padding: 1.429rem;
        }
    
        // ============= Modal Popup Height xsm =================
        .payment_milestone {
            // min-height: 550px;
            max-height: calc(100vh - 771px);
            overflow-y: auto;
        }
    
        .payment_milestone::-webkit-scrollbar {
            width: 0.313rem;
        }
    
        .payment_milestone::-webkit-scrollbar-track {
            background-color: $bg-white;
        }
    
        .payment_milestone::-webkit-scrollbar-thumb {
            border-radius: 0.375rem;
            background-color: $border-gray-400;
        }
}