.view_proposal_list{
    // margin-left: 19.5rem;
    // background-color: $bg-blue-200;
    // height: 100vh;
    .view_proposal_list_container{
        padding:2rem;
       
            .proposal_header{
                padding:1rem 1rem; 
                min-height: 4.9rem;
            }
      
            .proposal_date{
                .date_input{
                    position: relative; 
                    .date_icon{
                        position:absolute;
                        top: 37%;
                        right: 8%;
                    }

                }
            }
            
            .description{
                font-size: 0.857rem;
                font-weight: 600;
                line-height: 1.071rem;
                color:$text-gray-1300;
            }
          
            // tabs
            .cstm_tab{
                border-bottom: 0rem;
            }
            .cstm_tab .nav-link{
                border: 0rem;
                // border-bottom: 4px solid $bg-blue-800 ;
                padding: 1.3rem 1rem;
                margin-right: 3rem;
                font-size: 1.143rem;
                font-weight: 700;
                color:$text-gray-1300;
            }
            .cstm_tab .nav-link.active{
                border-bottom: 0.286rem solid $bg-blue-800 ; 
                color: $text-blue-800;
            } 

            .profile_imagecard{
                object-fit: contain;
                aspect-ratio: 4/3;
            }
            .profile_image{
                width: 160px;
            }
            // .info_box_item{
            //     width: 10rem;
            // }
            .card_icon {
                position: relative;
            }
            .tag_btn_tab_active{
                position: absolute;
                top: -0.7rem;
                right: -1rem;
            }
            .carousel-indicators .active{
                background-color: $bg-blue-800;
            }
            .carousel-indicators li{
                width: 7px;
                height: 8px;
                border-radius: 50%;
                background-color:$bg-gray-200;
                opacity: 1;
          
            }
            //dropedownbox
            .dropedown_container.show{
            right: 0px;
            }
            .dropedown_container{
                .btn:focus {
                    outline: 0;
                    box-shadow:none;
                } 
            }
            .dropedown_icon{
                display: block;
                margin-bottom: 0.3rem;
            }
            .dropdown-menu{
                border-radius: 0.571rem;
                box-shadow: 0px 10px 14px rgba($bg-black, 0.08);
            }
            .dropdown-item{
                padding:0.6rem 1rem;
            }
            .dropdown-menu.show{
                top: -33px !important;
                left: -133px !important;
            }
      
        
    }
} 