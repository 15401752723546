.projectcomplete_wrapper{
    .sucess_logo{
        max-width: 187px;
        height: 174px;
        margin: auto;
        img{
            width:100%;
            height:100%;
            object-fit: contain;
        }
    }
}
