.work_log_view_file_wrapper{
    .modal_top_header{
        padding: 1.5rem 2.7rem 1.5rem;
    }
    .view_document_wrapper{ 
        padding:1.5rem 0.10rem 1.5rem 1.5rem;
        background-color:$bg-blue-200;
        border-bottom-left-radius: 0.675rem;
        border-bottom-right-radius: 0.675rem;
    }
    .uploading-image{
        padding:0 1.5rem 0 0;
        background-color:$bg-blue-200;
    }
     // textarea
     .outer_textarea{
        padding-left:0rem;
        padding-right: 0rem;
    }
    .upload_notes{
        min-height: 3rem;
        background-color: #fff;
        border: 0.071rem solid $border-gray-600;
        border-radius:0.286rem;
        padding: 0.714rem;
        position: relative;
        .send_icon{
            position:absolute;
            top:0.8rem;
            right: 0;
        }
        textarea{
            // max-height: 4rem;
            color:rgba($color: #050421, $alpha: 0.4);
            // padding-left: 2.5rem;
            padding-right: 2.5rem;
            min-height: 3rem; 
            max-height:calc(100vh - 900px);
            overflow-y: auto;
            background-color: transparent;
        }
    }
    .upload_media_icons{
        padding:.375rem .75rem;
    }
    // commet_section
    .commet_section{
        // max-height: 550px;
        // min-height: 550px;
        height: calc(100vh - 381px);
        overflow-y: auto;
    }
    .commet_section::-webkit-scrollbar {
        width: 0.313rem;
    }
    .commet_section::-webkit-scrollbar-track {
        background-color :$bg-white;
    }
    .commet_section::-webkit-scrollbar-thumb {
        border-radius:0.375rem;
        background-color:$border-gray-400;
    }

   
}