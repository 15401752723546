// Select Project Type Modal CSS
.select_project_type{
    .modal-lg{
        max-width: 1050px;
        @media (max-width:$breakpoint-xxl) {
            max-width: 80vw;
        }
        @media(max-width:$breakpoint-xl){
            max-width: 90vw;
        }
        @media(max-width:$breakpoint-lg){
            max-width: 95vw;
        }
        @media(max-width:$breakpoint-sm){
            max-width: 100vw;
        }
    }
    .modal-dialog{ 
        .modal-content{
            border:none;
            border-radius: 0.429rem;
            .modal-header{
                align-items: center;
                padding-top: 2rem;
                padding-left: 3rem;
                padding-right:3rem;
                .close_btn{
                    opacity: 1.5;
                    .close_icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0.3rem 0.6rem;
                        border-radius: 0.426rem;
                        height: 100%;
                        color: $bg-blue-800;
                        background-color: $bg-blue-100;
                        font-size: 1.8rem;
                    }
                }
            }
            
            .modal-body{
                padding: 2rem;
                @media(max-width:$breakpoint-sm){
                        padding:0.3rem !important;
                }
                .project_type_cards{
                    position: relative;
                    margin: 1rem 0rem 1rem 0rem;
                    .card{
                        position:relative;
                        border: 0.133rem solid $text-gray-1000;
                        padding: 0.5rem 0.4rem;
                        border-radius: 0.482rem;
                        @media(max-width:$breakpoint-md){
                            padding: 2rem 1rem;
                            border-radius: 0.675rem;
                        }
                        .card-title{
                            font-weight: 700;
                            font-size: 1.143rem;
                            line-height: 1.429rem;
                            color:$text-black-700;
                            @media(max-width:$breakpoint-md){
                                    font-size: 1.4rem;
                                    line-height: 1.8rem;
                            }
                        }
                        .icon {
                            display: flex;
                            width: 5rem;
                            min-height: 5rem;
                            justify-content: center;
                            padding: 1rem;
                            align-items: center;  
                            border-radius: 0.804rem;
                            margin: 0rem auto 1rem;
                            @media(max-width:$breakpoint-md){
                                width: 6rem;
                                min-height: 6rem;
                            }
                        }
                        .card-text{
                            font-size: 1rem;
                            line-height: 1.571rem;
                            color:$text-gray-1100;
                            font-weight: 600;
                            // text-align: justify;
                            padding: 0 10px;
                            @media (max-width:$breakpoint-md){
                                font-size: 1.2rem;
                                line-height: 1.8rem;
                            }
                        } 
                        // ================== checkbox =============================
                        // .checkbox_icon{
                        //     position: absolute;
                        //     top:1rem;
                        //     right: 1rem;
        
                        //     input[type=checkbox] {
                        //         display: none;
                
                        //         + label {
                        //             display: inline-block;
                        //             position: relative;
                        //             padding: 0.9rem;
                        //             background-color: white;
                        //             border: 0.08rem solid $border-gray-400;
                        //             border-radius: 100%;
                        //             cursor: pointer;
                        //             @media (max-width:$breakpoint-md){
                        //                 padding: 1.2rem;
                        //                 border: 0.133rem solid $border-gray-400;
                        //             }
                        //         }
                                
                        //     }
                        //     input[type=checkbox]:checked + label {
                        //         background-color:$bg-blue-800;
                        //         color: #A4B7C6;
                        //     }
                            
                        //     input[type=checkbox]:checked + label:after {
                        //         position: absolute;
                        //         left: 0.571rem;
                        //         top: 0.214rem;
                        //         color: #fff; 
                        //         content: '\2714'; 
                        //         font-size:0.929rem;
                        //         @media (max-width: $breakpoint-md){
                        //             font-size: 1.6rem;
                        //             left:0.6rem;
                        //             top:0;
                        //         }
                        //     } 
        
                        // } 
                        // ===================end checkbox==================
                        
        
                    }

                    // Custom Input
                    .cstm_checkbox{
                        position: absolute;
                        top: 1rem;
                        right: 0rem;
                    }
                    .cstm_checkbox input{
                        padding:0;
                        height:initial;
                        width: initial;
                        display: none;
                        cursor:pointer;
                    }
                    .cstm_checkbox label{
                        position: relative;
                        cursor: pointer;
                        color:$text-black;
                        font-size:1.1rem;
                        margin-bottom:1rem;
                    }
                    .cstm_checkbox label:before{
                        content:'';
                        background-color: transparent;
                        border:0.1rem solid $text-gray-300;
                        padding:11px;
                        border-radius: 100%;
                        display:inline-block;
                        position: relative;
                        vertical-align: middle;
                        cursor: pointer;
                        margin-right: 15px; 
                        transition: background-color 0.3s ease-in-out;
                    }
                    .cstm_checkbox input:checked + label::after{
                        content:'';
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 8px;
                        width: 8px;
                        height: 12px;
                        border:solid $bg-white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);

                        @media(max-width:$breakpoint-md){
                            top:3px;
                        }
                    }
                    .cstm_checkbox input:checked + label::before{
                        content:'';
                        background-color: $bg-blue-800;
                        border-color: $bg-blue-800;
                    }
                }
            }

            .relevent_left_icon{
                position:absolute;
                top:0;
                left:0;
                i {
                    color:#9393ab;
                }
                .nav_link_btn:active i{
                    color:#F5F7FD ;
                }
            }
            .relevent_right_icon{
                position:absolute;
                top:0;
                right: 4px;
                i {
                    color:#9393ab;
                }
                .nav_link_btn:active i{
                    color:#F5F7FD ;
                }
            }
            .relevent_ul_wrapper{
                margin-left:1%;
                margin-right:3.5%;
            }
            
            .modal-footer{
                padding: 1.8rem 2.5rem;
                .btn_continue{
                    padding: 0.75rem 3rem;
                    font-weight: 700;
                }
            }
        }
    }
   
}

