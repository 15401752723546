// Font Size
.fs_9{
    font-size: 0.643rem;
}
.fs_10{
    font-size: 0.714rem;
}
.fs_11{
    font-size: 0.786rem;
}
.fs_12{
    font-size: 0.857rem;
}
.fs_13{
    font-size: 0.929rem;
}
.fs_14{
    font-size: 1rem;
}
.fs_15{
    font-size: 1.071rem;
}
.fs_16{
    font-size: 1.143rem;
}
.fs_17{
    font-size: 1.214rem;
}
.fs_18{
    font-size: 1.286rem;
}
.fs_19{
    font-size:1.357rem;
}
.fs_20{
    font-size: 1.429rem;
}
.fs_21{
    font-size: 1.5rem;
}
.fs_22{
    font-size: 1.571rem;
}
.fs_23{
    font-size: 1.643rem;
}
.fs_24{
    font-size: 1.714rem;
}
.fs_25{
    font-size: 1.786rem;
}
.fs_26{
    font-size: 1.857rem;
}
.fs_27{
    font-size: 1.929rem;
}
.fs_28{
    font-size: 8rem;
}
.fs_29{
    font-size: 2.071rem;
}